import { AnyAction } from 'redux';
import { all, takeEvery } from 'redux-saga/effects';
import { EErrorStatus } from 'common/models/Request.models';
import { communicationCompanies } from 'entities/Companies/Companies.communication';
import { communicationClientNotes } from 'entities/ClientNotes/ClientNotes.communication';
import { communicationMessages } from 'entities/Messages/Messages.communication';
import { clearAuth, communicationAuth } from 'entities/Auth/Auth.communication';
import { communicationUsers } from 'entities/User/Users.communication';
import { communicationSupport } from 'entities/Support/Support.communication';
import { communicationPayment } from 'entities/Payment/Payment.communication';
import { communicationGift } from 'entities/Gift/Gift.communication';
import { communicationInvitation } from 'entities/Invitations/Invitations.communication';
import { communicationContributors } from 'entities/Contributors/Contributors.communication';
import { communicationRequests } from 'entities/Requests/Requests.communication';
import { communicationGiftTransactions } from 'entities/GiftTransactions/GiftTransactions.communication';
import { communicationEmployees } from 'entities/Employees/Employees.communication';
import { communicationGiftRequests } from 'entities/GiftRequests/GiftRequests.communication';
import { communicationTransactions } from 'entities/Transactions/Transactions.communication';
import { communicationRequestExpenses } from 'entities/RequestExpenses/RequestExpenses.communication';
import { communicationCountries } from 'entities/Countries/Countries.communication';

function* errorWatcher() {
  yield takeEvery('*', function* logger(action: AnyAction) {
    if (action.type.match('FAIL')) {
      const status = action?.payload?.status;
      const message = action?.payload?.message;

      const cognitoValidationMessage = 'Incorrect username or password.';
      if (status === EErrorStatus.Unauthorized && message !== cognitoValidationMessage) {
        yield clearAuth();
      }

      // eslint-disable-next-line no-console
      console.log('ERROR', action.payload);
    }
  });
}

export function* rootSaga() {
  yield all([
    errorWatcher(),
    ...communicationGiftTransactions.sagas,
    ...communicationTransactions.sagas,
    ...communicationAuth.sagas,
    ...communicationSupport.sagas,
    ...communicationContributors.sagas,
    ...communicationInvitation.sagas,
    ...communicationPayment.sagas,
    ...communicationGift.sagas,
    ...communicationGiftRequests.sagas,
    ...communicationRequests.sagas,
    ...communicationRequestExpenses.sagas,
    ...communicationMessages.sagas,
    ...communicationClientNotes.sagas,
    ...communicationCompanies.sagas,
    ...communicationEmployees.sagas,
    ...communicationCountries.sagas,
    ...communicationUsers.sagas
  ]);
}
