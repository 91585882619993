import * as React from 'react';
import Icon from 'antd/es/icon';
import { Row, Col } from 'antd/es/grid';
import Form from 'antd/es/form';
import { FormComponentProps } from 'antd/es/form';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { InputForm } from 'common/components/InputForm';
import { IInvitationsFormItem } from 'entities/Invitations/Invintations.models';

interface IComponentProps {
  item?: IInvitationsFormItem;
  submit?: (values: IInvitationsFormItem) => void;
  remove?: (item: IInvitationsFormItem) => void;
}

type AllProps = FormComponentProps & IComponentProps;

const InviteCrudFormComponent = ({ submit, remove, form, item }: AllProps) => {
  const { t } = useTranslation();
  const { getFieldDecorator } = form;

  const handleSubmit = () => {
    form.validateFields((err, values) => {
      if (err) {
        return;
      }

      submit && submit(values);
      form.resetFields();
    });
  };

  return (
    <Form hideRequiredMark>
      <div className="contribution-form contribution-form contribution-form_line_full stepper-contribution">
        <Row gutter={15}>
          <Col md={12}>
            <InputForm
              getFieldDecorator={getFieldDecorator}
              fieldName="name"
              label={t('inviteFormLabelFirstName')}
              initialValue={item?.name}
              formItemProps={{ className: 'mb-0' }}
              onBlur={handleSubmit}
              required
            />
          </Col>

          <Col md={12}>
            <InputForm
              getFieldDecorator={getFieldDecorator}
              fieldName="email"
              label={t('inviteFormLabelEmail')}
              initialValue={item?.email}
              formItemProps={{ className: 'mb-0' }}
              onBlur={handleSubmit}
              rules={[
                {
                  type: 'email',
                  message: t('errorEmailNotEmail')
                }
              ]}
              required
            />
          </Col>
        </Row>
      </div>

      {remove && (
        <Link to="#" onClick={() => item && remove(item)} className="contributor-button">
          <Icon type="close" /> <span className="contributor-button__text">{t('includeFormCrudDelete')}</span>
        </Link>
      )}

      {!item && (
        <Link to="#" onClick={handleSubmit} className="contributor-button">
          <Icon type="plus" /> <span className="contributor-button__text">{t('includeFormCrudAdd')}</span>
        </Link>
      )}
    </Form>
  );
};

export const InviteCrudForm = Form.create<AllProps>()(InviteCrudFormComponent);
