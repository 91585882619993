import { Row, Col } from 'antd/es/grid';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import {
  ManagersTransactionsList,
  ManagersTransactionsMake,
  ManagersTransactionsGenerate
} from 'entities/GiftTransactions/components';
import { TransactionsHeader } from 'entities/Requests/components/TransactionsHeader';
import { BudgetCalculatorTable, BudgetCalculatorForm } from 'entities/Payment/components';

export const GiftRequestTransactions = () => {
  const { t } = useTranslation();

  return (
    <Row gutter={30}>
      <Col {...wrapperCol}>
        <TransactionsHeader className="mb-20">{t('calculator.header')}</TransactionsHeader>

        <BudgetCalculatorTable />

        <BudgetCalculatorForm />
      </Col>

      <Col {...wrapperCol}>
        <ManagersTransactionsList />

        <ManagersTransactionsMake />

        <ManagersTransactionsGenerate />
      </Col>
    </Row>
  );
};

const wrapperCol = { xs: 24, sm: 12 };
