import * as React from 'react';
import AntSelect from 'antd/es/select';
import { Row, Col } from 'antd/es/grid';
import { FormItem, Select, Input } from '@axmit/clp-library';
import { useTranslation } from 'react-i18next';
import { ERequestPriority, ERequestStatus, RequestOptions } from 'entities/Requests/Requests.models';

interface IComponentProps {
  disabled: boolean;
  status: ERequestStatus;
  priority: ERequestPriority;
  updatePriority: (value: ERequestPriority) => void;
}

export const RequestEditPageStatusInfo = ({ status, priority, updatePriority, disabled }: IComponentProps) => {
  const { t } = useTranslation();
  const wrapperCol = { xs: 24, sm: 12, lg: 24, xl: 12 };
  const statusLabel = status ? t(`request.statuses.${status}`) : '-';

  return (
    <Row gutter={30}>
      <Col {...wrapperCol}>
        <FormItem label={t('request.editForm.giftStatus')}>
          <Input disabled value={statusLabel} />
        </FormItem>
      </Col>

      <Col {...wrapperCol}>
        <FormItem label={t('request.editForm.requestPriority')}>
          <Select
            defaultValue={priority}
            width="sm"
            bordered="bottom"
            disabled={disabled}
            className="w-100p"
            onChange={value => {
              updatePriority(value as ERequestPriority);
            }}
          >
            {RequestOptions.priorities.map(({ value, label }) => (
              <AntSelect.Option key={value} value={value}>
                {label}
              </AntSelect.Option>
            ))}
          </Select>
        </FormItem>
      </Col>
    </Row>
  );
};
