import { StoreBranch } from '@axmit/redux-communications';
import { IUpdateParams } from 'common/models/Request.models';

function setOldData<Data, Params, Errors>(
  response: Data,
  payload: Params,
  branchState: StoreBranch<Data, Params, Errors>
): Data | undefined {
  return branchState.data || undefined;
}

function setOldDataForUpdating<Data, Params, Errors>(
  payload?: IUpdateParams<Params>,
  branchState?: StoreBranch<Data, Params, Errors>
): Data | undefined {
  return branchState?.data && payload ? { ...branchState.data, ...payload.data } : undefined;
}

export const RequestLoadingHelper = {
  setOldDataForUpdating,
  setOldData
};
