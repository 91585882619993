import { useSelector } from 'react-redux';
import { IApplicationState } from 'app/store/reducers';

interface IUseCurrent {
  currentUserId?: string;
}

function useCurrent(): IUseCurrent {
  const userId = useSelector<IApplicationState>(state => state?.users.current?.data?.id);

  return { currentUserId: userId ? String(userId) : undefined };
}

export const UsersHooks = {
  useCurrent
};
