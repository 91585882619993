import { APIProvider, BaseStrategy, Branch, buildCommunication, EActionsTypes, StoreBranch } from '@axmit/redux-communications';
import message from 'antd/es/message';
import { EErrorStatus, IUpdateParams } from 'common/models/Request.models';
import { RequestLoadingHelper, MessageHelper } from 'common/helpers';
import {
  IRequestCollection,
  IRequestCollectionParams,
  IRequestModel,
  IRequestUpdateParams
} from 'entities/Requests/Requests.models';
import { RequestsTransport } from 'entities/Requests/Requests.transport';

const namespace = 'requests';

export interface IRequestsConnectedProps {
  requestsModel: StoreBranch<IRequestModel>;
  getRequestsModel(id: string): IRequestModel;
  finalizeRequestsModel(id: string): IRequestModel;
  updateRequestsModel(params: IUpdateParams<IRequestUpdateParams>): IRequestModel;
  clearRequestsModel(): void;

  requestsAllCollection: StoreBranch<IRequestCollection, IRequestCollectionParams>;
  getRequestsAllCollection(params?: IRequestCollectionParams): IRequestCollection;
  clearRequestsAllCollection(): void;

  requestsInProgressCollection: StoreBranch<IRequestCollection, IRequestCollectionParams>;
  getRequestsInProgressCollection(params?: IRequestCollectionParams): IRequestCollection;
  clearRequestsInProgressCollection(): void;

  requestsNewCollection: StoreBranch<IRequestCollection, IRequestCollectionParams>;
  getRequestsNewCollection(params?: IRequestCollectionParams): IRequestCollection;
  clearRequestsNewCollection(): void;
}

const transport = new RequestsTransport('requests');

const modelApiProvider = [
  new APIProvider<IRequestModel, string>(EActionsTypes.get, transport.get, {
    preRequestDataMapper: RequestLoadingHelper.setOldData
  }),
  new APIProvider<IRequestModel, string>('finalize', transport.finalize, {
    preRequestDataMapper: RequestLoadingHelper.setOldData,
    onFail: response => {
      if (response?.message && response.status === EErrorStatus.Validation) {
        message.error(response.message);
      }
    }
  }),
  new APIProvider<IRequestModel, IUpdateParams<IRequestUpdateParams>>(EActionsTypes.update, transport.update, {
    preRequestDataMapper: RequestLoadingHelper.setOldData,
    onSuccess: MessageHelper.updateSuccess
  })
];

const newCollectionApiProvider = [
  new APIProvider<IRequestCollection, IRequestCollectionParams>(
    EActionsTypes.get,
    params => transport.getCollection({ ...params, status: 'new' }),
    {
      preRequestDataMapper: RequestLoadingHelper.setOldData
    }
  )
];
const inProgressCollectionApiProvider = [
  new APIProvider<IRequestCollection, IRequestCollectionParams>(
    EActionsTypes.get,
    params => transport.getCollection({ ...params, status: 'inProgress' }),
    {
      mapParams: (originalParams, branchState, fullState) => {
        // @ts-ignore reason: bad typing for fullState
        const userAssignee = fullState?.users?.current?.data?.id;

        return { ...branchState?.params, ...originalParams, userAssignee };
      },
      preRequestDataMapper: RequestLoadingHelper.setOldData
    }
  )
];
const allCollectionApiProvider = [
  new APIProvider<IRequestCollection, IRequestCollectionParams>(
    EActionsTypes.get,
    params => transport.getCollection({ ...params, statusIn: ['inProgress', 'new', 'finished'] }),
    {
      mapParams: (originalParams, branchState) => {
        return { ...branchState?.params, ...originalParams };
      },
      preRequestDataMapper: RequestLoadingHelper.setOldData
    }
  )
];

const branches = [
  new Branch('model', modelApiProvider),
  new Branch('inProgressCollection', inProgressCollectionApiProvider),
  new Branch('allCollection', allCollectionApiProvider),
  new Branch('newCollection', newCollectionApiProvider)
];

const strategy = new BaseStrategy({
  namespace,
  branches
});

export const communicationRequests = buildCommunication<IRequestsConnectedProps>(strategy);
