import { PaginationConfig } from 'antd/lib/pagination';

type GetListsPaginationType = (
  onChangePage: (limit: number, offset: number, page: number) => void,
  itemsPerPage: number,
  total?: number,
  current?: number
) => PaginationConfig | false;

const getListsPagination: GetListsPaginationType = (onChangePage, itemsPerPage, total, current) => {
  const currentPage = current ? { current } : {};

  return total && total > itemsPerPage
    ? {
        ...currentPage,
        className: 'pagination-light mt-10 mb-20 ta-r',
        size: 'small',
        onChange: page => onChangePage(itemsPerPage, itemsPerPage * (page - 1), page),
        pageSize: itemsPerPage,
        total
      }
    : false;
};

export const PaginationHelper = { getListsPagination };
