import * as React from 'react';
import uuidv1 from 'uuid';
import { InviteCrudForm } from 'entities/Invitations/components/index';
import { IInvitationsFormItem, INewGiftInvitationsItem } from 'entities/Invitations/Invintations.models';

interface IComponentProps {
  invites?: IInvitationsFormItem[];
  updateInvites: (invites: IInvitationsFormItem[]) => void;
}

export const InvitesListCrud = ({ invites = [], updateInvites }: IComponentProps) => {
  const submit = (values: IInvitationsFormItem, index: number) => {
    const prev = [...invites];

    prev[index] = values;
    updateInvites(prev);
  };

  const addNew = (values: INewGiftInvitationsItem) => {
    updateInvites([...invites, { ...values, id: uuidv1() }]);
  };

  const remove = (removed: IInvitationsFormItem) => {
    updateInvites(invites.filter(item => item.id !== removed.id));
  };

  return (
    <>
      {invites?.map((item, index) => (
        <InviteCrudForm item={item} submit={values => submit(values, index)} remove={remove} key={item.id} />
      ))}

      <InviteCrudForm submit={addNew} />
    </>
  );
};
