import React from 'react';
import { compose } from 'redux';
import message from 'antd/es/message';
import Typography from 'antd/es/typography';
import { useTranslation } from 'react-i18next';
import { LOCAL_STORAGE_NEW_GIFT } from 'common/const';
import { MIN_BUDGET_INDIVIDUAL_EUR } from 'common/config';
import i18n from 'app/locales/i18n';
import { communicationGift, IGiftConnectedProps } from 'entities/Gift';
import { GiftCheckoutForm } from 'entities/Payment/components';
import { communicationPayment, IPaymentConnectedProps } from 'entities/Payment/Payment.communication';

type AllProps = IPaymentConnectedProps & IGiftConnectedProps;

const AddMorePaymentFormComponent = (props: AllProps) => {
  const { giftModel, paymentCalculator } = props;
  const { data: gift } = giftModel;
  const { currentBalance = 0 } = gift || {};
  const isMailRequired = !gift?.isMailPaid && gift?.meta.isSendByMail;
  const { country } = gift?.meta || {};
  const { t } = useTranslation();

  const difActualAndMin: number = currentBalance
    ? +(MIN_BUDGET_INDIVIDUAL_EUR - currentBalance).toFixed(2)
    : MIN_BUDGET_INDIVIDUAL_EUR;
  const defaultAmount = difActualAndMin > 0 ? difActualAndMin : 0;

  return (
    gift && (
      <>
        <Typography.Title className="clp-typography-title clp-typography-title_size_xs mb-15" level={3}>
          {t('paymentFormHeader')}
        </Typography.Title>

        <GiftCheckoutForm
          calculateParams={{ amount: defaultAmount, country, isMailRequired }}
          giftId={gift.id}
          amountInit={defaultAmount}
          amountWithFee={paymentCalculator.data?.total}
          successCallback={() => {
            localStorage.removeItem(LOCAL_STORAGE_NEW_GIFT);
            props.clearGiftViewModel();
            props.clearGiftCreateModel();
            message.success(i18n.t('successUpdateDefault'), 1, () => {
              window.location.reload();
            });
          }}
        />
      </>
    )
  );
};

const containers = compose(communicationGift.injector, communicationPayment.injector);

export const AddMorePaymentForm = containers(AddMorePaymentFormComponent);
