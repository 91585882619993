import { HooksHelper } from '@axmit/clp-library/dist/helpers';
import { StoreBranch } from '@axmit/redux-communications';
import Pagination from 'antd/es/pagination';
import { Row, Col } from 'antd/es/grid';
import * as React from 'react';
import { useCallback, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Spinner } from 'common/components';
import { EBackOfficeRoutes } from 'common/const';
import { PaginationHelper } from 'common/helpers';
import { EOrderDirection } from 'common/models/Request.models';
import { GiftRequestsFilters } from 'entities/Requests/components/GiftRequestsFilters';
import { RequestsCard } from 'entities/Requests/components/RequestsCard';
import { REQUESTS_COL, REQUESTS_PER_PAGE } from 'entities/Requests/Request.consts';
import { ERequestsFilterBy, IRequestCollection, IRequestCollectionParams } from 'entities/Requests/Requests.models';

type AllProps = IComponentProps;

interface IComponentProps {
  getCollection: any;
  clearCollection: any;
  collection: StoreBranch<IRequestCollection, IRequestCollectionParams>;
}

export const GiftRequestsListFiltered = ({ getCollection, collection, clearCollection }: AllProps) => {
  const currentPage = HooksHelper.useStateBuilder<number>(1);
  const orderDirection = HooksHelper.useStateBuilder<EOrderDirection>(EOrderDirection.ASC);
  const ordering = HooksHelper.useStateBuilder<ERequestsFilterBy | undefined>(undefined);
  const search = HooksHelper.useStateBuilder<string>('');

  useEffect(() => {
    return () => {
      clearCollection();
    };
  }, [clearCollection]);

  const reloadCollection = useCallback(
    (newParams: IRequestCollectionParams) => {
      getCollection(newParams);
    },
    [getCollection]
  );

  useEffect(() => {
    reloadCollection({
      limit: REQUESTS_PER_PAGE,
      orderDirection: orderDirection.value,
      ordering: ordering.value,
      quickSearch: search.value
    });
  }, [orderDirection.value, ordering.value, search.value, reloadCollection]);

  const onChangePage = (limit: number, offset: number, page: number) => {
    currentPage.set(page);
    reloadCollection({
      limit,
      offset,
      orderDirection: orderDirection.value,
      ordering: ordering.value,
      quickSearch: search.value
    });
  };

  const changeFilter = (value?: any) => {
    currentPage.set(1);
    ordering.set(value);
  };

  const selectFilter = (value?: any) => {
    const { params } = collection;

    currentPage.set(1);

    params?.ordering === value &&
      orderDirection.set(orderDirection.value === EOrderDirection.ASC ? EOrderDirection.DESC : EOrderDirection.ASC);
  };

  const onSearch = (value: string) => {
    currentPage.set(1);
    search.set(value);
  };

  const data = collection?.data;
  const listPagination = PaginationHelper.getListsPagination(
    onChangePage,
    REQUESTS_PER_PAGE,
    data?.meta.count,
    currentPage.value
  );

  return (
    <>
      <GiftRequestsFilters
        onSearch={onSearch}
        selectFilter={selectFilter}
        changeFilter={changeFilter}
        orderDirection={orderDirection.value}
        ordering={ordering.value}
      />

      <Spinner spinning={collection?.loading}>
        <Row gutter={16} type="flex">
          {data?.data.map(request => (
            <Col {...REQUESTS_COL} key={request.id}>
              <Link to={`${EBackOfficeRoutes.RequestEditPage}/${request.id}`}>
                <RequestsCard request={request} />
              </Link>
            </Col>
          ))}
        </Row>

        {listPagination && <Pagination size="small" {...listPagination} />}
      </Spinner>
    </>
  );
};
