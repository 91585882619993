import * as React from 'react';
import { FormItem, Checkbox } from '@axmit/clp-library';
import { Trans } from 'react-i18next';
import { AppLandingHelper } from '@axmit/router-app-landing/index';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { Link } from 'react-router-dom';
import { ELandingRoutes } from 'common/const';

interface IComponentProps {
  onChange: (e: CheckboxChangeEvent) => void;
}

export const AgreePrivacyCheckbox = ({ onChange }: IComponentProps) => {
  return (
    <FormItem>
      <Checkbox onChange={onChange}>
        <Trans i18nKey="contributorForm.agreeText">
          I agree with the Clé Privée
          <Link
            to="#"
            onClick={() => AppLandingHelper.toggleRoute(ELandingRoutes.TOC)}
            className="pr-5 pl-5 terms-and-gdrp-links"
          >
            terms and conditions
          </Link>
        </Trans>
      </Checkbox>
    </FormItem>
  );
};
