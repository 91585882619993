import * as React from 'react';
import { FormItem, Checkbox } from '@axmit/clp-library';
import { Trans } from 'react-i18next';
import { AppLandingHelper } from '@axmit/router-app-landing/index';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { ELandingRoutes } from 'common/const';

interface IComponentProps {
  onChange: (e: CheckboxChangeEvent) => void;
}

export const AgreeTocCheckbox = ({ onChange }: IComponentProps) => {
  return (
    <FormItem>
      <Checkbox onChange={onChange}>
        <Trans i18nKey="signupAgreeWithTac">
          I understand and agree with the Clé Privée
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={AppLandingHelper.getLink(ELandingRoutes.PrivacyPolicy)}
            className="pr-5 pl-5 terms-and-gdrp-links"
          >
            Privacy Policy
          </a>
          and that Clé Privée will collect only my personal information that is necessary for the purposes of managing any order
          that I make, and will not share any of this information with any other third parties.
        </Trans>
      </Checkbox>
    </FormItem>
  );
};
