import React, { FormEvent } from 'react';
import Form from 'antd/es/form';
import Spin from 'antd/es/spin';
import { FormComponentProps } from 'antd/es/form';
import { Button, Checkbox, FormItem, Paragraph } from '@axmit/clp-library';
import { AntdFormHelper } from '@axmit/antd-helpers';
import { useTranslation } from 'react-i18next';
import { InputForm } from 'common/components';
import { FormatHelper } from 'common/helpers';
import { CheckboxHooks } from 'common/hooks';
import { IUpdateParams } from 'common/models/Request.models';
import { EmailValidationRules } from 'entities/Auth/Auth.consts';
import { IUserManagerCreateParams, IUserModel, IUserModelUpdateParams } from 'entities/User/Users.models';
import { EUserRoles } from 'entities/User/Users.consts';

interface IComponentProps {
  submit: (data: IUserManagerCreateParams) => void;
  model?: IUserModel | null;
  params?: IUpdateParams<IUserModelUpdateParams> | null;
  toggleArchive?: () => void;
  loading?: boolean;
}

type AllProps = FormComponentProps & IComponentProps;

const ManagersFormComponent = ({ form, submit, model, toggleArchive, loading }: AllProps) => {
  const { t } = useTranslation();
  const { getFieldDecorator } = form;
  const date = FormatHelper.getDateWithYear(model?.createdAt || new Date());
  const isAdmin = CheckboxHooks.useCheckbox(!!model && model.role === EUserRoles.Administrator);
  const isDeleted = model?.isDeleted;

  const submitForm = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    form.validateFields((err, values) => {
      if (err) {
        return;
      }

      submit({ ...values, role: isAdmin.value ? EUserRoles.Administrator : EUserRoles.Manager });
    });
  };

  return (
    <Spin spinning={loading}>
      <Form layout="vertical" onSubmit={submitForm}>
        <Paragraph size="sm" className="mb-25">
          {t('managers.signUpDate')} <b>{date}</b>
        </Paragraph>

        <InputForm
          inputProps={{ disabled: isDeleted }}
          formItemProps={{ wrapperCol }}
          getFieldDecorator={getFieldDecorator}
          fieldName="firstName"
          label={t('form.labels.firstName')}
          required
        />

        <InputForm
          inputProps={{ disabled: isDeleted }}
          formItemProps={{ wrapperCol }}
          getFieldDecorator={getFieldDecorator}
          fieldName="lastName"
          label={t('form.labels.lastName')}
          required
        />

        <InputForm
          inputProps={{ disabled: !!model || isDeleted }}
          formItemProps={{ wrapperCol }}
          getFieldDecorator={getFieldDecorator}
          fieldName="email"
          label={t('form.labels.email')}
          rules={EmailValidationRules}
        />

        <FormItem label={t('form.labels.roles')}>
          <Checkbox className="mt-10" defaultChecked={isAdmin.value} onChange={isAdmin.set} disabled={isDeleted}>
            {t('managers.checkboxForRoles')}
          </Checkbox>
        </FormItem>

        <Button type="primary" htmlType="submit" className="mr-20 ant-btn_mob-block" disabled={isDeleted}>
          {t(model ? 'btnSave' : 'managers.addNewBtn')}
        </Button>

        {toggleArchive && (
          <Button onClick={toggleArchive} className="ant-btn_mob-block mt-10" disabled={!toggleArchive}>
            {t(isDeleted ? 'btnUnArchive' : 'btnArchive')}
          </Button>
        )}
      </Form>
    </Spin>
  );
};

const wrapperCol = { xs: 24, sm: 12, xl: 6 };

export const ManagersForm = Form.create<AllProps>({
  mapPropsToFields({ model, params, loading }) {
    if (loading && params?.data) {
      return AntdFormHelper.mapValidationToFields({ ...params.data, email: model?.email });
    }

    return model && AntdFormHelper.mapValidationToFields(model);
  }
})(ManagersFormComponent);
