import Typography from 'antd/es/typography';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { EmptyData } from 'common/components';
import { DateHelper } from 'common/helpers';
import { IGiftRequestModel } from 'entities/Requests/Requests.models';

interface IComponentProps {
  number?: string;
  request?: IGiftRequestModel | null;
}

export const RequestDetails = ({ request, number = '-' }: IComponentProps) => {
  const { t } = useTranslation();
  if (!request) {
    return <EmptyData />;
  }

  const {
    meta: { description },
    createdAt,
    deliveryDate
  } = request;

  return (
    <>
      <Typography.Title className="clp-typography-title clp-typography-title_size_xs mb-30" level={3}>
        {t('details')}
      </Typography.Title>

      <div className="mb-15">
        <b className="color_light opacity_light">{t('dueDate')}: </b>
        <b className="color_primary">{DateHelper.getDateWithYear(deliveryDate)}</b>
      </div>

      <div className="mb-15">
        <b className="color_light opacity_light">{t('created')}: </b>
        <b className="color_primary"> {DateHelper.getDateWithYear(createdAt)}</b>
        <b className="color_default"> {DateHelper.getTime(createdAt)}</b>
      </div>

      <div className="mb-30">
        <b className="color_light opacity_light">{t('idNumber')}: </b>
        <b className="color_primary">{number}</b>
      </div>

      {description && (
        <>
          <Typography.Title className="clp-typography-title clp-typography-title_size_xs mb-30" level={3}>
            {t('description')}
          </Typography.Title>

          <Typography.Text className="clp-typography-text clp-typography-text_size_md mb-30 break-word">
            {description}
          </Typography.Text>
        </>
      )}
    </>
  );
};
