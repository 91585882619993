import {
  EActionsTypes,
  APIProvider,
  BaseStrategy,
  Branch,
  buildCommunication,
  getStartType,
  StoreBranch
} from '@axmit/redux-communications';
import { put } from '@redux-saga/core/effects';
import { BaseTransport } from 'common/dto/base.transport';
import { RequestLoadingHelper } from 'common/helpers';
import {
  IInvitationCollection,
  IInvitationCollectionParams,
  IInvitationModel,
  IInvitationModelParams
} from 'entities/Invitations/Invintations.models';

const namespace = 'invitations';

export interface IInvitationConnectedProps {
  invitationsModel: StoreBranch<IInvitationModel>;
  addInvitationsModel(params: IInvitationModelParams): void;

  invitationsCollection: StoreBranch<IInvitationCollection>;
  getInvitationsCollection(params: IInvitationCollectionParams): void;
}

const transport = new BaseTransport('gift-invitations');

const modelApiProvider = [
  new APIProvider<IInvitationModel, IInvitationModelParams>(EActionsTypes.add, transport.add, {
    onSuccess: function*(response) {
      yield put({ type: getStartType(namespace, 'collection', EActionsTypes.get), payload: { gift: response.gift } });
    }
  })
];

const collectionApiProvider = [
  new APIProvider(EActionsTypes.get, transport.getCollection, {
    preRequestDataMapper: RequestLoadingHelper.setOldData
  })
];

const branches = [new Branch('model', modelApiProvider), new Branch('collection', collectionApiProvider)];

const strategy = new BaseStrategy({
  namespace,
  branches
});

export const communicationInvitation = buildCommunication<IInvitationConnectedProps>(strategy);
