import React from 'react';
import { Container } from '@axmit/clp-library';
import { Desktop, Mobile } from 'common/components';
import { DashboardDesktop } from 'app/pages/DashboardDesktop';
import { DashboardMobile } from 'app/pages/DashboardMobile';

const DashboardComponent = () => {
  return (
    <Container>
      <Desktop>
        <DashboardDesktop />
      </Desktop>

      <Mobile>
        <DashboardMobile />
      </Mobile>
    </Container>
  );
};

export const Dashboard = DashboardComponent;
