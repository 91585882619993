import { Button, FormItem, PhoneInput } from '@axmit/clp-library';
import { HooksHelper } from '@axmit/clp-library/dist/helpers';
import Radio from 'antd/es/radio';
import Typography from 'antd/es/typography';
import Spin from 'antd/es/spin';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { DateHelper, ValidateHelper } from 'common/helpers';
import { PhoneHooks } from 'common/hooks';
import { DateTimeSplitPicker } from 'common/components/DateTimeSlitPicker';
import { communicationGift, IGiftConnectedProps } from 'entities/Gift/Gift.communication';
import { contactTypes, EContactDateType, EContactType } from 'entities/Gift/Gift.consts';
import { communicationUsers, IUsersConnectedProps } from 'entities/User/Users.communication';

interface IComponentProps {
  showForm: (show: boolean) => void;
  giftId?: string;
}

type AllProps = IUsersConnectedProps & IGiftConnectedProps & IComponentProps;

const ContactFormComponent = ({ usersCurrent, discoverGiftModel, giftId, showForm, giftModel }: AllProps) => {
  const { t } = useTranslation();
  const dateType = HooksHelper.useStateBuilder(giftModel.data?.contactDate ? EContactDateType.other : EContactDateType.now);
  const contactType = HooksHelper.useStateBuilder(giftModel.data?.contactType ? giftModel.data?.contactType : EContactType.email);

  const dateValidateMessage = HooksHelper.useStateBuilder('');
  const dateValidateStatus: 'error' | '' = ValidateHelper.getValidateStatus(dateValidateMessage.value);

  const date = HooksHelper.useStateBuilder(
    giftModel.data?.contactDate ? moment(giftModel.data?.contactDate) : DateHelper.getNextDay()
  );

  const { phone, phoneValidateMessage, validatePhone, changePhone, phoneValidateStatus } = PhoneHooks.usePhone(
    usersCurrent.data?.phoneNumber || undefined
  );

  const submit = async () => {
    const resultDate = dateType.value === EContactDateType.now ? DateHelper.getNextDay() : date.value;

    if (DateHelper.isTodayOrBefore(resultDate)) {
      dateValidateMessage.set(t('errorContactDate'));

      return;
    }

    const resource =
      contactType.value === EContactType.email ? usersCurrent.data?.email : phone || usersCurrent.data?.phoneNumber;

    giftId &&
      (await discoverGiftModel({
        id: giftId,
        contactDate: resultDate.toISOString(),
        contactDetail: resource || '',
        contactType: contactType.value
      }));

    showForm(false);
  };

  const isVisiblePhone = contactType.value === EContactType.phone || contactType.value === EContactType.whatsApp;

  return (
    <Spin spinning={false}>
      <Typography.Title className="clp-typography-title clp-typography-title_size_md mob-text_center mb-25" level={3}>
        {t('discoveredGift.form.header')}
      </Typography.Title>

      <div className="description mb-20">{t('discoveredGift.form.dateType.header')}</div>

      <Radio.Group onChange={e => dateType.set(e.target.value)} value={dateType.value} className="radio-group">
        <Radio value={EContactDateType.now}>{t('discoveredGift.form.dateType.soon.header')}</Radio>

        <Radio value="other">
          {t('discoveredGift.form.dateType.other.header')}

          <br />

          <FormItem validateStatus={dateValidateStatus} help={dateValidateMessage.value}>
            <DateTimeSplitPicker
              date={date.value}
              dateClassName="mt-15 ml-25 mr-15"
              disabled={dateType.value !== 'other'}
              disabledDate={DateHelper.isTodayOrBefore}
              minMobDate={DateHelper.getTomorrow().toDate()}
              onChange={value => value && date.set(value)}
            />
          </FormItem>
        </Radio>
      </Radio.Group>

      <div className="description mb-20">{t('discoveredGift.form.serviceType.header')}</div>

      <Radio.Group
        onChange={e => {
          contactType.set(e.target.value);
        }}
        value={contactType.value}
        className="radio-group"
      >
        {contactTypes.map(({ value, label }) => (
          <Radio key={value} value={value}>
            {label}
          </Radio>
        ))}
      </Radio.Group>

      {isVisiblePhone && (
        <FormItem
          label={t('discoveredGift.form.serviceType.phone.label')}
          className="mob-form-wrapper"
          validateStatus={phoneValidateStatus}
          help={phoneValidateMessage}
        >
          <PhoneInput validateTrigger={validatePhone} onChange={changePhone} defaultValue={phone} />
        </FormItem>
      )}

      <br />
      <Button type="primary" onClick={submit} className="ant-btn_mob-block mb-30">
        {t('discoveredGift.form.submit')}
      </Button>
    </Spin>
  );
};

export const ContactForm = communicationUsers.injector(communicationGift.injector(ContactFormComponent));
