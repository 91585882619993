import * as React from 'react';
import Layout from 'antd/es/layout';
import { Row, Col } from 'antd/es/grid';
import { Container } from '@axmit/clp-library';

interface IComponentProps {
  leftSlot?: JSX.Element | string;
  rightSlot?: JSX.Element | string;
}

export const Header = ({ leftSlot, rightSlot }: IComponentProps) => {
  return (
    <Layout.Header className="clp-header">
      <Container>
        <Row align="middle" type="flex">
          <Col xl={8} lg={9} md={12} xs={21}>
            {leftSlot}
          </Col>

          <Col xl={16} lg={15} md={12} xs={3}>
            {rightSlot}
          </Col>
        </Row>
      </Container>
    </Layout.Header>
  );
};
