import { APIProvider, BaseStrategy, Branch, buildCommunication, EActionsTypes, StoreBranch } from '@axmit/redux-communications';
import message from 'antd/es/message';
import { put } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { BaseTransport } from 'common/dto/base.transport';
import { CookiesHelper, RequestLoadingHelper, MessageHelper } from 'common/helpers';
import { COOKIES_NAME, COOKIES_ROLE, EPublicRoutes } from 'common/const';
import { IErrorResponse, IUpdateParams } from 'common/models/Request.models';
import i18n from 'app/locales/i18n';
import {
  IUserCollection,
  IUserCollectionParams,
  IUserManagerCreateParams,
  IUserModel,
  IUserModelParams,
  IUserModelUpdateParams
} from 'entities/User/Users.models';
import { UsersTransport } from 'entities/User/Users.transport';
import { IEmployeesBalance } from 'entities/Companies/Companies.models';
import { EUserKeysErrors } from 'entities/User/Users.consts';

const namespace = 'users';

export interface IUsersConnectedProps {
  usersModel: StoreBranch<IUserModel>;
  getUsersModel(id: string): void;
  addUsersModel(params: IUserModelParams): void;
  updateUsersModel(params: IUpdateParams<IUserModelUpdateParams>): void;
  deleteUsersModel(id: string): void;
  recoverUsersModel(id: string): void;
  clearUsersModel(): void;

  usersCurrent: StoreBranch<IUserModel>;
  getUsersCurrent(id: string): void;
  updateUsersCurrent(params: IUpdateParams<IUserModelUpdateParams>): void;

  usersCollection: StoreBranch<IUserCollection>;
  getUsersCollection(params: IUserCollectionParams): void;
  clearUsersCollection(): void;

  usersManager: StoreBranch<IUserModel>;
  addUsersManager(params: IUserManagerCreateParams): void;

  usersBalance: StoreBranch<IEmployeesBalance>;
  getUsersBalance(id: string): void;
  clearUsersBalance(): void;
}

const transport = new UsersTransport(namespace);

const modelApiProvider = [
  new APIProvider(EActionsTypes.get, transport.get),
  new APIProvider(EActionsTypes.update, transport.update, {
    preRequestDataMapper: RequestLoadingHelper.setOldData,
    onSuccess: MessageHelper.updateSuccess
  }),
  new APIProvider(EActionsTypes.add, transport.add, {
    onSuccess: function*(response, originalParams) {
      message.success(i18n.t('signUpSuccessfullyCreated'));
      yield put(push(EPublicRoutes.GetConfirm, { email: originalParams?.email }));
    }
  }),
  new APIProvider('recover', transport.recover, {
    preRequestDataMapper: RequestLoadingHelper.setOldData
  }),
  new APIProvider(EActionsTypes.delete, transport.delete, {
    preRequestDataMapper: RequestLoadingHelper.setOldData,
    onFail: function(response) {
      if (response?.errorCode === EUserKeysErrors.HasAssignedRequests) {
        message.error(i18n.t(EUserKeysErrors.HasAssignedRequests));
      }
    },
    onSuccess: function() {
      message.success(i18n.t('successArchiveDefault'));
    }
  })
];

const currentApiProvider = [
  new APIProvider<IUserModel>(EActionsTypes.get, transport.get, {
    onSuccess: response => {
      response?.firstName && CookiesHelper.setCookies(COOKIES_NAME, response.firstName);
      response?.role && CookiesHelper.setCookies(COOKIES_ROLE, response.role);
    }
  }),
  new APIProvider(EActionsTypes.update, transport.update, {
    preRequestDataMapper: (response, payload, branchState) => {
      return RequestLoadingHelper.setOldDataForUpdating(payload, branchState);
    },
    onSuccess: MessageHelper.updateSuccess
  })
];

const collectionApiProvider = [
  new APIProvider(EActionsTypes.get, transport.getCollection, {
    mapParams: (originalParams, branchState) => {
      return { ...branchState?.params, ...originalParams };
    },
    preRequestDataMapper: RequestLoadingHelper.setOldData
  })
];

const balanceApiProvider = [new APIProvider<IEmployeesBalance, string>(EActionsTypes.get, transport.getUsersBalance)];

const managerTransport = new BaseTransport<IUserModel, null, IUserManagerCreateParams>(`${namespace}/create-manually`);

const managerApiProvider = [
  new APIProvider<IUserModel, IUserManagerCreateParams, IErrorResponse>(EActionsTypes.add, managerTransport.add, {
    onSuccess: MessageHelper.createSuccess
  })
];

const branches = [
  new Branch('model', modelApiProvider),
  new Branch('manager', managerApiProvider),
  new Branch('current', currentApiProvider),
  new Branch('balance', balanceApiProvider),
  new Branch('collection', collectionApiProvider)
];

const strategy = new BaseStrategy({
  namespace,
  branches
});

export const communicationUsers = buildCommunication<IUsersConnectedProps>(strategy);
