import React from 'react';
import Typography from 'antd/es/typography';
import { Row, Col } from 'antd/es/grid';
import { useTranslation } from 'react-i18next';
import { communicationGift, IGiftConnectedProps } from 'entities/Gift/Gift.communication';
import { InviteForm } from 'entities/Invitations/components/InviteForm';
import { InviteList } from 'entities/Invitations/components/InviteList';
import { ContributorsList } from 'entities/Contributors/components';

interface IComponentProps {
  disabled?: boolean;
}

type AllProps = IGiftConnectedProps & IComponentProps;

const GiftFormContributorsComponent = ({ giftModel, disabled }: AllProps) => {
  const { t } = useTranslation();
  const { data: gift } = giftModel;

  if (!gift) {
    return null;
  }

  return (
    <Row className="mt-15">
      <ContributorsList giftId={gift.id} />

      <Typography.Title className="clp-typography-title clp-typography-title_size_xs mb-15" level={3}>
        {t('inviteFormHeader')}
      </Typography.Title>

      <Col>
        <InviteForm giftId={gift.id} disabled={disabled} />
        <InviteList giftId={gift.id} />
      </Col>
    </Row>
  );
};

export const GiftFormContributors = communicationGift.injector(GiftFormContributorsComponent);
