import {
  EActionsTypes,
  APIProvider,
  BaseStrategy,
  Branch,
  buildCommunication,
  getStartType,
  StoreBranch
} from '@axmit/redux-communications';
import { put } from '@redux-saga/core/effects';
import { push } from 'connected-react-router';
import { getCreds } from '@axmit/axios-patch-jwt';
import { ECommonRoutes, EPrivateRoutes, EPublicRoutes } from 'common/const/Routes.const';
import { RequestLoadingHelper, MessageHelper } from 'common/helpers';
import { LOCAL_STORAGE_NEW_GIFT, LOCAL_STORAGE_NEW_GIFT_CLOSED } from 'common/const';
import i18n from 'app/locales/i18n';
import {
  FRIENDS_GIFTS_PER_PAGE,
  GiftMapper,
  IAcceptGiftParams,
  ICheckCodeParams,
  IDiscoverGiftParams,
  IGiftCollection,
  IGiftCollectionParams,
  IGiftCreateModel,
  IGiftCreateViewModel,
  IGiftModel,
  IGiftPublicModel,
  IGiftPublicParams,
  IUpdateGiftParams,
  IRefundGiftParams
} from 'entities/Gift';
import { GiftTransport } from 'entities/Gift/Gift.transport';

const namespace = 'gift';

export interface IGiftConnectedProps {
  giftModel: StoreBranch<IGiftModel>;
  getGiftModel(id: string): void;
  clearGiftModel(): void;
  discoverGiftModel(params: IDiscoverGiftParams): void;
  acceptGiftModel(params: IAcceptGiftParams): void;
  updateGiftModel({ id, data }: IUpdateGiftParams): void;
  refundGiftModel(params: IRefundGiftParams): void;

  giftFriendsCollection: StoreBranch<IGiftCollection>;
  getGiftFriendsCollection(params: IGiftCollectionParams): IGiftCollection;

  giftMyCollection: StoreBranch<IGiftCollection>;
  getGiftMyCollection(params: IGiftCollectionParams): IGiftCollection;

  giftCreateModel: StoreBranch<IGiftCreateModel>;
  addGiftCreateModel(params: IGiftCreateViewModel): void;
  clearGiftCreateModel(): void;

  giftViewModel: StoreBranch<IGiftCreateViewModel>;
  addGiftViewModel(params: IGiftCreateViewModel): void;
  clearGiftViewModel(): void;

  giftCode: StoreBranch<IGiftModel>;
  checkGiftCode(params: ICheckCodeParams): void;
  clearGiftCode(): void;

  giftPublicModel: StoreBranch<IGiftPublicModel>;
  getGiftPublicModel(params: IGiftPublicParams): void;
}

const transport = new GiftTransport('gifts');

const createModelApiProvider = [
  new APIProvider(EActionsTypes.add, transport.add, {
    clearParams: true,
    mapParams: originalParams => originalParams && GiftMapper.mapViewModelToParams(originalParams),
    onFail: function*() {
      yield put(push(ECommonRoutes.NewGift, { hideNewOrEditChoice: true }));
    },
    onSuccess: function*(response: IGiftModel, payload) {
      const currency = payload?.currency || 0;
      const isNeedPayment = payload ? currency || !payload.isSendByEmail : false;

      yield put({ type: getStartType(namespace, 'viewModel', EActionsTypes.clear) });

      yield localStorage.removeItem(LOCAL_STORAGE_NEW_GIFT);
      yield localStorage.removeItem(LOCAL_STORAGE_NEW_GIFT_CLOSED);

      if (isNeedPayment) {
        yield put(push(EPrivateRoutes.GiftPayment, { currency }));
      } else {
        yield put(push(ECommonRoutes.Finalize));
      }
    }
  })
];

const modelApiProvider = [
  new APIProvider(EActionsTypes.get, transport.get, {
    preRequestDataMapper: RequestLoadingHelper.setOldData
  }),
  new APIProvider('discover', transport.discover),
  new APIProvider<IGiftModel, IRefundGiftParams>('refund', transport.refund, {
    onSuccess: (response, payload) => {
      MessageHelper.updateSuccess();
      payload?.successCallback && payload.successCallback();
    }
  }),
  new APIProvider('accept', transport.accept, {
    onSuccess: function*(response) {
      yield put(push(`${EPrivateRoutes.GiftViewPage}/${response.id}`));
    }
  }),
  new APIProvider(EActionsTypes.update, transport.update, {
    preRequestDataMapper: RequestLoadingHelper.setOldData,
    onSuccess: MessageHelper.updateSuccess
  })
];

const codeApiProvider = [
  new APIProvider('check', transport.checkCode, {
    onSuccess: function*(response) {
      const authModelData = yield getCreds();

      if (!authModelData.access) {
        yield put(push(EPublicRoutes.SignUp, { message: i18n.t('signUpMessageAfterAcceptGift') }));
      } else {
        yield put({ type: getStartType(namespace, 'model', 'accept'), payload: { id: response.id } });
      }
    }
  })
];

const collectionApiProvider = [
  new APIProvider(
    EActionsTypes.get,
    payload => transport.getCollection({ showCurrentBalance: true, limit: FRIENDS_GIFTS_PER_PAGE, ...payload }),
    {
      preRequestDataMapper: RequestLoadingHelper.setOldData
    }
  )
];

const publicModelApiProvider = [new APIProvider(EActionsTypes.get, transport.getPublic)];

const createViewModelApiProvider = [new APIProvider(EActionsTypes.add, payload => payload)];

const branches = [
  new Branch('model', modelApiProvider),
  new Branch('code', codeApiProvider),
  new Branch('publicModel', publicModelApiProvider),
  new Branch('createModel', createModelApiProvider),
  new Branch('friendsCollection', collectionApiProvider),
  new Branch('viewModel', createViewModelApiProvider),
  new Branch('myCollection', collectionApiProvider)
];

const strategy = new BaseStrategy({
  namespace,
  branches
});

export const communicationGift = buildCommunication<IGiftConnectedProps>(strategy);
