import { ColumnProps } from 'antd/lib/table';
import moment from 'moment';
import i18n from 'i18next';
import { TableFormatHelper, FormatHelper, DateHelper } from 'common/helpers';
import { BE_DATE_FORMAT_DEFAULT, DATE_FORMAT_DEFAULT } from 'common/config';
import { ISelectorOption } from 'common/models/Selector.models';
import { ICompanyModel, ICompanyTransferHistoryModel } from 'entities/Companies/Companies.models';
import { IEmployeesModel } from 'entities/Employees/Employees.models';

export const COMPANIES_PER_PAGE = 8;

enum ECompaniesColumns {
  name = 'name',
  phoneNumber = 'userPhone',
  email = 'userEmail',
  registrationDate = 'createdAt'
}

export const companiesColumns: ColumnProps<ICompanyModel>[] = [
  {
    dataIndex: ECompaniesColumns.name,
    title: 'companyName'
  },
  { dataIndex: ECompaniesColumns.phoneNumber, title: 'form.labels.phoneNumber' },
  { dataIndex: ECompaniesColumns.email, title: 'form.labels.email' },
  {
    dataIndex: ECompaniesColumns.registrationDate,
    title: 'form.labels.createdAt',
    render: TableFormatHelper.formatDate
  }
];

export const EMPLOYEES_PER_PAGE = 5;

enum EEmployeesColumns {
  Name = 'name',
  Amount = 'subscriptionPlanAmount',
  Date = 'nextTransferDate',
  Balance = 'balance',
  Received = 'amountReceived',
  Requests = 'requestCount',
  Plan = 'subscriptionPlanName'
}

export const employeesCompanyColumns: ColumnProps<IEmployeesModel>[] = [
  {
    dataIndex: EEmployeesColumns.Name,
    title: `employeesPage.table.columns.name`,
    render: TableFormatHelper.formatNameWithDot
  },
  {
    dataIndex: EEmployeesColumns.Amount,
    title: `employeesPage.table.columns.amount`,
    render: value => (value ? FormatHelper.getCurrency(value) : '-')
  },
  {
    dataIndex: EEmployeesColumns.Date,
    title: `employeesPage.table.columns.date`,
    render: (value, model) => {
      if (!model.subscriptionPlan || !value) {
        return '-';
      }

      return moment(value, BE_DATE_FORMAT_DEFAULT).format(DATE_FORMAT_DEFAULT);
    }
  },
  {
    dataIndex: EEmployeesColumns.Balance,
    title: `employeesPage.table.columns.balance`,
    render: FormatHelper.getCurrency
  },
  {
    dataIndex: EEmployeesColumns.Received,
    title: `employeesPage.table.columns.received`,
    render: FormatHelper.getCurrency
  },
  {
    dataIndex: EEmployeesColumns.Requests,
    title: `employeesPage.table.columns.requests`
  },
  {
    dataIndex: EEmployeesColumns.Plan,
    title: `employeesPage.table.columns.plan`,
    render: value => value || '-'
  }
];

enum ETransferHistoryColumns {
  createdAt = 'createdAt',
  type = 'type',
  amount = 'amount',
  employee = 'employee'
}

export enum ETransferHistoryTypes {
  Payment = 'payment',
  Transfer = 'transfer'
}

export const transferHistoryColumns: ColumnProps<ICompanyTransferHistoryModel>[] = [
  {
    dataIndex: ETransferHistoryColumns.createdAt,
    title: `accountPage.historyTable.columns.date`,
    render: DateHelper.getDateWithYear
  },
  {
    dataIndex: ETransferHistoryColumns.type,
    title: `accountPage.historyTable.columns.type`,
    render: value => {
      return value === ETransferHistoryTypes.Transfer
        ? i18n.t(`accountPage.historyTable.typeTransfer`)
        : i18n.t(`accountPage.historyTable.typePayment`);
    }
  },
  {
    dataIndex: ETransferHistoryColumns.amount,
    title: `accountPage.historyTable.columns.amount`,
    render: FormatHelper.getCurrency
  },
  {
    dataIndex: ETransferHistoryColumns.employee,
    title: `accountPage.historyTable.columns.employee`,
    render: (value, { employeeFirstName, employeeLastName }) => {
      if (!employeeLastName || !employeeFirstName) {
        return '-';
      }

      return `${employeeLastName} ${employeeFirstName.charAt(0)}.`;
    }
  }
];

export const COMPANY_HISTORY_ITEMS_PER_PAGE = 10;

export const transferHistoryOptions: Record<'types', ISelectorOption[]> = {
  types: [
    { value: ETransferHistoryTypes.Payment, label: `employeePage.history.type.${ETransferHistoryTypes.Payment}` },
    { value: ETransferHistoryTypes.Transfer, label: `employeePage.history.type.${ETransferHistoryTypes.Transfer}` }
  ]
};
