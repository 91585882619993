import React from 'react';
import { CardInfo, Paragraph } from '@axmit/clp-library';
import Form from 'antd/es/form';
import { HooksHelper } from '@axmit/clp-library/dist/helpers';
import { FormComponentProps } from 'antd/es/form';
import { useTranslation } from 'react-i18next';
import { MobFormButton, TextAreaForm } from 'common/components';
import { communicationGift, IGiftConnectedProps, IGiftMeta, IGiftModel } from 'entities/Gift/index';
import { GiftUpdateHelper } from 'entities/Gift/helpers/GiftUpdate.helper';

interface IComponentProps {
  disabled?: boolean;
}

type AllProps = FormComponentProps & IGiftConnectedProps & IComponentProps;

const GiftFormActivationComponent = ({ form, updateGiftModel, giftModel, disabled }: AllProps) => {
  const { t } = useTranslation();

  const { getFieldDecorator } = form;
  const { data: gift } = giftModel;

  if (!gift) {
    return null;
  }

  const formData = HooksHelper.useStateBuilder<IGiftModel>(gift);

  const submit = () => {
    GiftUpdateHelper.submitForm(form, () => updateGiftModel({ id: formData.value.id, data: formData.value }));
  };

  const setValueToMeta = (value: Partial<IGiftMeta>) => {
    GiftUpdateHelper.setValueToMeta(formData.set, value);
  };

  return (
    <>
      <CardInfo
        content={
          <Paragraph size="xs" className="mb-20 mt-15">
            {t('newGiftActivationMessage')}
          </Paragraph>
        }
      />

      <TextAreaForm
        getFieldDecorator={getFieldDecorator}
        fieldName="message"
        onBlur={setValueToMeta}
        initialValue={formData.value.meta.message}
        textAreaProps={{ autoSize: { minRows: 10, maxRows: 16 }, disabled }}
        required
      />

      <MobFormButton className="mb-30" type="primary" onClick={submit} disabled={disabled}>
        {t('giftEditFormSubmit')}
      </MobFormButton>
    </>
  );
};

export const GiftFormActivation = communicationGift.injector(Form.create<AllProps>()(GiftFormActivationComponent));
