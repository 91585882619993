import axios from 'axios';

export class BaseTransport<
  Item,
  Collection = Item[],
  CreateItem = Partial<Item>,
  UpdateItem = Partial<Item>,
  CollectionParams = {}
> {
  constructor(readonly path: string) {}

  public add = (data: CreateItem): Promise<Item> => {
    return axios.post(`${this.path}`, data);
  };

  public update = ({ id, data }: { id: string; data: UpdateItem }): Promise<Item> => {
    return axios.put(`${this.path}/${id}`, data);
  };

  public delete = (id: string): Promise<void> => {
    return axios.delete(`${this.path}/${id}`);
  };

  public deleteAll = (): Promise<void> => {
    return axios.delete(`${this.path}`);
  };

  public get = (id: string): Promise<Item> => {
    return axios.get(`${this.path}/${id}`);
  };

  public getCollection = (params: CollectionParams): Promise<Collection> => {
    return axios.get(`${this.path}`, { params });
  };
}
