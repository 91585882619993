import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import 'app/locales/i18n';
import * as React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Provider } from 'react-redux';
import { Store } from 'redux';
import { ConnectedRouter } from 'connected-react-router';
import { history } from 'app/store';
import { stripeFonts } from 'entities/Payment';
import { LayoutBase } from 'entities/Layout/LayoutBase';

interface IComponentProps {
  store: Store;
}

type AllProps = WithTranslation & IComponentProps;

// @ts-ignore
const key = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_STRIPE_PUB_KEY : window?.stripe_pub_key;
const stripePromise = key ? loadStripe(key) : null;

class AppComponent extends React.Component<AllProps> {
  public render() {
    const { store } = this.props;

    return (
      <Elements options={{ fonts: stripeFonts }} stripe={stripePromise}>
        <Provider store={store}>
          <ConnectedRouter history={history}>
            <LayoutBase />
          </ConnectedRouter>
        </Provider>
      </Elements>
    );
  }
}

export const App = withTranslation()(AppComponent);
