import { ColumnProps } from 'antd/lib/table';
import { FontProximaNovaSrc } from 'common/config';
import { FormatHelper } from 'common/helpers';
import { IRequestExpensesModel } from 'entities/RequestExpenses/RequestExpenses.models';

export enum ETransactionsType {
  Fee = 'fee',
  Payment = 'payment'
}

export enum ECalculatorColumns {
  description = 'description',
  actions = 'actions',
  amount = 'amount'
}

export const calculatorColumns: ColumnProps<IRequestExpensesModel>[] = [
  { dataIndex: ECalculatorColumns.description, title: `calculator.table.${ECalculatorColumns.description}` },
  {
    dataIndex: ECalculatorColumns.amount,
    title: `calculator.table.${ECalculatorColumns.amount}`,
    render: amount => FormatHelper.getCurrency(amount)
  },
  {
    dataIndex: ECalculatorColumns.actions,
    title: `calculator.table.${ECalculatorColumns.actions}`
  }
];

export const stripeStyles = {
  base: {
    fontFamily: 'proxima-nova, sans-serif',
    fontSize: '16px',
    color: '#0F0D18',
    lineHeight: '24px'
  }
};

export const stripeFonts = [
  {
    src: FontProximaNovaSrc,
    family: 'proxima-nova'
  }
];

export enum ECardFormType {
  New = 'new',
  Saved = 'saved'
}

export const cardFormTypes: { value: ECardFormType; label: string }[] = [
  {
    value: ECardFormType.Saved,
    label: 'cardsSelector.label'
  },
  {
    value: ECardFormType.New,
    label: 'cardsSelector.showNew'
  }
];
