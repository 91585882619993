import { ListGridType } from 'antd/es/list';

export const FRIENDS_GIFTS_PER_PAGE = 4;
export const DASHBOARD_GIFTS_GRID: ListGridType = { gutter: 16, xl: 4, lg: 3, sm: 2 };

export const giftEditTabsLabels = {
  experience: 'giftEditTabExperience',
  contributors: 'giftEditTabContributors',
  idea: 'giftEditTabIdea',
  discovery: 'giftEditTabDiscovery',
  activation: 'giftEditTabActivation',
  payment: 'giftEditTabPayment'
};

export const giftEditTabs = {
  experience: 'experience',
  contributors: 'contributors',
  idea: 'idea',
  discovery: 'discovery',
  activation: 'activation',
  payment: 'payment'
};

export enum EContactDateType {
  now = 'now',
  other = 'other'
}

export enum EContactType {
  phone = 'phone',
  email = 'email',
  whatsApp = 'whatsApp'
}

export const contactTypes = [
  { label: 'By Email', value: EContactType.email },
  { label: 'By Phone', value: EContactType.phone },
  { label: 'By WhatsApp', value: EContactType.whatsApp }
];
