import Skeleton from 'antd/es/skeleton';
import Spin from 'antd/es/spin';
import Divider from 'antd/es/divider';
import React, { FC, useEffect } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { communicationMessages, IMessagesConnectedProps } from 'entities/Messages/Messages.communication';
import { IMessageCollectionParams, EMessageType } from 'entities/Messages/Messages.models';
import { MESSAGES_PER_PAGE, CHAT_ID } from 'entities/Messages/Messages.consts';
import { ChatListItem } from 'entities/Messages/components/ChatListItem';

interface IComponentProps {
  clientId: string;
}

type AllProps = IMessagesConnectedProps & IComponentProps;

const ChatListComponent: FC<AllProps> = props => {
  const { messagesCollection, getMessagesCollection, clientId } = props;
  const { data, params, loading } = messagesCollection;

  useEffect(() => {
    const elem = document.getElementById(CHAT_ID) as HTMLElement | null;

    if (elem) {
      elem.scrollTop = -1;
    }
  }, [clientId]);

  useEffect(() => {
    getMessagesCollection({ client: clientId, limit: MESSAGES_PER_PAGE, offset: 0 });
  }, [getMessagesCollection, clientId]);

  const fetchMoreData = () => {
    const oldParams: IMessageCollectionParams | {} = params || {};
    const offset: number = (params?.offset || 0) + MESSAGES_PER_PAGE;

    getMessagesCollection({ ...oldParams, offset });
  };

  const renderSpinner = (
    <>
      <div className="message message_messenger">
        <Skeleton paragraph={{ rows: 3 }} active />
      </div>

      <div className="message message_messenger">
        <Skeleton paragraph={{ rows: 2 }} active />
      </div>

      <div className="message message_right">
        <Skeleton paragraph={{ rows: 2 }} active />
      </div>
    </>
  );

  const limit = params?.limit || 0;
  const offset = params?.offset || 0;
  const count = data?.meta.count || 0;
  const hasMore = limit + offset < count;

  const loadingAfterChangeChannel = loading && !offset;

  return (
    <Spin spinning={loadingAfterChangeChannel}>
      <InfiniteScroll
        dataLength={data?.data.length || 0}
        next={fetchMoreData}
        className="chat-scroll-reverse"
        inverse={true}
        hasMore={hasMore}
        loader={renderSpinner}
        scrollableTarget={CHAT_ID}
      >
        {data?.data.map(({ message, direction, createdAt, id, type, managerFirstName, managerLastName, status }) => {
          const { messagesCollectionDividers } = props;
          const { data: dataDividers } = messagesCollectionDividers;

          const divider = dataDividers?.find(item => item.firstMessageId === id);

          const isClient = direction === 'incoming';
          const name: string | undefined = managerFirstName
            ? `${managerFirstName || ''} ${managerLastName || ''}`
            : managerLastName || undefined;

          return (
            <React.Fragment key={id}>
              <ChatListItem
                isClient={isClient}
                content={message.content}
                date={createdAt}
                type={type as EMessageType}
                name={name}
                status={status}
              />

              {divider && <Divider className="message-divider">{divider.date}</Divider>}
            </React.Fragment>
          );
        })}
      </InfiniteScroll>
    </Spin>
  );
};

export const ChatList = communicationMessages.injector(ChatListComponent);
