import React from 'react';
import { Button } from '@axmit/clp-library';
import Icon from 'antd/es/icon';
import { useTranslation } from 'react-i18next';
import { SeparatedModalContent } from 'common/components';
import julesMapSearch from 'app/assets/images/julesMapSearch.png';

interface IComponentProps {
  message?: string;
  handlePreviousSlide: () => void;
}

type AllProps = React.PropsWithChildren<IComponentProps>;

export const NewGiftAnauthModal = ({ handlePreviousSlide, children, message }: AllProps) => {
  const { t } = useTranslation();
  return (
    <SeparatedModalContent
      rightSlotFooter={
        <Button size="small" onClick={handlePreviousSlide} className="mt-5">
          <span>
            <Icon type="left" className="mr-8" />
            {t('newGiftDefaultBtnBack')}
          </span>
        </Button>
      }
    >
      <img src={julesMapSearch} alt="type" className="aside-slide hidden_mob aside-slide_position_center" />

      {children}
    </SeparatedModalContent>
  );
};
