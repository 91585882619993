import React, { useEffect } from 'react';
import Spin from 'antd/es/spin';
import List from 'antd/es/list';
import { CardInfo } from '@axmit/clp-library';
import { compose } from 'redux';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { EmptyData } from 'common/components';
import { EPrivateRoutes } from 'common/const';
import { FormatHelper, PaginationHelper } from 'common/helpers';
import { CardGift } from 'entities/Gift/components';
import { communicationGift, IGiftConnectedProps } from 'entities/Gift/Gift.communication';
import { communicationAuth, IAuthConnectedProps } from 'entities/Auth/Auth.communication';
import { DASHBOARD_GIFTS_GRID, FRIENDS_GIFTS_PER_PAGE } from 'entities/Gift/Gift.consts';

type AllProps = IGiftConnectedProps & IAuthConnectedProps;

const DashboardGiftsFriendsFromComponent = ({ authModel, giftMyCollection, getGiftMyCollection }: AllProps) => {
  const { t } = useTranslation();
  const { data, loading } = giftMyCollection;

  useEffect(() => {
    const userRecipient = authModel?.data?.id?.userId;

    userRecipient && getGiftMyCollection({ userRecipient, limit: FRIENDS_GIFTS_PER_PAGE });
  }, [authModel, getGiftMyCollection]);

  const onChangePage = (limit: number, offset: number) => {
    authModel.data &&
      getGiftMyCollection({
        userRecipient: authModel.data.id.userId,
        limit,
        offset
      });
  };

  return (
    <Spin spinning={loading}>
      <div className="bordered_bottom bordered_bottom_light hidden_mob">
        <CardInfo header={t('dashboardHeaderFromFriends')} content="" headerSize="sm" />
      </div>

      <List
        pagination={PaginationHelper.getListsPagination(onChangePage, FRIENDS_GIFTS_PER_PAGE, data?.meta?.count)}
        grid={DASHBOARD_GIFTS_GRID}
        locale={{ emptyText: <EmptyData description={t('emptyGiftsMessage')} /> }}
        dataSource={data?.data || []}
        renderItem={({ status, type, deliveryDate, currentBalance = 0, id, userSenderName }) => (
          <List.Item>
            <Link to={`${EPrivateRoutes.GiftViewPage}/${id}`}>
              <CardGift
                giftId={id}
                status={status}
                sender={userSenderName}
                type={type}
                date={deliveryDate}
                price={FormatHelper.getCurrency(currentBalance)}
              />
            </Link>
          </List.Item>
        )}
      />
    </Spin>
  );
};

const containers = compose(communicationGift.injector, communicationAuth.injector);

export const DashboardGiftsFriendsFrom = containers(DashboardGiftsFriendsFromComponent);
