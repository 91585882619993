import React from 'react';
import { Col, Row } from 'antd/es/grid';
import { Paragraph, Select } from '@axmit/clp-library';
import { useTranslation } from 'react-i18next';
import AntSelect from 'antd/es/select';
import moment from 'moment';
import { DatePicker } from 'common/components/DatePicker';
import { transferHistoryOptions } from 'entities/Companies/Companies.consts';
import { EmployeeCreditSelector } from 'entities/Employees/components/EmployeeCreditSelector';

interface IComponentProps {
  onChangeDateFrom: (value: moment.Moment | null | undefined) => void;
  dateFrom?: moment.Moment | null;
  onChangeDateTo: (value: moment.Moment | null | undefined) => void;
  dateTo?: moment.Moment | null;
  changeType: (value: string) => void;
  employee?: string;
  setEmployee: (value?: string) => void;
  companyId: string;
}

export const CompanyAccountHistoryFilters = (props: IComponentProps) => {
  const { onChangeDateFrom, dateFrom, onChangeDateTo, dateTo, changeType, employee, setEmployee, companyId } = props;
  const { t } = useTranslation();

  return (
    <Row gutter={[24, 24]}>
      <Col lg={12} xl={5}>
        <Paragraph size="xs">{t('employeePage.history.fromSelectorLabel')}</Paragraph>

        <DatePicker allowClear onChange={onChangeDateFrom} value={dateFrom} />
      </Col>

      <Col lg={12} xl={5}>
        <Paragraph size="xs">{t('employeePage.history.toSelectorLabel')}</Paragraph>

        <DatePicker allowClear onChange={onChangeDateTo} value={dateTo} />
      </Col>

      <Col lg={12} xl={5}>
        <Paragraph size="xs">{t('employeePage.history.toSelectorType')}</Paragraph>

        <Select bordered="bottom" className="w-100p" allowClear onChange={value => changeType(value as string)}>
          {transferHistoryOptions.types.map(({ value, label }) => (
            <AntSelect.Option key={value} value={value}>
              {t(label)}
            </AntSelect.Option>
          ))}
        </Select>
      </Col>

      <Col lg={12} xl={5}>
        <Paragraph size="xs">{t('employeePage.history.toSelectorEmployee')}</Paragraph>

        <EmployeeCreditSelector setEmployee={setEmployee} employee={employee} companyId={companyId} />
      </Col>
    </Row>
  );
};
