import * as React from 'react';
import { FormItem, TextArea } from '@axmit/clp-library';
import { TextAreaProps } from 'antd/es/input';
import { useTranslation } from 'react-i18next';
import { FieldDecorator } from 'common/models/Form.models';

interface IComponentProps<T> {
  getFieldDecorator: FieldDecorator;
  fieldName: string;
  onBlur?: ({ key: value }: { [key: string]: string }) => void;
  initialValue?: T;
  required?: boolean;
  label?: string;
  textAreaProps?: TextAreaProps;
}

export function TextAreaForm<T>({
  getFieldDecorator,
  onBlur,
  initialValue,
  required,
  label,
  fieldName,
  textAreaProps
}: IComponentProps<T>) {
  const { t } = useTranslation();
  const requiredRules = required
    ? [
        {
          required: true,
          message: t('errorRequiredDefault')
        }
      ]
    : [];

  const handleBlur = ({ target: { value } }: React.FocusEvent<HTMLTextAreaElement>) => {
    if (initialValue && String(initialValue) === value) {
      return;
    }

    return onBlur && onBlur({ [fieldName]: value });
  };

  return (
    <FormItem label={label}>
      {getFieldDecorator(fieldName, {
        initialValue,
        rules: requiredRules
      })(<TextArea {...textAreaProps} onBlur={handleBlur} />)}
    </FormItem>
  );
}
