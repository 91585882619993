import React from 'react';
import { Redirect } from 'react-router';
import { Container } from '@axmit/clp-library';
import Typography from 'antd/es/typography';
import { useTranslation } from 'react-i18next';
import { EPrivateRoutes } from 'common/const';
import { Desktop } from 'common/components';
import { PersonalInfo } from 'entities/User/components/PersonalInfo';

export const PersonalMobilePage = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <Typography.Title className="clp-typography-title clp-typography-title_size_md mob-text_center mb-10">
        {t('dashboardTabPersonalInfo')}
      </Typography.Title>

      <PersonalInfo />

      <Desktop>
        <Redirect to={EPrivateRoutes.PersonalDesktop} />
      </Desktop>
    </Container>
  );
};
