import { AntdFormHelper } from '@axmit/antd-helpers';
import { Button, FormItem, Input, TextArea, Checkbox } from '@axmit/clp-library';
import { HooksHelper } from '@axmit/clp-library/dist/helpers';
import { Row, Col } from 'antd/es/grid';
import Form from 'antd/es/form';
import Divider from 'antd/es/divider';
import { FormComponentProps } from 'antd/es/form';
import * as React from 'react';
import { FormEvent } from 'react';
import { useHistory, useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';
import { DateHelper } from 'common/helpers';
import { Spinner, EmptyData } from 'common/components';
import { RequiredRules } from 'common/const';
import { UsersHooks, CheckboxHooks } from 'common/hooks';
import { DatePicker } from 'common/components/DatePicker';
import { communicationGiftRequests, IGiftRequestsConnectedProps } from 'entities/GiftRequests/GiftRequests.communication';

type AllProps = FormComponentProps & IGiftRequestsConnectedProps;

interface IRouteParams {
  employeeId?: string;
  userId?: string;
  userName?: string;
}

const RequestCreateFormComponent = ({ form, addGiftRequestsModel, giftRequestsModel }: AllProps) => {
  const { t } = useTranslation();
  const { getFieldDecorator } = form;

  const date = HooksHelper.useStateBuilder(DateHelper.getNextDay());
  const { state } = useLocation<IRouteParams>();
  const history = useHistory();
  const assignee = CheckboxHooks.useCheckbox(true);
  const { currentUserId } = UsersHooks.useCurrent();

  if (!state?.userId) {
    return <EmptyData />;
  }

  const { userId, employeeId, userName } = state;

  const submit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    form.validateFields((err, values) => {
      if (err || !date.value) {
        return;
      }

      addGiftRequestsModel({
        user: userId,
        deliveryDate: date.value.toISOString(),
        meta: values,
        employee: employeeId,
        userAssignee: assignee.value ? currentUserId : undefined
      });
    });
  };

  return (
    <Spinner spinning={giftRequestsModel.loading}>
      <Form onSubmit={submit}>
        <Row gutter={50} className="pt-30">
          <Col xs={24} md={12}>
            <h2 className="color_primary mob-text_center mb-30">
              {t('makeARequestFor')} <b>{userName}</b>
            </h2>

            <FormItem labelAlign="left" labelCol={{ xs: 24 }} wrapperCol={{ xs: 24, sm: 12 }} label={t('requestMake.title')}>
              {getFieldDecorator('title', {
                rules: RequiredRules
              })(<Input />)}
            </FormItem>

            <FormItem wrapperCol={{ xs: 24, sm: 20 }}>
              {getFieldDecorator('description')(
                <TextArea placeholder={t('requestMake.describe')} autoSize={{ minRows: 4, maxRows: 8 }} />
              )}
            </FormItem>

            <FormItem label={t('requestMake.timing')}>
              <DatePicker
                value={date.value}
                className="mt-10"
                allowClear={false}
                disabledDate={DateHelper.isTodayOrBefore}
                minDate={DateHelper.getTomorrow().toDate()}
                onChange={value => {
                  value && date.set(value);
                }}
              />
            </FormItem>

            <FormItem>
              <Checkbox className="mt-10" defaultChecked onChange={assignee.set}>
                {t('assignOnMe')}
              </Checkbox>
            </FormItem>
          </Col>
        </Row>

        <Divider className="divider" />

        <Button type="primary" size="small" htmlType="submit" className="ant-btn_mob-block mb-15 mr-8">
          {t('defaultBtnSubmitRequest')}
        </Button>

        <Button
          size="small"
          className="ant-btn_mob-block"
          onClick={() => {
            history.goBack();
          }}
        >
          {t('defaultBtnCancel')}
        </Button>
      </Form>
    </Spinner>
  );
};

export const RequestCreateForm = communicationGiftRequests.injector(
  Form.create<AllProps>({
    mapPropsToFields(props: AllProps) {
      const { giftRequestsModel } = props;

      return AntdFormHelper.mapValidationToFields(giftRequestsModel.params, giftRequestsModel.errors);
    }
  })(RequestCreateFormComponent)
);
