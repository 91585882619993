import { MIN_BUDGET_INDIVIDUAL_EUR } from 'common/config';
import { FormatHelper } from 'common/helpers';

const getMinFormattedBudget = () => {
  const minEUR = FormatHelper.getCurrency(MIN_BUDGET_INDIVIDUAL_EUR);

  return { minEUR };
};

export const PaymentFormat = { getMinFormattedBudget };
