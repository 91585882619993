import { EActionsTypes, APIProvider, BaseStrategy, Branch, buildCommunication, StoreBranch } from '@axmit/redux-communications';
import { BaseTransport } from 'common/dto/base.transport';
import { RequestLoadingHelper } from 'common/helpers';
import {
  IContributorsCollectionParams,
  IGiftContributorCollection,
  IGiftContributorCreateParams,
  IGiftContributorModel
} from 'entities/Contributors/Contributors.models';

const namespace = 'contributors';

export interface IContributorsConnectedProps {
  contributorsCollection: StoreBranch<IGiftContributorCollection>;
  getContributorsCollection(params: IContributorsCollectionParams): void;
  clearContributorsCollection(): void;

  contributorsModel: StoreBranch<IGiftContributorModel>;
  addContributorsModel(params: IGiftContributorCreateParams): void;
}

const transport = new BaseTransport('gift-contributors');

const collectionApiProvider = [
  new APIProvider(EActionsTypes.get, transport.getCollection, {
    preRequestDataMapper: RequestLoadingHelper.setOldData
  })
];

const modelApiProvider = [new APIProvider(EActionsTypes.add, transport.add)];

const branches = [new Branch('collection', collectionApiProvider), new Branch('model', modelApiProvider)];

const strategy = new BaseStrategy({
  namespace,
  branches
});

export const communicationContributors = buildCommunication<IContributorsConnectedProps>(strategy);
