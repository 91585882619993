import React from 'react';
import Typography from 'antd/es/typography';
import { useTranslation } from 'react-i18next';
import { FormatHelper } from 'common/helpers';
import { communicationGift, IGiftConnectedProps } from 'entities/Gift';

type AllProps = IGiftConnectedProps;

const ContributorFormInfoComponent = ({ giftPublicModel }: AllProps) => {
  const { t } = useTranslation();

  if (!giftPublicModel.data) {
    return null;
  }

  const { currentBalance, participantCount, recipientName } = giftPublicModel.data;

  return (
    <>
      <Typography.Title className="clp-typography-title clp-typography-title_size_md mob-text_center mb-10 hidden_desk">
        {t('contributorForm.header', { name: recipientName })}
      </Typography.Title>

      <div className="contribution-info mob-block_center">
        <p>
          <span className="contribution-info__label">{t('contributorInfo.amount')}</span>
          <br />
          <span className="contribution-info__value">{FormatHelper.getCurrency(currentBalance)}</span>
        </p>

        <p className="m-0">
          <span className="contribution-info__label">{t('contributorInfo.participants')}</span>
          <br />
          <span className="contribution-info__value">{participantCount}</span>
        </p>
      </div>
    </>
  );
};

export const ContributorFormInfo = communicationGift.injector(ContributorFormInfoComponent);
