import Pagination from 'antd/es/pagination';
import { Row, Col } from 'antd/es/grid';
import * as React from 'react';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { compose } from 'redux';
import { Spinner } from 'common/components';
import { EBackOfficeRoutes } from 'common/const';
import { PaginationHelper } from 'common/helpers';
import { RequestsCard } from 'entities/Requests/components/RequestsCard';
import { REQUESTS_COL, REQUESTS_PER_PAGE } from 'entities/Requests/Request.consts';
import { communicationRequests, IRequestsConnectedProps } from 'entities/Requests/Requests.communication';

type AllProps = IRequestsConnectedProps;

const GiftRequestsNewComponent = ({ getRequestsNewCollection, requestsNewCollection, clearRequestsNewCollection }: AllProps) => {
  useEffect(() => {
    return () => {
      clearRequestsNewCollection();
    };
  }, [clearRequestsNewCollection]);

  useEffect(() => {
    getRequestsNewCollection({ limit: REQUESTS_PER_PAGE });
  }, [getRequestsNewCollection]);

  const onChangePage = (limit: number, offset: number) => {
    getRequestsNewCollection({
      limit,
      offset
    });
  };

  const data = requestsNewCollection?.data;
  const listPagination = PaginationHelper.getListsPagination(onChangePage, REQUESTS_PER_PAGE, data?.meta.count);

  return (
    <Spinner spinning={requestsNewCollection?.loading}>
      <Row gutter={16} type="flex">
        {data?.data.map(request => (
          <Col {...REQUESTS_COL} key={request.id}>
            <Link to={`${EBackOfficeRoutes.RequestEditPage}/${request.id}`}>
              <RequestsCard request={request} />
            </Link>
          </Col>
        ))}
      </Row>

      {listPagination && <Pagination size="small" {...listPagination} />}
    </Spinner>
  );
};

const containers = compose(communicationRequests.injector);

export const GiftRequestsNew = containers(GiftRequestsNewComponent);
