import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { TIME_FORMAT_DEFAULT } from 'common/config';
import { FormatHelper } from 'common/helpers';
import { EMessageType, IMessageContent } from 'entities/Messages/Messages.models';
import { Content } from 'entities/Messages/components';

interface IComponentProps {
  content: IMessageContent;
  isClient?: boolean;
  name?: string;
  date?: string;
  status?: string;
  type: EMessageType;
}

export const ChatListItem = ({ isClient, content, name = '', type, date, status = '' }: IComponentProps) => {
  const { t } = useTranslation();

  const formattedStatus: string = status ? t(`messageStatuses.${status}`) : '';
  const formattedDate: string = date ? FormatHelper.formatDate(TIME_FORMAT_DEFAULT, date) : '';

  return (
    <div>
      <div className={`message-header ${isClient ? '' : 'message-header_right'} message-header_${type}`}>
        {type} {name && <span className="message-header__sign"> - {name}</span>}
      </div>

      <div className={`message ${isClient ? `message_${type}` : 'message_right'}`}>
        <Content {...content} />

        <br />

        <div className="message__date">
          {formattedStatus} {formattedDate}
        </div>
      </div>
    </div>
  );
};
