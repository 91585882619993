import { AntdFormHelper } from '@axmit/antd-helpers';
import { Button, FormItem, Input, PhoneInput, Checkbox } from '@axmit/clp-library';
import { Row, Col } from 'antd/es/grid';
import Form from 'antd/es/form';
import Spin from 'antd/es/spin';
import { FormComponentProps } from 'antd/es/form';
import React, { FormEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { PhoneHooks, CheckboxHooks } from 'common/hooks';
import i18n from 'app/locales/i18n';
import { communicationUsers, IUsersConnectedProps } from 'entities/User/Users.communication';
import { AgreeTocCheckbox } from 'entities/Auth/components/AgreeTOCCheckbox';
import { PasswordInput } from 'entities/Auth/components/PasswordInput';

interface IComponentProps {
  redirectToLogIn: () => void;
  withSmallBtns?: boolean;
}

type AllProps = FormComponentProps & IUsersConnectedProps & IComponentProps;

const SignUpFormComponent = (props: AllProps) => {
  const { form, usersModel, redirectToLogIn, withSmallBtns = false } = props;
  const { getFieldDecorator, getFieldError } = form;

  const { t } = useTranslation();
  const wrapperCol = { xs: 24, xl: 12 };

  const { phone, phoneValidateMessage, validatePhone, changePhone, phoneValidateStatus } = PhoneHooks.usePhone(
    undefined,
    getFieldError('phoneNumber')
  );
  const agreeTOC = CheckboxHooks.useCheckbox(false);
  const agreePromo = CheckboxHooks.useCheckbox(false);

  const submit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    form.validateFields((err, values) => {
      if (err || !agreeTOC.value) {
        return;
      }

      props.addUsersModel({ ...values, phoneNumber: phone, isPromoAllowed: agreePromo.value });
    });
  };

  return (
    <Spin spinning={usersModel.loading}>
      <Form className="mt-30" onSubmit={submit}>
        <Row gutter={22}>
          <Col {...wrapperCol}>
            <FormItem label={t('form.labels.firstName')}>
              {getFieldDecorator('firstName', {
                rules: [
                  {
                    required: true,
                    message: t('errorRequiredDefault')
                  }
                ]
              })(<Input />)}
            </FormItem>
          </Col>

          <Col {...wrapperCol}>
            <FormItem label={t('form.labels.lastName')}>
              {getFieldDecorator('lastName', {
                rules: [
                  {
                    required: true,
                    message: t('errorRequiredDefault')
                  }
                ]
              })(<Input />)}
            </FormItem>
          </Col>

          <Col {...wrapperCol}>
            <FormItem label={t('form.labels.email')} className="email-input">
              {getFieldDecorator('email', {
                rules: [
                  {
                    type: 'email',
                    message: t('errorEmailNotEmail')
                  },
                  {
                    required: true,
                    message: t('errorRequiredDefault')
                  }
                ]
              })(<Input type="email" />)}
            </FormItem>
          </Col>

          <Col {...wrapperCol}>
            <FormItem label={t('form.labels.phoneNumber')} validateStatus={phoneValidateStatus} help={phoneValidateMessage}>
              <PhoneInput onChange={changePhone} validateTrigger={validatePhone} block />
            </FormItem>
          </Col>

          <Col {...wrapperCol}>
            <PasswordInput fieldName="password" getFieldDecorator={getFieldDecorator} label={t('form.labels.password')} />
          </Col>
        </Row>

        <AgreeTocCheckbox onChange={agreeTOC.set} />
        <FormItem>
          <Checkbox onChange={agreePromo.set}>{i18n.t('signupAgreeWithPromo')}</Checkbox>
        </FormItem>

        {withSmallBtns ? (
          <>
            <Button
              className="ant-btn_mob-block mob-form-wrapper mr-20"
              disabled={usersModel.loading || !agreeTOC.value}
              type="primary"
              size="small"
              htmlType="submit"
            >
              {t('signUpSubmit')}
            </Button>

            <Button type="link" size="small" className="pl-0 pr-0" onClick={redirectToLogIn}>
              {t('signUpLogInLink')}
            </Button>
          </>
        ) : (
          <Row align="middle" gutter={16}>
            <Col xs={24} xl={13} className="mob-form-wrapper">
              <Button
                className="ant-btn_mob-block mob-form-wrapper"
                disabled={usersModel.loading || !agreeTOC.value}
                type="primary"
                htmlType="submit"
                block
              >
                {t('signUpSubmit')}
              </Button>
            </Col>

            <Col xs={24} xl={11} className="mob-text_center">
              <Button type="link" className="pl-0 pr-0" onClick={redirectToLogIn}>
                {t('signUpLogInLink')}
              </Button>
            </Col>
          </Row>
        )}
      </Form>
    </Spin>
  );
};

export const SignUpForm = communicationUsers.injector(
  Form.create<AllProps>({
    mapPropsToFields(props: AllProps) {
      const { usersModel } = props;

      return AntdFormHelper.mapValidationToFields(usersModel?.params, usersModel?.errors);
    }
  })(SignUpFormComponent)
);
