import { PropsWithChildren } from 'react';
import * as React from 'react';
import Spin from 'antd/es/spin';
import { SPIN_WAIT_TIME } from 'common/config';

interface IComponentProps {
  spinning?: boolean;
}

export const Spinner = ({ spinning, children }: PropsWithChildren<IComponentProps>) => {
  return (
    <Spin spinning={spinning} delay={SPIN_WAIT_TIME}>
      {children}
    </Spin>
  );
};
