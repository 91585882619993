import '@axmit/clp-api';
import { ISelectorOption } from 'common/models/Selector.models';
import i18n from 'app/locales/i18n';

export interface IGiftRequestModel extends Components.Schemas.GiftRequestDto {}

export interface IRequestModel extends Components.Schemas.RequestDto {
  priority: ERequestPriority;
  status: ERequestStatus;
}
export interface IRequestUpdateParams extends Components.Schemas.RequestUpdateDataDto {}
export interface IRequestCollection extends Components.Schemas.RequestCollection {
  data: IRequestModel[];
}
export interface IRequestCollectionParams extends Paths.RequestControllerGetList.QueryParameters {}

export enum ERequestStatus {
  New = 'new',
  InProgress = 'inProgress',
  Finished = 'finished'
}

export enum ERequestPriority {
  Low = 'low',
  Normal = 'normal',
  High = 'high'
}

export enum ERequestsFilterBy {
  Priority = 'priority',
  CreationDate = 'createdAt',
  LastUpdated = 'updatedAt'
}

export const RequestOptions: Record<'statuses' | 'priorities' | 'filterBy', ISelectorOption[]> = {
  statuses: [
    { value: ERequestStatus.New, label: i18n.t(`request.statuses.${ERequestStatus.New}`) },
    { value: ERequestStatus.InProgress, label: i18n.t(`request.statuses.${ERequestStatus.InProgress}`) },
    { value: ERequestStatus.Finished, label: i18n.t(`request.statuses.${ERequestStatus.Finished}`) }
  ],
  priorities: [
    { value: ERequestPriority.High, label: i18n.t(`request.priorities.${ERequestPriority.High}`) },
    { value: ERequestPriority.Normal, label: i18n.t(`request.priorities.${ERequestPriority.Normal}`) },
    { value: ERequestPriority.Low, label: i18n.t(`request.priorities.${ERequestPriority.Low}`) }
  ],
  filterBy: [
    { value: ERequestsFilterBy.Priority, label: i18n.t(`request.sortBy.${ERequestsFilterBy.Priority}`) },
    { value: ERequestsFilterBy.CreationDate, label: i18n.t(`request.sortBy.${ERequestsFilterBy.CreationDate}`) },
    { value: ERequestsFilterBy.LastUpdated, label: i18n.t(`request.sortBy.${ERequestsFilterBy.LastUpdated}`) }
  ]
};
