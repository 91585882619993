import axios from 'axios';
import { BaseTransport } from 'common/dto/base.transport';
import {
  IEmployeesCollection,
  IEmployeesCollectionParams,
  IEmployeesModel,
  IEmployeeTransactions,
  IEmployeeTransactionsParams
} from 'entities/Employees/Employees.models';

export class EmployeesTransport extends BaseTransport<
  IEmployeesModel,
  IEmployeesCollection,
  null,
  null,
  IEmployeesCollectionParams
> {
  public getTransactions = ({ id, ...rest }: IEmployeeTransactionsParams): Promise<IEmployeeTransactions> => {
    return axios.get(`${this.path}/${id}/transactions`, { params: rest });
  };
}
