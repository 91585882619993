import * as React from 'react';
import Divider from 'antd/es/divider';
import { PropsWithChildren } from 'react';

interface IComponentProps {
  contentClassName?: string;
  footerClassName?: string;
  rightSlotFooter?: JSX.Element | string;
}

type AllProps = PropsWithChildren<IComponentProps>;

export const SeparatedModalContent = ({ children, rightSlotFooter, contentClassName = '', footerClassName = '' }: AllProps) => {
  return (
    <>
      <div className={`separated-modal__content ${contentClassName}`}>{children}</div>

      {rightSlotFooter && (
        <div className={footerClassName}>
          <Divider className="mt-15 mb-15" />
          {rightSlotFooter}
        </div>
      )}
    </>
  );
};
