import * as React from 'react';
import Menu from 'antd/lib/menu';
import Dropdown from 'antd/es/dropdown';
import { Button, SvgArrowLang } from '@axmit/clp-library';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { EBackOfficeRoutes } from 'common/const';
import { IEmployeesCollection } from 'entities/Employees/Employees.models';

interface IComponentProps {
  collection: IEmployeesCollection | null;
  userId: string;
  userName: string;
}

export const MakeRequestBtn = ({ collection, userId, userName }: IComponentProps) => {
  const { t } = useTranslation();
  const data = collection?.data || [];
  const history = useHistory();

  const makePersonalRequest = () => {
    history.push(EBackOfficeRoutes.CreateRequest, { userId, userName });
  };

  const makeEmployeeRequest = (employeeId: string) => {
    history.push(EBackOfficeRoutes.CreateRequest, { employeeId, userId, userName });
  };

  if (!data?.length) {
    return (
      <Button type="primary" onClick={makePersonalRequest} className="ant-btn_mob-block float_r mb-15">
        {t('makeARequest')}
      </Button>
    );
  }

  const menu = (
    <Menu className="clp-antd-submenu">
      <Menu.Item key="menu-personal" onClick={makePersonalRequest}>
        <b>{t('personal')}</b>
      </Menu.Item>

      <Menu.ItemGroup title={t('employeeOf')} className="mt-10" />
      <Menu.Divider />

      {data.map(({ companyName, id }) => (
        <Menu.Item key={`menu-${companyName}`} onClick={() => makeEmployeeRequest(id)}>
          <b>{companyName}</b>
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <Dropdown overlay={menu} overlayClassName="clp-antd-submenu">
      <Button type="primary" className="float_r pr-10 mb-15 ant-btn_mob-block">
        <span className="mr-15">{t('makeARequest')}</span>{' '}
        <span>
          <SvgArrowLang />
        </span>
      </Button>
    </Dropdown>
  );
};
