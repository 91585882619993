import { APIProvider, BaseStrategy, Branch, buildCommunication, EActionsTypes, StoreBranch } from '@axmit/redux-communications';
import { RequestLoadingHelper } from 'common/helpers';
import {
  IEmployeesCollection,
  IEmployeesCollectionParams,
  IEmployeeTransactions,
  IEmployeeTransactionsParams
} from 'entities/Employees/Employees.models';
import { EmployeesTransport } from 'entities/Employees/Employees.transport';

const namespace = 'employees';

export interface IEmployeesConnectedProps {
  employeesCollection: StoreBranch<IEmployeesCollection>;
  getEmployeesCollection(params: IEmployeesCollectionParams): void;
  clearEmployeesCollection(): void;

  employeesTransactions: StoreBranch<IEmployeeTransactions>;
  getEmployeesTransactions(params: IEmployeeTransactionsParams): void;
  clearEmployeesTransactions(): void;

  employeesCompanyCollection: StoreBranch<IEmployeesCollection>;
  getEmployeesCompanyCollection(params: IEmployeesCollectionParams): void;
  clearEmployeesCompanyCollection(): void;

  employeesForTransferCollection: StoreBranch<IEmployeesCollection>;
  getEmployeesForTransferCollection(params: IEmployeesCollectionParams): void;
  clearEmployeesForTransferCollection(): void;
}

const transport = new EmployeesTransport(namespace);

const collectionApiProvider = [
  new APIProvider<IEmployeesCollection, IEmployeesCollectionParams>(EActionsTypes.get, transport.getCollection, {
    preRequestDataMapper: RequestLoadingHelper.setOldData
  })
];

const transactionsApiProvider = [new APIProvider(EActionsTypes.get, transport.getTransactions)];

const branches = [
  new Branch('collection', collectionApiProvider),
  new Branch('companyCollection', collectionApiProvider),
  new Branch('forTransferCollection', collectionApiProvider),
  new Branch('transactions', transactionsApiProvider)
];

const strategy = new BaseStrategy({
  namespace,
  branches
});

export const communicationEmployees = buildCommunication<IEmployeesConnectedProps>(strategy);
