import React from 'react';
import { Button, CardStepByStep } from '@axmit/clp-library';
import Typography from 'antd/es/typography';
import { useTranslation } from 'react-i18next';
import julesMap from 'app/assets/images/julesMap.png';
import { EGiftType, IGiftCreateViewModel } from 'entities/Gift';

interface IComponentProps {
  updateGift: (data: Partial<IGiftCreateViewModel>) => void;
  handleNextSlide: () => void;
  currency?: number;
}

export const NewGiftFormType = ({ updateGift, handleNextSlide, currency }: IComponentProps) => {
  const { t } = useTranslation();
  const setType = (type: EGiftType) => {
    updateGift({ type, currency });
    handleNextSlide();
  };

  return (
    <>
      <img src={julesMap} alt="type" className="aside-slide hidden_mob" />

      <CardStepByStep label={t('newGiftTypeHeader')} subLabel={t('step1')} className="mb-20" />

      <div className="mb-50 hidden_mob" />

      <Typography.Title className="clp-typography-title clp-typography-title_size_xs mb-30" level={3}>
        {t('newGiftTypeMessage')}
      </Typography.Title>

      <div className="mb-50 hidden_mob" />

      <div className="mob-form-wrapper mb-15 mob-text_center">
        <Button className="pl-30 pr-30 mr-5" onClick={() => setType(EGiftType.Collective)}>
          {t('newGiftTypeBtnCollective')}
        </Button>

        <Button className=" pl-30 pr-30" onClick={() => setType(EGiftType.Individual)}>
          {t('newGiftTypeBtnIndividual')}
        </Button>
      </div>
    </>
  );
};
