import * as React from 'react';
import { Route, Switch } from 'react-router';
import { EBackOfficeRoutes } from 'common/const';
import { ManagersCreate } from 'entities/User/components/ManagersCreate';
import { Managers } from 'entities/User/components/Managers';
import { ManagersEdit } from 'entities/User/components/ManagersEdit';

export const ManagersPage = () => {
  return (
    <Switch>
      <Route path={`${EBackOfficeRoutes.ManagersCreate}`} component={ManagersCreate} exact />
      <Route path={`${EBackOfficeRoutes.Managers}/:id`} component={ManagersEdit} exact />
      <Route path="*" component={Managers} />
    </Switch>
  );
};
