import { useEffect } from 'react';
import * as React from 'react';
import Badge from 'antd/es/badge';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { SocketHooks } from 'common/hooks/Socket.hooks';
import { ESocketActions } from 'common/const/Socket.const';
import { communicationMessages, IMessagesConnectedProps } from 'entities/Messages/Messages.communication';
import { IUnreadInfoModel } from 'entities/Messages/Messages.models';

type AllProps = IMessagesConnectedProps;

const MessageTabCounterComponent = ({ getMessagesUnreadInfo, messagesUnreadInfo }: AllProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { socket } = SocketHooks.useSocket();

  useEffect(() => {
    socket.connect();

    getMessagesUnreadInfo();

    socket.on('totalUnread', (data: IUnreadInfoModel) => {
      dispatch({ type: ESocketActions.TotalInfoUpdate, payload: data });
    });
  }, [dispatch, socket, getMessagesUnreadInfo]);

  const total = messagesUnreadInfo.data?.totalUnread;
  const classSpaceForBadge = `${total && total > 0 ? 'pr-15' : ''}`;

  return (
    <Badge count={total} className={`pt-5 ${classSpaceForBadge}`}>
      {t('backOfficeDashboard.tab.messages')}
    </Badge>
  );
};

export const MessageTabCounter = communicationMessages.injector(MessageTabCounterComponent);
