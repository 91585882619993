import { APIProvider, BaseStrategy, Branch, buildCommunication, EActionsTypes, StoreBranch } from '@axmit/redux-communications';
import { RequestLoadingHelper } from 'common/helpers';
import {
  ICompanyCollection,
  ICompanyCollectionParams,
  ICompanyModel,
  ICompanySummary,
  ICompanyTransferHistory,
  ICompanyTransferHistoryParams
} from 'entities/Companies/Companies.models';
import { CompaniesTransport } from 'entities/Companies/Companies.transport';

const namespace = 'companies';

export interface ICompaniesConnectedProps {
  companiesModel: StoreBranch<ICompanyModel>;
  getCompaniesModel(id: string): ICompanyModel;
  clearCompaniesModel(): void;

  companiesCollection: StoreBranch<ICompanyCollection, ICompanyCollectionParams>;
  getCompaniesCollection(params: ICompanyCollectionParams): ICompanyCollection;
  clearCompaniesCollection(): void;

  companiesTransferHistory: StoreBranch<ICompanyTransferHistory>;
  getCompaniesTransferHistory(params: ICompanyTransferHistoryParams): ICompanyTransferHistory;
  clearCompaniesTransferHistory(): void;

  companiesSummary: StoreBranch<ICompanySummary>;
  getCompaniesSummary(id: string): ICompanySummary;
  clearCompaniesSummary(): void;
}

const transport = new CompaniesTransport('companies');

const collectionApiProvider = [
  new APIProvider<ICompanyCollection, ICompanyCollectionParams>(EActionsTypes.get, transport.getCollection, {
    preRequestDataMapper: RequestLoadingHelper.setOldData
  })
];

const summaryApiProvider = [
  new APIProvider<ICompanySummary, string>(EActionsTypes.get, transport.getSummary, {
    preRequestDataMapper: RequestLoadingHelper.setOldData
  })
];

const transferHistoryApiProvider = [
  new APIProvider<ICompanyTransferHistory, ICompanyTransferHistoryParams>(EActionsTypes.get, transport.getTransferHistory, {
    preRequestDataMapper: RequestLoadingHelper.setOldData
  })
];

const modelApiProvider = [new APIProvider<ICompanyModel>(EActionsTypes.get, transport.get)];

const branches = [
  new Branch('collection', collectionApiProvider),
  new Branch('model', modelApiProvider),
  new Branch('summary', summaryApiProvider),
  new Branch('transferHistory', transferHistoryApiProvider)
];

const strategy = new BaseStrategy({
  namespace,
  branches
});

export const communicationCompanies = buildCommunication<ICompaniesConnectedProps>(strategy);
