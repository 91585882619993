import * as React from 'react';
import Badge from 'antd/es/badge';
import { giftCardHelper } from 'entities/Gift/helpers/giftCard.helper';
import { EGiftStatus } from 'entities/Gift/Gift.models';

interface IComponentProps {
  status: EGiftStatus;
  className: string;
}

export const CardGiftStatus = ({ status, className = '' }: IComponentProps) => {
  const statusIcon = giftCardHelper.getStatusIcon(status);
  const statusText = giftCardHelper.getStatusDescription(status);

  return <Badge status={statusIcon} text={statusText} className={className} />;
};
