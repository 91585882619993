import Skeleton from 'antd/es/skeleton';
import React from 'react';
import uuidv1 from 'uuid';
import { InfiniteList } from 'common/components/InfiniteList';
import { IBaseFilterModel } from 'common/models/Request.models';
import { ChatHelper } from 'entities/Messages/helpers';
import { ChannelsListItem } from 'entities/Messages/components';
import { communicationMessages, IMessagesConnectedProps } from 'entities/Messages/Messages.communication';
import { IChannelsCollection, IChannelsCollectionParams, IChannelsModel } from 'entities/Messages/Messages.models';

interface IComponentProps {
  selectChannel: (model: IChannelsModel) => void;
  filter?: IChannelsCollectionParams;
  activeId?: string | null;
}

type AllProps = IComponentProps & IMessagesConnectedProps;

class ChannelsListComponent extends InfiniteList<IChannelsCollection, IChannelsModel, AllProps, IChannelsCollectionParams> {
  loadCollection = (params: IBaseFilterModel) => {
    const { getMessagesChannels, filter } = this.props;

    getMessagesChannels({ ...filter, ...params });
  };

  renderSpinner = () => (
    <>
      <Skeleton paragraph={{ rows: 1 }} active className="contact-card" />
      <Skeleton paragraph={{ rows: 1 }} active className="contact-card" />
      <Skeleton paragraph={{ rows: 1 }} active className="contact-card" />
      <Skeleton paragraph={{ rows: 1 }} active className="contact-card" />
      <Skeleton paragraph={{ rows: 1 }} active className="contact-card" />
    </>
  );

  renderListItem = (item: IChannelsModel) => {
    const { activeId, selectChannel } = this.props;
    const { client, unreadCount, firstName, lastName, message, userId } = item;
    // There is always exist client or userId
    const id = userId || client || uuidv1();
    const isActive = !!activeId && activeId === id;
    const header = ChatHelper.getChatHeader(firstName, lastName, client);

    return (
      <ChannelsListItem
        active={isActive}
        onClick={() => selectChannel(item)}
        key={id}
        count={unreadCount}
        lastMessage={message?.content}
        header={header}
      />
    );
  };

  getCollection = () => {
    return this.props.messagesChannels;
  };

  clearCollection = () => {
    this.props.clearMessagesChannels();
  };
}

export const ChannelsList = communicationMessages.injector(ChannelsListComponent);
