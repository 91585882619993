import { TabPaneProps } from 'antd/lib/tabs';
import React from 'react';
import { GiftRequestTransactions } from 'entities/Requests/components/GiftRequestTransactions';
import { GiftRequestEditPageDetails } from 'entities/Requests/components/GiftRequestEditPageDetails';
import { ClientNotes } from 'entities/ClientNotes/components/ClientNotes';
import { RequestEditPageDetails } from 'entities/Requests/components/RequestEditPageDetails';
import { RequestEditPageTransactions } from 'entities/Requests/components/RequestEditPageTransactions';

export interface ITab extends TabPaneProps {
  key: string;
  content: JSX.Element;
}

export const requestEditTabs: ITab[] = [
  { key: 'details', tab: 'request.editTab.details', content: <RequestEditPageDetails /> },
  { key: 'clientNotes', tab: 'request.editTab.clientNotes', content: <ClientNotes /> },
  { key: 'transactions', tab: 'request.editTab.transactions', content: <RequestEditPageTransactions /> }
];

export const giftRequestEditTabs: ITab[] = [
  { key: 'details', tab: 'request.editTab.details', content: <GiftRequestEditPageDetails /> },
  { key: 'clientNotes', tab: 'request.editTab.clientNotes', content: <ClientNotes /> },
  { key: 'transactions', tab: 'request.editTab.transactions', content: <GiftRequestTransactions /> }
];
