import * as React from 'react';
import { useEffect } from 'react';
import { Button, Container } from '@axmit/clp-library';
import Alert from 'antd/es/alert';
import Icon from 'antd/es/icon';
import { Row } from 'antd/es/grid';
import Spin from 'antd/es/spin';
import { useHistory, useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { EPrivateRoutes } from 'common/const';
import { EmptyData } from 'common/components';
import { ScrollHooks } from 'common/hooks';
import { communicationGift, EGiftStatus, IGiftConnectedProps } from 'entities/Gift/index';
import { CardGiftHeader, CardGiftStatus, GiftFormTabs } from 'entities/Gift/components/index';

type AllProps = IGiftConnectedProps;

const GiftEditPageComponent = ({ getGiftModel, clearGiftModel, giftModel }: AllProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { id } = useParams();
  const { loading, data: gift } = giftModel;
  const failed = gift?.status === EGiftStatus.BudgetNotCollected;
  const needMorePayment = gift?.status === EGiftStatus.PaymentAwaiting;
  const needMailPaid = !gift?.isMailPaid && gift?.meta.isSendByMail;

  const disableForm = gift?.status !== EGiftStatus.Created && gift?.status !== EGiftStatus.PaymentAwaiting;

  ScrollHooks.useScrollToTop();

  useEffect(() => {
    id && getGiftModel(id);

    return () => clearGiftModel();
  }, [getGiftModel, clearGiftModel, id]);

  return (
    <Spin spinning={loading}>
      <Container>
        <Row type="flex" align="bottom" className="mb-25">
          <Button onClick={() => history.push(EPrivateRoutes.Main)} size="small" className="mr-25">
            <span>
              <Icon type="left" className="mr-8" />
              {t('defaultBtnBack')}
            </span>
          </Button>

          {gift && (
            <>
              <CardGiftHeader giftType={gift.type} recipient={gift.meta.recipientName} size="md" className="mb-5 mr-10" />

              <CardGiftStatus status={gift.status} className="mb-5" />
            </>
          )}
        </Row>

        {failed && <Alert message={t('errorDeliveryFailed')} type="error" className="mb-10" />}
        {needMailPaid && <Alert message={t('errorNeedMailPaid')} type="warning" className="mb-10" />}
        {needMorePayment && <Alert message={t('errorInsufficientFunds')} type="warning" className="mb-10" />}
      </Container>

      {gift ? <GiftFormTabs disabled={disableForm} /> : <EmptyData />}
    </Spin>
  );
};

export const GiftEditPage = communicationGift.injector(GiftEditPageComponent);
