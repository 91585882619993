import * as React from 'react';
import Typography from 'antd/es/typography';

export const TransactionsHeader = ({ className, children }: React.PropsWithChildren<{ className?: string }>) => {
  return (
    <Typography.Title className={`clp-typography-title clp-typography-title_size_xs mob-text_center ${className}`} level={3}>
      {children}
    </Typography.Title>
  );
};
