import React from 'react';
import { useHistory } from 'react-router';
import { CardInfo, Paragraph } from '@axmit/clp-library';
import Typography from 'antd/es/typography';
import { useTranslation } from 'react-i18next';
import { EPublicRoutes } from 'common/const';
import { TwoColumnsWithImage } from 'common/components/TwoColumnsWithImage';
import imgCamel700 from 'app/assets/images/camel_w_700.jpg';
import imgCamel565 from 'app/assets/images/camel_w_565.jpg';
import imgCamel1440 from 'app/assets/images/camel_w_1440.jpg';
import imgCamel2000 from 'app/assets/images/camel_w_2000.jpg';
import { SignUpForm } from 'entities/Auth/components/SignUpForm';

export const AuthSignUpForm = () => {
  const { t } = useTranslation();
  const history = useHistory<{ message?: string }>();

  const redirectToLogIn = () => {
    history.push(EPublicRoutes.LogIn);
  };
  const message = history.location.state?.message;
  const signUpFormHeader = message ? t('signUpFormHeaderAfterAcceptGift') : t('signUpFormHeader');

  return (
    <TwoColumnsWithImage
      imageSlot={
        <img
          sizes="calc(50vw - 20px)"
          srcSet={`
            ${imgCamel565} 565w,
            ${imgCamel700} 700w,
            ${imgCamel1440} 1440w,
            ${imgCamel2000} 2000w`}
          src={imgCamel700}
          alt="camel"
          className="img-sign"
        />
      }
      contentSlot={
        <>
          <CardInfo
            header={
              <Typography.Title className="clp-typography-title clp-typography-title_size_md mob-text_center" level={2}>
                {signUpFormHeader}
              </Typography.Title>
            }
            content={
              message ? <Paragraph className="mb-20 mt-15 optional-message">{t('signUpMessageAfterAcceptGift')}</Paragraph> : ''
            }
          />

          <SignUpForm redirectToLogIn={redirectToLogIn} />
        </>
      }
    />
  );
};
