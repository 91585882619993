import { APIProvider, BaseStrategy, Branch, buildCommunication, EActionsTypes, StoreBranch } from '@axmit/redux-communications';
import { BaseTransport } from 'common/dto/base.transport';
import { MessageHelper } from 'common/helpers';
import { IRequestCreateParams, IRequestModel } from 'entities/GiftRequests/GiftRequests.models';

const namespace = 'giftRequests';

export interface IGiftRequestsConnectedProps {
  giftRequestsModel: StoreBranch<IRequestModel>;
  addGiftRequestsModel(params: IRequestCreateParams): IRequestModel;
  getGiftRequestsModel(id: string): IRequestModel;
  clearGiftRequestsModel(): void;
}

const transport = new BaseTransport('gift-requests');

const modelApiProvider = [
  new APIProvider<IRequestModel, IRequestCreateParams>(EActionsTypes.add, transport.add, {
    onSuccess: MessageHelper.createSuccess
  }),
  new APIProvider<IRequestModel, string>(EActionsTypes.get, transport.get)
];

const branches = [new Branch('model', modelApiProvider)];

const strategy = new BaseStrategy({
  namespace,
  branches
});

export const communicationGiftRequests = buildCommunication<IGiftRequestsConnectedProps>(strategy);
