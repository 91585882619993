import React from 'react';
import { Container } from '@axmit/clp-library';
import { Row, Col } from 'antd/es/grid';
import imageKeyHolder from 'app/assets/images/julesCle.png';
import { ContactUsForm } from 'entities/Support/components/ContactUsForm';

export const ContactUs = () => {
  return (
    <Container>
      <Row type="flex" align="middle" style={{ minHeight: 600 }}>
        <Col sm={10}>
          <ContactUsForm />
        </Col>

        <Col sm={14}>
          <div className="ta-r pb-30 pt-30 pl-30">
            <img src={imageKeyHolder} alt="contact us" className="max-w_100" />
          </div>
        </Col>
      </Row>
    </Container>
  );
};
