import * as React from 'react';
import { FormItem, Input, PhoneInput, Button } from '@axmit/clp-library';
import { Row, Col } from 'antd/es/grid';
import { useTranslation } from 'react-i18next';
import { FormatHelper } from 'common/helpers';
import { TIME_FORMAT_DEFAULT } from 'common/config';

interface IComponentProps {
  recipient?: string;
  deliveryDate?: string;
  recipientPhone?: string;
  recipientEmail?: string;
  createdDate: string;
  userSenderName?: string;
  contactType?: string;
  budget?: number;
  contactDate?: string;
  refund?: () => void;
}

export const RequestEditPageGiftInfo = ({
  recipient,
  budget,
  contactDate,
  createdDate,
  deliveryDate,
  recipientEmail,
  refund,
  contactType,
  userSenderName,
  recipientPhone
}: IComponentProps) => {
  const { t } = useTranslation();

  const date = contactDate
    ? `${FormatHelper.getDateWithYear(contactDate)} ${FormatHelper.formatDate(TIME_FORMAT_DEFAULT, contactDate)}`
    : '-';

  return (
    <Row gutter={30}>
      <Col {...wrapperColXl}>
        <FormItem label={t('request.editForm.recipientName')}>
          <Input value={recipient} disabled />
        </FormItem>
      </Col>

      <Col {...wrapperCol}>
        <FormItem label={t('request.editForm.deliveryDate')}>
          <Input value={deliveryDate && FormatHelper.getDateWithYear(deliveryDate)} disabled />
        </FormItem>
      </Col>

      <Col {...wrapperCol}>
        <FormItem
          label={t('request.editForm.budget')}
          extra={
            refund && (
              <Button type="link" className="btn-text btn-text_red btn-text_small" onClick={refund}>
                {t('defaultBtnRollback')}
              </Button>
            )
          }
        >
          <Input value={FormatHelper.getCurrency(budget)} disabled />
        </FormItem>
      </Col>

      <Col {...wrapperColLg}>
        <FormItem
          label={t('request.editForm.contactDate')}
          extra={contactType && <small>{t('bySomething', { name: contactType })}</small>}
        >
          <Input value={date} disabled />
        </FormItem>
      </Col>

      <Col {...wrapperColXl}>
        <FormItem label={t('request.editForm.recipientPhone')}>
          {recipientPhone ? (
            <PhoneInput defaultValue={recipientPhone} onChange={() => {}} validateTrigger={() => {}} disabled block />
          ) : (
            <Input value="-" disabled />
          )}
        </FormItem>
      </Col>

      <Col {...wrapperColLg} xl={8}>
        <FormItem label={t('request.editForm.recipientEmail')}>
          <Input value={recipientEmail} disabled />
        </FormItem>
      </Col>

      <Col {...wrapperColLg}>
        <FormItem
          label={t('request.editForm.createdDate')}
          extra={userSenderName && <small>{t('bySomeone', { name: userSenderName })}</small>}
        >
          <Input value={FormatHelper.getDateWithYear(createdDate)} disabled />
        </FormItem>
      </Col>
    </Row>
  );
};

const wrapperColXl = { xs: 24, sm: 12, xl: 9 };
const wrapperColLg = { xs: 24, sm: 12, xl: 7 };
const wrapperCol = { xs: 24, sm: 12, xl: 4 };
