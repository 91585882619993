import React from 'react';
import { Route, RouteComponentProps, Switch, withRouter } from 'react-router';
import { getCreds } from '@axmit/axios-patch-jwt';
import { EPrivateRoutes, EPublicRoutes } from 'common/const/Routes.const';
import { AuthPage } from 'app/pages/AuthPage';
import { AuthSignUpForm } from 'entities/Auth/components/AuthSignUpForm';

class LayoutPublicComponent extends React.PureComponent<RouteComponentProps> {
  async componentDidMount() {
    const creds = await getCreds();

    if (creds.access) {
      this.props.history.push(EPrivateRoutes.Main);
    }
  }

  public render() {
    return (
      <Switch>
        <Route path={EPublicRoutes.SignUp} component={AuthSignUpForm} />
        <Route path="*" component={AuthPage} />
      </Switch>
    );
  }
}

export const LayoutPublic = withRouter(LayoutPublicComponent);
