import { WrappedFormUtils } from 'antd/es/form/Form';
import { SetStateAction } from 'react';
import { MIN_BUDGET_COLLECTIVE, MIN_BUDGET_INDIVIDUAL_EUR, MAX_BUDGET } from 'common/config';
import i18n from 'app/locales/i18n';
import { EGiftType, IGiftMeta, IGiftModel } from 'entities/Gift/Gift.models';

const setValueToMeta = (set: React.Dispatch<SetStateAction<IGiftModel>>, value: Partial<IGiftMeta>) => {
  set(prevValue => ({ ...prevValue, meta: { ...prevValue.meta, ...value } }));
};

const submitForm = (form: WrappedFormUtils, onSuccess: Function) => {
  form.validateFields(err => {
    if (err) {
      return;
    }

    onSuccess();
  });
};

const getMinBudget = (giftType?: EGiftType): number => {
  return giftType === EGiftType.Individual ? MIN_BUDGET_INDIVIDUAL_EUR : MIN_BUDGET_COLLECTIVE;
};

const amountIsValid = (minAmount?: number, amount?: number): boolean => {
  if (amount === undefined) {
    return false;
  }

  if (amount >= MAX_BUDGET) {
    return false;
  }

  if (minAmount === undefined) {
    return true;
  }

  return amount >= minAmount;
};

const getAmountValidationMessage = (minAmount?: number, amount?: number): string => {
  const defaultKey = i18n.t('errorValueMoreThen', { min: minAmount });

  if (amount === undefined) {
    return defaultKey;
  }

  if (amount >= MAX_BUDGET) {
    return i18n.t('errorValueMinThen', { max: MAX_BUDGET });
  }

  if (minAmount === undefined) {
    return '';
  }

  if (amount < minAmount) {
    return defaultKey;
  }

  return '';
};

export const GiftUpdateHelper = {
  setValueToMeta,
  submitForm,
  getMinBudget,
  getAmountValidationMessage,
  amountIsValid
};
