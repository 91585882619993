import * as React from 'react';
import { FormEvent } from 'react';
import { Button } from '@axmit/clp-library';
import { HooksHelper } from '@axmit/clp-library/dist/helpers';
import Form from 'antd/es/form';
import { FormComponentProps } from 'antd/es/form';
import { useTranslation } from 'react-i18next';
import { AntdFormHelper } from '@axmit/antd-helpers';
import { compose } from 'redux';
import { InputForm } from 'common/components';
import { ECalculatorColumns } from 'entities/Payment/Payment.const';
import {
  communicationRequestExpenses,
  IRequestExpensesConnectedProps
} from 'entities/RequestExpenses/RequestExpenses.communication';
import { IRequestsConnectedProps, communicationRequests } from 'entities/Requests/Requests.communication';

type AllProps = IRequestExpensesConnectedProps & IRequestsConnectedProps & FormComponentProps;

const BudgetCalculatorFormComponent = (props: AllProps) => {
  const { t } = useTranslation();
  const { form, addRequestExpensesModel, requestsModel } = props;
  const { getFieldDecorator } = form;

  const editMode = HooksHelper.useStateBuilder<boolean>(false);
  const toggleEditMode = () => {
    editMode.set(prevValue => !prevValue);
  };

  const submit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    form.validateFields((err, values) => {
      if (err) {
        return;
      }

      const requestId = requestsModel.data?.id;

      requestId &&
        addRequestExpensesModel({
          request: requestId,
          amount: Number(values[ECalculatorColumns.amount]),
          description: values[ECalculatorColumns.description]
        });
    });
  };

  if (!editMode.value) {
    return (
      <Button type="link" size="small" onClick={toggleEditMode}>
        {t('calculator.addNewBtn')}
      </Button>
    );
  }

  return (
    <Form className="mt-20" onSubmit={submit}>
      <InputForm
        getFieldDecorator={getFieldDecorator}
        fieldName={ECalculatorColumns.description}
        label={t(`calculator.table.${ECalculatorColumns.description}`)}
        rules={[{ required: true }]}
      />

      <InputForm
        inputProps={{ type: 'number', addonBefore: '€', min: 0, step: 1 }}
        getFieldDecorator={getFieldDecorator}
        fieldName={ECalculatorColumns.amount}
        label={t(`calculator.table.${ECalculatorColumns.amount}`)}
        rules={[{ required: true }]}
      />

      <Button type="primary" size="small" htmlType="submit" className="ant-btn_mob-block">
        {t('btnSave')}
      </Button>

      <Button size="small" onClick={toggleEditMode} htmlType="submit" className="ml-10 ant-btn_mob-block">
        {t('defaultBtnCancel')}
      </Button>
    </Form>
  );
};

const containers = compose(communicationRequestExpenses.injector, communicationRequests.injector);

export const BudgetCalculatorForm = containers(
  Form.create<AllProps>({
    mapPropsToFields(props: AllProps) {
      const { requestExpensesModel } = props;

      return AntdFormHelper.mapValidationToFields(requestExpensesModel?.params, requestExpensesModel?.errors);
    }
  })(BudgetCalculatorFormComponent)
);
