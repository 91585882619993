export enum EPublicRoutes {
  LogIn = '/login',
  GetConfirm = '/get-confirm',
  SignUp = '/signup',
  NewPassword = '/new-password/code',
  RestorePassword = '/restore-password'
}

export enum ECommonRoutes {
  Logout = '/logout',
  Discover = '/discover',
  ContactUs = '/contact-us',
  Finalize = '/finalize',
  Contributor = '/gift-contributor',
  Confirmation = '/confirmation',
  NewGift = '/new-gift'
}

export enum EPrivateRoutes {
  Main = '/',
  ChangePassword = '/change-password',
  PersonalDesktop = '/personal',
  PersonalMobile = '/personal-view',
  BankingDesktop = '/banking',
  BankingMobile = '/banking-view',
  GiftEditPage = '/gift-edit',
  GiftViewPage = '/gift-view',
  TransactionRequest = '/transaction-request',
  TransactionGiftRequest = '/transaction-gift-request',
  GiftPayment = '/gift-payment'
}

export enum EBackOfficeRoutes {
  TransactionRequest = '/transaction-request',
  TransactionGiftRequest = '/transaction-gift-request',
  CreateRequest = '/requests-create',
  Messages = '/messages',
  Managers = '/managers',
  ManagersCreate = '/managers/create',
  Clients = '/clients',
  Companies = '/companies',
  RequestEditPage = '/request-edit'
}

export enum ELandingRoutes {
  How = 'how',
  FAQ = 'faq',
  TOC = 'toc',
  PrivacyPolicy = 'privacy-policy',
  Philosophy = 'philosophy'
}
