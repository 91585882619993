import { Container } from '@axmit/clp-library';
import Typography from 'antd/es/typography';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

export const BOUserTransactions = () => {
  const { t } = useTranslation();
  const link = window?.location.href || '';
  return (
    <Container>
      <Typography.Title className="clp-typography-title clp-typography-title_size_md ta-c mb-30 mt-50">
        {t('userTransactionsForBO')}
      </Typography.Title>

      {link && (
        <Typography.Paragraph className="ta-c" copyable>
          {link}
        </Typography.Paragraph>
      )}
    </Container>
  );
};
