import { EActionsTypes, APIProvider, BaseStrategy, Branch, buildCommunication, StoreBranch } from '@axmit/redux-communications';
import { BaseTransport } from 'common/dto/base.transport';
import { ICountriesCollection, ICountriesCollectionParams } from 'entities/Countries/Countries.models';

const namespace = 'countries';

export interface ICountriesConnectedProps {
  countriesCollection: StoreBranch<ICountriesCollection>;
  getCountriesCollection(params: ICountriesCollectionParams): void;
  clearCountriesCollection(): void;
}

const transport = new BaseTransport('countries');

const collectionApiProvider = [new APIProvider(EActionsTypes.get, transport.getCollection)];

const branches = [new Branch('collection', collectionApiProvider)];

const strategy = new BaseStrategy({
  namespace,
  branches
});

export const communicationCountries = buildCommunication<ICountriesConnectedProps>(strategy);
