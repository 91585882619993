import { useLocation } from 'react-router';

const host: string = window.location.hostname;
const subDomain: string = 'app.';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function getDomain() {
  const index = host.indexOf(subDomain);

  return index < 0 ? host : host.slice(index + subDomain.length);
}

export const RouterHelper = { useQuery, getDomain };
