import * as React from 'react';

interface IComponentProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  logo?: string;
}

export const LogoGift = ({ className, logo, ...params }: IComponentProps) => {
  return (
    <div className={`clp-logo ${params.onClick ? 'c-pointer' : ''} ${className}`} {...params}>
      <div>
        <img src={logo} alt="clé privée" />
      </div>

      <div className="clp-logo__text-wrapper">
        <div className="clp-logo__label">clé privée</div>
      </div>
    </div>
  );
};
