import React from 'react';

export const SvgInstagram = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.9991 8.87628C10.2788 8.87628 8.87492 10.2802 8.87492 12.0005C8.87492 13.7208 10.2788 15.1247 11.9991 15.1247C13.7194 15.1247 15.1234 13.7208 15.1234 12.0005C15.1234 10.2802 13.7194 8.87628 11.9991 8.87628ZM21.3694 12.0005C21.3694 10.7068 21.3812 9.42472 21.3085 8.13331C21.2359 6.63331 20.8937 5.30206 19.7968 4.20519C18.6976 3.10597 17.3687 2.76613 15.8687 2.69347C14.5749 2.62081 13.2929 2.63253 12.0015 2.63253C10.7077 2.63253 9.4257 2.62081 8.13429 2.69347C6.63429 2.76613 5.30304 3.10831 4.20617 4.20519C3.10695 5.30441 2.7671 6.63331 2.69445 8.13331C2.62179 9.42706 2.63351 10.7091 2.63351 12.0005C2.63351 13.2919 2.62179 14.5763 2.69445 15.8677C2.7671 17.3677 3.10929 18.6989 4.20617 19.7958C5.30538 20.895 6.63429 21.2349 8.13429 21.3075C9.42804 21.3802 10.7101 21.3685 12.0015 21.3685C13.2952 21.3685 14.5773 21.3802 15.8687 21.3075C17.3687 21.2349 18.6999 20.8927 19.7968 19.7958C20.896 18.6966 21.2359 17.3677 21.3085 15.8677C21.3835 14.5763 21.3694 13.2943 21.3694 12.0005ZM11.9991 16.8075C9.33898 16.8075 7.1921 14.6607 7.1921 12.0005C7.1921 9.34035 9.33898 7.19347 11.9991 7.19347C14.6593 7.19347 16.8062 9.34035 16.8062 12.0005C16.8062 14.6607 14.6593 16.8075 11.9991 16.8075ZM17.003 8.11925C16.3819 8.11925 15.8804 7.61769 15.8804 6.9966C15.8804 6.3755 16.3819 5.87394 17.003 5.87394C17.6241 5.87394 18.1257 6.3755 18.1257 6.9966C18.1259 7.14408 18.097 7.29015 18.0406 7.42644C17.9843 7.56273 17.9016 7.68656 17.7973 7.79085C17.693 7.89513 17.5692 7.97782 17.4329 8.03417C17.2966 8.09053 17.1505 8.11944 17.003 8.11925Z"
      fill="black"
    />
  </svg>
);
