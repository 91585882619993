import React from 'react';
import { Button, CardInfo, CardStepByStep, FormItem, Paragraph, TextArea } from '@axmit/clp-library';
import { FormComponentProps } from 'antd/es/form';
import Icon from 'antd/es/icon';
import { Row } from 'antd/es/grid';
import Form from 'antd/es/form';
import { useTranslation } from 'react-i18next';
import { SeparatedModalContent } from 'common/components';
import julesMapLetter from 'app/assets/images/julesMapLetter.png';
import { IGiftCreateViewModel } from 'entities/Gift';
import { GiftCreateHelper } from 'entities/Gift/helpers/GiftCreate.helper';

interface IComponentProps {
  updateGift: (data: Partial<IGiftCreateViewModel>) => void;
  handlePreviousSlide: () => void;
  handleNextSlide: () => void;
  gift: Partial<IGiftCreateViewModel>;
  loading?: boolean;
}

type AllProps = IComponentProps & FormComponentProps;

export const NewGiftFormActivationComponent = ({
  gift,
  form,
  updateGift,
  handlePreviousSlide,
  handleNextSlide,
  loading
}: AllProps) => {
  const { t } = useTranslation();
  const { getFieldDecorator } = form;

  const letter = GiftCreateHelper.getActivationLetter(gift.name);

  const submit = () => {
    form.validateFields(err => {
      if (err) {
        return;
      }

      handleNextSlide();
    });
  };

  return (
    <SeparatedModalContent
      rightSlotFooter={
        <Row type="flex" justify="space-between" className="mt-20 mb-10">
          <Button size="small" onClick={handlePreviousSlide} disabled={loading}>
            <span>
              <Icon type="left" className="mr-8" />
              {t('newGiftDefaultBtnBack')}
            </span>
          </Button>

          <Button type="primary" size="small" onClick={submit} disabled={loading}>
            <span>
              {t('newGiftDefaultBtnNext')}
              <Icon type="right" className="ml-8" />
            </span>
          </Button>
        </Row>
      }
    >
      <div className="aside-slide aside-slide_discovery hidden_mob" style={{ backgroundImage: `url(${julesMapLetter})` }}>
        <div className="aside-slide__wrapper aside-slide__wrapper_letter">
          <div className="aside-slide__content aside-slide__content_letter">{letter}</div>
        </div>
      </div>

      <CardStepByStep label={t('newGiftActivationHeader')} subLabel={t('step5')} className="mb-30" />

      <CardInfo
        headerSize="sm"
        content={
          <Paragraph size="xs" className="mb-20 mt-15">
            {t('newGiftActivationMessage')}
          </Paragraph>
        }
      />

      <Form
        onBlur={() => {
          updateGift(form.getFieldsValue());
        }}
      >
        <FormItem>
          {getFieldDecorator('activation', {
            initialValue: gift.activation || letter,
            rules: [
              {
                required: true,
                message: t('errorRequiredDefault')
              }
            ]
          })(<TextArea disabled={loading} autoSize={{ minRows: 10, maxRows: 16 }} autoFocus />)}
        </FormItem>
      </Form>
    </SeparatedModalContent>
  );
};

export const NewGiftFormActivation = Form.create<AllProps>()(NewGiftFormActivationComponent);
