import * as React from 'react';
import { compose } from 'redux';
import { EmptyData } from 'common/components';
import { ClientNotesForm } from 'entities/ClientNotes/components/ClientNotesForm';
import { communicationRequests, IRequestsConnectedProps } from 'entities/Requests/Requests.communication';
import { communicationUsers, IUsersConnectedProps } from 'entities/User/Users.communication';

type AllProps = IRequestsConnectedProps & IUsersConnectedProps;

const ClientNotesComponent = ({ requestsModel, usersModel }: AllProps) => {
  const email =
    requestsModel.data?.giftData?.meta?.recipientEmail ||
    requestsModel.data?.giftRequestData?.userEmail ||
    usersModel.data?.email;

  return email ? <ClientNotesForm email={email} /> : <EmptyData />;
};

const containers = compose(communicationRequests.injector, communicationUsers.injector);

export const ClientNotes = containers(ClientNotesComponent);
