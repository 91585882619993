import React, { useEffect } from 'react';
import Typography from 'antd/es/typography';
import Icon from 'antd/es/icon';
import { Row } from 'antd/es/grid';
import { Button } from '@axmit/clp-library';
import { useTranslation } from 'react-i18next';
import { SeparatedModalContent, Spinner } from 'common/components';
import { FormatHelper } from 'common/helpers';
import julesMapSearch from 'app/assets/images/julesMapSearch.png';
import { IGiftCreateViewModel } from 'entities/Gift';
import { communicationPayment, IPaymentConnectedProps } from 'entities/Payment';

interface IComponentProps {
  handlePreviousSlide: () => void;
  handleNextSlide: () => void;
  gift: Partial<IGiftCreateViewModel>;
  loading?: boolean;
}

type AllProps = IPaymentConnectedProps & IComponentProps;

export const NewGiftFormCalculatorComponent = ({
  gift,
  loading,
  handlePreviousSlide,
  handleNextSlide,
  paymentCalculator,
  getPaymentCalculator
}: AllProps) => {
  const { t } = useTranslation();
  const { isSendByEmail, city, country, house, state, street, zipCode, date, name, email } = gift;
  const prefix: string = house ? `${house}, ` : '';
  const address: string | undefined = isSendByEmail
    ? undefined
    : `${prefix} ${street}, ${zipCode}, ${city}, ${state}, ${country?.name}`;
  const total = paymentCalculator.data?.total || 0;
  const amount = paymentCalculator.data?.amount || 0;
  const fee = paymentCalculator.data?.commission || 0;
  const mailCharge = paymentCalculator.data?.mailCharge || 0;
  const dueDate = date && FormatHelper.getDateWithYear(date.toString());
  const disabled = loading || paymentCalculator.loading;

  useEffect(() => {
    getPaymentCalculator({ isMailRequired: !gift.isSendByEmail, country: gift.country, amount: gift.currency || 0 });
  }, [getPaymentCalculator, gift.isSendByEmail, gift.country, gift.currency]);

  const submit = () => {
    handleNextSlide();
  };

  return (
    <SeparatedModalContent
      rightSlotFooter={
        <Row type="flex" justify="space-between" className="mt-20 mb-10">
          <Button size="small" onClick={handlePreviousSlide}>
            <span>
              <Icon type="left" className="mr-8" />
              {t('newGiftDefaultBtnBack')}
            </span>
          </Button>

          <Button type="primary" size="small" onClick={submit} disabled={disabled}>
            <span>
              {t('newGiftActivationSubmit')}
              <Icon type="right" className="ml-8" />
            </span>
          </Button>
        </Row>
      }
    >
      <img src={julesMapSearch} alt="type" className="aside-slide hidden_mob aside-slide_position_center" />

      <Spinner spinning={paymentCalculator.loading}>
        <Typography.Title className="clp-typography-title clp-typography-title_size_md mob-text_center mb-30" level={2}>
          {t('newGiftCalculator.header')}
        </Typography.Title>

        {paymentCalculator.data && (
          <>
            <div className="mb-15">
              <span className="color_light opacity_light">{t('newGiftCalculator.labels.recipient')}</span>
              <b className="color_primary">
                {name}, {email}
              </b>
            </div>

            <div className="mb-15">
              <span className="color_light opacity_light">{t('newGiftCalculator.labels.dueDate')}</span>
              <b className="color_primary">{dueDate}</b>
            </div>

            <div className="mb-15">
              <span className="color_light opacity_light">{t('newGiftCalculator.labels.mailAddress')}</span>
              <b className="color_primary">{address}</b>
            </div>

            <div className="mb-15">
              <span className="color_light opacity_light">{t('newGiftCalculator.labels.cost')}</span>
              <b className="color_primary">{FormatHelper.getCurrency(amount)}</b>
            </div>

            <div className="mb-15">
              <span className="color_light opacity_light">{t('newGiftCalculator.labels.fee')}</span>
              <b className="color_primary">{FormatHelper.getCurrency(fee)}</b>
            </div>

            <div className="mb-15">
              <span className="color_light opacity_light">{t('newGiftCalculator.labels.costDelivery')}</span>
              <b className="color_primary">{FormatHelper.getCurrency(mailCharge)}</b>
            </div>

            <div className="mb-15">
              <span className="color_light opacity_light">{t('newGiftCalculator.labels.costTotal')}</span>
              <b className="color_primary">{FormatHelper.getCurrency(total)}</b>
            </div>
          </>
        )}
      </Spinner>
    </SeparatedModalContent>
  );
};

export const NewGiftFormCalculator = communicationPayment.injector(NewGiftFormCalculatorComponent);
