import * as React from 'react';
import { Container, Tabs } from '@axmit/clp-library';
import AntTabs from 'antd/es/tabs';
import { TabsProps } from 'antd/lib/tabs';
import { useTranslation } from 'react-i18next';
import { Mobile } from 'common/components/Mobile';
import { Desktop } from 'common/components/Desktop';

export interface IExpandedTabPane {
  tab: string;
  label?: string;
  component: JSX.Element;
}

interface IComponentProps extends TabsProps {
  tabs: IExpandedTabPane[];
}

export const ExpandedTabs = ({ tabs, ...rest }: IComponentProps) => {
  const { t } = useTranslation();

  return (
    <Tabs
      {...rest}
      renderTabBar={(props: TabsProps, DefaultTabBar: React.ComponentClass) => {
        return (
          <>
            <Mobile>
              <DefaultTabBar {...props} />
            </Mobile>

            <Desktop>
              <Container>
                <DefaultTabBar {...props} />
              </Container>
            </Desktop>
          </>
        );
      }}
    >
      {tabs.map(item => (
        <AntTabs.TabPane tab={t(item.label || item.tab)} key={item.tab}>
          <Container>{item.component}</Container>
        </AntTabs.TabPane>
      ))}
    </Tabs>
  );
};
