import axios from 'axios';
import { BaseTransport } from 'common/dto/base.transport';
import { IEmployeesBalance } from 'entities/Companies/Companies.models';
import { IUserModel } from 'entities/User/Users.models';

export class UsersTransport extends BaseTransport<IUserModel> {
  public getUsersBalance = (userId: string): Promise<IEmployeesBalance> => {
    return axios.get(`${this.path}/${userId}/balance`);
  };

  public recover = (userId: string) => {
    return axios.post(`${this.path}/${userId}/recover`);
  };
}
