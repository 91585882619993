import React from 'react';
import { FormatHelper } from 'common/helpers';

export enum ETransactionsHistoryColumns {
  type = 'type',
  description = 'description',
  amount = 'amount',
  action = 'action'
}

export interface ITransactionsTotalRow {
  [ETransactionsHistoryColumns.type]: string;
  [ETransactionsHistoryColumns.amount]: number;
  key: 'total';
}

export const transactionsHistoryColumns = [
  {
    dataIndex: ETransactionsHistoryColumns.description,
    render: (description: string) => <div className="break-word">{description}</div>
  },
  {
    dataIndex: ETransactionsHistoryColumns.amount,
    align: 'right' as const,
    render: (amount: number) => {
      return <div>-&nbsp;{FormatHelper.getCurrency(amount)}</div>;
    }
  },
  {
    dataIndex: ETransactionsHistoryColumns.action,
    width: '75px'
  }
];
