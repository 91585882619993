import { Button, FormItem } from '@axmit/clp-library';
import { HooksHelper } from '@axmit/clp-library/dist/helpers';
import Typography from 'antd/es/typography';
import { Row, Col } from 'antd/es/grid';
import Form from 'antd/es/form';
import { FormComponentProps } from 'antd/es/form';
import React from 'react';
import { compose } from 'redux';
import { useTranslation } from 'react-i18next';
import { InputForm } from 'common/components';
import { CurrencyForm } from 'entities/Payment/components';
import { TransactionsHeader } from 'entities/Requests/components';
import { communicationRequests, IRequestsConnectedProps } from 'entities/Requests/Requests.communication';
import { GiftTransactionsHelper } from 'entities/GiftTransactions/helpers/GiftTransactions.helper';

type AllProps = FormComponentProps & IRequestsConnectedProps;

const ManagersTransactionsGenerateComponent = ({ form, requestsModel }: AllProps) => {
  const { t } = useTranslation();
  const { getFieldDecorator } = form;

  const link = HooksHelper.useStateBuilder<string>('');

  const amount = HooksHelper.useStateBuilder<number | undefined>(1);
  const currencyIsValid = HooksHelper.useStateBuilder(true);

  const submit = () => {
    form.validateFields((err, values) => {
      if (err || !requestsModel.data) {
        return;
      }

      const description = values?.requestDescription;
      const resultLink = GiftTransactionsHelper.getDonateLink(requestsModel.data, description, amount.value);

      link.set(encodeURI(resultLink));
    });
  };

  if (!requestsModel.data?.giftData && !requestsModel.data?.giftRequestData) {
    return null;
  }

  return (
    <>
      <TransactionsHeader className="mb-20 mt-50">{t('transactions.deposit.header')}</TransactionsHeader>

      <Row gutter={15}>
        <Col xs={24} lg={12} xl={16}>
          <InputForm
            initialValue=""
            getFieldDecorator={getFieldDecorator}
            fieldName="requestDescription"
            label={t('transactions.add.form.description')}
          />
        </Col>

        <Col xs={24} lg={12} xl={8}>
          <FormItem label={t('transactions.add.form.amount')} className="m-0">
            <CurrencyForm amount={amount.value} setAmount={amount.set} setValid={currencyIsValid.set} minAmount={1} />
          </FormItem>
        </Col>
      </Row>

      {link.value && (
        <Typography.Paragraph className="mb-20" copyable>
          {link.value}
        </Typography.Paragraph>
      )}

      <Button className="ant-btn_mob-block mb-15" onClick={submit}>
        {t('transactions.deposit.submit')}
      </Button>
    </>
  );
};

const containers = compose(communicationRequests.injector);

export const ManagersTransactionsGenerate = containers(Form.create<AllProps>()(ManagersTransactionsGenerateComponent));
