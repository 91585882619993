import React, { useEffect } from 'react';
import { CardInfo, CardStepByStep, Paragraph } from '@axmit/clp-library';
import { useHistory, useLocation } from 'react-router';
import { AppLandingHelper } from '@axmit/router-app-landing';
import { useTranslation } from 'react-i18next';
import { Col } from 'antd/es/grid';
import { ECommonRoutes, EPrivateRoutes } from 'common/const';
import { EmptyData, SeparatedModal, Spinner } from 'common/components';
import asideImg from 'app/assets/images/keyHolder.png';
import logo from 'app/assets/images/logo.png';
import { GiftCheckoutForm } from 'entities/Payment/components';
import { communicationGift, EGiftType, IGiftConnectedProps } from 'entities/Gift';
import { GiftUpdateHelper } from 'entities/Gift/helpers/GiftUpdate.helper';
import { communicationPayment, IPaymentConnectedProps } from 'entities/Payment';

type AllProps = IGiftConnectedProps & IPaymentConnectedProps;

export const GiftPaymentComponent = (props: AllProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { state } = useLocation<{ currency?: number }>();
  const currency = state?.currency;
  const { giftCreateModel, clearGiftCreateModel, clearGiftViewModel, getPaymentCalculator, paymentCalculator } = props;
  const { meta } = giftCreateModel.data || {};
  const { isSendByMail, country } = meta || {};

  const minAmount = GiftUpdateHelper.getMinBudget(
    giftCreateModel.data?.type === EGiftType.Individual ? EGiftType.Individual : EGiftType.Collective
  );
  const initAmount: number = paymentCalculator.data?.total || minAmount;
  const hasData: boolean = !!giftCreateModel.data && !!paymentCalculator.data;

  useEffect(() => {
    getPaymentCalculator({
      isMailRequired: !!isSendByMail,
      country,
      amount: currency || minAmount
    });
  }, [getPaymentCalculator, country, isSendByMail, currency, minAmount]);

  const onClose = async () => {
    clearGiftViewModel();
    clearGiftCreateModel();

    history.push(EPrivateRoutes.Main);
  };

  return (
    <SeparatedModal
      logo={logo}
      onClose={onClose}
      onLogoClick={() => AppLandingHelper.toggleRoute()}
      rightSlot={
        <>
          <img
            src={asideImg}
            alt="type"
            className="aside-slide aside-slide_position_center aside-slide_rotate_horizontal hidden_mob"
          />
          <Spinner spinning={giftCreateModel.loading}>
            <CardStepByStep label={t('paymentHeader')} subLabel="" className="mb-20" />

            {hasData ? (
              <CardInfo
                content={
                  <Paragraph size="xs" className="mb-25 mt-10">
                    {t('paymentPageMessage')}
                  </Paragraph>
                }
              />
            ) : (
              <Col xs={24} xl={12}>
                <EmptyData />
              </Col>
            )}

            {giftCreateModel.data?.id && hasData && (
              <GiftCheckoutForm
                calculateParams={{ amount: currency || minAmount, country, isMailRequired: !!isSendByMail, viewOnly: true }}
                disableAmount
                giftId={giftCreateModel.data.id}
                minAmount={minAmount}
                amountInit={initAmount}
                successCallback={() => {
                  props.clearGiftViewModel();
                  props.clearGiftCreateModel();
                  history.push(ECommonRoutes.Finalize);
                }}
              />
            )}
          </Spinner>
        </>
      }
    />
  );
};

export const GiftPayment = communicationPayment.injector(communicationGift.injector(GiftPaymentComponent));
