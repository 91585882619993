import React from 'react';
import { Row, Col } from 'antd/es/grid';
import Form from 'antd/es/form';
import { CardInfo, Checkbox, Paragraph } from '@axmit/clp-library';
import { HooksHelper } from '@axmit/clp-library/dist/helpers';
import { FormComponentProps } from 'antd/es/form';
import { useTranslation } from 'react-i18next';
import { InputForm, TextAreaForm } from 'common/components';
import { communicationGift, IGiftConnectedProps, IGiftMeta, IGiftModel } from 'entities/Gift';
import { GiftUpdateHelper } from 'entities/Gift/helpers/GiftUpdate.helper';
import { GiftCreateHelper } from 'entities/Gift/helpers/GiftCreate.helper';

type AllProps = FormComponentProps & IGiftConnectedProps;

export const GiftFormDiscoveryComponent = ({ form, giftModel }: AllProps) => {
  const { t } = useTranslation();
  const { getFieldDecorator } = form;
  const { data: gift } = giftModel;

  if (!gift) {
    return null;
  }

  const formData = HooksHelper.useStateBuilder<IGiftModel>(gift);

  const setValueToMeta = (value: Partial<IGiftMeta>) => {
    GiftUpdateHelper.setValueToMeta(formData.set, value);
  };

  const letter = GiftCreateHelper.getDiscoveryLetter(gift?.meta?.recipientName);

  return (
    <Form>
      <CardInfo
        header={t('newGiftDiscoverySubHeader')}
        headerSize="sm"
        content={
          <Paragraph size="xs" className="mb-20 mt-15">
            {t('newGiftDiscoveryMessage')}
          </Paragraph>
        }
      />

      <TextAreaForm
        getFieldDecorator={getFieldDecorator}
        fieldName="emailMessage"
        onBlur={setValueToMeta}
        initialValue={letter}
        textAreaProps={{ autoSize: { minRows: 10, maxRows: 16 }, className: 'mb-15', disabled: true }}
      />

      <Checkbox className="mb-20" disabled defaultChecked={!formData.value.meta.isSendByMail}>
        {t('newGiftDiscoveryCheckbox')}
      </Checkbox>

      {formData.value.meta.isSendByMail && (
        <>
          <Row gutter={30}>
            <Col md={12}>
              <InputForm
                inputProps={{ disabled: true }}
                getFieldDecorator={getFieldDecorator}
                fieldName="street"
                label={t('form.labels.address1')}
                initialValue={formData.value.meta.street}
                onBlur={setValueToMeta}
                required
              />
            </Col>

            <Col md={12}>
              <InputForm
                inputProps={{ disabled: true }}
                getFieldDecorator={getFieldDecorator}
                fieldName="house"
                label={t('form.labels.address2')}
                initialValue={formData.value.meta.house}
                onBlur={setValueToMeta}
                required
              />
            </Col>
          </Row>

          <Row gutter={30}>
            <Col md={12}>
              <InputForm
                inputProps={{ disabled: true }}
                getFieldDecorator={getFieldDecorator}
                fieldName="city"
                label={t('form.labels.city')}
                initialValue={formData.value.meta.city}
                onBlur={setValueToMeta}
                required
              />
            </Col>

            <Col md={12}>
              <InputForm
                inputProps={{ disabled: true }}
                getFieldDecorator={getFieldDecorator}
                fieldName="state"
                label={t('form.labels.state')}
                initialValue={formData.value.meta.state}
                onBlur={setValueToMeta}
                required
              />
            </Col>
          </Row>

          <Row gutter={30}>
            <Col md={12}>
              <InputForm
                inputProps={{ disabled: true }}
                getFieldDecorator={getFieldDecorator}
                fieldName="country"
                label={t('form.labels.country')}
                initialValue={formData.value.meta.country?.name}
                onBlur={setValueToMeta}
                required
              />
            </Col>

            <Col md={12}>
              <InputForm
                inputProps={{ disabled: true }}
                getFieldDecorator={getFieldDecorator}
                fieldName="zipCode"
                label={t('form.labels.zipCode')}
                initialValue={formData.value.meta.zipCode}
                onBlur={setValueToMeta}
                required
              />
            </Col>
          </Row>
        </>
      )}
    </Form>
  );
};

export const GiftFormDiscovery = communicationGift.injector(Form.create<AllProps>()(GiftFormDiscoveryComponent));
