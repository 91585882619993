import * as React from 'react';
import Icon from 'antd/es/icon';

interface IComponentProps {
  price?: string | number;
  fullWidth?: boolean;
  className?: string;
}

export const GiftPrice = ({ price, fullWidth = false, className = '' }: IComponentProps) => {
  return (
    <div className={`gift-price ${fullWidth ? 'gift-price_full' : ''} ${className}`}>
      <div className="gift-price__icon">
        <Icon type="gift" />
      </div>

      <div>{price}</div>
    </div>
  );
};
