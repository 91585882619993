import React from 'react';
import Spin from 'antd/es/spin';
import { HooksHelper } from '@axmit/clp-library/dist/helpers';
import { communicationPayment, IPaymentConnectedProps, ICheckoutFormParams } from 'entities/Payment';
import { CheckoutForm, CurrencyForm } from 'entities/Payment/components';

interface IComponentProps {
  add: (params: ICheckoutFormParams) => void;
  minAmount?: number;
  successCallback?: () => void;
  amountInit?: number;
  onlyNewCard?: boolean;
}

type AllProps = IPaymentConnectedProps & IComponentProps;

const UserEmployeeCheckoutFormComponent = (props: AllProps) => {
  const { paymentModel, minAmount, successCallback, amountInit, add, onlyNewCard } = props;

  const amount = HooksHelper.useStateBuilder<number | undefined>(amountInit);
  const currencyIsValid = HooksHelper.useStateBuilder(true);
  const stripeLoading = HooksHelper.useStateBuilder(false);

  return (
    <Spin spinning={paymentModel.loading || stripeLoading.value}>
      <CurrencyForm amount={amount.value} setAmount={amount.set} setValid={currencyIsValid.set} minAmount={minAmount} />

      <CheckoutForm
        onlyNewCard={onlyNewCard}
        add={add}
        amount={amount.value}
        currencyIsValid={currencyIsValid.value}
        successCallback={successCallback}
        setStripeLoading={stripeLoading.set}
      />
    </Spin>
  );
};

export const UserEmployeeCheckoutForm = communicationPayment.injector(UserEmployeeCheckoutFormComponent);
