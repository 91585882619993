import { AntdFormHelper } from '@axmit/antd-helpers';
import { Button, FormItem, MonthPicker } from '@axmit/clp-library';
import { HooksHelper } from '@axmit/clp-library/dist/helpers';
import { Row, Col } from 'antd/es/grid';
import Form from 'antd/es/form';
import { FormComponentProps } from 'antd/es/form';
import moment from 'moment';
import React from 'react';
import { compose } from 'redux';
import { useTranslation } from 'react-i18next';
import { DateHelper } from 'common/helpers';
import { InputForm, Spinner } from 'common/components';
import { communicationPayment, IPaymentConnectedProps } from 'entities/Payment/Payment.communication';

interface IComponentProps {
  userId: string;
}

type AllProps = IPaymentConnectedProps & IComponentProps & FormComponentProps;

const CardCreateFormComponent = (props: AllProps) => {
  const { t } = useTranslation();
  const { userId, form, paymentMethod } = props;
  const { loading } = paymentMethod;
  const { getFieldDecorator } = form;
  const expiry = HooksHelper.useStateBuilder<moment.Moment>(moment().add(1, 'year'));

  const submit = () => {
    form.validateFields((err, values) => {
      if (err) {
        return;
      }

      props.addPaymentMethod({
        cvc: values.cvc,
        // eslint-disable-next-line @typescript-eslint/camelcase
        exp_month: Number(expiry.value.format('MM')),
        // eslint-disable-next-line @typescript-eslint/camelcase
        exp_year: Number(expiry.value.format('YY')),
        number: values.number,
        userId
      });
    });
  };

  return (
    <Spinner spinning={loading}>
      <Form>
        <Row gutter={30}>
          <Col xs={24} md={12}>
            <InputForm
              getFieldDecorator={getFieldDecorator}
              fieldName="number"
              label={t('cardCreate.number.label')}
              rules={[
                {
                  min: 13,
                  max: 19,
                  message: t('cardCreate.number.notValid')
                }
              ]}
              required
            />
          </Col>
        </Row>

        <Row gutter={30}>
          <Col xs={24} md={12} lg={6}>
            <FormItem label={t('cardCreate.expiry.label')}>
              <MonthPicker
                allowClear={false}
                format="MM/YY"
                defaultValue={expiry.value}
                disabledDate={DateHelper.isTodayOrBefore}
                onChange={date => date && expiry.set(date)}
              />
            </FormItem>
          </Col>

          <Col xs={24} md={12} lg={6}>
            <InputForm
              getFieldDecorator={getFieldDecorator}
              fieldName="cvc"
              label={t('cardCreate.cvc.label')}
              rules={[
                {
                  min: 3,
                  max: 4,
                  message: t('cardCreate.cvc.notValid')
                }
              ]}
              required
            />
          </Col>

          <Col xs={24}>
            <Button type="primary" onClick={submit} className="ant-btn_mob-block" htmlType="submit" disabled={loading}>
              {t('btnSave')}
            </Button>
          </Col>
        </Row>
      </Form>
    </Spinner>
  );
};

const containers = compose(communicationPayment.injector);

export const CardCreateForm = containers(
  Form.create<AllProps>({
    mapPropsToFields({ paymentMethod }) {
      return AntdFormHelper.mapValidationToFields(paymentMethod.params, paymentMethod.errors);
    }
  })(CardCreateFormComponent)
);
