import React from 'react';
import { Button, CardInfo, Paragraph } from '@axmit/clp-library';
import Icon from 'antd/es/icon';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { AppLandingHelper } from '@axmit/router-app-landing';
import { useTranslation } from 'react-i18next';
import { EPrivateRoutes, ELandingRoutes } from 'common/const';
import { SeparatedModal, SeparatedModalContent } from 'common/components';
import julesMapPlane from 'app/assets/images/julesMapPlane.png';
import logo from 'app/assets/images/logo.png';

export const Finalize = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const submit = () => {
    history.push(EPrivateRoutes.Main);
  };

  return (
    <SeparatedModal
      logo={logo}
      onLogoClick={() => AppLandingHelper.toggleRoute()}
      rightSlot={
        <SeparatedModalContent
          contentClassName="finalize-content"
          rightSlotFooter={
            <Button size="small" onClick={submit} className="float_r mt-5" type="primary">
              <span>
                {t('finalizeSubmit')}
                <Icon type="right" className="ml-8" />
              </span>
            </Button>
          }
        >
          <div
            className="aside-slide aside-slide_discovery aside-slide_plane hidden_mob"
            style={{ backgroundImage: `url(${julesMapPlane})`, backgroundPositionX: 'center', backgroundRepeat: 'no-repeat' }}
          >
            <div className="aside-slide__wrapper aside-slide__wrapper_plane">
              <div className="aside-slide__content aside-slide__content_plane aside-slide__content_ribbon-text">
                {t('finalizeImgLabel')}
              </div>
            </div>
          </div>

          <CardInfo
            content={
              <>
                <Paragraph size="xs" className="mt-30">
                  {t('finalizeMessage')}
                </Paragraph>

                <Paragraph size="xs">
                  {t('finalizeMessageFooter')}{' '}
                  <Link to="#" onClick={() => AppLandingHelper.toggleRoute(ELandingRoutes.FAQ)}>
                    <b>{t('finalizeMessageFooterMain')}</b>
                  </Link>
                </Paragraph>
              </>
            }
            header={t('finalizeHeader')}
          />
        </SeparatedModalContent>
      }
    />
  );
};
