import React, { useEffect } from 'react';
import AntTabs from 'antd/es/tabs';
import Typography from 'antd/es/typography';
import { Container, Tabs } from '@axmit/clp-library';
import { useHistory, useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';
import { EPrivateRoutes } from 'common/const';
import { DashboardGiftsFriendsFor, DashboardGiftsFriendsFrom } from 'entities/Gift/components';

export const DashboardMobile = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (location.pathname === EPrivateRoutes.BankingDesktop) {
      history.push(EPrivateRoutes.BankingMobile);
    }

    if (location.pathname === EPrivateRoutes.PersonalDesktop) {
      history.push(EPrivateRoutes.PersonalMobile);
    }
  }, [location, history]);

  return (
    <Container>
      <Typography.Title className="clp-typography-title clp-typography-title_size_md mob-text_center mb-10">
        {t('dashboardTabGifts')}
      </Typography.Title>

      <Tabs>
        <AntTabs.TabPane tab={t('dashboardHeaderForFriends')} key="1" className="pt-10">
          <DashboardGiftsFriendsFor />
        </AntTabs.TabPane>

        <AntTabs.TabPane tab={t('dashboardHeaderFromFriends')} key="2" className="pt-10">
          <DashboardGiftsFriendsFrom />
        </AntTabs.TabPane>
      </Tabs>
    </Container>
  );
};
