import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { EBackOfficeRoutes } from 'common/const';
import { BackOfficeBreadcrumb } from 'common/components';
import { communicationUsers, IUsersConnectedProps } from 'entities/User/Users.communication';
import { ManagersForm } from 'entities/User/components/ManagersForm';
import { IUserManagerCreateParams } from 'entities/User/Users.models';

type AllProps = IUsersConnectedProps;

const ManagersCreateComponent = ({ addUsersManager, usersManager }: AllProps) => {
  const { t } = useTranslation();

  const submit = (data: IUserManagerCreateParams) => {
    addUsersManager(data);
  };

  return (
    <>
      <BackOfficeBreadcrumb
        current={t('managers.addNewManager')}
        previous={t('backOfficeDashboard.tab.managers')}
        previousHref={EBackOfficeRoutes.Managers}
      />

      <ManagersForm submit={submit} loading={usersManager.loading} />
    </>
  );
};

export const ManagersCreate = communicationUsers.injector(ManagersCreateComponent);
