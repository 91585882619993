export interface IUnreadInfoModel extends Components.Schemas.TotalUnreadDto {}

export interface IMessageModel extends Components.Schemas.MessageDto {}
export interface IMessageCreateParams extends Components.Schemas.MessageDataDto {}
export interface IMessageCollection extends Components.Schemas.MessageCollection {}
export interface IMessageCollectionParams extends Paths.MessageControllerGetList.QueryParameters {}
export interface IMessageContent extends Components.Schemas.ContentDto {}

export interface IMarkAsReadParams extends Components.Schemas.MultipleReadDataDto {}

export interface IChannelsCollection extends Components.Schemas.ClientStateCollection {}
export interface IChannelsModel extends Components.Schemas.ClientStateDto {}
export interface IChannelsCollection extends Components.Schemas.ClientStateCollection {}
export interface IChannelsCollectionParams extends Paths.MessageControllerChannelState.QueryParameters {}
export enum EMessageType {
  'sms' = 'sms',
  'whatsApp' = 'whatsapp',
  'messenger' = 'messenger'
}

export interface ISocketToggleRoomParams {
  clientType: 'user' | 'client';
  client: string;
}

export interface IMessageCollectionDivider {
  firstMessageId: string;
  date: string;
}

export enum EWhatsappLocale {
  En = 'en',
  Fr = 'fr'
}
