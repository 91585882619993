import * as React from 'react';
import { Button } from '@axmit/clp-library';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { EPrivateRoutes } from 'common/const';
import { EGiftStatus } from 'entities/Gift/Gift.models';

interface IComponentProps {
  status: EGiftStatus;
  giftId: string;
  sender?: string;
}

export const CardGiftActions = ({ status, giftId, sender }: IComponentProps) => {
  const { t } = useTranslation();
  const history = useHistory();

  switch (status) {
    case EGiftStatus.Activated:
      if (!sender) {
        return null;
      }

      return (
        <Button
          onClick={() => {
            history.push(`${EPrivateRoutes.GiftViewPage}/${giftId}`);
          }}
          size="small"
          block
          className="card-gift__another-time-btn"
        >
          {t('setAnotherTimeBtn')}
        </Button>
      );

    case EGiftStatus.Completed:
      return (
        <Button disabled size="small" block>
          {t('giveFeedbackBtn')}
        </Button>
      );

    case EGiftStatus.Created:
    case EGiftStatus.Delivered:
    case EGiftStatus.InProgress:
    default:
      return null;
  }
};
