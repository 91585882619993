import React from 'react';
import { FormItem, PhoneInput } from '@axmit/clp-library';
import { Row, Col } from 'antd/es/grid';
import Form from 'antd/es/form';
import moment from 'moment';
import { HooksHelper } from '@axmit/clp-library/dist/helpers';
import { FormComponentProps } from 'antd/es/form';
import { useTranslation } from 'react-i18next';
import { DateHelper, FormatHelper, ValidateHelper } from 'common/helpers';
import { InputForm, MobFormButton } from 'common/components';
import { DatePicker } from 'common/components/DatePicker';
import { communicationGift, IGiftConnectedProps, IGiftMeta, IGiftModel } from 'entities/Gift/index';
import { GiftPrice } from 'entities/Gift/components/index';
import { GiftUpdateHelper } from 'entities/Gift/helpers/GiftUpdate.helper';

interface IComponentProps {
  disabled?: boolean;
}

type AllProps = FormComponentProps & IGiftConnectedProps & IComponentProps;

const NewGiftFormExperienceComponent = ({ form, giftModel, disabled, updateGiftModel }: AllProps) => {
  const { t } = useTranslation();
  const { getFieldDecorator } = form;
  const { data: gift } = giftModel;

  if (!gift) {
    return null;
  }

  const formData = HooksHelper.useStateBuilder<IGiftModel>(gift);

  const phone = HooksHelper.useStateBuilder(gift.meta.recipientPhone);
  const phoneValidateMessage = HooksHelper.useStateBuilder('');

  const date = HooksHelper.useStateBuilder<moment.Moment | null>(moment(gift.deliveryDate) || null);
  const dateValidateMessage = HooksHelper.useStateBuilder<string | undefined>(undefined);

  const changePhoneValue = (newValue: string) => {
    phoneValidateMessage.set('');
    phone.set(newValue);
    formData.set(prevValue => ({ ...prevValue, meta: { ...prevValue.meta, recipientPhone: newValue } }));
  };

  const triggerPhoneValidation = (message: string) => {
    phoneValidateMessage.set(message);
  };

  const changeDate = (value: moment.Moment | null) => {
    date.set(value);

    if (value) {
      formData.set(prevValue => ({
        ...prevValue,
        deliveryDate: value.toISOString()
      }));
      dateValidateMessage.set('');
    } else {
      dateValidateMessage.set(t('errorRequiredDefault'));
    }
  };

  const submit = () => {
    if (!phone.value) {
      phoneValidateMessage.set(t('errorRequiredDefault'));

      return;
    }

    if (!date.value) {
      dateValidateMessage.set(t('errorRequiredDefault'));

      return;
    }

    form.validateFields(err => {
      if (err) {
        return;
      }

      updateGiftModel({ id: formData.value.id, data: formData.value });
    });
  };

  const setValueToMeta = (value: Partial<IGiftMeta>) => {
    GiftUpdateHelper.setValueToMeta(formData.set, value);
  };

  return (
    <Form hideRequiredMark className="mt-15">
      <Row gutter={30} type="flex">
        <Col {...defaultCol}>
          <InputForm
            inputProps={{ disabled }}
            getFieldDecorator={getFieldDecorator}
            fieldName="recipientName"
            onBlur={setValueToMeta}
            label={t('newGiftExperienceFormLabelName')}
            initialValue={gift.meta.recipientName}
            required
          />
        </Col>

        <Col {...defaultCol}>
          <InputForm
            inputProps={{ disabled }}
            getFieldDecorator={getFieldDecorator}
            fieldName="recipientEmail"
            onBlur={setValueToMeta}
            label={t('newGiftExperienceFormLabelEmail')}
            initialValue={gift.meta.recipientEmail}
            rules={[
              {
                type: 'email',
                message: t('errorEmailNotEmail')
              }
            ]}
            required
          />
        </Col>

        <Col {...phoneCol}>
          <FormItem
            label={t('newGiftExperienceFormLabelPhone')}
            className="mob-form-wrapper"
            validateStatus={ValidateHelper.getValidateStatus(phoneValidateMessage.value)}
            help={phoneValidateMessage.value}
          >
            <PhoneInput
              block
              disabled={disabled}
              validateTrigger={triggerPhoneValidation}
              onChange={changePhoneValue}
              defaultValue={gift.meta.recipientPhone}
            />
          </FormItem>
        </Col>

        <Col {...defaultCol}>
          <div className="description mob-form-wrapper mb-5">{t('newGiftExperienceFormLabelDate')}</div>

          <FormItem validateStatus={ValidateHelper.getValidateStatus(dateValidateMessage.value)} help={dateValidateMessage.value}>
            <DatePicker
              value={date.value}
              minDate={DateHelper.getTomorrow().toDate()}
              disabledDate={DateHelper.isTodayOrBefore}
              onChange={value => changeDate(value)}
              disabled={disabled}
            />
          </FormItem>
        </Col>

        <Col {...defaultCol}>
          <div className="description mob-form-wrapper mb-5">{t('giftEditFormLabelBudget')}</div>

          <GiftPrice price={FormatHelper.getCurrency(gift.currentBalance)} className="mob-form-wrapper mb-30" />
        </Col>
      </Row>

      <MobFormButton className="mb-30" type="primary" onClick={submit} disabled={disabled}>
        {t('giftEditFormSubmit')}
      </MobFormButton>
    </Form>
  );
};

export const GiftFormExperience = communicationGift.injector(Form.create<AllProps>()(NewGiftFormExperienceComponent));

const phoneCol = { xs: 24, sm: 12, lg: 8, xl: { span: 6, offset: 1, pull: 1 } };
const defaultCol = { xs: 24, sm: 12, xl: 6 };
