import React from 'react';
import { Button, CardInfo, Paragraph } from '@axmit/clp-library';
import Typography from 'antd/es/typography';
import { useTranslation } from 'react-i18next';
import { SvgDiamond } from 'common/SVG/SvgDiamond';
import { Desktop, Mobile } from 'common/components';

interface IComponentProps {
  redirectToSignUp: () => void;
  redirectToLogIn: () => void;
  name?: string;
}

export const NewGiftFormIdentify = ({ name = '', redirectToSignUp, redirectToLogIn }: IComponentProps) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="d-flex d-flex_align_center mob-text_center mb-20">
        <Desktop>
          <SvgDiamond />
        </Desktop>

        <Typography.Text className="d-flex__item clp-typography-text clp-typography-text_size_sm t_spacing_lg">
          {t('newGiftIdentifyStart')}
          <b>{name}</b>
          {t('newGiftIdentifyEnd')}

          <Mobile>
            <br />

            <SvgDiamond className="mt-10" />
          </Mobile>
        </Typography.Text>
      </div>

      <CardInfo
        headerSize="sm"
        content={
          <Paragraph size="xs" className="mb-30 mt-15">
            {t('newGiftIdentifyMessage')}
          </Paragraph>
        }
        header={t('newGiftIdentifySubHeader')}
      />

      <Button type="primary" size="small" className="ant-btn_mob-block mr-10 mb-25" onClick={redirectToLogIn}>
        {t('newGiftIdentifyLogin')}
      </Button>

      <Button size="small" className="ant-btn_mob-block" onClick={redirectToSignUp}>
        {t('newGiftIdentifySignUp')}
      </Button>
    </>
  );
};
