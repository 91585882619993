import React, { useEffect } from 'react';
import { Row, Col } from 'antd/es/grid';
import Spin from 'antd/es/spin';
import message from 'antd/es/message';
import { Container, CardInfo, Paragraph } from '@axmit/clp-library';
import { useLocation } from 'react-router-dom';
import { HooksHelper } from '@axmit/clp-library/dist/helpers';
import { useTranslation } from 'react-i18next';
import { EmptyData } from 'common/components';
import julesCoffret from 'app/assets/images/julesCoffret.png';
import julesCoffretMob from 'app/assets/images/julesCoffretMob.png';
import { ContributorFormInfo, ContributorFormMain } from 'entities/Contributors/components';
import { communicationContributors, IContributorsConnectedProps } from 'entities/Contributors/Contributors.communication';
import { communicationPayment, IPaymentConnectedProps } from 'entities/Payment';
import { CheckoutForm } from 'entities/Payment/components';
import { communicationGift, IGiftConnectedProps } from 'entities/Gift';

type AllProps = IGiftConnectedProps & IPaymentConnectedProps & IContributorsConnectedProps;

const ContributorFormWrapperComponent = ({
  giftPublicModel,
  getGiftPublicModel,
  contributorsModel,
  paymentModel,
  paymentCalculator,
  addPaymentModel
}: AllProps) => {
  const { t } = useTranslation();
  const currency = HooksHelper.useStateBuilder<number | undefined>(undefined);
  const payed = HooksHelper.useStateBuilder<boolean>(false);
  const stripeLoading = HooksHelper.useStateBuilder(false);
  const { data: giftData, loading: giftLoading } = giftPublicModel;
  const { data: calculator } = paymentCalculator;
  const { total } = calculator || {};

  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };

  const giftId = useQuery().get('gift');
  const contributorEmail = useQuery().get('email');

  useEffect(() => {
    giftId && getGiftPublicModel({ id: giftId });
  }, [giftId, getGiftPublicModel]);

  if (!giftId) {
    return <EmptyData />;
  }

  const recipientName = giftData?.recipientName || '';

  return (
    <Container>
      <Row className="contribution-block" type="flex" style={{ flexDirection: 'row-reverse' }} align="top">
        <Col xs={24} md={12} lg={13}>
          {giftData && !payed.value && <ContributorFormInfo />}

          <img
            src={julesCoffret}
            className="clp-content-img clp-content-img_mt_md ml-50 mt-100 pt-50 hidden_mob contribution-form__content-img"
            alt="jules contributor"
          />
        </Col>

        <Col xs={24} md={12} lg={11}>
          {!giftData && !giftLoading && (
            <CardInfo
              header={t('contributionFormClosedGift.header')}
              content={
                <Paragraph size="sm" className="mt-12">
                  {t('contributionFormClosedGift.1')} <b>contact@cleprivee.com</b>
                </Paragraph>
              }
            />
          )}

          {giftData && contributorsModel.data && !payed.value && (
            <Spin spinning={paymentModel.loading || stripeLoading.value}>
              <CheckoutForm
                add={params => addPaymentModel({ ...params, giftId, giftContributor: contributorsModel.data?.id })}
                setStripeLoading={stripeLoading.set}
                amount={total}
                currencyIsValid={true}
                invoiceRecipientEmail={contributorEmail || undefined}
                successCallback={() => {
                  message.success(t('successUpdateDefault'));
                  payed.set(true);
                }}
              />
            </Spin>
          )}
          {giftData && contributorsModel.data && payed.value && (
            <CardInfo
              header={t('contributionThankYou.header', { name: recipientName })}
              content={
                <>
                  <Paragraph size="sm" className="mt-12">
                    {t('contributionThankYou.1')}
                  </Paragraph>
                  <Paragraph size="sm" className="mt-12">
                    {t('contributionThankYou.2')} <br />
                    {t('contributionThankYou.3')}
                  </Paragraph>
                </>
              }
            />
          )}
          {giftData && !contributorsModel.data && (
            <ContributorFormMain recipientName={recipientName} giftId={giftId} budget={currency.value} setBudget={currency.set} />
          )}

          <img src={julesCoffretMob} className="hidden_desk mt-30 mb-50 w-100p" alt="jules contributor" />
        </Col>
      </Row>
    </Container>
  );
};

export const ContributorFormWrapper = communicationPayment.injector(
  communicationContributors.injector(communicationGift.injector(ContributorFormWrapperComponent))
);
