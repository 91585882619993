import { StoreBranch } from '@axmit/redux-communications';
import { IBaseFilterModel } from 'common/models/Request.models';

export function buildInfinityCollection<TResponse extends { data: any; meta: any }, TPayload extends IBaseFilterModel>(
  response: TResponse,
  payload: TPayload,
  branchState: StoreBranch<TResponse, TPayload>
) {
  if (!payload.offset || payload.offset === 0) {
    return { data: response.data, meta: response.meta };
  }

  const oldData = branchState.data?.data || [];

  return { data: [...oldData, ...response.data], meta: response.meta };
}

export const ResponseHelper = {
  buildInfinityCollection
};
