import React, { useState } from 'react';
import { FormItem, Input } from '@axmit/clp-library';
import { useTranslation } from 'react-i18next';
import { ValidateHelper } from 'common/helpers';
import { GiftUpdateHelper } from 'entities/Gift/helpers/GiftUpdate.helper';
import { GiftCalculatorInfo } from 'entities/Payment/components/GiftCalculatorInfo';
import { IGiftPaymentCalculatorParams } from 'entities/Payment/Payment.models';

interface IComponentProps {
  amount?: number;
  setAmount: (value?: number) => void;
  setValid: (value: boolean) => void;
  minAmount?: number;
  calculateParams?: IGiftPaymentCalculatorParams;
  disableAmount?: boolean;
}

export const CurrencyForm = (props: IComponentProps) => {
  const { amount, setAmount, setValid, minAmount = 1, calculateParams, disableAmount } = props;
  const { t } = useTranslation();
  const [validateMessage, setValidateMessage] = useState<string | undefined>(
    amount ? GiftUpdateHelper.getAmountValidationMessage(minAmount, amount) : undefined
  );

  const changeAmountValue = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
    if (!value) {
      setValidateMessage(t('errorRequiredDefault'));
      setValid(false);
      setAmount(undefined);

      return;
    }

    const updatedValue = +(+value).toFixed(2);
    const validationError = GiftUpdateHelper.getAmountValidationMessage(minAmount, updatedValue);

    setValidateMessage(validationError);
    setValid(!validationError);
    setAmount(updatedValue);
  };

  return (
    <FormItem help={validateMessage} validateStatus={ValidateHelper.getValidateStatus(validateMessage)}>
      <Input
        step={1}
        disabled={disableAmount}
        type="number"
        min={minAmount}
        value={amount}
        onChange={changeAmountValue}
        addonBefore="€"
        className="currency-input"
      />
      {calculateParams && <GiftCalculatorInfo calculateParams={{ ...calculateParams, amount: amount || 0 }} />}
    </FormItem>
  );
};
