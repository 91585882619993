import React from 'react';
import { Button, CardInfo, CardStepByStep, FormItem, Paragraph, TextArea } from '@axmit/clp-library';
import Icon from 'antd/es/icon';
import { Row } from 'antd/es/grid';
import Form, { FormComponentProps } from 'antd/es/form';
import { useTranslation } from 'react-i18next';
import { SeparatedModalContent } from 'common/components';
import julesMapBook from 'app/assets/images/julesMapBook.png';
import { IGiftCreateViewModel } from 'entities/Gift';
import { GiftCreateHelper } from 'entities/Gift/helpers/GiftCreate.helper';

interface IComponentProps {
  updateGift: (data: Partial<IGiftCreateViewModel>) => void;
  handlePreviousSlide: () => void;
  handleNextSlide: () => void;
  gift: Partial<IGiftCreateViewModel>;
}

type AllProps = IComponentProps & FormComponentProps;

const NewGiftFormIdeaComponent = ({ form, updateGift, gift, handlePreviousSlide, handleNextSlide }: AllProps) => {
  const { t } = useTranslation();
  const { getFieldDecorator } = form;

  const submit = () => {
    form.validateFields((err, values) => {
      if (err) {
        return;
      }

      handleNextSlide();
    });
  };

  const letter = GiftCreateHelper.getDiscoveryLetter(gift.name);

  return (
    <SeparatedModalContent
      rightSlotFooter={
        <Row type="flex" justify="space-between" className="mt-20 mb-10">
          <Button size="small" onClick={handlePreviousSlide}>
            <span>
              <Icon type="left" className="mr-8" />
              {t('newGiftDefaultBtnBack')}
            </span>
          </Button>

          <Button type="primary" size="small" onClick={submit}>
            <span>
              {t('newGiftDefaultBtnNext')}
              <Icon type="right" className="ml-8" />
            </span>
          </Button>
        </Row>
      }
    >
      <div className="aside-slide aside-slide_discovery hidden_mob" style={{ backgroundImage: `url(${julesMapBook})` }}>
        <div className="aside-slide__wrapper">
          <div className="aside-slide__content aside-slide__content_letter">{letter}</div>
        </div>
      </div>

      <CardStepByStep label={t('newGiftIdeaHeader')} subLabel={t('step3')} className="mb-30" />

      <CardInfo
        headerSize="sm"
        content={
          <Paragraph size="xs" className="mb-20 mt-15">
            {t('newGiftIdeaMessage')}
          </Paragraph>
        }
        header={t('newGiftIdeaSubHeader')}
      />

      <Form
        onBlur={() => {
          updateGift(form.getFieldsValue());
        }}
      >
        <FormItem>
          {getFieldDecorator('idea', {
            initialValue: gift.idea
          })(<TextArea placeholder={t('newGiftIdeaPlaceholder')} autoSize={{ minRows: 8, maxRows: 8 }} maxLength={4096} />)}
        </FormItem>
      </Form>
    </SeparatedModalContent>
  );
};

export const NewGiftFormIdea = Form.create<AllProps>()(NewGiftFormIdeaComponent);
