import React from 'react';
import { Environment } from 'common/components';
import { MEDIA_WIDTH_DESKTOP } from 'common/config';

interface IComponentProps {
  customWidth?: number;
}

type AllProps = React.PropsWithChildren<IComponentProps>;

export const Desktop = ({ children, customWidth }: AllProps) => (
  <Environment min={customWidth || MEDIA_WIDTH_DESKTOP}>{children}</Environment>
);
