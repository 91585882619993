import * as React from 'react';
import { useEffect } from 'react';
import Table from 'antd/es/table';
import { useTranslation } from 'react-i18next';
import { Button } from '@axmit/clp-library';
import uuid from 'uuid';
import i18next from 'i18next';
import { TableFormatHelper } from 'common/helpers';
import { calculatorColumns } from 'entities/Payment/Payment.const';
import {
  IRequestExpensesConnectedProps,
  communicationRequestExpenses
} from 'entities/RequestExpenses/RequestExpenses.communication';
import { communicationRequests, IRequestsConnectedProps } from 'entities/Requests/Requests.communication';

type AllProps = IRequestExpensesConnectedProps & IRequestsConnectedProps;

const BudgetCalculatorTableComponent = ({
  requestsModel,
  getRequestExpensesCollection,
  clearRequestExpensesCollection,
  deleteRequestExpensesModel,
  requestExpensesCollection
}: AllProps) => {
  const { t } = useTranslation();

  useEffect(() => {
    return () => {
      clearRequestExpensesCollection();
    };
  }, [clearRequestExpensesCollection]);

  useEffect(() => {
    const requestId = requestsModel.data?.id;

    requestId && getRequestExpensesCollection({ request: requestId });
  }, [requestsModel, getRequestExpensesCollection]);

  if (!requestExpensesCollection.data?.data) {
    return null;
  }

  const {
    data: { data, meta }
  } = requestExpensesCollection;

  const dataWithActions =
    data.map(item => ({
      ...item,
      actions: (
        <Button size="small" type="link" className="p-0" onClick={() => deleteRequestExpensesModel(item.id)}>
          {t('btnRemove')}
        </Button>
      )
    })) || [];

  const mappedData = [...dataWithActions, getTotalField(meta?.totalAmount)];

  return (
    <Table
      scroll={{ x: true }}
      className="clw-table clw-table_last-row-bold"
      size="middle"
      bordered
      loading={false}
      columns={TableFormatHelper.translateTitles(calculatorColumns, t)}
      dataSource={mappedData}
      rowKey={record => record.id}
      pagination={false}
    />
  );
};

export const BudgetCalculatorTable = communicationRequestExpenses.injector(
  communicationRequests.injector(BudgetCalculatorTableComponent)
);

const getTotalField = (total = 0) => {
  return {
    id: uuid(),
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
    request: uuid(),
    amount: total,
    description: i18next.t('total')
  };
};
