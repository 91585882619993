import React, { useEffect } from 'react';
import { Button, CardInfo, CardStepByStep, FormItem, Input, Paragraph, PhoneInput } from '@axmit/clp-library';
import Icon from 'antd/es/icon';
import { Col, Row } from 'antd/es/grid';
import Form, { FormComponentProps } from 'antd/es/form';
import moment from 'moment';
import { HooksHelper } from '@axmit/clp-library/dist/helpers';
import { useTranslation } from 'react-i18next';
import { DateHelper, FormatHelper, ValidateHelper } from 'common/helpers';
import { SeparatedModalContent } from 'common/components';
import { DatePicker } from 'common/components/DatePicker';
import julesMapVuitton from 'app/assets/images/julesMapVuitton.png';
import { EGiftType, IGiftCreateViewModel } from 'entities/Gift';
import { InvitesListCrud } from 'entities/Invitations/components';
import { GiftUpdateHelper } from 'entities/Gift/helpers/GiftUpdate.helper';
import { PaymentFormat } from 'entities/Payment/helpers';

interface IComponentProps {
  updateGift: (data: Partial<IGiftCreateViewModel>) => void;
  handlePreviousSlide: () => void;
  handleNextSlide: () => void;
  gift: Partial<IGiftCreateViewModel>;
}

type AllProps = IComponentProps & FormComponentProps;

const NewGiftFormExperienceComponent = ({ form, updateGift, gift, handlePreviousSlide, handleNextSlide }: AllProps) => {
  const { t } = useTranslation();
  const { getFieldDecorator } = form;

  const phone = HooksHelper.useStateBuilder(gift.phone);
  const phoneValidateMessage = HooksHelper.useStateBuilder('');

  const date = HooksHelper.useStateBuilder<moment.Moment | null>(gift.date ? moment(gift.date) : null);
  const dateValidateMessage = HooksHelper.useStateBuilder<string | undefined>(undefined);

  const minCurrency = GiftUpdateHelper.getMinBudget(gift.type);

  useEffect(() => {
    if (date.value && DateHelper.isTodayOrBefore(moment(date.value))) {
      dateValidateMessage.set(t('errorDateMustBeMore'));
    }
  }, [date.value, dateValidateMessage, t]);

  const changePhoneValue = (newValue: string) => {
    phoneValidateMessage.set('');
    phone.set(newValue);
    updateGift({ phone: newValue });
  };

  const triggerPhoneValidation = (message: string) => {
    phoneValidateMessage.set(message);
  };

  const submit = () => {
    if (!phone.value) {
      phoneValidateMessage.set(t('errorRequiredDefault'));

      return;
    }

    if (!date.value) {
      dateValidateMessage.set(t('errorRequiredDefault'));

      return;
    }

    if (dateValidateMessage.value) {
      return;
    }

    form.validateFields(err => {
      if (err) {
        return;
      }

      handleNextSlide();
    });
  };

  const updateFormFields = () => {
    const values = form.getFieldsValue();
    const formattedOrEmptyCurrency = values.currency ? FormatHelper.formatAmount(values.currency) : values.currency;

    updateGift({ ...values, currency: formattedOrEmptyCurrency });
  };

  const isCollective = gift.type === EGiftType.Collective;

  const changeDate = (value: moment.Moment | null) => {
    date.set(value);

    if (value) {
      updateGift({ date: value });
      dateValidateMessage.set('');
    } else {
      dateValidateMessage.set(t('errorRequiredDefault'));
    }
  };

  const validateAmount = (rule: string, value: string, callback: Function) => {
    if (!value) {
      callback(t('errorRequiredDefault'));

      return;
    }

    const updatedValue = FormatHelper.formatAmount(value);
    const validationError = GiftUpdateHelper.getAmountValidationMessage(minCurrency, updatedValue);

    return callback(validationError || undefined);
  };

  return (
    <SeparatedModalContent
      contentClassName="pl-5"
      rightSlotFooter={
        <Row type="flex" justify="space-between" className="mt-20 mb-10">
          <Button size="small" onClick={handlePreviousSlide}>
            <span>
              <Icon type="left" className="mr-8" />
              {t('newGiftDefaultBtnBack')}
            </span>
          </Button>

          <Button type="primary" size="small" onClick={submit}>
            <span>
              {t('newGiftDefaultBtnNext')}
              <Icon type="right" className="ml-8" />
            </span>
          </Button>
        </Row>
      }
    >
      <Form layout="horizontal" hideRequiredMark>
        <img src={julesMapVuitton} alt="type" className="aside-slide aside-slide_position_center hidden_mob" />

        <CardStepByStep label={t('newGiftExperienceHeader')} subLabel={t('step2')} className="mb-30" />

        <Row gutter={30}>
          <Col xs={24} lg={12}>
            <FormItem label={t('newGiftExperienceFormLabelName')} className="mob-form-wrapper pale-label">
              {getFieldDecorator('name', {
                initialValue: gift.name,
                rules: [
                  {
                    required: true,
                    message: t('errorRequiredDefault')
                  }
                ]
              })(<Input onBlur={updateFormFields} />)}
            </FormItem>
          </Col>

          <Col xs={24} lg={12}>
            <FormItem label={t('newGiftExperienceFormLabelEmail')} className="mob-form-wrapper pale-label">
              {getFieldDecorator('email', {
                initialValue: gift.email,
                rules: [
                  {
                    type: 'email',
                    message: t('errorEmailNotEmail')
                  },
                  {
                    required: true,
                    message: t('errorRequiredDefault')
                  }
                ]
              })(<Input onBlur={updateFormFields} />)}
            </FormItem>
          </Col>

          <Col xs={24} xl={14}>
            <FormItem
              label={t('newGiftExperienceFormLabelPhone')}
              className="mob-form-wrapper pale-label phone-input-wrapper"
              validateStatus={ValidateHelper.getValidateStatus(phoneValidateMessage.value)}
              help={phoneValidateMessage.value}
            >
              <PhoneInput block validateTrigger={triggerPhoneValidation} onChange={changePhoneValue} defaultValue={gift.phone} />
            </FormItem>
          </Col>
        </Row>
      </Form>

      <div className="description mob-form-wrapper mb-5">{t('newGiftExperienceFormLabelDate')}</div>

      <FormItem validateStatus={ValidateHelper.getValidateStatus(dateValidateMessage.value)} help={dateValidateMessage.value}>
        <DatePicker
          value={date.value}
          minDate={DateHelper.getTomorrow().toDate()}
          disabledDate={DateHelper.isTodayOrBefore}
          onChange={value => changeDate(value)}
        />
      </FormItem>

      <CardInfo
        content={
          <Paragraph size="xs" className="mt-5 mb-25">
            {t('paymentMessage', PaymentFormat.getMinFormattedBudget())}
          </Paragraph>
        }
        header={t('newGiftExperienceFormLabelBudget')}
        headerSize="sm"
      />

      <FormItem>
        {getFieldDecorator('currency', {
          initialValue: gift.currency,
          normalize: FormatHelper.normalizeAmount,
          rules: [
            {
              validator: validateAmount
            }
          ]
        })(
          <Input
            onChange={e => {
              // TODO: Will be refactored in https://axmit.myjetbrains.com/youtrack/issue/CLP-1424
              const values = form.getFieldsValue();

              updateGift({ ...values, currency: FormatHelper.formatAmount(e.target.value) });
            }}
            addonBefore="€"
            className="currency-input"
            step={1}
            type="number"
            min={minCurrency}
          />
        )}
      </FormItem>

      {isCollective && (
        <>
          <div className="description mb-5">{t('inviteFormHeader')}</div>

          <InvitesListCrud
            invites={gift.invites}
            updateInvites={invites => {
              updateGift({ invites });
            }}
          />
        </>
      )}
    </SeparatedModalContent>
  );
};

export const NewGiftFormExperience = Form.create<AllProps>()(NewGiftFormExperienceComponent);
