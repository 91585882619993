import Cookies from 'universal-cookie';
import { RouterHelper } from 'common/helpers';

const cookies = new Cookies();

const setCookies = (key: string, value: string) => {
  const domain = RouterHelper.getDomain();

  cookies.set(key, value, { domain: `.${domain}` });
};

const clearCookies = (key: string) => {
  const domain = RouterHelper.getDomain();

  cookies.remove(key, { domain: `.${domain}` });
};

export const CookiesHelper = {
  setCookies,
  clearCookies
};
