import React, { FormEvent } from 'react';
import Form from 'antd/es/form';
import Spin from 'antd/es/spin';
import Typography from 'antd/es/typography';
import { Row, Col } from 'antd/es/grid';
import { Button, FormItem, Input } from '@axmit/clp-library';
import { useHistory } from 'react-router-dom';
import { FormComponentProps } from 'antd/es/form';
import { compose } from 'redux';
import { useTranslation } from 'react-i18next';
import { EPublicRoutes } from 'common/const/Routes.const';
import { communicationAuth, IAuthConnectedProps } from 'entities/Auth/Auth.communication';

type AllProps = FormComponentProps & IAuthConnectedProps;

const RestorePasswordFormComponent = (props: AllProps) => {
  const { t } = useTranslation();

  const formLayout = {
    labelCol: {
      span: 24
    },
    wrapperCol: {
      md: 12
    }
  };

  const { form, authRestoreModel } = props;
  const { getFieldDecorator } = form;

  const history = useHistory();

  const redirectToLogIn = () => {
    history.push(EPublicRoutes.LogIn);
  };

  const submit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    form.validateFields((err, values) => {
      if (err) {
        return;
      }

      props.addAuthRestoreModel(values);
    });
  };

  return (
    <Spin spinning={authRestoreModel.loading}>
      <Typography.Title className="clp-typography-title clp-typography-title_size_md mob-text_center mb-25">
        {t('restorePassHeader')}
      </Typography.Title>

      <Typography.Text className="clp-typography-text clp-typography-text_size_sm mob-form-wrapper">
        {t('restorePassMessage')}
      </Typography.Text>

      <Form {...formLayout} className="mt-30" onSubmit={submit}>
        <FormItem label={t('form.labels.email')}>
          {getFieldDecorator('email', {
            rules: [
              {
                type: 'email',
                message: t('errorEmailNotEmail')
              },
              {
                required: true,
                message: t('errorRequiredDefault')
              }
            ]
          })(<Input />)}
        </FormItem>

        <Row align="middle" className="mob-form-wrapper" gutter={10}>
          <Col xs={24} lg={12}>
            <Button type="primary" block className="ant-btn_mob-block" htmlType="submit">
              {t('restorePassSubmit')}
            </Button>
          </Col>

          <Col xs={24} lg={12}>
            <Button type="link" onClick={redirectToLogIn} className="ant-btn_mob-block pl-0 pr-0">
              {t('restorePassLogin')}
            </Button>
          </Col>
        </Row>
      </Form>
    </Spin>
  );
};

const containers = compose(communicationAuth.injector, Form.create<AllProps>());

export const RestorePasswordForm = containers(RestorePasswordFormComponent);
