import React, { useEffect, useCallback } from 'react';
import AntSelect from 'antd/es/select';
import Spin from 'antd/es/spin';
import { FormItem, Select } from '@axmit/clp-library';
import { useTranslation } from 'react-i18next';
import { WrappedFormUtils } from 'antd/es/form/Form';
import debounce from 'lodash.debounce';
import { LabeledValue } from 'antd/lib/select';
import { SEARCH_INIT_LIMIT, SEARCH_WAIT_TIME } from 'common/config';
import { EmptyData } from 'common/components';
import { communicationCountries, ICountriesConnectedProps } from 'entities/Countries/Countries.communication';
import { ICountriesCreateModel } from 'entities/Countries/Countries.models';

interface IComponentProps {
  fieldName: string;
  form: WrappedFormUtils; // Reason: if error FC can't be changed only by getFieldDecorator
  initialModel?: ICountriesCreateModel;
  updateCountry?: (model: ICountriesCreateModel) => void;
}

type AllProps = ICountriesConnectedProps & IComponentProps;

const CountriesSelectorComponent = (props: AllProps) => {
  const {
    getCountriesCollection,
    clearCountriesCollection,
    countriesCollection,
    fieldName,
    form,
    updateCountry,
    initialModel
  } = props;
  const { t } = useTranslation();
  const { data: countries, loading } = countriesCollection;

  useEffect(() => {
    return () => {
      clearCountriesCollection();
    };
  }, [clearCountriesCollection]);

  useEffect(() => {
    getCountriesCollection({ limit: SEARCH_INIT_LIMIT });
  }, [getCountriesCollection]);

  const debounceSearch = useCallback(
    debounce((value: string) => {
      getCountriesCollection({ limit: SEARCH_INIT_LIMIT, name: value });
    }, SEARCH_WAIT_TIME),
    [getCountriesCollection]
  );

  const initial = initialModel && { key: initialModel.name, label: initialModel.name };

  return (
    <FormItem label={t('form.labels.country')}>
      {form.getFieldDecorator(fieldName, {
        valuePropName: 'select',
        initialValue: initial,
        rules: [
          {
            required: true,
            message: t('errorRequiredDefault')
          }
        ]
      })(
        <Select
          labelInValue
          filterOption={false}
          showSearch
          onSearch={debounceSearch}
          bordered="bottom"
          defaultValue={initial}
          className="clp-select clp-select_input_bold"
          notFoundContent={loading ? <Spin size="small" /> : <EmptyData />}
          onChange={value => {
            const mapped = value as LabeledValue;

            if (!countries?.data.length || !updateCountry || !mapped?.key) {
              return;
            }

            const fullModel = countries?.data.find(item => mapped.key === item.id);

            fullModel && updateCountry(fullModel);
          }}
        >
          {countries?.data?.map(({ name, id }) => (
            <AntSelect.Option key={id} value={id}>
              {name}
            </AntSelect.Option>
          ))}
        </Select>
      )}
    </FormItem>
  );
};

export const CountriesSelector = communicationCountries.injector(CountriesSelectorComponent);
