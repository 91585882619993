import moment from 'moment';
import { ColumnProps } from 'antd/lib/table';
import { TFunction } from 'i18next';
import { DATE_FORMAT_DEFAULT } from 'common/config';
import { IUserModel } from 'entities/User/Users.models';

const formatDate = (value: Date | string) => {
  return moment(value).format(DATE_FORMAT_DEFAULT);
};

function formatNameWithDot<T extends { lastName: string; firstName: string }>(value: string, { lastName, firstName }: T) {
  return `${lastName} ${firstName.charAt(0)}.`;
}

const formatFullName = (value: string, { lastName, firstName }: IUserModel) => {
  return `${lastName} ${firstName}`;
};

function translateTitles<T>(data: ColumnProps<T>[], t: TFunction): ColumnProps<T>[] {
  return data.map(({ title, ...rest }) => ({ title: title ? t(String(title)) : '', ...rest }));
}

export const TableFormatHelper = {
  translateTitles,
  formatDate,
  formatNameWithDot,
  formatFullName
};
