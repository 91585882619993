import { HooksHelper } from '@axmit/clp-library/dist/helpers';
import { Row, Col } from 'antd/es/grid';
import Table from 'antd/es/table';
import React, { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { EmptyData, QuickSearch, Spinner } from 'common/components';
import { EBackOfficeRoutes } from 'common/const';
import { PaginationHelper, TableFormatHelper } from 'common/helpers';
import { communicationCompanies, ICompaniesConnectedProps } from 'entities/Companies/Companies.communication';
import { COMPANIES_PER_PAGE, companiesColumns } from 'entities/Companies/Companies.consts';
import { ICompanyCollectionParams, ICompanyModel } from 'entities/Companies/Companies.models';

type AllProps = ICompaniesConnectedProps;

const CompaniesListComponent = ({ getCompaniesCollection, clearCompaniesCollection, companiesCollection }: AllProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const currentPage = HooksHelper.useStateBuilder<number>(1);
  const search = HooksHelper.useStateBuilder<string>('');

  useEffect(() => {
    return () => {
      clearCompaniesCollection();
    };
  }, [clearCompaniesCollection]);

  const reloadCollection = useCallback(
    (newParams: ICompanyCollectionParams) => {
      getCompaniesCollection(newParams);
    },
    [getCompaniesCollection]
  );

  useEffect(() => {
    reloadCollection({
      limit: COMPANIES_PER_PAGE,
      quickSearch: search.value
    });
  }, [search.value, reloadCollection]);

  const onChangePage = (limit: number, offset: number, page: number) => {
    currentPage.set(page);
    reloadCollection({
      limit,
      offset,
      quickSearch: search.value
    });
  };

  const { loading, data } = companiesCollection;

  const onSearch = (value: string) => {
    currentPage.set(1);
    search.set(value);
  };

  const onRowClick = (record: ICompanyModel) => ({
    onClick: () => history.push(`${EBackOfficeRoutes.Companies}/${record.id}`, { company: record })
  });

  return (
    <>
      <Row className="mb-30" type="flex" gutter={22}>
        <Col lg={8}>
          <QuickSearch onSearch={onSearch} />
        </Col>
      </Row>

      <Spinner spinning={loading}>
        {data && data.meta.count ? (
          <Table
            scroll={{ x: true }}
            className="clw-table mb-20"
            size="middle"
            loading={loading}
            columns={TableFormatHelper.translateTitles(companiesColumns, t)}
            dataSource={data.data}
            pagination={PaginationHelper.getListsPagination(onChangePage, COMPANIES_PER_PAGE, data.meta.count, currentPage.value)}
            rowKey={record => record.id}
            onRow={onRowClick}
            rowClassName={() => 'c-pointer'}
            bordered
          />
        ) : (
          <EmptyData />
        )}
      </Spinner>
    </>
  );
};

export const CompaniesList = communicationCompanies.injector(CompaniesListComponent);
