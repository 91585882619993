import * as React from 'react';
import { useCallback } from 'react';
import { Input, Paragraph } from '@axmit/clp-library';
import debounce from 'lodash.debounce';
import { useTranslation } from 'react-i18next';
import { SEARCH_WAIT_TIME } from 'common/config';

interface IComponentProps {
  onSearch: (value: string) => void;
  inputClassName?: string;
  labelClassName?: string;
  labelOnInput?: boolean;
}

export const QuickSearch = ({ onSearch, inputClassName = '', labelClassName = '', labelOnInput }: IComponentProps) => {
  const { t } = useTranslation();
  const debounceSearch = useCallback(debounce(onSearch, SEARCH_WAIT_TIME), [onSearch]);

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    debounceSearch(value);
  };

  const label = t('baseFilters.quickSearch');

  return (
    <>
      {!labelOnInput && (
        <Paragraph size="xs" className={labelClassName}>
          {label}
        </Paragraph>
      )}

      <Input onChange={handleSearch} className={inputClassName} placeholder={labelOnInput ? label : undefined} />
    </>
  );
};
