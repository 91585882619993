import React from 'react';
import { Container } from '@axmit/clp-library';
import Typography from 'antd/es/typography';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { EPrivateRoutes } from 'common/const';
import { ChangePasswordForm } from 'entities/Auth/components/ChangePasswordForm';

export const ChangePasswordMobilePage = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const handleCancel = () => {
    history.push(EPrivateRoutes.PersonalMobile);
  };

  return (
    <Container>
      <Typography.Title className="clp-typography-title clp-typography-title_size_md mob-text_center mb-10">
        {t('changePassword.header')}
      </Typography.Title>

      <ChangePasswordForm cancel={handleCancel} />
    </Container>
  );
};
