import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { getCreds } from '@axmit/axios-patch-jwt';
import { compose } from 'redux';
import { EPublicRoutes, EPrivateRoutes } from 'common/const/Routes.const';
import { communicationUsers, IUsersConnectedProps } from 'entities/User/Users.communication';
import { LayoutPrivateBasic } from 'entities/Layout/LayoutPrivateBasic';
import { LayoutPrivateBackOffice } from 'entities/Layout/LayoutPrivateBackOffice';
import { EUserRoles } from 'entities/User/Users.consts';

type AllProps = RouteComponentProps & IUsersConnectedProps;

class LayoutPrivateComponent extends React.PureComponent<AllProps> {
  async componentDidMount() {
    const { history, location } = this.props;

    const creds = await getCreds();

    if (!creds.access) {
      const fullPath = `${location.pathname}${location.search}`;
      const redirectUrl = routesForRedirects.includes(location.pathname) ? fullPath : undefined;

      history.push(EPublicRoutes.LogIn, { redirectAfterLogin: redirectUrl });
    }
  }

  public render() {
    const { usersCurrent } = this.props;

    if (!usersCurrent.data) {
      return null;
    }

    return usersCurrent.data.role === EUserRoles.Administrator || usersCurrent.data.role === EUserRoles.Manager ? (
      <LayoutPrivateBackOffice />
    ) : (
      <LayoutPrivateBasic />
    );
  }
}

const containers = compose(withRouter, communicationUsers.injector);

export const LayoutPrivate = containers(LayoutPrivateComponent);

const routesForRedirects: string[] = [EPrivateRoutes.TransactionGiftRequest, EPrivateRoutes.TransactionRequest];
