import { CardInfo, Container } from '@axmit/clp-library';
import * as React from 'react';
import { EmptyData } from 'common/components';
import { RouterHelper, MessageHelper } from 'common/helpers';
import { UserEmployeeCheckoutForm } from 'entities/Payment/components';
import { communicationPayment, IPaymentConnectedProps } from 'entities/Payment';

type AllProps = IPaymentConnectedProps;

export const UserTransactionsFormComponent = (props: AllProps) => {
  const { addEmployeesPaymentModel, addUsersPaymentModel } = props;
  const query = RouterHelper.useQuery();

  const userId = query.get('userId');
  const employeeId = query.get('employeeId');
  const description = query.get('description');
  const amountInit = query.get('amount') || 1;

  const id = employeeId || userId;

  if (!id) {
    return <EmptyData />;
  }

  const add = employeeId ? addEmployeesPaymentModel : addUsersPaymentModel;

  return (
    <Container>
      <div className="content-wrapper content-wrapper_height_full content-wrapper_width_half">
        {description && <CardInfo header={description} headerSize="sm" />}

        <UserEmployeeCheckoutForm
          onlyNewCard={!!employeeId}
          add={params => add({ id, ...params })}
          amountInit={Number(amountInit)}
          successCallback={MessageHelper.updateSuccess}
        />
      </div>
    </Container>
  );
};

export const UserTransactionsForm = communicationPayment.injector(UserTransactionsFormComponent);
