import i18n from 'app/locales/i18n';

const getDiscoveryLetterContent = (): string => {
  const spacer = `\n\n`;

  return (
    spacer +
    i18n.t('newGiftDiscoveryLetter.1') +
    spacer +
    i18n.t('newGiftDiscoveryLetter.2') +
    spacer +
    i18n.t('newGiftDiscoveryLetter.3') +
    spacer +
    i18n.t('newGiftDiscoveryLetter.4') +
    spacer +
    i18n.t('newGiftDiscoveryLetter.5')
  );
};

const getDiscoveryLetter = (name?: string): string => {
  return `${i18n.t('newGiftDiscoveryLetter.header', {
    name: name || '',
    interpolation: { escapeValue: false }
  })}${getDiscoveryLetterContent()}`;
};

const getActivationLetterContent = (): string => {
  const spacer = `\n\n`;

  return (
    spacer +
    i18n.t('newGiftActivationLetter.1') +
    spacer +
    i18n.t('newGiftActivationLetter.2') +
    spacer +
    i18n.t('newGiftActivationLetter.3') +
    spacer +
    i18n.t('newGiftActivationLetter.4')
  );
};

const getActivationLetter = (name?: string): string => {
  return `${i18n.t('newGiftActivationLetter.header', {
    name: name || '',
    interpolation: { escapeValue: false }
  })}${getActivationLetterContent()}`;
};

export const GiftCreateHelper = {
  getActivationLetter,
  getDiscoveryLetter
};
