import message from 'antd/es/message';
import { MessageType } from 'antd/lib/message';
import { EErrorStatus } from 'common/models/Request.models';
import { IMappedValidateData, IErrorData, IValidateDetailsItem } from 'common/models/Validate.models';
import i18n from 'app/locales/i18n';

function mapError(
  status: number,
  statusText: string,
  data: string | IErrorData
): null | string | IMappedValidateData | MessageType {
  switch (status) {
    case EErrorStatus.Forbidden:
    case EErrorStatus.BadRequest:
    case EErrorStatus.Conflict:
    case EErrorStatus.InternalServerError:
      return typeof data === 'object' ? message.error(data.message || i18n.t('errorDefault')) : data;
    case EErrorStatus.Validation:
      return typeof data === 'object' ? mapValidationData(status, statusText, data) : data;
    // TODO: Return to 1 condition when BE'll fixed
    case EErrorStatus.NotFound:
    case EErrorStatus.Unauthorized:
      return typeof data === 'object' ? { message: data.message, status: data.statusCode, errors: {} } : null;
    default:
      return getDefaultError();
  }
}

const getDefaultError = () => {
  return message.error(i18n.t('errorDefault'));
};

function mapValidationData(status: number, statusText: string, data: IErrorData): IMappedValidateData {
  const { details } = data;

  return {
    errors: details ? getErrorsFromDetails(details) : {},
    errorCode: data.error,
    message: statusText,
    status
  };
}

const getErrorsFromDetails = (details: IValidateDetailsItem[]) => {
  return details.reduce((accumulator, current) => {
    const missingProperty = current.params?.missingProperty || '';
    const key = current.dataPath.replace('.body.', '') || missingProperty;

    if (!key) {
      console.error('UNPROCESSABLE validation ERROR', current);

      return accumulator;
    }

    return {
      ...accumulator,
      [key]: [current.message]
    };
  }, {});
};

const getValidateStatus = (message?: string | number) => {
  return message ? 'error' : '';
};

export const ValidateHelper = {
  getValidateStatus,
  mapError
};
