import message from 'antd/es/message';
import {
  APIProvider,
  BaseStrategy,
  Branch,
  buildCommunication,
  EActionsTypes,
  getStartType,
  StoreBranch
} from '@axmit/redux-communications';
import { put } from '@redux-saga/core/effects';
import { RequestLoadingHelper } from 'common/helpers';
import i18n from 'app/locales/i18n';
import {
  IGiftPaymentModel,
  IGiftPaymentParams,
  IPaymentCreateViewParams,
  IPaymentMethodCollection,
  IPaymentMethodCollectionParams,
  IPaymentMethodDetachParams,
  IPaymentMethodShort,
  IPaymentIntentParams,
  IGiftPaymentCalculatorParams,
  IGiftPaymentCalculatorModel
} from 'entities/Payment/Payment.models';
import { PaymentMethodsTransport, PaymentTransport, GiftPaymentCalculatorTransport } from 'entities/Payment/Payment.transport';

const namespace = 'payment';

export interface IPaymentConnectedProps {
  paymentModel: StoreBranch<IGiftPaymentModel>;
  addPaymentModel(params: IGiftPaymentParams): void;
  addOwnersPaymentModel(params: IGiftPaymentParams): void;
  addUsersPaymentModel(params: IPaymentIntentParams): void;
  addEmployeesPaymentModel(params: IPaymentIntentParams): void;

  paymentMethod: StoreBranch<IPaymentMethodShort>;
  addPaymentMethod(params: IPaymentCreateViewParams): void;
  deletePaymentMethod(params: IPaymentMethodDetachParams): void;

  paymentMethodCollection: StoreBranch<IPaymentMethodCollection>;
  getPaymentMethodCollection(params: IPaymentMethodCollectionParams): void;

  paymentCalculator: StoreBranch<IGiftPaymentCalculatorModel>;
  getPaymentCalculator(params: IGiftPaymentCalculatorParams): void;
}

const transport = new PaymentTransport();

const mapPaymentIntentParams = (originalParams?: IGiftPaymentParams | IPaymentIntentParams) => {
  if (!originalParams) {
    return {};
  }

  const { successCallback, ...rest } = originalParams;

  return rest;
};

const onSuccessPaymentIntent = (response: IGiftPaymentModel, payload?: IGiftPaymentParams | IPaymentIntentParams) => {
  if (!payload?.successCallback) {
    return;
  }

  if (!response.clientSecret) {
    message.error(i18n.t('errorPaymentInfo'));

    return;
  }

  payload.successCallback(response.clientSecret);
};

const modelApiProvider = [
  new APIProvider<IGiftPaymentModel, IGiftPaymentParams>(EActionsTypes.add, transport.addPaymentIntent, {
    mapParams: mapPaymentIntentParams,
    onSuccess: onSuccessPaymentIntent
  }),
  new APIProvider<IGiftPaymentModel, IPaymentIntentParams>('addUsers', transport.addUserIntent, {
    mapParams: mapPaymentIntentParams,
    onSuccess: onSuccessPaymentIntent
  }),
  new APIProvider<IGiftPaymentModel, IPaymentIntentParams>('addEmployees', transport.addEmployeeIntent, {
    mapParams: mapPaymentIntentParams,
    onSuccess: onSuccessPaymentIntent
  }),
  new APIProvider<IGiftPaymentModel, IGiftPaymentParams>('addOwners', transport.addOwnersPaymentIntent, {
    mapParams: mapPaymentIntentParams,
    onSuccess: onSuccessPaymentIntent
  })
];

const methodTransport = new PaymentMethodsTransport();

const methodApiProvider = [
  new APIProvider<IPaymentMethodShort, IPaymentCreateViewParams>(EActionsTypes.add, methodTransport.add, {
    clearParams: true,
    onSuccess: function*(response, originalParams) {
      yield originalParams && reloadMethodCollection(originalParams.userId);
    }
  }),
  new APIProvider(EActionsTypes.get, methodTransport.getCollection, {
    preRequestDataMapper: RequestLoadingHelper.setOldData
  }),
  new APIProvider(EActionsTypes.delete, methodTransport.delete, {
    onSuccess: function*(response, originalParams) {
      yield originalParams && reloadMethodCollection(originalParams.id);
    }
  })
];

function* reloadMethodCollection(id: string) {
  yield put({
    type: getStartType(namespace, 'methodCollection', EActionsTypes.get),
    payload: { userId: id }
  });
}

const calculatorTransport = new GiftPaymentCalculatorTransport();

const calculatorApiProvider = [
  new APIProvider<IGiftPaymentCalculatorModel, IGiftPaymentCalculatorParams>(EActionsTypes.get, calculatorTransport.calculate, {
    mapSuccess: ({ amount, mailCharge = 0, commission, ...rest }: IGiftPaymentCalculatorModel) => {
      const total: number = amount + mailCharge + commission;

      return { amount, mailCharge, commission, ...rest, total };
    }
  })
];

const branches = [
  new Branch('model', modelApiProvider),
  new Branch('calculator', calculatorApiProvider),
  new Branch('method', methodApiProvider),
  new Branch('methodCollection', methodApiProvider)
];

const strategy = new BaseStrategy({
  namespace,
  branches
});

export const communicationPayment = buildCommunication<IPaymentConnectedProps>(strategy);
