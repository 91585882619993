import { HooksHelper } from '@axmit/clp-library/dist/helpers';
import Form from 'antd/es/form';
import Spin from 'antd/es/spin';
import { FormComponentProps } from 'antd/es/form';
import React, { FormEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { FormItem, Input } from '@axmit/clp-library';
import { MobFormButton } from 'common/components';
import { communicationAuth, IAuthConnectedProps } from 'entities/Auth/Auth.communication';
import { communicationUsers, IUsersConnectedProps } from 'entities/User/Users.communication';
import { PasswordInput } from 'entities/Auth/components/PasswordInput';

interface IComponentProps {
  cancel: () => void;
}

type AllProps = FormComponentProps & IAuthConnectedProps & IUsersConnectedProps & IComponentProps;

const ChangePasswordComponent = (props: AllProps) => {
  const { t } = useTranslation();
  const { form, authPassword, cancel, usersCurrent } = props;
  const { getFieldDecorator } = form;

  const confirmDirty = HooksHelper.useStateBuilder(false);

  const submit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    form.validateFields((err, values) => {
      if (err) {
        return;
      }

      usersCurrent.data && props.changeAuthPassword({ id: usersCurrent.data.id, data: values });
    });
  };

  const compareToFirstPassword = (rule: string, value: string, callback: Function) => {
    if (value && value !== form.getFieldValue('newPassword')) {
      return callback(t('validationErrorNewPasswordInconsistentPasswords'));
    }

    return callback();
  };

  const validateToNextPassword = (rule: string, value: string, callback: Function) => {
    if (value && confirmDirty.value) {
      form.validateFields(['confirm'], { force: true });
    }

    return callback();
  };

  const handleConfirmBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const { value } = e.target;

    confirmDirty.set(prevValue => prevValue || !value);
  };

  return (
    <Spin spinning={authPassword.loading}>
      <Form className="mt-30" onSubmit={submit}>
        <PasswordInput
          fieldName="oldPassword"
          getFieldDecorator={getFieldDecorator}
          label={t('changePassword.form.labelOld')}
          formItemProps={{ hasFeedback: true }}
        />

        <PasswordInput
          fieldName="newPassword"
          getFieldDecorator={getFieldDecorator}
          label={t('changePassword.form.labelNew')}
          validatorFunction={validateToNextPassword}
          formItemProps={{ hasFeedback: true }}
        />

        <FormItem label={t('changePassword.form.labelRepeat')} hasFeedback>
          {getFieldDecorator('confirm', {
            rules: [
              {
                required: true,
                message: t('errorPasswordConfirmation')
              },
              {
                validator: compareToFirstPassword
              }
            ]
          })(<Input type="password" onBlur={handleConfirmBlur} />)}
        </FormItem>

        <MobFormButton type="primary" htmlType="submit" className="mb-30">
          {t('changePassword.submit')}
        </MobFormButton>

        <MobFormButton onClick={cancel}>{t('defaultBtnCancel')}</MobFormButton>
      </Form>
    </Spin>
  );
};

export const ChangePasswordForm = communicationAuth.injector(
  communicationUsers.injector(Form.create<AllProps>()(ChangePasswordComponent))
);
