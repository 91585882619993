import Badge from 'antd/es/badge';
import Icon from 'antd/es/icon';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { FormatHelper } from 'common/helpers';
import { EGiftType, EGiftStatus } from 'entities/Gift/Gift.models';
import { RequestCardHelper } from 'entities/Requests/helpers/RequestCard.helper';
import { IRequestModel } from 'entities/Requests/Requests.models';

interface IComponentProps {
  request: IRequestModel;
}

export const RequestsCard = ({ request }: IComponentProps) => {
  const { t } = useTranslation();
  const { priority, status, requestNumber, giftData, createdAt, userAssigneeName, giftRequestData } = request;
  const isGift = RequestCardHelper.isGift(requestNumber);

  const statusIcon = RequestCardHelper.getStatusIcon(status);
  const requestIcon = RequestCardHelper.getPriorityIcon(priority);

  const title = isGift ? RequestCardHelper.getGiftType(giftData?.type as EGiftType) : giftRequestData?.meta.title;
  const giftStatus = isGift ? giftData?.status !== 'activated' && ` (${t('request.notActivatedLabel')})` : '';

  const date = FormatHelper.getDateWithYear(createdAt);

  const assignee = getLabel(userAssigneeName);
  const recipient = getLabel(isGift ? giftData?.meta.recipientName : giftRequestData?.userName);
  const isNeedRefund = giftData?.status === EGiftStatus.BudgetNotCollected && !!giftData.currentBalance;

  return (
    <div className="card-gift mt-20 mb-20 pb-15">
      <div className="card-gift__code">
        {isNeedRefund && <Icon type="warning" className="float_r card-gift__icon" theme="filled" />}
        <Badge status={statusIcon} text={t(`request.statuses.${status}`)} className="float_r first-letter_capitalize" />
        {requestNumber}
      </div>

      <div className="bordered_bottom bordered_bottom_light mt-10 mb-10">
        <b className="float_r">{date}</b>
        <b>{title}</b>
        <div>{giftStatus}</div>
      </div>

      <div className="card-gift__sublabel">{t(`${isGift ? 'request.card.recipient' : 'client'}`)}:</div>
      {recipient}

      <div className="card-gift__sublabel mt-10">{t('request.card.manager')}:</div>
      {assignee}

      <div className="card-gift__sublabel mt-15">{t('request.card.priority')}:</div>

      <div className="card-gift__priority">
        <Badge status={requestIcon} text={priority} className="first-letter_capitalize" />
      </div>
    </div>
  );
};

const getLabel = (value?: string | null): string => {
  return value ? `${value}` : '-';
};
