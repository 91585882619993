import * as React from 'react';
import { Tabs } from '@axmit/clp-library';
import AntTabs from 'antd/es/tabs';
import { compose } from 'redux';
import { useTranslation } from 'react-i18next';
import { GiftRequestsNew } from 'entities/Requests/components/GiftRequestsNew';
import { GiftRequestsListFiltered } from 'entities/Requests/components/GiftRequestsListFiltered';
import { communicationRequests, IRequestsConnectedProps } from 'entities/Requests/Requests.communication';

type AllProps = IRequestsConnectedProps;

export const GiftRequestsComponent = ({
  getRequestsAllCollection,
  getRequestsInProgressCollection,
  requestsAllCollection,
  requestsInProgressCollection,
  clearRequestsAllCollection,
  clearRequestsInProgressCollection
}: AllProps) => {
  const { t } = useTranslation();
  return (
    <Tabs className="mt-20" type="card">
      <AntTabs.TabPane key="giftRequest" tab={t('request.tab.new')}>
        <GiftRequestsNew />
      </AntTabs.TabPane>

      <AntTabs.TabPane key="inProgress" tab={t('request.tab.inProgress')}>
        <GiftRequestsListFiltered
          collection={requestsInProgressCollection}
          getCollection={getRequestsInProgressCollection}
          clearCollection={clearRequestsInProgressCollection}
        />
      </AntTabs.TabPane>

      <AntTabs.TabPane key="all" tab={t('request.tab.all')}>
        <GiftRequestsListFiltered
          collection={requestsAllCollection}
          getCollection={getRequestsAllCollection}
          clearCollection={clearRequestsAllCollection}
        />
      </AntTabs.TabPane>
    </Tabs>
  );
};

const containers = compose(communicationRequests.injector);

export const GiftRequests = containers(GiftRequestsComponent);
