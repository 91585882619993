import * as React from 'react';
import AntSelect from 'antd/es/select';
import Icon from 'antd/es/icon';
import { Row, Col } from 'antd/es/grid';
import { Paragraph, Select } from '@axmit/clp-library';
import { useTranslation } from 'react-i18next';
import { EOrderDirection } from 'common/models/Request.models';
import { QuickSearch } from 'common/components';
import { REQUESTS_FILTER_COL } from 'entities/Requests/Request.consts';
import { ERequestsFilterBy, RequestOptions } from 'entities/Requests/Requests.models';

interface IComponentProps {
  onSearch: (value: string) => void;
  selectFilter: (value: any) => void;
  changeFilter: (value: any) => void;
  ordering?: ERequestsFilterBy;
  orderDirection: EOrderDirection;
}

export const GiftRequestsFilters = ({ changeFilter, onSearch, selectFilter, orderDirection, ordering }: IComponentProps) => {
  const { t } = useTranslation();
  return (
    <Row gutter={16}>
      <Col {...REQUESTS_FILTER_COL}>
        <QuickSearch onSearch={onSearch} />
      </Col>

      <Col {...REQUESTS_FILTER_COL}>
        <Paragraph size="xs">{t('baseFilters.sortBy')}</Paragraph>

        <Select bordered="bottom" className="w-100p" allowClear onChange={changeFilter} onSelect={selectFilter}>
          {RequestOptions.filterBy.map(({ value, label }) => (
            <AntSelect.Option key={value} value={value}>
              {label}{' '}
              {ordering === value && (
                <Icon type={`sort-${orderDirection === EOrderDirection.ASC ? 'ascending' : 'descending'}`} />
              )}
            </AntSelect.Option>
          ))}
        </Select>
      </Col>
    </Row>
  );
};
