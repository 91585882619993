import * as React from 'react';
import { SvgFB } from 'common/SVG/SvgFB';
import { SvgSMS } from 'common/SVG/SvgSMS';
import { SvgWhatsApp } from 'common/SVG/SvgWhatsApp';
import { EMessageType } from 'entities/Messages/Messages.models';

interface IComponentProps {
  selectType: (value: EMessageType) => void;
  type?: EMessageType;
  availableTypes: EMessageType[];
  disableAll?: boolean;
}

export const SendingTypeGroup = ({ type, selectType, availableTypes, disableAll }: IComponentProps) => {
  const disableWhatsApp = disableAll || !availableTypes.includes(EMessageType.whatsApp);
  const disableSMS = disableAll || !availableTypes.includes(EMessageType.sms);
  const disableFB = disableAll || !availableTypes.includes(EMessageType.messenger);

  const whatsAppIconClasses = getItemClasses(EMessageType.whatsApp, disableWhatsApp, type);
  const smsIconClasses = getItemClasses(EMessageType.sms, disableSMS, type);
  const fbIconClasses = getItemClasses(EMessageType.messenger, disableFB, type);

  return (
    <div className="chat-selector">
      <div
        className={`chat-selector__item chat-selector__item-fb ${fbIconClasses}`}
        onClick={() => !disableFB && selectType(EMessageType.messenger)}
      >
        <SvgFB />
      </div>

      <div
        className={`chat-selector__item chat-selector__item-whatsApp ${whatsAppIconClasses}`}
        onClick={() => !disableWhatsApp && selectType(EMessageType.whatsApp)}
      >
        <SvgWhatsApp />
      </div>

      <div
        className={`chat-selector__item chat-selector__item-sms ${smsIconClasses}`}
        onClick={() => !disableSMS && selectType(EMessageType.sms)}
      >
        <SvgSMS />
      </div>
    </div>
  );
};

const getItemClasses = (type: EMessageType, disabled: boolean, activeType?: EMessageType): string => {
  return disabled ? 'chat-selector__item_disabled' : activeType === type ? 'chat-selector__item_active' : '';
};
