import React, { FormEvent } from 'react';
import { compose } from 'redux';
import { Row, Col } from 'antd/es/grid';
import Form from 'antd/es/form';
import { Button } from '@axmit/clp-library';
import { useTranslation } from 'react-i18next';
import { FormComponentProps } from 'antd/es/form';
import { TextAreaForm } from 'common/components';
import { ERequestStatus } from 'entities/Requests/Requests.models';
import { communicationRequests, IRequestsConnectedProps } from 'entities/Requests/Requests.communication';
import { ContributorsSuggestions } from 'entities/Contributors/components';

type AllProps = FormComponentProps & IRequestsConnectedProps;

const RequestEditPageDetailsComponent = ({ requestsModel, updateRequestsModel, form }: AllProps) => {
  const { t } = useTranslation();
  if (!requestsModel.data?.giftData) {
    return null;
  }

  const { giftData, giftNotes, id, priority, status } = requestsModel.data;
  const isFinished = status === ERequestStatus.Finished;
  const { meta, id: giftId, userSenderName, code } = giftData;
  const { description: idea, message, isSendByMail, city, country, house, state, street, zipCode } = meta;
  const prefix: string = house ? `${house}, ` : '';
  const address: string | undefined = isSendByMail
    ? `${prefix} ${street}, ${zipCode}, ${city}, ${state}, ${country?.name}`
    : undefined;
  const wrapperCol = { xs: 24, md: 12 };

  const submit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    form.validateFields((err, values) => {
      if (err || giftNotes === values.giftNotes) {
        return;
      }

      updateRequestsModel({ id, data: { ...values, priority } });
    });
  };

  return (
    <Row gutter={30}>
      <Col {...wrapperCol}>
        {address && (
          <div className="card-details">
            <div className="card-details__header mb-10">{t('request.mailAddress')}</div>

            <div className="card-details__content card-details__content_black">{address}</div>
          </div>
        )}

        {code && (
          <div className="card-details">
            <div className="card-details__header mb-10">Gift Code: {code}</div>
          </div>
        )}

        {idea && (
          <div className="card-details card-details_bordered">
            <div className="card-details__header">{t('request.suggestion')}</div>

            <div className="card-details__content">{idea}</div>

            <div className="card-details__sign">{userSenderName}</div>

            <ContributorsSuggestions giftId={giftId} />
          </div>
        )}

        {message && (
          <div className="card-details card-details_bordered">
            <div className="card-details__header">{t('request.giftMessage')}</div>

            <div className="card-details__content">{message}</div>
          </div>
        )}
      </Col>

      <Col {...wrapperCol}>
        <div className="card-details">
          <div className="card-details__header">{t('request.giftNotes')}</div>

          <Form onSubmit={submit}>
            <TextAreaForm
              getFieldDecorator={form.getFieldDecorator}
              textAreaProps={{ disabled: isFinished, autoSize: { minRows: 11, maxRows: 16 } }}
              initialValue={giftNotes}
              fieldName="giftNotes"
            />

            <Button type="primary" htmlType="submit" className="ant-btn_mob-block">
              {t('btnSave')}
            </Button>
          </Form>
        </div>
      </Col>
    </Row>
  );
};

const containers = compose(communicationRequests.injector);

export const RequestEditPageDetails = containers(Form.create<AllProps>()(RequestEditPageDetailsComponent));
