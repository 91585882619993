import i18n from 'app/locales/i18n';
import { EGiftStatus } from 'entities/Gift/Gift.models';

const getStatusIcon = (status: EGiftStatus): 'success' | 'processing' | 'default' | 'error' | 'warning' => {
  switch (status) {
    case EGiftStatus.BudgetNotCollected:
      return 'error';

    case EGiftStatus.Activated:
    case EGiftStatus.PaymentAwaiting:
    case EGiftStatus.Created:
      return 'warning';

    case EGiftStatus.Completed:
    case EGiftStatus.Delivered:
      return 'success';

    case EGiftStatus.InProgress:
      return 'processing';

    default:
      return 'default';
  }
};

const getStatusDescription = (status: EGiftStatus): string => {
  const label = `giftStatusesDescription.${status}`;

  return i18n.t(label, { defaultValue: i18n.t('giftStatusesDescription.unknown') });
};

export const giftCardHelper = {
  getStatusIcon,
  getStatusDescription
};
