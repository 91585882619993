import { Paragraph } from '@axmit/clp-library';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Icon from 'antd/es/icon';
import { Tooltip } from 'antd';
import { Desktop, Spinner, TwoColorsField } from 'common/components';
import { DateHelper, FormatHelper } from 'common/helpers';
import { communicationCompanies, ICompaniesConnectedProps } from 'entities/Companies/Companies.communication';

interface IComponentProps {
  companyId: string;
}

type AllProps = ICompaniesConnectedProps & IComponentProps;

const CompanyInfoSummaryComponent = (props: AllProps) => {
  const { t } = useTranslation();
  const { companiesSummary, getCompaniesSummary, companyId, clearCompaniesSummary } = props;
  const { data, loading } = companiesSummary;
  const { balance, nextTransferAmount, nextTransferDate, autoPaymentEnabled } = data || {};

  const needPayment = nextTransferAmount && (!balance || balance < nextTransferAmount);
  const isVisiblePaymentWarning = !autoPaymentEnabled && needPayment;
  const enabledLabel: string = loading ? '' : t(`accountPage.summary.autoPayment${autoPaymentEnabled ? 'Enabled' : 'Disabled'}`);

  useEffect(() => {
    getCompaniesSummary(companyId);
  }, [companyId, getCompaniesSummary]);

  useEffect(() => {
    return () => {
      clearCompaniesSummary();
    };
  }, [clearCompaniesSummary]);

  return (
    <Spinner spinning={loading}>
      <Desktop>
        <Paragraph className="color_primary t_bold mt-15" size="sm">
          {t('accountPage.summary.header')}
        </Paragraph>
      </Desktop>

      <TwoColorsField
        leftSlot={t('accountPage.summary.balance')}
        rightSlot={FormatHelper.getCurrency(balance)}
        className="mb-10"
      />

      <TwoColorsField
        leftSlot={t('accountPage.summary.credit')}
        rightSlot={FormatHelper.getCurrency(nextTransferAmount)}
        className={isVisiblePaymentWarning ? 'mb-0' : 'mb-10'}
      />

      {isVisiblePaymentWarning && (
        <div className="has-error mb-10">
          <div className="ant-form-explain">{t('accountPage.summary.paymentWarning')}</div>
        </div>
      )}

      <TwoColorsField
        leftSlot={t('accountPage.summary.date')}
        rightSlot={nextTransferDate ? DateHelper.getDateWithYear(nextTransferDate) : '-'}
        className="mb-10"
      />

      <TwoColorsField
        leftSlot={t('accountPage.summary.autoPayment')}
        rightSlot={
          enabledLabel && (
            <>
              {enabledLabel}

              <Tooltip placement="rightTop" title={<div>{t('accountPage.summary.autoPaymentDescription')}</div>}>
                <Icon
                  type="question-circle"
                  theme="filled"
                  className="svg-icon_gray svg-icon_size-21 svg-icon_center ml-5 pb-2"
                />
              </Tooltip>
            </>
          )
        }
        className="mb-30"
      />
    </Spinner>
  );
};

export const CompanyInfoSummary = communicationCompanies.injector(CompanyInfoSummaryComponent);
