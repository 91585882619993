import axios from 'axios';
import {
  IGiftPaymentParams,
  IPaymentCreateViewParams,
  IPaymentMethodCollection,
  IPaymentMethodCollectionParams,
  IPaymentMethodDetachParams,
  IPaymentMethodShort,
  IPaymentIntentParams,
  IGiftPaymentModel,
  IGiftPaymentCalculatorParams,
  IGiftPaymentCalculatorModel
} from 'entities/Payment/Payment.models';

const baseUrl = `/gifts`;
const baseMethodsPath = '/users/';
const baseEmployeesPath = '/employees/';

export class PaymentTransport {
  public addPaymentIntent = ({ giftId, ...params }: IGiftPaymentParams): Promise<any> => {
    return axios.post(`${baseUrl}/${giftId}/payment-intent`, params);
  };

  public addOwnersPaymentIntent = ({ giftId, ...params }: IGiftPaymentParams): Promise<any> => {
    return axios.post(`${baseUrl}/${giftId}/payment-intent/owner`, params);
  };

  public addUserIntent = ({ id, ...rest }: IPaymentIntentParams): Promise<IGiftPaymentModel> => {
    return axios.post(`${baseMethodsPath}${id}/payment-intent`, rest);
  };

  public addEmployeeIntent = ({ id, ...rest }: IPaymentIntentParams): Promise<IGiftPaymentModel> => {
    return axios.post(`${baseEmployeesPath}${id}/payment-intent`, rest);
  };
}

export class PaymentMethodsTransport {
  public add = ({ userId, ...rest }: IPaymentCreateViewParams): Promise<IPaymentMethodShort> => {
    return axios.post(`${baseMethodsPath}${userId}/payment-methods`, rest);
  };

  public delete = ({ id, idPm }: IPaymentMethodDetachParams): Promise<void> => {
    return axios.delete(`${baseMethodsPath}${id}/payment-methods/${idPm}`);
  };

  public getCollection = ({ userId }: IPaymentMethodCollectionParams): Promise<IPaymentMethodCollection> => {
    return axios.get(`${baseMethodsPath}${userId}/payment-methods`);
  };
}

export class GiftPaymentCalculatorTransport {
  public calculate = (params: IGiftPaymentCalculatorParams): Promise<IGiftPaymentCalculatorModel> => {
    return axios.post(`${baseUrl}/payment-intent/calculate`, params);
  };
}
