import Badge from 'antd/es/badge';
import * as React from 'react';
import { ContentSimple } from 'entities/Messages/components';
import { IMessageContent } from 'entities/Messages/Messages.models';

interface IComponentProps {
  lastMessage: IMessageContent;
  count?: number;
  active?: boolean;
  onClick?: () => void;
  header: string;
}

export const ChannelsListItem = ({ header, lastMessage, count, active, onClick }: IComponentProps) => {
  return (
    <div className={`contact-card ${active ? 'contact-card_active' : ''}`} onClick={onClick}>
      <Badge count={count} className="contact-card__badge" />

      <span className="contact-card__header">{header}</span>
      <br />
      <span className="contact-card__message">{lastMessage && <ContentSimple {...lastMessage} />}</span>
    </div>
  );
};
