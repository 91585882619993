import React from 'react';
import { HorizontalMenu } from '@axmit/clp-library';
import Menu from 'antd/lib/menu';
import { useHistory } from 'react-router';
import { AppLandingHelper } from '@axmit/router-app-landing';
import { useTranslation } from 'react-i18next';
import { EBackOfficeRoutes, EPrivateRoutes } from 'common/const/Routes.const';
import { Header, LogoGift } from 'common/components';
import { TabsHooks } from 'common/hooks';
import logo from 'app/assets/images/logo.png';
import { languages } from 'app/locales/i18n';
import julesMobile from 'app/assets/images/julesMobile.png';
import { MessageTabCounter } from 'entities/Messages/components';
import { communicationAuth, IAuthConnectedProps } from 'entities/Auth/Auth.communication';

type AllProps = IAuthConnectedProps;

const HeaderBackOfficeComponent = (props: AllProps) => {
  const history = useHistory();
  const { i18n, t } = useTranslation();
  const { activeTabs } = TabsHooks.useSelectedTabs(availableRoutes, 'commonRequest');

  const logout = () => {
    props.deleteAuthModel();
  };

  return (
    <Header
      leftSlot={<LogoGift logo={logo} onClick={() => AppLandingHelper.toggleRoute()} />}
      rightSlot={
        <HorizontalMenu
          className="clp-main-menu"
          selectedKeys={activeTabs}
          mobileImg={julesMobile}
          currentLanguage={i18n.language}
          languages={languages}
          updateLanguage={language => i18n.changeLanguage(language)}
        >
          <Menu.Item key="commonRequest" onClick={() => history.push(EPrivateRoutes.Main)}>
            {t('backOfficeDashboard.tab.request')}
          </Menu.Item>

          <Menu.Item key={EBackOfficeRoutes.Messages} onClick={() => history.push(EBackOfficeRoutes.Messages)}>
            <MessageTabCounter />
          </Menu.Item>

          <Menu.Item key={EBackOfficeRoutes.Managers} onClick={() => history.push(EBackOfficeRoutes.Managers)}>
            {t('backOfficeDashboard.tab.managers')}
          </Menu.Item>

          <Menu.Item key={EBackOfficeRoutes.Clients} onClick={() => history.push(EBackOfficeRoutes.Clients)}>
            {t('backOfficeDashboard.tab.clients')}
          </Menu.Item>

          <Menu.Item key={EBackOfficeRoutes.Companies} onClick={() => history.push(EBackOfficeRoutes.Companies)}>
            {t('backOfficeDashboard.tab.companies')}
          </Menu.Item>

          <Menu.Item key="deskLogin" onClick={logout} className="hidden_mob">
            {t('headerSignOut')}
          </Menu.Item>

          <Menu.Item key="mobLogin" onClick={logout} className="hidden_desk mt-20">
            {t('headerSignOut')}
          </Menu.Item>
        </HorizontalMenu>
      }
    />
  );
};

export const HeaderBackOffice = communicationAuth.injector(HeaderBackOfficeComponent);

const availableRoutes: string[] = [
  EBackOfficeRoutes.Messages,
  EBackOfficeRoutes.Managers,
  EBackOfficeRoutes.Clients,
  EBackOfficeRoutes.Companies
];
