import { AntdFormHelper } from '@axmit/antd-helpers';
import { Button } from '@axmit/clp-library';
import * as React from 'react';
import { FormEvent, useEffect } from 'react';
import { FormComponentProps } from 'antd/es/form';
import Form from 'antd/es/form';
import { useTranslation } from 'react-i18next';
import { Spinner, TextAreaForm } from 'common/components';
import { communicationClientNotes, IClientNotesConnectedProps } from 'entities/ClientNotes/ClientNotes.communication';

interface IComponentProps {
  email: string;
}

type AllProps = FormComponentProps & IComponentProps & IClientNotesConnectedProps;

const ClientNotesFormComponent = ({
  email,
  form,
  getClientNotesModel,
  clientNotesModel,
  clearClientNotesModel,
  addClientNotesModel,
  updateClientNotesModel
}: AllProps) => {
  const { t } = useTranslation();
  useEffect(() => {
    getClientNotesModel({ email });

    return () => {
      clearClientNotesModel();
    };
  }, [email, getClientNotesModel, clearClientNotesModel]);

  const updateNotes = ({ notes }: { [key: string]: string }) => {
    if (clientNotesModel.data?.id) {
      updateClientNotesModel({ id: clientNotesModel.data?.id, data: { notes: notes } });
    } else {
      addClientNotesModel({ notes: notes, emailList: [email] });
    }
  };

  const submit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    form.validateFields((err, values) => {
      if (err) {
        return;
      }

      updateNotes(values);
    });
  };

  const { data, loading } = clientNotesModel;

  return (
    <Spinner spinning={loading}>
      <Form className="card-details" onSubmit={submit}>
        <TextAreaForm
          getFieldDecorator={form.getFieldDecorator}
          textAreaProps={{ autoSize: { minRows: 20, maxRows: 30 } }}
          fieldName="notes"
          initialValue={data?.notes}
        />

        <Button type="primary" htmlType="submit" className="ant-btn_mob-block">
          {t('btnSave')}
        </Button>
      </Form>
    </Spinner>
  );
};

export const ClientNotesForm = communicationClientNotes.injector(
  Form.create<AllProps>({
    mapPropsToFields({ clientNotesModel }) {
      return AntdFormHelper.mapValidationToFields(clientNotesModel?.data, clientNotesModel?.errors);
    }
  })(ClientNotesFormComponent)
);
