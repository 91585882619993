import {
  APIProvider,
  BaseStrategy,
  Branch,
  buildCommunication,
  EActionsTypes,
  getStartType,
  getSuccessType,
  IAction,
  StoreBranch
} from '@axmit/redux-communications';
import { put, takeEvery, select } from 'redux-saga/effects';
import { DATE_FORMAT_DEFAULT } from 'common/config';
import { DateHelper, FormatHelper, RequestLoadingHelper, ResponseHelper } from 'common/helpers';
import { ESocketActions } from 'common/const/Socket.const';
import { BaseTransport } from 'common/dto/base.transport';
import { IApplicationState } from 'app/store/reducers';
import i18n from 'app/locales/i18n';
import { MessagesTransport } from 'entities/Messages/Messages.transport';
import {
  IChannelsCollection,
  IChannelsCollectionParams,
  IChannelsModel,
  IMarkAsReadParams,
  IMessageCollection,
  IMessageCollectionDivider,
  IMessageCollectionParams,
  IMessageCreateParams,
  IMessageModel,
  IUnreadInfoModel
} from 'entities/Messages/Messages.models';

const namespace = 'messages';

export interface IMessagesConnectedProps {
  messagesModel: StoreBranch<IMessageModel>;
  addMessagesModel(params: IMessageCreateParams): void;

  messagesChannels: StoreBranch<IChannelsCollection>;
  getMessagesChannels(params: IChannelsCollectionParams): void;
  clearMessagesChannels(): void;

  messagesCollection: StoreBranch<IMessageCollection, IMessageCollectionParams>;
  getMessagesCollection(params: IMessageCollectionParams): void;
  clearMessagesCollection(): void;

  messagesCollectionDividers: StoreBranch<IMessageCollectionDivider[]>;

  messagesUnreadInfo: StoreBranch<IUnreadInfoModel>;
  getMessagesUnreadInfo(): void;

  updateMessagesMarkAsRead(): void;
}

const transport = new MessagesTransport(namespace);
const modelApiProvider = [new APIProvider<IMessageModel, IMessageCreateParams>(EActionsTypes.add, transport.add)];
const collectionApiProvider = [
  new APIProvider<IMessageCollection, IMessageCollectionParams>(EActionsTypes.get, transport.getCollection, {
    mapSuccess: ResponseHelper.buildInfinityCollection,
    onSuccess: function*(response, originalParams) {
      yield mapDividers(response);

      const unreadArray = response.data.filter(item => !item.isRead).map(item => item.id);

      if (!unreadArray?.length || !originalParams?.client) {
        return;
      }

      yield markAsRead(unreadArray, originalParams?.client);
    },
    preRequestDataMapper: RequestLoadingHelper.setOldData
  })
];
const collectionDividersApiProvider = [new APIProvider(EActionsTypes.get, transport.get)];

function* mapDividers(response: IMessageCollection) {
  const dividers: IMessageCollectionDivider[] = [];
  const length = response.data.length;

  for (let i = length; i--; ) {
    const { createdAt, id } = response.data[i];
    const responseDate = FormatHelper.getDateWithYear(createdAt);

    if (!dividers.find(item => item.date === responseDate)) {
      dividers.push({ date: responseDate, firstMessageId: id });
    }
  }

  const lastDay = dividers[dividers.length - 1];
  if (lastDay) {
    const isToday = DateHelper.isToday(lastDay.date, DATE_FORMAT_DEFAULT);
    const isYesterday = DateHelper.isYesterday(lastDay.date, DATE_FORMAT_DEFAULT);

    if (isToday) {
      lastDay.date = i18n.t('today');
    }

    if (isYesterday) {
      lastDay.date = i18n.t('yesterday');
    }

    const prevDay = dividers[dividers.length - 2];

    if (prevDay) {
      const prevIsYesterday = DateHelper.isYesterday(prevDay.date, DATE_FORMAT_DEFAULT);

      if (prevIsYesterday) {
        prevDay.date = i18n.t('yesterday');
      }
    }
  }

  yield put({ type: getSuccessType(namespace, 'collectionDividers', EActionsTypes.get), payload: dividers });
}

function* markAsRead(unreadArray: string[], client: string) {
  const payload: IMarkAsReadParams = { messageIds: unreadArray, client };
  yield put({ type: getStartType(namespace, 'markAsRead', EActionsTypes.update), payload });
}

const markAsReadApiProvider = [
  new APIProvider<null, IMarkAsReadParams>(EActionsTypes.update, transport.markAsRead, { clearParams: true })
];

const channelTransport = new BaseTransport(`${namespace}/channel-state`);
const channelsApiProvider = [
  new APIProvider<IChannelsCollection, IChannelsCollectionParams>(EActionsTypes.get, channelTransport.getCollection, {
    preRequestDataMapper: RequestLoadingHelper.setOldData,
    mapSuccess: ResponseHelper.buildInfinityCollection
  })
];

const unreadTransport = new BaseTransport(`${namespace}/total-unread`);
const unreadApiProvider = [new APIProvider<IUnreadInfoModel>(EActionsTypes.get, unreadTransport.getCollection)];

function* handleIncomingMessage() {
  yield takeEvery(ESocketActions.IncomingMessage, function*({ payload: message }: IAction<IMessageModel>) {
    if (!message) {
      return;
    }

    const collection: IMessageCollection = yield select((state: IApplicationState) => state.messages.collection.data);
    const params: IMessageCollectionParams = yield select((state: IApplicationState) => state.messages.collection.params);
    if (!collection || !params?.client) {
      return;
    }

    yield put({
      type: getSuccessType(namespace, 'collection', EActionsTypes.get),
      payload: { data: [message, ...collection.data], meta: { count: collection?.meta.count + 1 } }
    });

    yield markAsRead([message.id], params.client);
  });
}

function* onTotalInfoUpdate() {
  yield takeEvery(ESocketActions.TotalInfoUpdate, function*({ payload }: IAction<IUnreadInfoModel>) {
    if (!payload) {
      return;
    }

    yield put({
      type: getSuccessType(namespace, 'unreadInfo', EActionsTypes.get),
      payload
    });
  });
}

function* onChannelsUpdate() {
  yield takeEvery(ESocketActions.ChannelsUpdate, function*({ payload: channel }: IAction<IChannelsModel>) {
    if (!channel) {
      return;
    }

    const collection: IChannelsCollection = yield select((state: IApplicationState) => state.messages.channels.data);
    if (!collection) {
      return;
    }

    const index = collection.data.findIndex(item => item.client === channel.client);
    if (index === -1) {
      return;
    }

    collection?.data?.splice(index, 1, channel);
    yield put({
      type: getSuccessType(namespace, 'channels', EActionsTypes.get),
      payload: { data: collection?.data, meta: { count: collection?.meta.count } }
    });
  });
}

const branches = [
  new Branch('model', modelApiProvider),
  new Branch('collection', collectionApiProvider),
  new Branch('collectionDividers', collectionDividersApiProvider),
  new Branch('channels', channelsApiProvider),
  new Branch('markAsRead', markAsReadApiProvider),
  new Branch('unreadInfo', unreadApiProvider)
];

const strategy = new BaseStrategy({
  namespace,
  branches
});

export const communicationMessages = buildCommunication<IMessagesConnectedProps>(strategy);

communicationMessages.sagas.push(onChannelsUpdate(), handleIncomingMessage(), onTotalInfoUpdate());
