import React from 'react';
import { ColumnProps } from 'antd/lib/table';
import { DateHelper, FormatHelper } from 'common/helpers';
import i18n from 'app/locales/i18n';
import { ITransactionsModel } from 'entities/Transactions/Transactions.models';

export const TRANSACTIONS_PER_PAGE = 8;

enum EUsersTransactionColumns {
  type = 'type',
  date = 'createdAt',
  amount = 'amount'
}

enum EDepositType {
  Deposit = 'deposit',
  Charge = 'charge',
  Transfer = 'transfer'
}

export const usersTransactionColumns: ColumnProps<ITransactionsModel>[] = [
  {
    dataIndex: EUsersTransactionColumns.type,
    title: EUsersTransactionColumns.type,
    render: valueInit => i18n.t(`transactionsTypes.${valueInit}`)
  },
  {
    dataIndex: EUsersTransactionColumns.amount,
    title: EUsersTransactionColumns.amount,
    render: (valueInit, model) => {
      const { type } = model;
      const isNegative: boolean = type === EDepositType.Charge;
      const colorClass = isNegative ? 'color_error' : 'color_success';

      return (
        <span className={colorClass}>
          {isNegative && '-'}
          {FormatHelper.getCurrency(valueInit)}
        </span>
      );
    }
  },
  {
    dataIndex: EUsersTransactionColumns.date,
    title: EUsersTransactionColumns.date,
    align: 'right',
    render: DateHelper.getDateWithYear
  }
];
