import { CardInfo, Container } from '@axmit/clp-library';
import * as React from 'react';
import { EmptyData } from 'common/components';
import { RouterHelper, MessageHelper } from 'common/helpers';
import { GiftCheckoutForm } from 'entities/Payment/components';

export const GiftTransactionsForm = () => {
  const query = RouterHelper.useQuery();

  const id = query.get('id');
  const description = query.get('description');
  const amount = query.get('amount') || 1;

  if (!id) {
    return <EmptyData />;
  }

  return (
    <Container>
      <div className="content-wrapper content-wrapper_height_full content-wrapper_width_half">
        {description && <CardInfo header={description} headerSize="sm" />}

        <GiftCheckoutForm isOwner giftId={id} amountInit={Number(amount)} successCallback={MessageHelper.updateSuccess} />
      </div>
    </Container>
  );
};
