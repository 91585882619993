import axios from 'axios';
import { BaseTransport } from 'common/dto/base.transport';
import {
  IAcceptGiftParams,
  ICheckCodeParams,
  IDiscoverGiftParams,
  IGiftCollection,
  IGiftCreateViewModel,
  IGiftModel,
  IGiftPublicParams,
  IRefundGiftParams
} from 'entities/Gift/Gift.models';

const baseUrl = `/gifts`;

export class GiftTransport extends BaseTransport<IGiftModel, IGiftCollection, IGiftCreateViewModel, IGiftModel> {
  public getPublic = (params: IGiftPublicParams): Promise<any> => {
    return axios.get(`${baseUrl}/${params.id}/public`);
  };

  public checkCode = (params: ICheckCodeParams): Promise<any> => {
    return axios.get(`${baseUrl}/by-code`, { params });
  };

  public accept = (params: IAcceptGiftParams): Promise<any> => {
    return axios.post(`${baseUrl}/${params.id}/accept`);
  };

  public discover = ({ id, ...rest }: IDiscoverGiftParams): Promise<any> => {
    return axios.post(`${baseUrl}/${id}/discover`, rest);
  };

  public refund = ({ id }: IRefundGiftParams): Promise<any> => {
    return axios.post(`${baseUrl}/${id}/refund`);
  };
}
