import { APIProvider, BaseStrategy, Branch, buildCommunication, EActionsTypes, StoreBranch } from '@axmit/redux-communications';
import { BaseTransport } from 'common/dto/base.transport';
import { RequestLoadingHelper, MessageHelper } from 'common/helpers';
import {
  IClientNotesCreateParams,
  IClientNotesModel,
  IClientNotesParams,
  IClientNotesUpdateParams
} from 'entities/ClientNotes/ClientNotes.models';

const namespace = 'clientNotes';

export interface IClientNotesConnectedProps {
  clientNotesModel: StoreBranch<IClientNotesModel>;
  getClientNotesModel(params: IClientNotesParams): void;
  addClientNotesModel(params: IClientNotesCreateParams): void;
  updateClientNotesModel(params: IClientNotesUpdateParams): void;
  clearClientNotesModel(): void;
}

const transport = new BaseTransport('client-notes');

const modelApiProvider = [
  new APIProvider(EActionsTypes.add, transport.add, {
    preRequestDataMapper: RequestLoadingHelper.setOldData,
    onSuccess: MessageHelper.updateSuccess
  }),
  new APIProvider(EActionsTypes.update, transport.update, {
    preRequestDataMapper: (response, payload, branchState) => {
      return RequestLoadingHelper.setOldDataForUpdating(payload, branchState);
    },
    onSuccess: MessageHelper.updateSuccess
  }),
  new APIProvider(EActionsTypes.get, transport.getCollection)
];

const branches = [new Branch('model', modelApiProvider)];

const strategy = new BaseStrategy({
  namespace,
  branches
});

export const communicationClientNotes = buildCommunication<IClientNotesConnectedProps>(strategy);
