import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { FormatHelper } from 'common/helpers';
import { EGiftStatus, EGiftType } from 'entities/Gift/Gift.models';
import { CardGiftActions } from 'entities/Gift/components/CardGiftActions';
import { CardGiftHeader } from 'entities/Gift/components/CardGiftHeader';
import { CardGiftStatus } from 'entities/Gift/components/CardGiftStatus';
import { GiftPrice } from 'entities/Gift/components/GiftPrice';

interface IComponentProps {
  giftId: string;
  type: EGiftType;
  status: EGiftStatus;
  recipient?: string;
  sender?: string;
  price?: string | number;
  date?: string;
}

export const CardGift = ({ status, recipient, sender, type, price, date, giftId }: IComponentProps) => {
  const { t } = useTranslation();

  return (
    <div className="card-gift mt-20 mb-20">
      <CardGiftHeader giftType={type} sender={sender} recipient={recipient} className="mb-0" ellipsis={{ rows: 2 }} />

      <GiftPrice price={price} fullWidth className="mb-30" />

      {date && (
        <div className="d-flex mb-10">
          <div className="d-flex__item card-gift__label">{t('deliveryDate')}:</div>

          <b>{FormatHelper.getDateWithYear(date)}</b>
        </div>
      )}

      <CardGiftActions status={status} giftId={giftId} sender={sender} />

      <div className="card-gift__footer">
        <CardGiftStatus status={status} className="mb-5" />
      </div>
    </div>
  );
};
