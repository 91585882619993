import Icon from 'antd/es/icon';
import * as React from 'react';
import { IMessageContent } from 'entities/Messages/Messages.models';

interface IComponentProps extends IMessageContent {}

export const ContentSimple = ({ text = '', file, image, audio, location, video }: IComponentProps) => {
  return (
    <>
      {text}

      {image && (
        <>
          <Icon type="file-image" /> {image.caption || ''}
        </>
      )}

      {location && <Icon type="global" />}

      {file && <Icon type="file" />}

      {audio && <Icon type="audio" />}

      {video && <Icon type="video-camera" />}
    </>
  );
};
