import React from 'react';
import { Route, Switch } from 'react-router';
import { EPublicRoutes } from 'common/const/Routes.const';
import { RestorePasswordForm } from 'entities/Auth/components/RestorePasswordForm';
import { NewPasswordForm } from 'entities/Auth/components/NewPasswordForm';
import { AuthLogInForm } from 'entities/Auth/components/AuthLogInForm';
import { GetConfirm } from 'entities/Auth/components/GetConfirm';

export const AuthForms = () => {
  return (
    <Switch>
      <Route path={EPublicRoutes.NewPassword} component={NewPasswordForm} />
      <Route path={EPublicRoutes.RestorePassword} component={RestorePasswordForm} />
      <Route path={EPublicRoutes.GetConfirm} component={GetConfirm} />
      <Route paths={[EPublicRoutes.LogIn, '*']} component={AuthLogInForm} />
    </Switch>
  );
};
