import { applyMiddleware, compose, createStore } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import createSagaMiddleware from 'redux-saga';
import { createBrowserHistory } from 'history';
import { reducers } from 'app/store/reducers';
import { rootSaga } from 'app/store/sagas';

const sagaMiddleware = createSagaMiddleware();

const windowObject = window as any;
let composeEnhancers = compose;

export const history = createBrowserHistory();

if (process.env.NODE_ENV === 'development' && typeof windowObject.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ === 'function') {
  composeEnhancers = windowObject.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
}

export const createReduxStore = () => {
  const middleware = [sagaMiddleware, routerMiddleware(history)];

  const store = createStore(reducers(history), composeEnhancers(applyMiddleware(...middleware)));

  sagaMiddleware.run(rootSaga);

  return store;
};
