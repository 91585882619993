import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router';

function useInitFromRoute(tabs: string[]) {
  const location = useLocation();
  const [value, set] = useState<string>(getInitTab(location.pathname, tabs));

  return { value, set };
}

function useActiveTab<T>(initialValue: T) {
  const [value, onChange] = useState<T>(initialValue);
  // Assertion reason: ts error because antd has string type for onChange
  const set = React.useCallback(item => onChange(item as T), [onChange]);

  return { value, set };
}

function useSelectedTabs(availableRoutes: string[], defaultRoute: string) {
  const location = useLocation();
  const [activeTabs, setActiveTabs] = useState<Array<string> | undefined>([]);

  useEffect(() => {
    setActiveTabs(getActiveTab(availableRoutes, location.pathname, defaultRoute));
  }, [location.pathname, availableRoutes, defaultRoute]);

  return { activeTabs };
}

export const TabsHooks = {
  useSelectedTabs,
  useActiveTab,
  useInitFromRoute
};

const getInitTab = (pathname: string, tabs: string[]): string => {
  const currentTab = tabs.reverse().find(item => {
    return pathname.startsWith(item) || pathname.startsWith(item, 1);
  });

  return currentTab || tabs[0];
};

const getActiveTab = (availableRoutes: string[], route: string, defaultKey: string): string[] => {
  const currentRoute = availableRoutes.find(item => {
    return route.startsWith(item);
  });

  return currentRoute ? [currentRoute] : [defaultKey];
};
