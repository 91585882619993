import * as React from 'react';
import Typography from 'antd/es/typography';
import { Col } from 'antd/es/grid';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { ExpandedTabs } from 'common/components';
import { RouterHelper } from 'common/helpers';
import { giftEditTabs, giftEditTabsLabels } from 'entities/Gift/Gift.consts';
import { GiftFormExperience } from 'entities/Gift/components/GiftEdit/GiftFormExperience';
import { GiftFormIdea } from 'entities/Gift/components/GiftEdit/GiftFormIdea';
import { GiftFormActivation, GiftFormContributors, GiftFormDiscovery } from 'entities/Gift/components/GiftEdit/index';
import { AddMorePaymentForm } from 'entities/Payment/components/AddMorePaymentForm';

interface IComponentProps {
  disabled?: boolean;
}

export const GiftFormTabs = ({ disabled }: IComponentProps) => {
  const { t } = useTranslation();
  const tabs: Array<{ tab: string; label?: string; component: JSX.Element }> = [
    { tab: giftEditTabs.experience, label: giftEditTabsLabels.experience, component: <GiftFormExperience disabled={disabled} /> },
    {
      tab: giftEditTabs.contributors,
      label: giftEditTabsLabels.contributors,
      component: <GiftFormContributors disabled={disabled} />
    },
    {
      tab: giftEditTabs.idea,
      label: giftEditTabsLabels.idea,
      component: (
        <Col lg={12} xl={10} className="mt-15">
          <GiftFormIdea disabled={disabled} />
        </Col>
      )
    },
    {
      tab: giftEditTabs.discovery,
      label: giftEditTabsLabels.discovery,
      component: (
        <Col lg={12} xl={10}>
          <GiftFormDiscovery />
        </Col>
      )
    },
    {
      tab: giftEditTabs.activation,
      label: giftEditTabsLabels.activation,
      component: (
        <Col lg={12} xl={10}>
          <GiftFormActivation disabled={disabled} />
        </Col>
      )
    },
    {
      tab: giftEditTabs.payment,
      label: giftEditTabsLabels.payment,
      component: (
        <Col md={18} lg={12} xl={10} className="mt-15">
          {disabled ? (
            <Typography.Text className="clp-typography-text clp-typography-text_size_md">
              {t('errorPaymentClosed')}
            </Typography.Text>
          ) : (
            <AddMorePaymentForm />
          )}
        </Col>
      )
    }
  ];

  const history = useHistory();
  const query = RouterHelper.useQuery();

  const activeTab = query.get('tab');

  return (
    <ExpandedTabs
      onChange={a => {
        query.set('tab', a);
        history.push({ search: query.toString() });
      }}
      tabs={tabs}
      activeKey={activeTab || giftEditTabs.experience}
    />
  );
};
