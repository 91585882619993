import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { Tabs } from '@axmit/clp-library';
import AntTabs from 'antd/es/tabs';
import { Row, Col } from 'antd/es/grid';
import { useTranslation } from 'react-i18next';
import { EBackOfficeRoutes } from 'common/const';
import { BackOfficeBreadcrumb, ColumnsContent } from 'common/components';
import { FormatHelper, TableFormatHelper } from 'common/helpers';
import { ClientNotes } from 'entities/ClientNotes/components/ClientNotes';
import { communicationUsers, IUsersConnectedProps } from 'entities/User/Users.communication';
import { communicationEmployees, IEmployeesConnectedProps } from 'entities/Employees/Employees.communication';
import { MakeRequestBtn } from 'entities/Requests/components';
import { UserTransactionsList } from 'entities/Transactions/components';

type AllProps = IUsersConnectedProps & IEmployeesConnectedProps;

const ClientsEditComponent = ({
  clearUsersModel,
  clearEmployeesCollection,
  clearUsersBalance,
  getEmployeesCollection,
  getUsersModel,
  getUsersBalance,
  employeesCollection,
  usersBalance,
  usersModel
}: AllProps) => {
  const { t } = useTranslation();
  const { id } = useParams();

  useEffect(() => {
    return () => {
      clearUsersModel();
      clearEmployeesCollection();
      clearUsersBalance();
    };
  }, [clearUsersModel, clearEmployeesCollection, clearUsersBalance]);

  useEffect(() => {
    if (id) {
      getUsersBalance(id);
      getUsersModel(id);
      getEmployeesCollection({ user: id, showBalance: true });
    }
  }, [getUsersModel, getEmployeesCollection, getUsersBalance, id]);

  const { data: user } = usersModel;
  const name = user && `${user.firstName} ${user.lastName}`;

  return (
    <>
      {user && (
        <MakeRequestBtn
          collection={employeesCollection.data}
          userId={user.id}
          userName={TableFormatHelper.formatFullName('', user)}
        />
      )}

      <BackOfficeBreadcrumb
        current={name}
        previous={t('backOfficeDashboard.tab.clients')}
        previousHref={EBackOfficeRoutes.Clients}
      />

      {user && (
        <ColumnsContent
          data={[
            {
              key: 'form.labels.accountBalance',
              value: FormatHelper.getCurrency(usersBalance?.data?.balance)
            },
            { key: 'form.labels.phone', value: user.phoneNumber || '-' },
            { key: 'form.labels.email', value: user.email || '-' },
            { key: 'form.labels.createdAt', value: FormatHelper.getDateWithYear(user.createdAt) }
          ]}
          gutter={10}
          className="mb-30 pt-15"
        />
      )}

      {employeesCollection.data?.data.map(({ id: employeeId, companyName, balance }) => (
        <ColumnsContent
          data={[
            { key: 'employeeOf', value: companyName || '-', col: wrapperColFull },
            { key: 'creditAccumulated', value: FormatHelper.getCurrency(balance) }
          ]}
          gutter={10}
          className="mb-30"
          key={employeeId}
        />
      ))}

      <Tabs className="mt-20" type="card" destroyInactiveTabPane>
        <AntTabs.TabPane key="client-notes" tab={t('clients.tabs.clientNotes')}>
          <ClientNotes />
        </AntTabs.TabPane>

        <AntTabs.TabPane
          key="client-transactions"
          tab={
            <>
              <b>{t('personal')}</b> {t('transactions.label')}
            </>
          }
        >
          <Row>
            <Col {...transactionsWrapperCol}>
              <UserTransactionsList userId={id} />
            </Col>
          </Row>
        </AntTabs.TabPane>

        {employeesCollection.data?.data.map(({ id: employeeId, companyName }) => (
          <AntTabs.TabPane
            key={`${employeeId}-transactions`}
            tab={
              <>
                <b>{companyName}</b> {t('transactions.label')}
              </>
            }
          >
            <Row>
              <Col {...transactionsWrapperCol}>
                <UserTransactionsList employeeId={employeeId} />
              </Col>
            </Row>
          </AntTabs.TabPane>
        ))}
      </Tabs>
    </>
  );
};

export const ClientsEdit = communicationUsers.injector(communicationEmployees.injector(ClientsEditComponent));

const wrapperColFull = { xs: 24, md: 24, xl: 24 };
const transactionsWrapperCol = { xs: 24, lg: 14, xl: 12 };
