import { AntdFormHelper } from '@axmit/antd-helpers';
import { Button, Input } from '@axmit/clp-library';
import { HooksHelper } from '@axmit/clp-library/dist/helpers';
import Form from 'antd/es/form';
import { FormComponentProps } from 'antd/es/form';
import * as React from 'react';
import { FormEvent, useEffect, useMemo } from 'react';
import { SvgPaperAirplane } from 'common/SVG/SvgPaperAirplane';
import { CHAT_TEMPLATE_SENT_MSG } from 'common/const';
import { ChatHelper } from 'entities/Messages/helpers';
import { SendingTypeGroup } from 'entities/Messages/components/SendingTypeGroup';
import { communicationMessages, IMessagesConnectedProps } from 'entities/Messages/Messages.communication';
import { EMessageType, IChannelsModel, EWhatsappLocale } from 'entities/Messages/Messages.models';

interface IComponentProps {
  channel?: IChannelsModel;
  client?: string | null;
}

type AllProps = IComponentProps & IMessagesConnectedProps & FormComponentProps;

export const ChatFormComponent = ({ channel, client, addMessagesModel, form }: AllProps) => {
  const sendingType = HooksHelper.useStateBuilder<EMessageType | undefined>(undefined);
  const availableChats = useMemo(() => {
    return channel ? ChatHelper.getAvailableChats(channel) : [];
  }, [channel]);

  useEffect(() => {
    if (!sendingType.value || !availableChats.includes(sendingType.value)) {
      sendingType.set(availableChats[0]);
    }
  }, [availableChats, sendingType]);

  const disableAll = !availableChats.length;
  const sendIconClasses = useMemo(
    () => (disableAll ? 'chat-send-icon_disabled' : sendingType.value ? `chat-send-icon_${sendingType.value}` : ''),
    [sendingType.value, disableAll]
  );
  const isWhatsappInitRequired =
    typeof channel?.isWhatsappAvailable !== 'undefined' &&
    !channel.isWhatsappAvailable &&
    sendingType.value === EMessageType.whatsApp;
  const isInputDisabled = disableAll || isWhatsappInitRequired;

  const handleSubmit = (e?: FormEvent<HTMLFormElement>) => {
    e && e.preventDefault();

    form.validateFields((err, values) => {
      if (err) {
        return;
      }

      if (!client || !sendingType.value || !values?.text) {
        return;
      }

      addMessagesModel({ client, type: sendingType.value, message: { content: { type: 'text', ...values } } });
    });
  };

  const sendWhatsappInit = (locale: EWhatsappLocale) => {
    if (!client || !sendingType.value) {
      return;
    }

    addMessagesModel({
      client,
      type: sendingType.value,
      message: { content: { type: 'template', text: CHAT_TEMPLATE_SENT_MSG }, whatsapp: { locale } }
    });
  };

  return (
    <>
      <Form onSubmit={handleSubmit} className="d-flex d-flex_align_end">
        {form.getFieldDecorator('text')(
          <Input
            className={`chat-input ${isWhatsappInitRequired ? 'chat-input_hidden' : ''}`}
            disabled={isInputDisabled}
            addonBefore={
              <SendingTypeGroup
                availableTypes={availableChats}
                selectType={sendingType.set}
                type={sendingType.value}
                disableAll={disableAll}
              />
            }
            addonAfter={
              isWhatsappInitRequired ? null : (
                <SvgPaperAirplane
                  className={`${sendIconClasses} chat-send-icon chat-input__submit`}
                  onClick={() => !disableAll && !isWhatsappInitRequired && handleSubmit()}
                />
              )
            }
          />
        )}
      </Form>

      {isWhatsappInitRequired && (
        <div className="chat-wa-init">
          <Button className="w-100p chat-wa-init__btn" onClick={() => sendWhatsappInit(EWhatsappLocale.En)}>
            Contact <span className="chat-wa-init__btn-lang-span">Eng</span>
          </Button>
          <Button className="w-100p chat-wa-init__btn" onClick={() => sendWhatsappInit(EWhatsappLocale.Fr)}>
            Contact <span className="chat-wa-init__btn-lang-span">Fr</span>
          </Button>
        </div>
      )}
    </>
  );
};

export const ChatForm = communicationMessages.injector(
  Form.create<AllProps>({
    mapPropsToFields(props: AllProps) {
      const { messagesModel } = props;

      return AntdFormHelper.mapValidationToFields(messagesModel?.params, messagesModel?.errors);
    }
  })(ChatFormComponent)
);
