import React from 'react';
import { ChatForm, ChatList } from 'entities/Messages/components';
import { ChatHelper } from 'entities/Messages/helpers';
import { IChannelsModel } from 'entities/Messages/Messages.models';
import { CHAT_ID } from 'entities/Messages/Messages.consts';

interface IComponentProps {
  channel?: IChannelsModel;
}

export const Chat = ({ channel }: IComponentProps) => {
  if (!channel) {
    return null;
  }

  const { firstName, lastName, client, phoneNumber } = channel;
  const phoneWithoutPlus = phoneNumber?.replace(/^\++/, '');
  // TODO: Add a messenger after integration
  const clientId = client || phoneWithoutPlus;
  const header = ChatHelper.getChatHeader(firstName, lastName, clientId);

  return (
    <>
      <div className="chat-header">{header}</div>

      <div id={CHAT_ID} className="chat">
        {clientId && <ChatList clientId={clientId} />}
      </div>

      <ChatForm channel={channel} client={clientId} />
    </>
  );
};
