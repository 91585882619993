import React, { FormEvent } from 'react';
import { Row, Col } from 'antd/es/grid';
import Form from 'antd/es/form';
import Spin from 'antd/es/spin';
import { Button, CardInfo, FormItem, Input, Paragraph, TextArea } from '@axmit/clp-library';
import { compose } from 'redux';
import { AntdFormHelper } from '@axmit/antd-helpers';
import { FormComponentProps } from 'antd/es/form';
import { useTranslation } from 'react-i18next';
import { communicationSupport, ISupportConnectedProps } from 'entities/Support/Support.communication';

type AllProps = FormComponentProps & ISupportConnectedProps;

const ContactUsFormComponent = (props: AllProps) => {
  const { t } = useTranslation();
  const { supportMessage, form } = props;
  const { getFieldDecorator } = form;

  const submit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    form.validateFields((err, values) => {
      if (err) {
        return;
      }

      props.addSupportMessage(values);
    });
  };

  return (
    <Spin spinning={supportMessage.loading}>
      <CardInfo
        header={t('contactUsFormHeader')}
        content={
          <Paragraph size="sm" className="mt-12">
            {t('contactUsFormDescription')}
          </Paragraph>
        }
      />

      <Form className="mt-30" onSubmit={submit}>
        <Row gutter={22}>
          <Col xs={12} sm={24} md={12}>
            <FormItem label={t('contactUsName')}>
              {getFieldDecorator('name', {
                rules: [
                  {
                    required: true,
                    message: t('errorRequiredDefault')
                  }
                ]
              })(<Input />)}
            </FormItem>
          </Col>

          <Col xs={12} sm={24} md={12}>
            <FormItem label={t('contactUsEmail')}>
              {getFieldDecorator('email', {
                rules: [
                  {
                    type: 'email',
                    message: t('errorEmailNotEmail')
                  },
                  {
                    required: true,
                    message: t('errorRequiredDefault')
                  }
                ]
              })(<Input type="email" />)}
            </FormItem>
          </Col>
        </Row>

        <FormItem>
          {getFieldDecorator('message', {
            rules: [
              {
                required: true,
                message: t('errorRequiredDefault')
              }
            ]
          })(<TextArea placeholder={t('contactUsPlaceholder')} autoSize={{ minRows: 4, maxRows: 8 }} />)}
        </FormItem>

        <Button type="primary" className="mt-12 ant-btn_mob-block" htmlType="submit">
          {t('contactUsSubmit')}
        </Button>
      </Form>
    </Spin>
  );
};

const containers = compose(
  communicationSupport.injector,
  Form.create<AllProps>({
    mapPropsToFields(props: AllProps) {
      const { supportMessage } = props;

      return AntdFormHelper.mapValidationToFields(supportMessage?.params, supportMessage?.errors);
    }
  })
);

export const ContactUsForm = containers(ContactUsFormComponent);
