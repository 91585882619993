import axios from 'axios';
import { BaseTransport } from 'common/dto/base.transport';
import {
  IRequestCollection,
  IRequestCollectionParams,
  IRequestModel,
  IRequestUpdateParams
} from 'entities/Requests/Requests.models';

export class RequestsTransport extends BaseTransport<
  IRequestModel,
  IRequestCollection,
  null,
  IRequestUpdateParams,
  IRequestCollectionParams
> {
  public finalize = (id: string) => {
    return axios.post(`${this.path}/${id}/finalize`);
  };
}
