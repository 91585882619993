import { ColumnProps } from 'antd/lib/table';
import { TableFormatHelper } from 'common/helpers';
import { ISelectorOption } from 'common/models/Selector.models';
import i18n from 'app/locales/i18n';
import { IUserModel } from 'entities/User/Users.models';

export const MANAGERS_PER_PAGE = 10;
export const CLIENTS_PER_PAGE = 10;

export enum EUserRoles {
  Regular = 'regular',
  Manager = 'manager',
  Administrator = 'administrator'
}

export enum EUserKeysErrors {
  HasAssignedRequests = 'error.userHasAssignedRequests'
}

enum EManagersColumns {
  name = 'name',
  role = 'role',
  email = 'email',
  registrationDate = 'createdAt'
}

export const managersColumns: ColumnProps<IUserModel>[] = [
  {
    dataIndex: EManagersColumns.name,
    title: `managers.tableColumns.${EManagersColumns.name}`,
    render: TableFormatHelper.formatFullName
  },
  { dataIndex: EManagersColumns.role, title: `managers.tableColumns.${EManagersColumns.role}` },
  { dataIndex: EManagersColumns.email, title: `managers.tableColumns.${EManagersColumns.email}` },
  {
    dataIndex: EManagersColumns.registrationDate,
    title: `managers.tableColumns.${EManagersColumns.registrationDate}`,
    render: TableFormatHelper.formatDate
  }
];

enum EClientsColumns {
  name = 'name',
  phoneNumber = 'phoneNumber',
  email = 'email',
  registrationDate = 'createdAt'
}

export const clientsColumns: ColumnProps<IUserModel>[] = [
  {
    dataIndex: EClientsColumns.name,
    title: `clients.tableColumns.${EClientsColumns.name}`,
    render: TableFormatHelper.formatFullName
  },
  { dataIndex: EClientsColumns.phoneNumber, title: `clients.tableColumns.${EClientsColumns.phoneNumber}` },
  { dataIndex: EClientsColumns.email, title: `clients.tableColumns.${EClientsColumns.email}` },
  {
    dataIndex: EClientsColumns.registrationDate,
    title: `clients.tableColumns.${EClientsColumns.registrationDate}`,
    render: TableFormatHelper.formatDate
  }
];

export enum EManagerArchiveStatuses {
  Active = 'active',
  Archived = 'archived'
}

export const ManagersOptions: Record<'statuses', ISelectorOption[]> = {
  statuses: [
    { value: EManagerArchiveStatuses.Active, label: i18n.t(`managers.selectForArchived.${EManagerArchiveStatuses.Active}`) },
    { value: EManagerArchiveStatuses.Archived, label: i18n.t(`managers.selectForArchived.${EManagerArchiveStatuses.Archived}`) }
  ]
};
