import * as React from 'react';
import { FormItem, Input, PhoneInput } from '@axmit/clp-library';
import { Row, Col } from 'antd/es/grid';
import { useTranslation } from 'react-i18next';
import { FormatHelper } from 'common/helpers';
import { TIME_FORMAT_DEFAULT } from 'common/config';

interface IComponentProps {
  recipient?: string;
  recipientPhone?: string;
  recipientEmail?: string;
  contactType?: string;
  contactDate?: string;
}

export const RequestEditPageRequestInfo = ({
  recipient,
  contactDate,
  recipientEmail,
  contactType,
  recipientPhone
}: IComponentProps) => {
  const { t } = useTranslation();

  const date = contactDate
    ? `${FormatHelper.getDateWithYear(contactDate)} ${FormatHelper.formatDate(TIME_FORMAT_DEFAULT, contactDate)}`
    : '-';

  return (
    <Row gutter={30}>
      <Col {...wrapperColXl}>
        <FormItem label={t('client')}>
          <Input value={recipient} disabled />
        </FormItem>
      </Col>

      <Col {...wrapperColLg}>
        <FormItem label={t('form.labels.email')}>
          <Input value={recipientEmail || '-'} disabled />
        </FormItem>
      </Col>

      <Col {...wrapperCol}>
        <FormItem
          label={t('request.editForm.contactDate')}
          extra={contactType && <small>{t('bySomething', { name: contactType })}</small>}
        >
          <Input value={date} disabled />
        </FormItem>
      </Col>

      <Col {...wrapperColXl}>
        <FormItem label={t('FormLabelDefault.phone')}>
          {recipientPhone ? (
            <PhoneInput defaultValue={recipientPhone} onChange={() => {}} validateTrigger={() => {}} disabled block />
          ) : (
            <Input value="-" disabled />
          )}
        </FormItem>
      </Col>
    </Row>
  );
};

const wrapperColXl = { xs: 24, sm: 12, md: 12, lg: 12, xl: 9 };
const wrapperColLg = { xs: 24, sm: 12, md: 12, lg: 12, xl: 8 };
const wrapperCol = { xs: 24, sm: 12, md: 12, lg: 12, xl: 7 };
