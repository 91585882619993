import React, { useEffect, useMemo } from 'react';
import { compose } from 'redux';
import { communicationContributors, IContributorsConnectedProps } from 'entities/Contributors/Contributors.communication';

interface IComponentProps {
  giftId: string;
}

type AllProps = IContributorsConnectedProps & IComponentProps;

const ContributorsSuggestionsComponent = ({
  clearContributorsCollection,
  getContributorsCollection,
  giftId,
  contributorsCollection
}: AllProps) => {
  useEffect(() => {
    getContributorsCollection({ gift: giftId });

    return () => {
      clearContributorsCollection();
    };
  }, [getContributorsCollection, clearContributorsCollection, giftId]);

  const suggestions = useMemo(() => {
    return contributorsCollection.data?.data.filter(item => item.suggestion);
  }, [contributorsCollection]);

  if (!suggestions || !suggestions.length) {
    return null;
  }

  return (
    <>
      {suggestions.map(({ suggestion, name, id }) => (
        <React.Fragment key={id}>
          <div className="card-details__content mt-10">{suggestion}</div>

          <div className="card-details__sign">{name}</div>
        </React.Fragment>
      ))}
    </>
  );
};

const containers = compose(communicationContributors.injector);

export const ContributorsSuggestions = containers(ContributorsSuggestionsComponent);
