import React, { useEffect } from 'react';
import Icon from 'antd/es/icon';
import List from 'antd/es/list';
import { compose } from 'redux';
import { Button } from '@axmit/clp-library';
import { communicationPayment, IPaymentConnectedProps } from 'entities/Payment/Payment.communication';

interface IComponentProps {
  userId: string;
}

type AllProps = IPaymentConnectedProps & IComponentProps;

const CardsListComponent = (props: AllProps) => {
  const { userId, getPaymentMethodCollection, paymentMethodCollection, deletePaymentMethod, paymentMethod } = props;
  const { data: methods } = paymentMethodCollection;

  useEffect(() => {
    getPaymentMethodCollection({ userId });
  }, [userId, getPaymentMethodCollection]);

  if (!methods?.data) {
    return null;
  }

  return (
    <List
      className="mt-30"
      loading={paymentMethodCollection.loading || paymentMethod.loading}
      dataSource={methods.data}
      renderItem={({ id, card }) => {
        if (!card) {
          return '-';
        }

        return (
          <div key={id}>
            **** **** **** {card.last4}
            <Button
              type="link"
              size="small"
              onClick={() => {
                deletePaymentMethod({ id: userId, idPm: id });
              }}
            >
              <Icon type="close" />
            </Button>
          </div>
        );
      }}
    />
  );
};

const containers = compose(communicationPayment.injector);

export const CardsList = containers(CardsListComponent);
