import { Footer as CLPFooter } from '@axmit/clp-library';
import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AppLandingHelper } from '@axmit/router-app-landing/index';
import { ECommonRoutes, ELandingRoutes } from 'common/const/Routes.const';
import { SvgPinterest } from 'common/SVG/SvgPinterest';
import { SvgWhatsAppBlack } from 'common/SVG/SvgWhatsAppBlack';
import { INSTAGRAM_LINK, LES_LANDING_LINK, PINTEREST_LINK, WHATSAPP_LINK } from 'common/config';
import { SvgInstagram } from 'common/SVG/SvgInstagram';
import imageFooter from 'app/assets/images/footerMontgolfiere.png';
import { communicationUsers, IUsersConnectedProps } from 'entities/User/Users.communication';
import { EUserRoles } from 'entities/User/Users.consts';

type AllProps = IUsersConnectedProps;

const FooterComponent = ({ usersCurrent }: AllProps) => {
  const {
    t,
    i18n: { language }
  } = useTranslation();
  const isBackOfficeUser = usersCurrent.data?.role === EUserRoles.Administrator || usersCurrent.data?.role === EUserRoles.Manager;

  if (isBackOfficeUser) {
    return null;
  }

  const mediaKitPdfLink = `/api/files/media_${language}.pdf`;

  return (
    <CLPFooter
      footerImage={imageFooter}
      socials={[
        <a key="pinterest" href={PINTEREST_LINK} target="_blank" rel="noopener noreferrer">
          <SvgPinterest />
        </a>,
        <a key="Instagram" href={INSTAGRAM_LINK} target="_blank" rel="noopener noreferrer">
          <SvgInstagram />
        </a>,
        <a key="Whatsapp" href={WHATSAPP_LINK} target="_blank" rel="noopener noreferrer">
          <SvgWhatsAppBlack />
        </a>
      ]}
    >
      <nav className="clp-nav">
        <a href={LES_LANDING_LINK}> {t('footerLes')}</a>
        <Link target="_blank" to={mediaKitPdfLink}>
          {t('footerMediaKit')}
        </Link>
        <a href={AppLandingHelper.getLink(ELandingRoutes.TOC)}>{t('footerTaC')}</a>
        <a href={AppLandingHelper.getLink(ELandingRoutes.PrivacyPolicy)}>{t('footerPolicy')}</a>
        <Link to={ECommonRoutes.ContactUs}> {t('footerContactUs')}</Link>
      </nav>
    </CLPFooter>
  );
};

export const Footer = communicationUsers.injector(FooterComponent);
