import React from 'react';
import Popover from 'antd/es/popover';
import { Row, Col } from 'antd/es/grid';
import Form from 'antd/es/form';
import Spin from 'antd/es/spin';
import { Button } from '@axmit/clp-library';
import { FormComponentProps } from 'antd/es/form';
import { useTranslation } from 'react-i18next';
import copy from 'copy-to-clipboard';
import { HooksHelper } from '@axmit/clp-library/dist/helpers';
import { InputForm } from 'common/components';
import { POPUP_WAIT_TIME } from 'common/config';
import { communicationInvitation, IInvitationConnectedProps } from 'entities/Invitations/Invitations.communication';
import { GiftTransactionsHelper } from 'entities/GiftTransactions/helpers/GiftTransactions.helper';

interface IComponentProps {
  giftId: string;
  disabled?: boolean;
}

type AllProps = IComponentProps & FormComponentProps & IInvitationConnectedProps;

const InviteFormComponent = ({ form, addInvitationsModel, invitationsModel, giftId, disabled }: AllProps) => {
  const { t } = useTranslation();
  const { getFieldDecorator } = form;
  const isVisibleCopyMessage = HooksHelper.useStateBuilder<boolean>(false);

  const submit = () => {
    form.validateFields((err, values) => {
      if (err) {
        return;
      }

      addInvitationsModel({ ...values, gift: giftId });
      form.resetFields();
    });
  };

  const generateLink = () => {
    const resultLink = GiftTransactionsHelper.getContributeLink(giftId);

    copy(resultLink);

    isVisibleCopyMessage.set(true);
    setTimeout(() => {
      isVisibleCopyMessage.set(false);
    }, POPUP_WAIT_TIME);
  };

  return (
    <Spin spinning={invitationsModel.loading}>
      <Form hideRequiredMark>
        <div className="contribution-form">
          <Row gutter={15} type="flex" align="bottom">
            <Col xs={24} md={5}>
              <InputForm
                getFieldDecorator={getFieldDecorator}
                fieldName="name"
                label={t('inviteFormLabelFirstName')}
                inputProps={{ disabled }}
                required
              />
            </Col>

            <Col xs={24} md={5}>
              <InputForm
                getFieldDecorator={getFieldDecorator}
                fieldName="email"
                label={t('inviteFormLabelEmail')}
                rules={[
                  {
                    type: 'email',
                    message: t('errorEmailNotEmail')
                  }
                ]}
                inputProps={{ disabled }}
                required
              />
            </Col>

            <Col xs={24} md={3}>
              <Button onClick={submit} className="mb-25 ant-btn_mob-block" block disabled={disabled} size="small">
                {t('inviteFormSubmit')}
              </Button>
            </Col>

            <Col xs={24} md={11} className="ta-r">
              <Popover
                trigger="contextMenu"
                content={t('copyReferralLinkSuccess')}
                visible={isVisibleCopyMessage.value}
                onVisibleChange={isVisibleCopyMessage.set}
              >
                <Button onClick={generateLink} className="mb-25 ant-btn_mob-block" size="small">
                  {t('copyReferralLinkBtn')}
                </Button>
              </Popover>
            </Col>
          </Row>
        </div>
      </Form>
    </Spin>
  );
};

export const InviteForm = communicationInvitation.injector(Form.create<AllProps>()(InviteFormComponent));
