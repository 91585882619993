import React from 'react';

interface IComponentState {
  width: number;
}

interface IComponentProps {
  min?: number;
  max?: number;
}

type AllProps = React.PropsWithChildren<IComponentProps>;

export class Environment extends React.Component<AllProps, IComponentState> {
  constructor(props: AllProps) {
    super(props);

    this.state = {
      width: window.innerWidth
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleWindowSizeChange);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };

  render() {
    const { children, min: from, max: to } = this.props;
    const { width } = this.state;

    const inRange = (from ? width >= from : true) && (to ? width <= to : true);

    return inRange ? children : null;
  }
}
