import * as React from 'react';
import { default as AntDatePicker } from 'antd/es/date-picker';
import moment from 'moment';
import { HooksHelper } from '@axmit/clp-library/dist/helpers';
import { default as AntTimePicker } from 'antd/es/time-picker';
import Calendar from 'antd-mobile/lib/calendar';
import { Desktop } from 'common/components/Desktop';
import { Mobile } from 'common/components/Mobile';
import { DATE_FORMAT_DEFAULT, TIME_FORMAT_DEFAULT } from 'common/config';
import { localeCalendar } from 'common/const';

interface IComponentProps {
  date?: moment.Moment;
  dateClassName?: string;
  timeClassName?: string;
  allowClear?: boolean;
  disabled?: boolean;
  disabledDate?: (date: moment.Moment | null) => boolean;
  minMobDate?: Date;
  onChange: (date: moment.Moment | null) => void;
}

type AllProps = IComponentProps;

export const DateTimeSplitPicker = ({
  date,
  disabled,
  dateClassName,
  timeClassName,
  minMobDate,
  allowClear,
  disabledDate,
  onChange
}: AllProps) => {
  const visibleMobDateModal = HooksHelper.useStateBuilder<boolean>(false);

  const onMobSelect = (dateTime?: Date) => {
    dateTime && onChange(dateTime ? moment(dateTime) : null);
    visibleMobDateModal.set(false);
  };

  const calendarDate: [Date] | undefined = date && date?.toDate ? [date?.toDate()] : undefined;
  const calendarTime: Date | undefined = date && date?.toDate ? date?.toDate() : undefined;

  return (
    <>
      <Desktop>
        <AntDatePicker
          format={DATE_FORMAT_DEFAULT}
          value={date}
          showToday={false}
          disabled={disabled}
          allowClear={allowClear}
          disabledDate={disabledDate}
          className={`clp-datepicker__input ${dateClassName}`}
          dropdownClassName="clp-datepicker"
          onChange={onChange}
        />

        <AntTimePicker
          format={TIME_FORMAT_DEFAULT}
          value={date}
          disabled={disabled}
          allowClear={allowClear}
          className={`clp-timepicker ${timeClassName}`}
          popupClassName="clp-timepicker-popup"
          onChange={onChange}
        />
      </Desktop>

      <Mobile>
        <div className="mt-25">
          <AntDatePicker
            format={DATE_FORMAT_DEFAULT}
            value={date}
            dropdownClassName="clp-datepicker"
            className="clp-datepicker__input"
            allowClear={false}
            open={false}
            disabled={disabled}
            onOpenChange={() => !disabled && visibleMobDateModal.set(true)}
          />
        </div>

        <div className="mt-25 mb-25">
          <AntTimePicker
            format={TIME_FORMAT_DEFAULT}
            value={date}
            className="clp-timepicker"
            allowClear={false}
            open={false}
            disabled={disabled}
            onOpenChange={() => !disabled && visibleMobDateModal.set(true)}
          />
        </div>

        {!disabled && (
          <Calendar
            type="one"
            visible={visibleMobDateModal.value}
            locale={localeCalendar}
            pickTime
            onCancel={() => visibleMobDateModal.set(false)}
            onConfirm={onMobSelect}
            defaultValue={calendarDate}
            defaultTimeValue={calendarTime}
            minDate={minMobDate}
          />
        )}
      </Mobile>
    </>
  );
};
