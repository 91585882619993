// @ts-nocheck

const initGoogleAnalytics = (trackingId: string, env: string) => {
  if (env !== 'production') {
    return;
  }

  const script = document.createElement('script');
  script.src = `https://www.googletagmanager.com/gtag/js?id=${trackingId}`;
  script.async = true;
  const fs = document.getElementsByTagName('script')[0];
  fs.parentNode.insertBefore(script, fs);

  /* eslint-disable */
  window.dataLayer = window.dataLayer || [];
  function gtag() {
    dataLayer.push(arguments);
  }
  gtag('js', new Date());
  gtag('config', trackingId);
  /* eslint-enable */
};

export const ThirdPartyServicesHelper = { initGoogleAnalytics };
