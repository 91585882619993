import {
  APIProvider,
  BaseStrategy,
  Branch,
  buildCommunication,
  EActionsTypes,
  getFailType,
  getStartType,
  StoreBranch
} from '@axmit/redux-communications';
import { clearCreds, getCreds, saveCreds } from '@axmit/axios-patch-jwt';
import { push } from 'connected-react-router';
import { put } from 'redux-saga/effects';
import message from 'antd/es/message';
import { EPublicRoutes, EPrivateRoutes } from 'common/const/Routes.const';
import { BaseTransport } from 'common/dto/base.transport';
import { CookiesHelper, MessageHelper } from 'common/helpers';
import { COOKIES_NAME } from 'common/const';
import { IErrorResponse } from 'common/models/Request.models';
import i18n from 'app/locales/i18n';
import { RestorePasswordTransport } from 'entities/Auth/Auth.transport';
import {
  IAuthModel,
  IChangePasswordParams,
  IPasswordRecoveryModel,
  ITokenModel,
  IConfirmationParams,
  IRepeatConfirmationParams,
  IAuthViewModel
} from 'entities/Auth/Auth.models';
import { communicationUsers } from 'entities/User/Users.communication';

const namespace = 'auth';

export interface IAuthConnectedProps {
  authModel: StoreBranch<ITokenModel>;
  initAuthModel(): void;
  addAuthModel(params: IAuthViewModel): void;
  deleteAuthModel(): void;

  authRestoreModel: StoreBranch<ITokenModel>;
  addAuthRestoreModel(params: IAuthModel): void;
  updateAuthRestoreModel(params: IPasswordRecoveryModel): void;

  authPassword: StoreBranch<{}>;
  changeAuthPassword(params: IChangePasswordParams): void;

  authConfirmation: StoreBranch<{}>;
  addAuthConfirmation(params: IConfirmationParams): void;
  repeatAuthConfirmation(params: IRepeatConfirmationParams): void;
}

const transport = new BaseTransport<ITokenModel, null, IAuthModel>(namespace);

const modelApiProvider = [
  new APIProvider<ITokenModel, IAuthViewModel, IErrorResponse>(EActionsTypes.add, transport.add, {
    onFail: response => {
      response?.message && message.error(response.message);
    },
    onSuccess: function*(response, payload) {
      const userId = response?.id?.userId;
      yield userId && getCurrentUser(userId);
      // @ts-ignore wrong typing in axios-jwt
      yield saveCreds(response);
      yield put(push(payload?.pathAfterSuccess || EPrivateRoutes.Main));
    }
  }),
  new APIProvider(EActionsTypes.init, (): Promise<ITokenModel> => getCreds(), {
    onSuccess: function*() {
      const AuthModelData = yield getCreds();
      const userId = AuthModelData?.id?.userId;

      if (userId) {
        yield getCurrentUser(userId);
      } else {
        yield put({ type: getFailType(communicationUsers.namespace, 'current', EActionsTypes.get) });
      }
    }
  }),
  new APIProvider(EActionsTypes.delete, transport.deleteAll, {
    onSuccess: function*() {
      yield clearAuth();
    },
    onFail: function*() {
      yield clearAuth();
    }
  })
];

export function* clearAuth() {
  yield clearCreds();
  yield put({ type: getStartType(communicationUsers.namespace, 'current', EActionsTypes.clear) });
  yield put(push(EPublicRoutes.LogIn));
  yield CookiesHelper.clearCookies(COOKIES_NAME);
}

function* getCurrentUser(userId: string) {
  yield put({ type: getStartType('users', 'current', EActionsTypes.get), payload: userId });
}

const restorePassTransport = new RestorePasswordTransport('/restore-password');

const restoreModelApiProvider = [
  new APIProvider(EActionsTypes.add, restorePassTransport.add, {
    onSuccess: function() {
      message.success(i18n.t('restorePassSuccessMessage'));
    }
  }),
  new APIProvider(EActionsTypes.update, restorePassTransport.sendNewPassword, {
    onSuccess: function*() {
      MessageHelper.updateSuccess();
      yield put(push(EPublicRoutes.LogIn));
    }
  })
];

const passwordApiProvider = [
  new APIProvider('change', restorePassTransport.changePassword, {
    onFail: function(response) {
      response?.message && message.error(response.message);
    },
    onSuccess: MessageHelper.updateSuccess
  })
];

const confirmationTransport = new BaseTransport('/registration/confirm-email');
const resendConfirmationTransport = new BaseTransport('/registration/resend-confirmation');

const confirmationApiProvider = [
  new APIProvider(EActionsTypes.add, confirmationTransport.add, {
    onFail: function*(response) {
      const AuthModelData = yield getCreds();

      if (AuthModelData?.id) {
        yield put(push(EPrivateRoutes.Main));

        return;
      }

      if (!response?.errorCode) {
        yield put(push(EPublicRoutes.LogIn));
      }
    },
    onSuccess: function*(response, payload, branchState, fullState) {
      MessageHelper.updateSuccess();

      // @ts-ignore reason: bad typing for fullState
      if (!fullState?.users?.current?.data) {
        yield put(push(EPublicRoutes.LogIn));
      }
    }
  }),
  new APIProvider('repeat', resendConfirmationTransport.add, {
    onFail: function(response) {
      response?.message && message.error(response.message);
    },
    onSuccess: MessageHelper.updateSuccess
  })
];

const branches = [
  new Branch('model', modelApiProvider),
  new Branch('restoreModel', restoreModelApiProvider),
  new Branch('confirmation', confirmationApiProvider),
  new Branch('password', passwordApiProvider)
];

const strategy = new BaseStrategy({
  namespace,
  branches
});

export const communicationAuth = buildCommunication<IAuthConnectedProps>(strategy);
