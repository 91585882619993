import * as React from 'react';
import Typography from 'antd/es/typography';
import { BlockProps } from 'antd/es/typography/Base';
import { useTranslation } from 'react-i18next';
import { EGiftType } from 'entities/Gift/Gift.models';

interface IComponentProps extends BlockProps {
  giftType: EGiftType;
  recipient?: string;
  sender?: string;
  size?: 'sm' | 'md';
  className?: string;
}

type AllProp = IComponentProps;

export const CardGiftHeader = ({ recipient, sender, giftType, size = 'sm', className = '', ...rest }: AllProp) => {
  const { t } = useTranslation();

  const name = recipient || sender;
  const giftTransType = giftType === EGiftType.Collective ? 'collective' : 'individual';
  const giftHeaderRecipient = t(`giftFor.${giftTransType}`, { name: '' });
  const giftHeaderSender = t(`giftFrom.${giftTransType}`, { name: '' });

  return (
    <Typography.Paragraph
      className={`card-gift__label card-gift__label_size_${size} first-letter_capitalize ${className}`}
      {...rest}
    >
      {name === recipient && (
        <>
          {giftHeaderRecipient} <b>{name}</b>
        </>
      )}
      {name === sender && (
        <>
          {giftHeaderSender} <b>{name}</b>
        </>
      )}
    </Typography.Paragraph>
  );
};
