import React, { useEffect } from 'react';
import Spin from 'antd/es/spin';
import List from 'antd/es/list';
import { Button, CardInfo } from '@axmit/clp-library';
import { Link, useHistory } from 'react-router-dom';
import { compose } from 'redux';
import { useTranslation } from 'react-i18next';
import { EmptyData } from 'common/components';
import { ECommonRoutes, EPrivateRoutes } from 'common/const/Routes.const';
import { FormatHelper, PaginationHelper } from 'common/helpers';
import { CardGift } from 'entities/Gift/components';
import { communicationGift, IGiftConnectedProps } from 'entities/Gift/Gift.communication';
import { communicationAuth, IAuthConnectedProps } from 'entities/Auth/Auth.communication';
import { DASHBOARD_GIFTS_GRID, FRIENDS_GIFTS_PER_PAGE } from 'entities/Gift/Gift.consts';

type AllProps = IGiftConnectedProps & IAuthConnectedProps;

const DashboardGiftsFriendsForComponent = ({ authModel, giftFriendsCollection, getGiftFriendsCollection }: AllProps) => {
  const { t } = useTranslation();

  const { data, loading } = giftFriendsCollection;

  useEffect(() => {
    const userSender = authModel?.data?.id?.userId;

    userSender && getGiftFriendsCollection({ userSender, limit: FRIENDS_GIFTS_PER_PAGE });
  }, [authModel, getGiftFriendsCollection]);

  const history = useHistory();
  const redirectToNewGift = () => {
    history.push(ECommonRoutes.NewGift, { hideNewOrEditChoice: false });
  };

  const onChangePage = (limit: number, offset: number) => {
    authModel.data &&
      getGiftFriendsCollection({
        userSender: authModel.data.id.userId,
        limit,
        offset
      });
  };

  return (
    <>
      <div className="d-flex d-flex_align_end">
        <div className="d-flex__item bordered_bottom bordered_bottom_light mr-20 hidden_mob">
          <CardInfo header={t('dashboardHeaderForFriends')} content="" headerSize="sm" />
        </div>

        <Button type="primary" onClick={redirectToNewGift} className="ant-btn_mob-block">
          {t('dashboardNewGift')}
        </Button>
      </div>

      <Spin spinning={loading}>
        <List
          pagination={PaginationHelper.getListsPagination(onChangePage, FRIENDS_GIFTS_PER_PAGE, data?.meta?.count)}
          grid={DASHBOARD_GIFTS_GRID}
          locale={{ emptyText: <EmptyData description={t('emptyGiftsMessage')} /> }}
          dataSource={data?.data || []}
          renderItem={({ status, type, meta, deliveryDate, id, currentBalance }) => (
            <List.Item>
              <Link to={`${EPrivateRoutes.GiftEditPage}/${id}`}>
                <CardGift
                  giftId={id}
                  status={status}
                  recipient={meta.recipientName}
                  type={type}
                  date={deliveryDate}
                  price={FormatHelper.getCurrency(currentBalance)}
                />
              </Link>
            </List.Item>
          )}
        />
      </Spin>
    </>
  );
};

const containers = compose(communicationGift.injector, communicationAuth.injector);

export const DashboardGiftsFriendsFor = containers(DashboardGiftsFriendsForComponent);
