import React from 'react';
import List from 'antd/es/list';
import InfiniteScroll from 'react-infinite-scroller';
import { StoreBranch } from '@axmit/redux-communications';
import { Spinner } from 'common/components/Spinner';
import { IBaseFilterModel } from 'common/models/Request.models';

interface IComponentProps<Model, Collection extends { data: Model[]; meta: { count?: number } }, Filter extends any = undefined> {
  elementId: string;
  filter?: Filter;
  isReverse?: boolean;
  threshold?: number;
}

interface IComponentState {
  pageIndex: number;
}

abstract class InfiniteListComponent<
  Collection extends { data: Model[]; meta: { count?: number } },
  Model,
  IProps,
  Filter extends any = undefined,
  Params = any
> extends React.PureComponent<IComponentProps<Model, Collection, Filter> & IProps, IComponentState> {
  constructor(props: IComponentProps<Model, Collection, Filter> & IProps) {
    super(props);
    this.state = { pageIndex: 0 };
  }

  componentDidMount(): void {
    this.loadData();
  }

  componentWillUnmount(): void {
    this.clearCollection();
  }

  componentDidUpdate(prevProps: Readonly<IComponentProps<Model, Collection, Filter> & IProps>) {
    if (JSON.stringify(prevProps.filter) !== JSON.stringify(this.props.filter)) {
      this.setState({ pageIndex: 0 }, this.loadData);
    }
  }

  render() {
    const { elementId, isReverse, threshold } = this.props;
    const collection = this.getCollection();
    const { data: collectionData, loading } = collection;
    const items = collectionData?.data || [];
    const count = collectionData?.meta?.count || 0;
    const hasMore = !loading && items.length < count;
    const hasData = !!items.length;

    return (
      <InfiniteScroll
        pageStart={1}
        loadMore={() => this.loadData()}
        hasMore={!loading && hasMore}
        useWindow={false}
        isReverse={isReverse}
        threshold={threshold}
        getScrollParent={() => document.getElementById(elementId)}
      >
        {loading && isReverse && <div className="ta-c">{this.renderSpinner()}</div>}

        {hasData && <List itemLayout="vertical" dataSource={items} renderItem={this.renderListItem} />}

        {loading && !isReverse && <div className="ta-c">{this.renderSpinner()}</div>}
      </InfiniteScroll>
    );
  }

  loadData = () => {
    const collection = this.getCollection();
    const { pageIndex } = this.state;
    const pageSize = 15;
    const { data: collectionData, loading } = collection;
    const count = collectionData?.meta?.count || 0;
    const hasMore = !loading && (!count || count > pageSize * pageIndex);

    if (hasMore) {
      const params: IBaseFilterModel = {
        limit: pageSize,
        offset: pageSize * pageIndex
      };

      this.loadCollection(params);
      this.setState(state => ({ pageIndex: state.pageIndex + 1 }));
    }
  };

  clearCollection: () => void = () => {};
  abstract getCollection: () => StoreBranch<Collection, Params>;
  abstract loadCollection: (params: IBaseFilterModel) => void;
  abstract renderListItem: (model: Model) => JSX.Element;
  renderSpinner = () => <Spinner spinning={true} />;
}

export const InfiniteList = InfiniteListComponent;
