import {
  APIProvider,
  BaseStrategy,
  Branch,
  buildCommunication,
  EActionsTypes,
  getStartType,
  StoreBranch
} from '@axmit/redux-communications';
import { put } from 'redux-saga/effects';
import { BaseTransport } from 'common/dto/base.transport';
import { RequestLoadingHelper, MessageHelper } from 'common/helpers';
import { IErrorResponse } from 'common/models/Request.models';
import {
  IGiftRequestTransactionsCollection,
  IGiftRequestTransactionsCollectionParams,
  IGiftRequestTransactionsCreateParams,
  IGiftRequestTransactionsModel,
  IGiftTransactionsCollection,
  IGiftTransactionsCollectionParams,
  IGiftTransactionsCreateParams,
  IGiftTransactionsModel
} from 'entities/GiftTransactions/GiftTransactions.models';

const namespace = 'giftTransactions';

export interface IGiftTransactionsConnectedProps {
  giftTransactionsModel: StoreBranch<IGiftTransactionsModel>;
  addGiftTransactionsModel(params: IGiftTransactionsCreateParams): void;
  deleteGiftTransactionsModel(id: string): void;
  clearGiftTransactionsModel(): void;

  giftTransactionsCollection: StoreBranch<IGiftTransactionsCollection>;
  getGiftTransactionsCollection(params: IGiftTransactionsCollectionParams): void;

  giftTransactionsRequestModel: StoreBranch<IGiftRequestTransactionsModel>;
  addGiftTransactionsRequestModel(params: IGiftRequestTransactionsCreateParams): void;
  deleteGiftTransactionsRequestModel(id: string): void;
  clearGiftTransactionsRequestModel(): void;

  giftTransactionsRequestCollection: StoreBranch<IGiftRequestTransactionsCollection>;
  getGiftTransactionsRequestCollection(params: IGiftRequestTransactionsCollectionParams): void;
}

const transport = new BaseTransport<IGiftTransactionsModel, IGiftTransactionsCollection, IGiftTransactionsCreateParams>(
  'gift-transactions'
);

const modelApiProvider = [
  new APIProvider(EActionsTypes.delete, transport.delete, {
    onSuccess: function*(response, payload, branchState, fullState) {
      // @ts-ignore reason: bad typing for fullState, can't find collection
      const { params = {} } = fullState?.giftTransactions?.collection;
      yield reloadCollection(params);
    }
  }),
  new APIProvider<IGiftTransactionsModel, IGiftTransactionsCreateParams, IErrorResponse>(EActionsTypes.add, transport.add, {
    clearParams: true,
    onSuccess: function*(response, payload, branchState, fullState) {
      MessageHelper.createSuccess();
      // @ts-ignore reason: bad typing for fullState, can't find collection
      const { params = {} } = fullState?.giftTransactions?.collection;
      yield reloadCollection(params);
    }
  })
];

const requestTransport = new BaseTransport<
  IGiftRequestTransactionsModel,
  IGiftRequestTransactionsCollection,
  IGiftRequestTransactionsCreateParams
>('gift-request-transactions');

const requestModelApiProvider = [
  new APIProvider(EActionsTypes.delete, transport.delete, {
    onSuccess: function*(response, payload, branchState, fullState) {
      // @ts-ignore reason: bad typing for fullState, can't find collection
      const { params = {} } = fullState?.giftTransactions?.requestCollection;
      yield reloadRequestCollection(params);
    }
  }),
  new APIProvider(EActionsTypes.add, requestTransport.add, {
    clearParams: true,
    onSuccess: function*(response, payload, branchState, fullState) {
      MessageHelper.createSuccess();
      // @ts-ignore reason: bad typing for fullState, can't find collection
      const { params = {} } = fullState?.giftTransactions?.requestCollection;
      yield reloadRequestCollection(params);
    }
  })
];

function* reloadCollection(payload: IGiftTransactionsCollectionParams) {
  yield put({
    type: getStartType(namespace, 'collection', EActionsTypes.get),
    payload
  });
}

function* reloadRequestCollection(payload: IGiftTransactionsCollectionParams) {
  yield put({
    type: getStartType(namespace, 'requestCollection', EActionsTypes.get),
    payload
  });
}

const collectionApiProvider = [
  new APIProvider<IGiftTransactionsCollection, IGiftTransactionsCollectionParams, IErrorResponse>(
    EActionsTypes.get,
    transport.getCollection,
    {
      preRequestDataMapper: RequestLoadingHelper.setOldData
    }
  )
];

const branches = [
  new Branch('model', modelApiProvider),
  new Branch('collection', collectionApiProvider),
  new Branch('requestModel', requestModelApiProvider),
  new Branch('requestCollection', collectionApiProvider)
];

const strategy = new BaseStrategy({
  namespace,
  branches
});

export const communicationGiftTransactions = buildCommunication<IGiftTransactionsConnectedProps>(strategy);
