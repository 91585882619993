import { Container } from '@axmit/clp-library';
import { Route, Switch } from 'react-router';
import React, { useEffect } from 'react';
import { SocketHooks } from 'common/hooks/Socket.hooks';
import { Desktop } from 'common/components';
import { EBackOfficeRoutes } from 'common/const';
import { CompaniesPage } from 'entities/Companies/components/CompaniesPage';
import { ClientsPage } from 'entities/User/components/ClientsPage';
import { GiftRequestsPage } from 'entities/Requests/components/GiftRequestsPage';
import { ManagersPage } from 'entities/User/components/ManagersPage';
import { MessagesPage } from 'entities/Messages/components';
import { RequestCreateForm } from 'entities/GiftRequests/components';
import { BOUserTransactions } from 'entities/GiftTransactions/components';

export const LayoutPrivateBackOffice = () => {
  const { socket } = SocketHooks.useSocket();

  useEffect(() => {
    socket.connect();
  }, [socket]);

  return (
    <>
      <Desktop>
        <div className="mt-25" />
      </Desktop>

      <Container>
        <Switch>
          <Route
            path={[EBackOfficeRoutes.TransactionRequest, EBackOfficeRoutes.TransactionGiftRequest]}
            component={BOUserTransactions}
          />
          <Route path={EBackOfficeRoutes.CreateRequest} component={RequestCreateForm} />
          <Route path={EBackOfficeRoutes.Companies} component={CompaniesPage} />
          <Route path={EBackOfficeRoutes.Clients} component={ClientsPage} />
          <Route path={EBackOfficeRoutes.Managers} component={ManagersPage} />
          <Route path={EBackOfficeRoutes.Messages} component={MessagesPage} />
          <Route path="*" component={GiftRequestsPage} />
        </Switch>
      </Container>
    </>
  );
};
