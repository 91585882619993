import React, { useEffect } from 'react';
import Table from 'antd/es/table';
import { HooksHelper } from '@axmit/clp-library/dist/helpers';
import { Button } from '@axmit/clp-library';
import { useTranslation } from 'react-i18next';
import { FormatHelper } from 'common/helpers';
import {
  communicationGiftTransactions,
  IGiftTransactionsConnectedProps
} from 'entities/GiftTransactions/GiftTransactions.communication';
import { communicationRequests, IRequestsConnectedProps } from 'entities/Requests/Requests.communication';
import {
  ETransactionsHistoryColumns,
  ITransactionsTotalRow,
  transactionsHistoryColumns
} from 'entities/GiftTransactions/GiftTransactions.const';
import { IGiftTransactionsModel } from 'entities/GiftTransactions/GiftTransactions.models';
import { TransactionsHeader } from 'entities/Requests/components';
import { ERequestStatus } from 'entities/Requests/Requests.models';

interface IComponentProps {
  isGift?: boolean;
}

type AllProps = IComponentProps & IGiftTransactionsConnectedProps & IRequestsConnectedProps;

const ManagersTransactionsListComponent = (props: AllProps) => {
  const { t } = useTranslation();
  const {
    isGift,
    giftTransactionsCollection,
    getGiftTransactionsCollection,
    giftTransactionsRequestCollection,
    getGiftTransactionsRequestCollection,
    requestsModel
  } = props;

  const requestId = requestsModel.data?.id;
  const giftId = requestsModel.data?.giftData?.id;
  const total = HooksHelper.useStateBuilder<number>(0);
  const status = requestsModel.data?.status;

  useEffect(() => {
    const get = isGift ? getGiftTransactionsCollection : getGiftTransactionsRequestCollection;

    get({ request: requestId, gift: giftId });
  }, [getGiftTransactionsCollection, getGiftTransactionsRequestCollection, isGift, requestId, giftId]);

  useEffect(() => {
    const data = isGift ? giftTransactionsCollection.data : giftTransactionsRequestCollection.data;

    data && total.set(FormatHelper.sumFieldInArray(data.data, ETransactionsHistoryColumns.amount));
  }, [giftTransactionsCollection.data, giftTransactionsRequestCollection.data, total, isGift]);

  const data = isGift ? giftTransactionsCollection.data : giftTransactionsRequestCollection.data;

  if (!data?.data.length) {
    return null;
  }

  const removeRow = (id: string) => {
    const { deleteGiftTransactionsRequestModel, deleteGiftTransactionsModel } = props;
    const remove = isGift ? deleteGiftTransactionsModel : deleteGiftTransactionsRequestModel;

    remove(id);
  };

  const budgetRow: ITransactionsTotalRow = {
    [ETransactionsHistoryColumns.type]: t('calculator.table.total'),
    [ETransactionsHistoryColumns.amount]: total.value,
    key: 'total'
  };
  const dataWithActions = data.data.map(item => ({
    ...item,
    [ETransactionsHistoryColumns.action]: status !== ERequestStatus.Finished && (
      <Button type="link" className="p-0 float_r" onClick={() => removeRow(item.id)}>
        {t('defaultBtnRollback')}
      </Button>
    )
  }));
  const rowData = [...dataWithActions, budgetRow];

  return (
    <>
      <TransactionsHeader className="mb-0">{t('transactions.list.header')}</TransactionsHeader>

      <Table
        showHeader={false}
        className="clw-table clw-table_last-row-bold mb-20"
        size="middle"
        loading={false}
        columns={transactionsHistoryColumns}
        dataSource={rowData}
        pagination={false}
        rowKey={record => {
          if ((record as IGiftTransactionsModel).id) {
            return (record as IGiftTransactionsModel).id;
          }

          if ((record as ITransactionsTotalRow).key) {
            return (record as ITransactionsTotalRow).key;
          }

          return '-';
        }}
      />
    </>
  );
};

export const ManagersTransactionsList = communicationGiftTransactions.injector(
  communicationRequests.injector(ManagersTransactionsListComponent)
);
