import React from 'react';

export const SvgPinterest = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.99 2C6.472 2 2 6.473 2 11.99C2 16.222 4.633 19.84 8.35 21.296C8.262 20.506 8.184 19.29 8.384 18.428C8.566 17.648 9.556 13.462 9.556 13.462C9.556 13.462 9.257 12.863 9.257 11.978C9.257 10.59 10.062 9.553 11.065 9.553C11.918 9.553 12.329 10.193 12.329 10.96C12.329 11.818 11.783 13.099 11.502 14.287C11.267 15.281 12.001 16.092 12.981 16.092C14.756 16.092 16.122 14.22 16.122 11.517C16.122 9.125 14.403 7.453 11.949 7.453C9.106 7.453 7.437 9.585 7.437 11.788C7.437 12.646 7.768 13.567 8.181 14.068C8.2161 14.1057 8.24088 14.1519 8.25297 14.202C8.26505 14.2521 8.26403 14.3044 8.25 14.354C8.174 14.669 8.005 15.348 7.973 15.487C7.929 15.67 7.828 15.709 7.638 15.621C6.391 15.04 5.611 13.216 5.611 11.75C5.611 8.599 7.9 5.705 12.212 5.705C15.678 5.705 18.371 8.174 18.371 11.475C18.371 14.919 16.2 17.688 13.187 17.688C12.174 17.688 11.223 17.163 10.897 16.542L10.274 18.916C10.049 19.784 9.44 20.872 9.033 21.536C9.99111 21.8318 10.9883 21.9818 11.991 21.981C17.508 21.981 21.981 17.508 21.981 11.991C21.981 6.474 17.507 2 11.99 2Z"
      fill="#0F0D18"
    />
  </svg>
);
