import * as React from 'react';
import { Route, Switch } from 'react-router';
import { EBackOfficeRoutes } from 'common/const';
import { CompaniesList, CompanyPage } from 'entities/Companies/components';

export const CompaniesPage = () => {
  return (
    <Switch>
      <Route path={`${EBackOfficeRoutes.Companies}/:id`} component={CompanyPage} exact />
      <Route path="*" component={CompaniesList} />
    </Switch>
  );
};
