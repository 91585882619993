import React, { useEffect, useCallback } from 'react';
import AntSelect from 'antd/es/select';
import Spin from 'antd/es/spin';
import { FormItem, Select } from '@axmit/clp-library';
import { compose } from 'redux';
import { useTranslation } from 'react-i18next';
import debounce from 'lodash.debounce';
import { EmptyData } from 'common/components';
import { SEARCH_WAIT_TIME } from 'common/config';
import { communicationUsers, IUsersConnectedProps } from 'entities/User/Users.communication';
import { MANAGERS_FILTERS_ACTIVE } from 'entities/Requests/Request.consts';

interface IComponentProps {
  disabled: boolean;
  managerId?: string;
  managerName?: string;
  updateManager: (value?: string) => void;
}

type AllProps = IUsersConnectedProps & IComponentProps;

const RequestEditPageManagerComponent = ({
  disabled,
  managerId,
  managerName,
  getUsersCollection,
  usersCollection,
  clearUsersCollection,
  usersCurrent,
  updateManager
}: AllProps) => {
  const { t } = useTranslation();
  useEffect(() => {
    return () => {
      clearUsersCollection();
    };
  }, [clearUsersCollection]);

  useEffect(() => {
    getUsersCollection(MANAGERS_FILTERS_ACTIVE);
  }, [getUsersCollection]);

  const debounceSearch = useCallback(
    debounce((value: string) => {
      getUsersCollection({ ...MANAGERS_FILTERS_ACTIVE, quickSearch: value });
    }, SEARCH_WAIT_TIME),
    [getUsersCollection]
  );

  const defaultSelect = managerId && managerName ? { key: managerId, label: managerName } : undefined;
  const mappedManagers = usersCollection.data?.data?.filter(item => item.id !== usersCurrent.data?.id);

  const myId = usersCurrent.data?.id;
  const myFirstName = usersCurrent.data?.firstName;
  const myLastName = usersCurrent.data?.lastName;

  // antd has wrong typing for labelInValue prop
  const onChange = (value: any) => {
    updateManager(value?.key);
  };

  return (
    <FormItem label={t('request.editForm.managerName')}>
      <Select
        labelInValue
        allowClear
        defaultValue={defaultSelect}
        bordered="bottom"
        className="w-100p"
        notFoundContent={usersCollection.loading ? <Spin size="small" /> : <EmptyData />}
        showSearch
        filterOption={false}
        onSearch={debounceSearch}
        width="sm"
        onChange={onChange}
        disabled={disabled}
      >
        <AntSelect.Option key={myId} value={myId}>
          {myLastName} {myFirstName}
        </AntSelect.Option>

        {mappedManagers?.map(({ firstName, lastName, id }) => (
          <AntSelect.Option key={id} value={id}>
            {lastName} {firstName}
          </AntSelect.Option>
        ))}
      </Select>
    </FormItem>
  );
};

const containers = compose(communicationUsers.injector);

export const RequestEditPageManager = containers(RequestEditPageManagerComponent);
