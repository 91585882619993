import imagePayPal from 'app/assets/images/paymentPayPal.png';
import imageVisa from 'app/assets/images/paymentVisa.png';
import imageMC from 'app/assets/images/paymentMC.png';
import imageCB from 'app/assets/images/paymentCB.png';

interface IPaymentGroup {
  value: string;
  img: string;
  alt: string;
}

export const PaymentMethods: IPaymentGroup[] = [
  { value: 'CB', img: imageCB, alt: 'Cartes Bancaires' },
  { value: 'visa', img: imageVisa, alt: 'Visa' },
  { value: 'mastercard', img: imageMC, alt: 'Mastercard' },
  { value: 'payPal', img: imagePayPal, alt: 'Pay Pal' }
];
