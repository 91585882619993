import * as React from 'react';

export const SvgWhatsApp = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.5422 10.5451C12.3506 10.4467 11.3961 9.9791 11.2186 9.91582C11.041 9.84902 10.9109 9.81738 10.7826 10.0143C10.6525 10.2094 10.2834 10.6436 10.1674 10.7754C10.0549 10.9055 9.94062 10.9213 9.74902 10.8246C8.60996 10.2551 7.86289 9.80859 7.1123 8.52012C6.91367 8.17734 7.31094 8.20195 7.68184 7.46191C7.74512 7.33184 7.71348 7.22109 7.66426 7.12266C7.61504 7.02422 7.22832 6.07148 7.0666 5.68301C6.91016 5.30508 6.74844 5.35781 6.63066 5.35078C6.51816 5.34375 6.38984 5.34375 6.25977 5.34375C6.12969 5.34375 5.92051 5.39297 5.74297 5.58457C5.56543 5.77969 5.06445 6.24902 5.06445 7.20176C5.06445 8.15449 5.75879 9.07734 5.85371 9.20742C5.95215 9.3375 7.21953 11.2922 9.16543 12.1342C10.3959 12.665 10.8775 12.7107 11.4928 12.6193C11.8672 12.5631 12.6389 12.1518 12.7988 11.6965C12.9588 11.243 12.9588 10.8545 12.9113 10.7736C12.8639 10.6875 12.7338 10.6383 12.5422 10.5451Z"
        fill="#51CC66"
      />
      <path
        d="M16.2634 5.94844C15.8661 5.00449 15.2966 4.15723 14.5706 3.42949C13.8446 2.70352 12.9974 2.13223 12.0517 1.73672C11.0849 1.33066 10.0583 1.125 9.0001 1.125H8.96494C7.89971 1.13027 6.86787 1.34121 5.89756 1.75605C4.96065 2.15684 4.12041 2.72637 3.40147 3.45234C2.68252 4.17832 2.11826 5.02207 1.72803 5.9625C1.32373 6.93633 1.11983 7.97168 1.1251 9.03691C1.13037 10.2568 1.42217 11.468 1.96885 12.5508V15.2227C1.96885 15.6691 2.33096 16.0312 2.77744 16.0312H5.45108C6.53389 16.5779 7.74502 16.8697 8.96494 16.875H9.00186C10.0548 16.875 11.0761 16.6711 12.0376 16.2721C12.978 15.8801 13.8235 15.3176 14.5478 14.5986C15.2737 13.8797 15.845 13.0395 16.244 12.1025C16.6589 11.1322 16.8698 10.1004 16.8751 9.03516C16.8804 7.96465 16.673 6.92578 16.2634 5.94844ZM13.6073 13.6477C12.3751 14.8676 10.7403 15.5391 9.0001 15.5391H8.97022C7.91026 15.5338 6.85733 15.2701 5.92744 14.7744L5.77979 14.6953H3.30479V12.2203L3.22569 12.0727C2.72998 11.1428 2.46631 10.0898 2.46104 9.02988C2.45401 7.27734 3.12373 5.63203 4.35244 4.39277C5.5794 3.15352 7.21944 2.46797 8.97198 2.46094H9.00186C9.88076 2.46094 10.7333 2.63145 11.5366 2.96895C12.3206 3.29766 13.0237 3.77051 13.6284 4.3752C14.2314 4.97812 14.706 5.68301 15.0347 6.46699C15.3757 7.2791 15.5462 8.14043 15.5427 9.02988C15.5321 10.7807 14.8448 12.4207 13.6073 13.6477Z"
        fill="#51CC66"
      />
    </svg>
  );
};
