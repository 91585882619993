import React, { useCallback, useEffect } from 'react';
import { compose } from 'redux';
import Table from 'antd/es/table';
import { Row, Col } from 'antd/es/grid';
import Spin from 'antd/lib/spin';
import { HooksHelper } from '@axmit/clp-library/dist/helpers';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { EmptyData, QuickSearch } from 'common/components';
import { PaginationHelper, TableFormatHelper } from 'common/helpers';
import { EBackOfficeRoutes } from 'common/const';
import { communicationUsers, IUsersConnectedProps } from 'entities/User/Users.communication';
import { CLIENTS_PER_PAGE, clientsColumns, EUserRoles } from 'entities/User/Users.consts';
import { IUserCollectionParams, IUserModel } from 'entities/User/Users.models';

type AllProps = IUsersConnectedProps;

const ClientsComponent = ({ getUsersCollection, clearUsersCollection, usersCollection }: AllProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const currentPage = HooksHelper.useStateBuilder<number>(1);
  const search = HooksHelper.useStateBuilder<string>('');

  useEffect(() => {
    return () => {
      clearUsersCollection();
    };
  }, [clearUsersCollection]);

  const reloadCollection = useCallback(
    (newParams: IUserCollectionParams) => {
      getUsersCollection(newParams);
    },
    [getUsersCollection]
  );

  useEffect(() => {
    reloadCollection({
      role: EUserRoles.Regular,
      limit: CLIENTS_PER_PAGE,
      quickSearch: search.value
    });
  }, [search.value, reloadCollection]);

  const onChangePage = (limit: number, offset: number, page: number) => {
    currentPage.set(page);
    reloadCollection({
      role: EUserRoles.Regular,
      limit,
      offset,
      quickSearch: search.value
    });
  };

  const { loading, data } = usersCollection;

  const onSearch = (value: string) => {
    currentPage.set(1);
    search.set(value);
  };

  const onRowClick = (record: IUserModel) => ({
    onClick: () => history.push(`${EBackOfficeRoutes.Clients}/${record.id}`)
  });

  return (
    <>
      <Row className="mb-30" type="flex" gutter={22}>
        <Col lg={8}>
          <QuickSearch onSearch={onSearch} />
        </Col>
      </Row>

      <Spin spinning={loading}>
        {data && data.meta.count ? (
          <Table
            className="clw-table mb-20"
            size="middle"
            loading={loading}
            columns={TableFormatHelper.translateTitles(clientsColumns, t)}
            dataSource={data.data}
            pagination={PaginationHelper.getListsPagination(onChangePage, CLIENTS_PER_PAGE, data.meta.count, currentPage.value)}
            rowKey={record => record.id}
            onRow={onRowClick}
            rowClassName={() => 'c-pointer'}
            bordered
          />
        ) : (
          <EmptyData />
        )}
      </Spin>
    </>
  );
};

const containers = compose(communicationUsers.injector);

export const Clients = containers(ClientsComponent);
