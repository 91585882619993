import React from 'react';
import { HorizontalMenu } from '@axmit/clp-library';
import Menu from 'antd/lib/menu';
import { useHistory } from 'react-router';
import { AppLandingHelper } from '@axmit/router-app-landing';
import { useTranslation } from 'react-i18next';
import { ECommonRoutes, ELandingRoutes, EPrivateRoutes, EPublicRoutes } from 'common/const/Routes.const';
import { LogoGift, Header } from 'common/components';
import { TabsHooks } from 'common/hooks';
import logo from 'app/assets/images/logo.png';
import { languages } from 'app/locales/i18n';
import julesMobile from 'app/assets/images/julesMobile.png';
import { communicationUsers, IUsersConnectedProps } from 'entities/User/Users.communication';
import { communicationAuth, IAuthConnectedProps } from 'entities/Auth/Auth.communication';

type AllProps = IUsersConnectedProps & IAuthConnectedProps;

const HeaderBasicComponent = (props: AllProps) => {
  const history = useHistory();
  const { i18n, t } = useTranslation();
  const { activeTabs } = TabsHooks.useSelectedTabs(availableRoutes, 'deskLogin');

  const redirectToLogIn = () => {
    history.push(EPublicRoutes.LogIn);
  };

  const redirectToDashboard = () => {
    history.push(EPrivateRoutes.Main);
  };

  const logout = () => {
    props.deleteAuthModel();
  };

  const { usersCurrent } = props;

  const name = usersCurrent?.data?.firstName;
  const defaultLabel = usersCurrent?.loading ? '' : t('headerLogin');
  const isAuthorized = !!usersCurrent?.data;

  return (
    <Header
      leftSlot={<LogoGift logo={logo} onClick={() => AppLandingHelper.toggleRoute()} />}
      rightSlot={
        <HorizontalMenu
          selectedKeys={activeTabs}
          mobileImg={julesMobile}
          currentLanguage={i18n.language}
          languages={languages}
          updateLanguage={language => {
            i18n.changeLanguage(language);
          }}
        >
          <Menu.Item
            key="mobGifts"
            onClick={() => history.push(EPrivateRoutes.Main)}
            className="hidden_desk"
            hidden={!isAuthorized}
          >
            {t('dashboardTabGifts')}
          </Menu.Item>

          <Menu.Item
            key="mobPersonal"
            onClick={() => history.push(EPrivateRoutes.PersonalMobile)}
            className="hidden_desk"
            hidden={!isAuthorized}
          >
            {t('dashboardTabPersonalInfo')}
          </Menu.Item>

          <Menu.Item
            key="mobBanking"
            className="mb-20 hidden_desk"
            onClick={() => history.push(EPrivateRoutes.BankingMobile)}
            hidden={!isAuthorized}
          >
            {t('dashboardTabBankingInfo')}
          </Menu.Item>

          <Menu.Item
            key="landingPhilosophy"
            className={isAuthorized ? 'hidden_mob' : ''}
            onClick={() => AppLandingHelper.toggleRoute(ELandingRoutes.Philosophy)}
          >
            {t('headerPhilosophy')}
          </Menu.Item>

          <Menu.Item
            key="landingHow"
            className={isAuthorized ? 'hidden_mob' : ''}
            onClick={() => AppLandingHelper.toggleRoute(ELandingRoutes.How)}
          >
            {t('headerHow')}
          </Menu.Item>

          <Menu.Item
            key="landingFaq"
            className={isAuthorized ? 'hidden_mob' : ''}
            onClick={() => AppLandingHelper.toggleRoute(ELandingRoutes.FAQ)}
          >
            {t('headerFAQ')}
          </Menu.Item>

          <Menu.Item
            key={ECommonRoutes.Discover}
            onClick={() => {
              history.push(ECommonRoutes.Discover);
            }}
          >
            {t('headerGift')}
          </Menu.Item>

          <Menu.Item key="deskLogin" onClick={isAuthorized ? redirectToDashboard : redirectToLogIn} className="hidden_mob">
            {isAuthorized ? name : defaultLabel}
          </Menu.Item>

          <Menu.Item key="mobLogout" onClick={name ? logout : redirectToLogIn} className="hidden_desk">
            {isAuthorized ? t('headerLogout') : defaultLabel}
          </Menu.Item>
        </HorizontalMenu>
      }
    />
  );
};

export const HeaderBasic = communicationAuth.injector(communicationUsers.injector(HeaderBasicComponent));

const availableRoutes: string[] = [ECommonRoutes.Discover];
