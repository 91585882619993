import React, { useCallback, useEffect, useState } from 'react';
import { compose } from 'redux';
import AntSelect from 'antd/es/select';
import { Row, Col } from 'antd/es/grid';
import Spin from 'antd/es/spin';
import Table from 'antd/es/table';
import { HooksHelper } from '@axmit/clp-library/dist/helpers';
import { Button, Select } from '@axmit/clp-library';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { EmptyData, QuickSearch } from 'common/components';
import { PaginationHelper, TableFormatHelper } from 'common/helpers';
import { EBackOfficeRoutes } from 'common/const';
import { communicationUsers, IUsersConnectedProps } from 'entities/User/Users.communication';
import {
  EUserRoles,
  MANAGERS_PER_PAGE,
  managersColumns,
  ManagersOptions,
  EManagerArchiveStatuses
} from 'entities/User/Users.consts';
import { IUserCollectionParams, IUserModel } from 'entities/User/Users.models';
import { MANAGERS_FILTERS_ALL } from 'entities/Requests/Request.consts';

type AllProps = IUsersConnectedProps;

const ManagersComponent = ({ getUsersCollection, clearUsersCollection, usersCollection, usersCurrent }: AllProps) => {
  const { t } = useTranslation();
  const isDeleted = HooksHelper.useStateBuilder<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const search = HooksHelper.useStateBuilder<string>('');
  const history = useHistory();

  useEffect(() => {
    return () => {
      clearUsersCollection();
    };
  }, [clearUsersCollection]);

  const reloadCollection = useCallback(
    (newParams: IUserCollectionParams) => {
      getUsersCollection(newParams);
    },
    [getUsersCollection]
  );

  useEffect(() => {
    const reload = async () => {
      await reloadCollection({
        ...MANAGERS_FILTERS_ALL,
        isDeleted: isDeleted.value,
        offset: 0,
        limit: MANAGERS_PER_PAGE,
        quickSearch: search.value
      });

      setCurrentPage(1);
    };

    reload();
  }, [setCurrentPage, search.value, isDeleted.value, reloadCollection]);

  const onChangePage = (limit: number, offset: number, page: number) => {
    setCurrentPage(page);
    reloadCollection({
      ...MANAGERS_FILTERS_ALL,
      isDeleted: isDeleted.value,
      limit,
      offset,
      quickSearch: search.value
    });
  };

  const { loading, data } = usersCollection;

  const isAdmin = usersCurrent.data?.role === EUserRoles.Administrator;

  const addNewManager = () => {
    history.push(EBackOfficeRoutes.ManagersCreate);
  };

  const onRowClick = (record: IUserModel) => ({
    onClick: () => history.push(`${EBackOfficeRoutes.Managers}/${record.id}`)
  });

  return (
    <>
      <Row className="mb-20" align="bottom" type="flex" gutter={22}>
        <Col lg={8} sm={8} xs={24} className="mb-10">
          <QuickSearch onSearch={search.set} />
        </Col>

        <Col lg={4} sm={8} xs={24} className="mb-10">
          <Select
            defaultValue="active"
            bordered="bottom"
            className="w-100p"
            filterOption={false}
            width="sm"
            onChange={value => {
              isDeleted.set(value === EManagerArchiveStatuses.Archived);
            }}
            disabled={loading}
          >
            {ManagersOptions.statuses.map(({ value, label }) => (
              <AntSelect.Option key={value} value={value}>
                {label}
              </AntSelect.Option>
            ))}
          </Select>
        </Col>

        <Col lg={12} sm={8} xs={24} className="ta-r mb-10">
          {isAdmin && (
            <Button type="primary" size="small" onClick={addNewManager}>
              {t('managers.addNew')}
            </Button>
          )}
        </Col>
      </Row>

      <Spin spinning={loading}>
        {data && data.meta.count ? (
          <Table
            className="clw-table mb-20"
            size="middle"
            loading={loading}
            columns={TableFormatHelper.translateTitles(managersColumns, t)}
            dataSource={data.data}
            pagination={PaginationHelper.getListsPagination(onChangePage, MANAGERS_PER_PAGE, data.meta.count, currentPage)}
            rowKey={record => record.id}
            onRow={isAdmin ? onRowClick : undefined}
            rowClassName={isAdmin ? () => 'c-pointer' : undefined}
            bordered
          />
        ) : (
          <EmptyData />
        )}
      </Spin>
    </>
  );
};

const containers = compose(communicationUsers.injector);

export const Managers = containers(ManagersComponent);
