import React from 'react';
import { Route, RouteComponentProps, Switch, withRouter } from 'react-router';
import { compose } from 'redux';
import { EPrivateRoutes } from 'common/const/Routes.const';
import { LOCAL_STORAGE_NEW_GIFT, LOCAL_STORAGE_NEW_GIFT_CLOSED } from 'common/const';
import { Dashboard, GiftPayment } from 'app/pages';
import { ChangePasswordMobilePage } from 'entities/Auth/components';
import { UserTransactionsForm } from 'entities/GiftTransactions/components/UserTransactionsForm';
import { GiftEditPage, GiftViewPage } from 'entities/Gift/components';
import { BankingMobilePage } from 'entities/Payment/components';
import { PersonalMobilePage } from 'entities/User/components';
import { communicationGift, IGiftConnectedProps, IGiftCreateViewModel } from 'entities/Gift';
import { GiftTransactionsForm } from 'entities/GiftTransactions/components';

type AllProps = RouteComponentProps & IGiftConnectedProps;

class LayoutPrivateBasicComponent extends React.PureComponent<AllProps> {
  componentDidMount() {
    this.redirectIfHasNewGift();
  }

  redirectIfHasNewGift = () => {
    const { history, giftViewModel, giftCode, acceptGiftModel } = this.props;

    if (giftViewModel?.data?.currency) {
      this.initGiftPayment(giftViewModel.data);

      return;
    }

    if (giftCode.data) {
      acceptGiftModel({ id: giftCode.data.id });

      return;
    }

    const isClosedByUser = !!localStorage.getItem(LOCAL_STORAGE_NEW_GIFT_CLOSED);

    if (isClosedByUser) {
      return;
    }

    const gift = localStorage.getItem(LOCAL_STORAGE_NEW_GIFT);

    if (gift && history.location.pathname !== EPrivateRoutes.GiftPayment) {
      const parsedGift: IGiftCreateViewModel = JSON.parse(gift);

      if (!parsedGift) {
        localStorage.removeItem(LOCAL_STORAGE_NEW_GIFT);
        localStorage.removeItem(LOCAL_STORAGE_NEW_GIFT_CLOSED);

        return;
      }

      this.initGiftPayment(parsedGift);
    }
  };

  initGiftPayment = async (parsedGift: IGiftCreateViewModel) => {
    const { addGiftCreateModel, addGiftViewModel } = this.props;

    await addGiftViewModel(parsedGift);
    addGiftCreateModel(parsedGift);
  };

  public render() {
    return (
      <Switch>
        <Route path={`${EPrivateRoutes.GiftEditPage}/:id`} component={GiftEditPage} />
        <Route path={`${EPrivateRoutes.GiftViewPage}/:id`} component={GiftViewPage} />
        <Route path={EPrivateRoutes.GiftPayment} component={GiftPayment} />
        <Route path={EPrivateRoutes.BankingMobile} component={BankingMobilePage} />
        <Route path={EPrivateRoutes.PersonalMobile} component={PersonalMobilePage} />
        <Route path={EPrivateRoutes.ChangePassword} component={ChangePasswordMobilePage} />
        <Route path={EPrivateRoutes.TransactionRequest} component={GiftTransactionsForm} />
        <Route path={EPrivateRoutes.TransactionGiftRequest} component={UserTransactionsForm} />
        <Route path="*" component={Dashboard} />
      </Switch>
    );
  }
}

const containers = compose(withRouter, communicationGift.injector);

export const LayoutPrivateBasic = containers(LayoutPrivateBasicComponent);
