import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { EBackOfficeRoutes } from 'common/const';
import { BackOfficeBreadcrumb } from 'common/components';
import { communicationUsers, IUsersConnectedProps } from 'entities/User/Users.communication';
import { ManagersForm } from 'entities/User/components/ManagersForm';
import { IUserManagerCreateParams } from 'entities/User/Users.models';

type AllProps = IUsersConnectedProps;

const ManagersEditComponent = ({
  clearUsersModel,
  getUsersModel,
  usersModel,
  updateUsersModel,
  recoverUsersModel,
  deleteUsersModel,
  usersCurrent
}: AllProps) => {
  const { t } = useTranslation();
  const { id } = useParams();

  useEffect(() => {
    id && getUsersModel(id);

    return () => {
      clearUsersModel();
    };
  }, [getUsersModel, clearUsersModel, id]);

  const submit = ({ firstName, lastName, role }: IUserManagerCreateParams) => {
    id && updateUsersModel({ id, data: { firstName, lastName, role, id } });
  };

  const toggleArchive = () => {
    if (!id) {
      return;
    }

    const isDeleted = usersModel.data?.isDeleted;

    isDeleted ? recoverUsersModel(id) : deleteUsersModel(id);
  };

  const { data: manager, params } = usersModel;
  const name = manager && `${manager.firstName} ${manager.lastName}`;
  const isMyPage = usersCurrent.data?.id === manager?.id;

  return (
    <>
      <BackOfficeBreadcrumb
        current={name}
        previous={t('backOfficeDashboard.tab.managers')}
        previousHref={EBackOfficeRoutes.Managers}
      />

      {manager && (
        <ManagersForm
          submit={submit}
          model={manager}
          loading={usersModel.loading}
          toggleArchive={isMyPage ? undefined : toggleArchive}
          params={params}
        />
      )}
    </>
  );
};

export const ManagersEdit = communicationUsers.injector(ManagersEditComponent);
