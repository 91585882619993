import message from 'antd/es/message';
import i18n from 'app/locales/i18n';

function updateSuccess() {
  message.success(i18n.t('successUpdateDefault'));
}

function createSuccess() {
  message.success(i18n.t('successCreateDefault'));
}

function deleteSuccess() {
  message.success(i18n.t('successDeleteDefault'));
}

export const MessageHelper = {
  deleteSuccess,
  createSuccess,
  updateSuccess
};
