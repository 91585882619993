import * as React from 'react';
import julesAvatarSm from 'app/assets/images/julesAvatarSm.png';

interface IComponentProps {
  name: string | null;
  message: string | null;
}

export const ContributorsCommentsItem = ({ name, message }: IComponentProps) => {
  return (
    <div className="contributors-comment">
      <div className="contributors-comment__header">
        <img src={julesAvatarSm} alt={`${name}'s tmp avatar`} className="contributors-comment__avatar avatar_size_sm" />
        <span className="contributors-comment__name description">{name}</span>
      </div>

      <div className="contributors-comment__content clp-typography-text clp-typography-text_size_lg">{message}</div>
    </div>
  );
};
