import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ECommonRoutes, EPublicRoutes } from 'common/const';
import { NewGift } from 'app/pages/NewGift';
import { ContactUs } from 'app/pages/ContactUs';
import { Finalize } from 'app/pages/Finalize';
import { Logout } from 'entities/Auth/components';
import { ContributorFormWrapper } from 'entities/Contributors/components';
import { ActivateGift } from 'entities/Gift/components';
import { Confirmation } from 'entities/Auth/components/Confirmation';
import { LayoutPublic } from 'entities/Layout/LayoutPublic';
import { LayoutPrivate } from 'entities/Layout/LayoutPrivate';

export const Router = () => {
  return (
    <Switch>
      <Route path={ECommonRoutes.Logout} component={Logout} />
      <Route path={ECommonRoutes.Contributor} component={ContributorFormWrapper} />
      <Route path={ECommonRoutes.NewGift} component={NewGift} />
      <Route path={ECommonRoutes.Discover} component={ActivateGift} />
      <Route path={ECommonRoutes.ContactUs} component={ContactUs} />
      <Route path={ECommonRoutes.Finalize} component={Finalize} />
      <Route path={ECommonRoutes.Confirmation} component={Confirmation} />
      <Route path={EPublicRoutes.NewPassword} component={LayoutPublic} />
      <Route path={EPublicRoutes.RestorePassword} component={LayoutPublic} />
      <Route path={EPublicRoutes.SignUp} component={LayoutPublic} />
      <Route path={EPublicRoutes.LogIn} component={LayoutPublic} />
      <Route path={EPublicRoutes.GetConfirm} component={LayoutPublic} />
      <Route path="*" component={LayoutPrivate} />
    </Switch>
  );
};
