import { AntdFormHelper } from '@axmit/antd-helpers';
import { Button, FormItem, Input } from '@axmit/clp-library';
import Typography from 'antd/es/typography';
import { Row, Col } from 'antd/es/grid';
import Form from 'antd/es/form';
import Spin from 'antd/es/spin';
import { FormComponentProps } from 'antd/es/form';
import { ColProps } from 'antd/es/grid';
import React, { FormEvent } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { EPublicRoutes } from 'common/const/Routes.const';
import { communicationAuth, IAuthConnectedProps } from 'entities/Auth/Auth.communication';
import { PasswordInput } from 'entities/Auth/components/PasswordInput';

interface IComponentProps {
  redirectToSignUp: () => void;
  pathAfterSuccess?: string;
  wrapperCol?: ColProps;
}

type AllProps = IComponentProps & IAuthConnectedProps & FormComponentProps;

const LogInFormComponent = (props: AllProps) => {
  const { t } = useTranslation();

  const formLayout = {
    labelCol: {
      span: 24
    }
  };

  const { form, authModel, redirectToSignUp, wrapperCol = { xs: 24, lg: 12 }, pathAfterSuccess } = props;
  const { getFieldDecorator } = form;

  const history = useHistory();

  const redirectToRestorePassword = () => {
    history.push(EPublicRoutes.RestorePassword);
  };

  const submit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    form.validateFields((err, values) => {
      if (err) {
        return;
      }

      props.addAuthModel({ pathAfterSuccess, ...values });
    });
  };

  return (
    <Spin spinning={authModel.loading}>
      <Typography.Title className="clp-typography-title clp-typography-title_size_md mob-text_center mb-25">
        {t('logInFormHeader')}
      </Typography.Title>

      <Form {...formLayout} className="mt-30" wrapperCol={wrapperCol} onSubmit={submit}>
        <FormItem label={t('form.labels.email')}>
          {getFieldDecorator('email', {
            rules: [
              {
                type: 'email',
                message: t('errorEmailNotEmail')
              },
              {
                required: true,
                message: t('errorRequiredDefault')
              }
            ]
          })(<Input />)}
        </FormItem>

        <PasswordInput label={t('form.labels.password')} getFieldDecorator={getFieldDecorator} fieldName="password" />

        <Button type="link" className="p-0" onClick={redirectToRestorePassword}>
          {t('logInForgotLink')}
        </Button>

        <Row align="middle" gutter={16}>
          <Col {...wrapperCol} className="mob-form-wrapper">
            <Button className="ant-btn_mob-block mob-form-wrapper login-btn" type="primary" htmlType="submit" block>
              {t('logInFormBtn')}
            </Button>
          </Col>

          <Col {...wrapperCol} className="mob-text_center">
            <Button type="link" className="pl-0 pr-0" onClick={redirectToSignUp}>
              {t('logInSignUpLink')}
            </Button>
          </Col>
        </Row>
      </Form>
    </Spin>
  );
};

export const LogInForm = communicationAuth.injector(
  Form.create<AllProps>({
    mapPropsToFields(props: AllProps) {
      const { authModel } = props;

      return AntdFormHelper.mapValidationToFields(authModel?.params, authModel?.errors);
    }
  })(LogInFormComponent)
);
