import { EPrivateRoutes, ECommonRoutes } from 'common/const';
import { LES_APP_LINK } from 'common/config';
import { RequestCardHelper } from 'entities/Requests/helpers/RequestCard.helper';
import { IRequestModel } from 'entities/Requests/Requests.models';

const getDonateLink = (request: IRequestModel, description: string, amount = 0): string => {
  const { requestNumber, giftRequestData, giftData } = request;

  const isGift = RequestCardHelper.isGift(requestNumber);

  if (isGift) {
    const giftId = giftData?.id || giftRequestData?.id;
    const params = `id=${giftId}&description=${description}&amount=${amount}`;

    return `${window.location.host}${EPrivateRoutes.TransactionRequest}?${params}`;
  }

  const employeeId = giftRequestData?.employee;
  const idPart = employeeId ? `employeeId=${employeeId}` : `userId=${giftRequestData?.user}`;
  const params = `${idPart}&description=${description}&amount=${amount}`;

  return `${LES_APP_LINK}${EPrivateRoutes.TransactionGiftRequest}?${params}`;
};

const getContributeLink = (id: string): string => {
  const params = new URLSearchParams([['gift', id]]);

  return `${window.location.origin}${ECommonRoutes.Contributor}?${params.toString()}`;
};

export const GiftTransactionsHelper = {
  getContributeLink,
  getDonateLink
};
