import React from 'react';
import { Container, LandingRow } from '@axmit/clp-library';
import imageKeyHolder from 'app/assets/images/keyHolder.png';
import { AuthForms } from 'entities/Auth/components/AuthForms';

export const AuthPage = () => {
  return (
    <Container>
      <LandingRow
        leftSlot={<AuthForms />}
        rightSlot={<img src={imageKeyHolder} alt="auth" className="max-w_100 mt-50 mb-100 keyholder-img" />}
      />

      <img src={imageKeyHolder} alt="map" className="hidden_desk w-100p mt-25 mb-30" />
    </Container>
  );
};
