import React, { useEffect } from 'react';
import { Paragraph } from '@axmit/clp-library';
import Table from 'antd/es/table';
import { useTranslation } from 'react-i18next';
import { EmptyData, Spinner } from 'common/components';
import { PaginationHelper } from 'common/helpers';
import { ITransactionsConnectedProps, communicationTransactions } from 'entities/Transactions/Transactions.communication';
import { TRANSACTIONS_PER_PAGE, usersTransactionColumns } from 'entities/Transactions/Transactions.const';

interface IComponentProps {
  userId?: string;
  employeeId?: string;
}

type AllProps = ITransactionsConnectedProps & IComponentProps;

const UserTransactionsListComponent = ({
  transactionsCollection,
  getTransactionsCollection,
  clearTransactionsCollection,
  userId,
  employeeId
}: AllProps) => {
  const { t } = useTranslation();
  useEffect(() => {
    return () => clearTransactionsCollection();
  }, [clearTransactionsCollection]);

  useEffect(() => {
    const hasParams = userId || employeeId;

    hasParams && getTransactionsCollection({ user: userId, employee: employeeId, limit: TRANSACTIONS_PER_PAGE });
  }, [getTransactionsCollection, userId, employeeId]);

  if (!userId && !employeeId) {
    return <EmptyData />;
  }

  const onChangePage = (limit: number, offset: number) => {
    getTransactionsCollection({
      user: userId,
      employee: employeeId,
      limit,
      offset
    });
  };

  const { data, loading } = transactionsCollection;
  const listPagination = PaginationHelper.getListsPagination(onChangePage, TRANSACTIONS_PER_PAGE, data?.meta.count);
  const hasData = !!transactionsCollection?.data?.data.length;

  return (
    <Spinner spinning={loading}>
      <Paragraph className="color_primary t_bold mb-30 mt-15 first-letter_capitalize" size="sm">
        {t('transactions.label')}
      </Paragraph>

      {hasData || loading ? (
        <Table
          showHeader={false}
          className="clw-table"
          size="middle"
          loading={loading}
          columns={usersTransactionColumns}
          dataSource={data?.data}
          pagination={listPagination}
          rowKey={record => record.id}
        />
      ) : (
        <EmptyData />
      )}
    </Spinner>
  );
};

export const UserTransactionsList = communicationTransactions.injector(UserTransactionsListComponent);
