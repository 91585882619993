import { HooksHelper } from '@axmit/clp-library/dist/helpers';
import { Row, Col } from 'antd/es/grid';
import { useCallback, useEffect, useMemo } from 'react';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { QuickSearch } from 'common/components';
import { ESocketActions } from 'common/const/Socket.const';
import { SocketHooks } from 'common/hooks/Socket.hooks';
import { Chat, ChannelsList } from 'entities/Messages/components';
import { communicationMessages, IMessagesConnectedProps } from 'entities/Messages/Messages.communication';
import { IChannelsModel, IMessageModel } from 'entities/Messages/Messages.models';

type AllProps = IMessagesConnectedProps;

const MessagesPageComponent = ({ getMessagesChannels, messagesChannels }: AllProps) => {
  const { socket, joinRoom, leaveRoom } = SocketHooks.useSocket();
  const dispatch = useDispatch();
  const channelId = HooksHelper.useStateBuilder<string | undefined>(undefined);
  const activeChannel = useMemo(() => {
    return messagesChannels.data?.data.find(
      item => (item.userId && item.userId === channelId.value) || item.client === channelId.value
    );
  }, [messagesChannels, channelId.value]);

  useEffect(() => {
    socket.on('clientState', (data: IChannelsModel) => {
      dispatch({ type: ESocketActions.ChannelsUpdate, payload: data });
    });

    socket.on('messageToRoom', (data: IMessageModel) => {
      dispatch({ type: ESocketActions.IncomingMessage, payload: data });
    });
  }, [socket, dispatch]);

  const selectChannel = (model: IChannelsModel) => {
    activeChannel && leaveRoom(activeChannel);
    channelId.set(model.userId || model.client || undefined);
    joinRoom(model);
  };

  const onSearch = useCallback(
    (value: string) => {
      getMessagesChannels({ quickSearch: value });
    },
    [getMessagesChannels]
  );

  const activeChannelId = activeChannel?.userId || activeChannel?.client;

  return (
    <Row className="chat-wrapper chat-wrapper_fixed-height">
      <Col xs={8}>
        <div className="chat-search chat-search_bordered">
          <QuickSearch onSearch={onSearch} labelOnInput />
        </div>

        <div id="channels" className="chat-channels-desk mb-30">
          <ChannelsList activeId={activeChannelId} selectChannel={selectChannel} elementId="channels" />
        </div>
      </Col>

      <Col xs={16}>
        <Chat channel={activeChannel} />
      </Col>
    </Row>
  );
};

export const MessagesPage = communicationMessages.injector(MessagesPageComponent);
