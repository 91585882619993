import Icon from 'antd/es/icon';
import * as React from 'react';
import Linkify from 'react-linkify';
import { FormatHelper } from 'common/helpers';
import { ImagePreview } from 'common/components';
import { IMessageContent } from 'entities/Messages/Messages.models';

interface IComponentProps extends IMessageContent {}

export const Content = ({ text = '', file, image, audio, location, video }: IComponentProps) => {
  return (
    <Linkify>
      {text}

      {image && (
        <>
          <ImagePreview url={image.url} alt={image.caption || ''} />
          {image.caption || ''}
        </>
      )}

      {location && FormatHelper.getGoogleMapsLink(location.lat, location.long)}

      {file && (
        <a download href={file.url}>
          <Icon type="file" />
        </a>
      )}

      {audio && (
        <audio controls src={audio.url} style={{ width: '100%' }}>
          <a download href={audio.url}>
            <Icon type="audio" />
          </a>
        </audio>
      )}

      {video && (
        <>
          <video className="video-preview" controls>
            <source src={video.url} />
          </video>

          {video.caption}
        </>
      )}
    </Linkify>
  );
};
