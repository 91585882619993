import React, { useEffect } from 'react';
import { compose } from 'redux';
import Typography from 'antd/es/typography';
import Spin from 'antd/es/spin';
import List from 'antd/es/list';
import { Paragraph } from '@axmit/clp-library';
import { useTranslation } from 'react-i18next';
import { communicationInvitation, IInvitationConnectedProps } from 'entities/Invitations/Invitations.communication';

interface IComponentProps {
  giftId: string;
}

type AllProps = IInvitationConnectedProps & IComponentProps;

const InviteListComponent = ({ getInvitationsCollection, invitationsCollection, giftId }: AllProps) => {
  const { t } = useTranslation();
  useEffect(() => {
    getInvitationsCollection({ gift: giftId });
  }, [getInvitationsCollection, giftId]);

  if (!invitationsCollection.data) {
    return null;
  }

  const { loading, data } = invitationsCollection;
  const isInvitationsCollectionEmpty = !data?.data.length;

  if (isInvitationsCollectionEmpty) {
    return null;
  }

  return (
    <Spin spinning={loading}>
      <Typography.Title className="clp-typography-title clp-typography-title_size_xs mb-15" level={3}>
        {t('inviteListHeader')}
      </Typography.Title>

      <List
        className="mob-form-wrapper"
        dataSource={data?.data || []}
        renderItem={({ email, id }) => (
          <Paragraph size="xs" key={id}>
            {email}
          </Paragraph>
        )}
      />
    </Spin>
  );
};

const containers = compose(communicationInvitation.injector);

export const InviteList = containers(InviteListComponent);
