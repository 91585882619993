import * as React from 'react';
import { Button } from '@axmit/clp-library';
import { ButtonProps } from 'antd/es/button';

interface IComponentProps extends ButtonProps {}

export const MobFormButton = ({ className, children, ...rest }: IComponentProps) => {
  return (
    <div className="mob-form-wrapper">
      <Button className={`ant-btn_mob-block ${className}`} {...rest}>
        {children}
      </Button>
    </div>
  );
};
