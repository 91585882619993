import * as React from 'react';
import { Route, Switch } from 'react-router';
import { EBackOfficeRoutes } from 'common/const';
import { RequestEditPage } from 'entities/Requests/components/RequestEditPage';
import { GiftRequests } from 'entities/Requests/components/GiftRequests';

export const GiftRequestsPage = () => {
  return (
    <Switch>
      <Route path={`${EBackOfficeRoutes.RequestEditPage}/:id`} component={RequestEditPage} exact />
      <Route path="*" component={GiftRequests} />
    </Switch>
  );
};
