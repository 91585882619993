import React from 'react';
import { useHistory, useLocation } from 'react-router';
import { EPrivateRoutes, EPublicRoutes } from 'common/const';
import { LogInForm } from 'entities/Auth/components/LogInForm';

export const AuthLogInForm = () => {
  const history = useHistory();
  const { state } = useLocation<{ redirectAfterLogin?: string }>();

  const redirectToSignUp = () => {
    history.push(EPublicRoutes.SignUp);
  };

  return <LogInForm redirectToSignUp={redirectToSignUp} pathAfterSuccess={state?.redirectAfterLogin || EPrivateRoutes.Main} />;
};
