import React from 'react';
import { Button } from '@axmit/clp-library';
import Typography from 'antd/es/typography';
import { useTranslation } from 'react-i18next';
import julesMap from 'app/assets/images/julesMap.png';

interface IComponentProps {
  handleNew: () => void;
  handleSaved: () => void;
}

export const NewGiftFormSavedOrNew = ({ handleNew, handleSaved }: IComponentProps) => {
  const { t } = useTranslation();

  return (
    <>
      <img src={julesMap} alt="type" className="aside-slide hidden_mob" />

      <div className="mb-50 hidden_mob" />

      <Typography.Text className="clp-typography-text clp-typography-text_size_lg mb-30">
        {t('newGiftWasSavedDescription')}
      </Typography.Text>

      <div className="mob-form-wrapper mb-15 mob-text_center">
        <Button className="pl-30 pr-30 mr-5 mb-10" onClick={handleSaved}>
          {t('newGiftWasSavedBtnEdit')}
        </Button>

        <Button className=" pl-30 pr-30" onClick={handleNew}>
          {t('newGiftWasSavedBtnNew')}
        </Button>
      </div>
    </>
  );
};
