import React, { useEffect } from 'react';
import { compose } from 'redux';
import List from 'antd/es/list';
import { PaginationHelper } from 'common/helpers';
import { communicationContributors, IContributorsConnectedProps } from 'entities/Contributors/Contributors.communication';
import { ContributorsCommentsItem } from 'entities/Contributors/components';
import { CONTRIBUTORS_COMMENTS_PER_PAGE } from 'entities/Contributors/Contributors.consts';

interface IComponentProps {
  giftId: string;
}

type AllProps = IContributorsConnectedProps & IComponentProps;

const ContributorsCommentsComponent = ({ getContributorsCollection, giftId, contributorsCollection }: AllProps) => {
  useEffect(() => {
    getContributorsCollection({ status: 'paid', gift: giftId, limit: CONTRIBUTORS_COMMENTS_PER_PAGE });
  }, [getContributorsCollection, giftId]);

  const { data: contributors } = contributorsCollection;

  if (!contributors || !contributors.meta.count) {
    return null;
  }

  const onChangePage = (limit: number, offset: number) => {
    getContributorsCollection({
      gift: giftId,
      limit,
      offset
    });
  };

  return (
    <List
      pagination={PaginationHelper.getListsPagination(onChangePage, CONTRIBUTORS_COMMENTS_PER_PAGE, contributors.meta.count)}
      dataSource={contributors.data}
      renderItem={({ id, name, message }) => <ContributorsCommentsItem name={name} message={message} key={id} />}
    />
  );
};

const containers = compose(communicationContributors.injector);

export const ContributorsComments = containers(ContributorsCommentsComponent);
