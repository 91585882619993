import { ERequestPriority, ERequestStatus } from 'entities/Requests/Requests.models';
import { EGiftType } from 'entities/Gift';

type TBadgeIcon = 'success' | 'processing' | 'default' | 'error' | 'warning';

const getStatusIcon = (status: ERequestStatus): TBadgeIcon => {
  switch (status) {
    case ERequestStatus.InProgress:
    case ERequestStatus.New:
      return 'success';

    default:
      return 'default';
  }
};

const getPriorityIcon = (value: ERequestPriority): TBadgeIcon => {
  switch (value) {
    case ERequestPriority.Low:
      return 'success';
    case ERequestPriority.Normal:
      return 'warning';
    case ERequestPriority.High:
      return 'error';

    default:
      return 'default';
  }
};

const getGiftType = (type: EGiftType) => {
  return type === EGiftType.Request ? 'Request' : 'Gift';
};

const isGift = (requestNumber: string): boolean => requestNumber.startsWith('G');

export const RequestCardHelper = {
  isGift,
  getGiftType,
  getPriorityIcon,
  getStatusIcon
};
