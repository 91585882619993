import React, { useEffect } from 'react';
import { compose } from 'redux';
import Typography from 'antd/es/typography';
import Table from 'antd/es/table';
import { useTranslation } from 'react-i18next';
import { PaginationHelper, TableFormatHelper } from 'common/helpers';
import { communicationContributors, IContributorsConnectedProps } from 'entities/Contributors/Contributors.communication';
import { giftContributorsColumns, CONTRIBUTORS_PER_PAGE } from 'entities/Contributors/consts';

interface IComponentProps {
  giftId: string;
}

type AllProps = IContributorsConnectedProps & IComponentProps;

const ContributorsListComponent = ({ getContributorsCollection, contributorsCollection, giftId }: AllProps) => {
  const { t } = useTranslation();
  useEffect(() => {
    getContributorsCollection({ gift: giftId, limit: CONTRIBUTORS_PER_PAGE });
  }, [getContributorsCollection, giftId]);

  if (!contributorsCollection.data) {
    return null;
  }

  const { loading, data } = contributorsCollection;

  if (!data.data || !data.data.length) {
    return null;
  }

  const onChangePage = (limit: number, offset: number) => {
    getContributorsCollection({
      gift: giftId,
      limit,
      offset
    });
  };

  const listPagination = PaginationHelper.getListsPagination(onChangePage, CONTRIBUTORS_PER_PAGE, data?.meta.count);

  return (
    <>
      <Typography.Title className="clp-typography-title clp-typography-title_size_xs mb-15" level={3}>
        {t('contributorsList.header')}
      </Typography.Title>

      <Table
        scroll={{ x: true }}
        className="clw-table clw-table_first-col-bold mb-30"
        size="middle"
        bordered
        loading={loading}
        dataSource={data?.data}
        pagination={listPagination}
        columns={TableFormatHelper.translateTitles(giftContributorsColumns, t)}
        rowKey={record => record.id}
      />
    </>
  );
};

const containers = compose(communicationContributors.injector);

export const ContributorsList = containers(ContributorsListComponent);
