import React, { useEffect } from 'react';
import { compose } from 'redux';
import Typography from 'antd/es/typography';
import { Col } from 'antd/es/grid';
import Form from 'antd/es/form';
import { Button, Container, LandingRow, Paragraph } from '@axmit/clp-library';
import { FormComponentProps } from 'antd/es/form';
import { AntdFormHelper } from '@axmit/antd-helpers';
import { useTranslation } from 'react-i18next';
import { InputForm, Spinner } from 'common/components';
import julesSecure from 'app/assets/images/julesSecure.png';
import julesSecureMobile from 'app/assets/images/julesSecureMobile.png';
import { communicationAuth, IAuthConnectedProps } from 'entities/Auth/Auth.communication';
import { communicationGift, IGiftConnectedProps } from 'entities/Gift/Gift.communication';

type AllProps = IGiftConnectedProps & IAuthConnectedProps & FormComponentProps;

const ActivateGiftComponent = (props: AllProps) => {
  const { t } = useTranslation();
  const { form, checkGiftCode, clearGiftCode, giftCode } = props;
  const { getFieldDecorator } = form;

  useEffect(() => {
    clearGiftCode();
  }, [clearGiftCode]);

  const submit = () => {
    form.validateFields((err, values) => {
      if (err) {
        return;
      }

      checkGiftCode(values);
    });
  };

  return (
    <Container>
      <LandingRow
        leftSlot={
          <Form hideRequiredMark>
            <Spinner spinning={giftCode.loading}>
              <Typography.Title className="clp-typography-title clp-typography-title_size_md mob-text_center mb-25">
                {t('activateGift.header')}
              </Typography.Title>

              <Col lg={14}>
                <Paragraph size="sm" className="mb-30">
                  {t('activateGift.message')}
                </Paragraph>
              </Col>

              <Col lg={11}>
                <InputForm
                  getFieldDecorator={getFieldDecorator}
                  fieldName="code"
                  label={t('activateGift.codeFieldLabel')}
                  required
                />
              </Col>

              <Col span={24}>
                <Button type="primary" onClick={submit} className="ant-btn_mob-block mt-30">
                  {t('activateGift.submit')}
                </Button>
              </Col>

              <Col span={24}>
                <img src={julesSecureMobile} alt="lock" className="img-secure-mob hidden_desk" />
              </Col>
            </Spinner>
          </Form>
        }
        rightSlot={<img src={julesSecure} alt="lock" className="img-secure-desk" />}
      />
    </Container>
  );
};

const containers = compose(
  Form.create<AllProps>({
    mapPropsToFields(props: AllProps) {
      const { giftCode } = props;

      return AntdFormHelper.mapValidationToFields(giftCode?.params, giftCode?.errors);
    }
  }),
  communicationAuth.injector,
  communicationGift.injector
);

export const ActivateGift = containers(ActivateGiftComponent);
