import React from 'react';
import { HeaderBasic } from 'entities/Layout/HeaderBasic';
import { HeaderBackOffice } from 'entities/Layout/HeaderBackOffice';
import { communicationUsers, IUsersConnectedProps } from 'entities/User/Users.communication';
import { EUserRoles } from 'entities/User/Users.consts';

type AllProps = IUsersConnectedProps;

const HeaderComponent = (props: AllProps) => {
  const { usersCurrent } = props;
  const isBackOfficeUser = usersCurrent.data?.role === EUserRoles.Administrator || usersCurrent.data?.role === EUserRoles.Manager;

  return isBackOfficeUser ? <HeaderBackOffice /> : <HeaderBasic />;
};

export const Header = communicationUsers.injector(HeaderComponent);
