import React, { useEffect } from 'react';
import { useParams, useLocation } from 'react-router';
import { Tabs } from '@axmit/clp-library';
import AntTabs from 'antd/es/tabs';
import { useTranslation } from 'react-i18next';
import { BackOfficeBreadcrumb, ColumnsContent } from 'common/components';
import { EBackOfficeRoutes } from 'common/const';
import { FormatHelper } from 'common/helpers';
import { communicationCompanies, ICompaniesConnectedProps } from 'entities/Companies/Companies.communication';
import { ICompanyModel } from 'entities/Companies/Companies.models';
import { CompaniesEmployees } from 'entities/Companies/components';
import { CompanyInfo } from 'entities/Companies/components/CompanyInfo';

type AllProps = ICompaniesConnectedProps;

const CompanyPageComponent = ({ getCompaniesModel, clearCompaniesModel, companiesModel }: AllProps) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { state } = useLocation<{ company?: ICompanyModel }>();

  useEffect(() => {
    id && getCompaniesModel(id);

    return () => {
      clearCompaniesModel();
    };
  }, [getCompaniesModel, clearCompaniesModel, id]);

  const company = companiesModel.data || state?.company;

  const name = company?.name || '';
  const createdAt = company?.createdAt;
  const createdBy = company?.userName;
  const email = company?.userEmail;
  const phone = company?.userPhone;

  return (
    <>
      <BackOfficeBreadcrumb
        previous={t('backOfficeDashboard.tab.companies')}
        previousHref={EBackOfficeRoutes.Companies}
        current={name}
      />

      <ColumnsContent
        data={[
          { key: 'createdBy', value: createdBy || '-' },
          { key: 'form.labels.phone', value: phone || '-' },
          { key: 'form.labels.email', value: email || '-' },
          { key: 'form.labels.createdAt', value: createdAt ? FormatHelper.getDateWithYear(createdAt) : '-' }
        ]}
      />

      <Tabs className="mt-20" type="card">
        <AntTabs.TabPane key="transfer" tab={t('transfer')}>
          {company && <CompanyInfo companyId={company.id} />}
        </AntTabs.TabPane>

        <AntTabs.TabPane key="accounts" tab={t('accounts')}>
          {company && <CompaniesEmployees companyId={company.id} />}
        </AntTabs.TabPane>
      </Tabs>
    </>
  );
};

export const CompanyPage = communicationCompanies.injector(CompanyPageComponent);
