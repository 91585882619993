import Table from 'antd/es/table';
import React, { useEffect } from 'react';
import { compose } from 'redux';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { EmptyData, Spinner } from 'common/components';
import { PaginationHelper, TableFormatHelper } from 'common/helpers';
import { EBackOfficeRoutes } from 'common/const';
import { EMPLOYEES_PER_PAGE, employeesCompanyColumns } from 'entities/Companies/Companies.consts';
import { communicationEmployees, IEmployeesConnectedProps } from 'entities/Employees/Employees.communication';

interface IComponentProps {
  companyId: string;
}

type AllProps = IComponentProps & IEmployeesConnectedProps;

const CompaniesEmployeesComponent = ({
  companyId,
  getEmployeesCompanyCollection,
  clearEmployeesCompanyCollection,
  employeesCompanyCollection
}: AllProps) => {
  const { data, loading } = employeesCompanyCollection;
  const { t } = useTranslation();
  const history = useHistory();

  useEffect(() => {
    return () => clearEmployeesCompanyCollection();
  }, [clearEmployeesCompanyCollection]);

  useEffect(() => {
    getEmployeesCompanyCollection({ company: companyId, limit: EMPLOYEES_PER_PAGE });
  }, [getEmployeesCompanyCollection, companyId]);

  if (!employeesCompanyCollection?.data?.data.length && !loading) {
    return <EmptyData />;
  }

  const onChangePage = (limit: number, offset: number) => {
    getEmployeesCompanyCollection({ company: companyId, limit, offset });
  };

  const listPagination = PaginationHelper.getListsPagination(onChangePage, EMPLOYEES_PER_PAGE, data?.meta.count);

  return (
    <Spinner spinning={loading}>
      <Table
        className="clw-table clw-table_first-col-bold mb-25 mt-25"
        size="middle"
        loading={loading}
        columns={TableFormatHelper.translateTitles(employeesCompanyColumns, t)}
        dataSource={data?.data}
        pagination={listPagination}
        rowKey={record => record.id}
        scroll={{ x: true }}
        onRowClick={employee => {
          history.push(`${EBackOfficeRoutes.Clients}/${employee.user}`);
        }}
        rowClassName={() => 'c-pointer'}
        bordered
      />
    </Spinner>
  );
};

const containers = compose(communicationEmployees.injector);

export const CompaniesEmployees = containers(CompaniesEmployeesComponent);
