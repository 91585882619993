import React, { useEffect } from 'react';
import { compose } from 'redux';
import { Container } from '@axmit/clp-library';
import { useParams } from 'react-router';
import Typography from 'antd/es/typography';
import { Row, Col } from 'antd/es/grid';
import Spin from 'antd/es/spin';
import { HooksHelper } from '@axmit/clp-library/dist/helpers';
import { useTranslation } from 'react-i18next';
import { CLE_EMAIL, CLE_PHONE } from 'common/config';
import { ScrollHooks } from 'common/hooks';
import { communicationGift, IGiftConnectedProps } from 'entities/Gift/Gift.communication';
import { ContactForm } from 'entities/Gift/components/Discover/ContactForm';
import { EGiftStatus } from 'entities/Gift/Gift.models';
import { ContactInfo } from 'entities/Gift/components/Discover/ContactInfo';
import { ContributorsComments } from 'entities/Contributors/components';

type AllProps = IGiftConnectedProps;

const GiftViewPageComponent = ({ getGiftModel, giftModel, clearGiftModel }: AllProps) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const showForm = HooksHelper.useStateBuilder(false);

  ScrollHooks.useScrollToTop();

  useEffect(() => {
    id && getGiftModel(id);

    return () => clearGiftModel();
  }, [getGiftModel, clearGiftModel, id]);

  if (!giftModel.data) {
    return giftModel.loading ? (
      <div className="ta-c">
        <Spin spinning={giftModel.loading} />
      </div>
    ) : null;
  }

  return (
    <Container>
      <Spin spinning={giftModel.loading}>
        <Row className="mt-30 mb-100" type="flex" style={{ flexDirection: 'row-reverse' }} align="top" gutter={100}>
          <Col xs={24} md={12}>
            {showForm.value || giftModel.data?.status !== EGiftStatus.Activated ? (
              <ContactForm giftId={id} showForm={showForm.set} />
            ) : (
              <ContactInfo
                date={giftModel.data.contactDate}
                type={giftModel.data.contactType}
                balance={giftModel.data.fullBalance || 0}
                showForm={showForm.set}
              />
            )}

            <Typography.Text className="clp-typography-text">
              {t('discoveredGift.contacts.start')} <b>{CLE_PHONE}</b> {t('discoveredGift.contacts.middle')} <b>{CLE_EMAIL}</b>{' '}
              {t('discoveredGift.contacts.end')}
            </Typography.Text>

            <div className="text-sign mt-30 mb-30">{t('discoveredGift.sign')}</div>
          </Col>

          <Col xs={24} md={12}>
            <Typography.Title className="clp-typography-title clp-typography-title_size_md mob-text_center mb-25" level={3}>
              {t('discoveredGift.header')}
            </Typography.Title>

            <Typography.Text className="clp-typography-text clp-typography-text_size_lg mb-15">
              {giftModel.data?.meta.message}
            </Typography.Text>

            {id && <ContributorsComments giftId={id} />}
          </Col>
        </Row>
      </Spin>
    </Container>
  );
};

const containers = compose(communicationGift.injector);

export const GiftViewPage = containers(GiftViewPageComponent);
