import React, { useState } from 'react';
import Icon from 'antd/es/icon';
import { Col, Row } from 'antd/es/grid';
import Form, { FormComponentProps } from 'antd/es/form';
import { Button, CardInfo, CardStepByStep, Checkbox, FormItem, Input, Paragraph } from '@axmit/clp-library';
import { useTranslation } from 'react-i18next';
import { SeparatedModalContent } from 'common/components';
import julesMapBaggage from 'app/assets/images/julesMapBaggage.png';
import { IGiftCreateViewModel } from 'entities/Gift';
import { CountriesSelector } from 'entities/Countries/components/CountriesSelector';
import { ICountriesCreateModel } from 'entities/Countries/Countries.models';

interface IComponentProps {
  updateGift: (data: Partial<IGiftCreateViewModel>) => void;
  handlePreviousSlide: () => void;
  handleNextSlide: () => void;
  gift: Partial<IGiftCreateViewModel>;
}

type AllProps = IComponentProps & FormComponentProps;

export const NewGiftFormDiscoveryComponent = ({ form, updateGift, gift, handlePreviousSlide, handleNextSlide }: AllProps) => {
  const { t } = useTranslation();
  const { getFieldDecorator } = form;
  const [country, setCountry] = useState<ICountriesCreateModel | undefined>(gift.country);

  const submit = () => {
    form.validateFields(err => {
      if (err) {
        return;
      }

      handleNextSlide();
    });
  };

  return (
    <SeparatedModalContent
      rightSlotFooter={
        <Row type="flex" justify="space-between" className="mt-20 mb-10">
          <Button size="small" onClick={handlePreviousSlide}>
            <span>
              <Icon type="left" className="mr-8" />
              {t('newGiftDefaultBtnBack')}
            </span>
          </Button>

          <Button type="primary" size="small" onClick={submit}>
            <span>
              {t('newGiftDefaultBtnNext')}
              <Icon type="right" className="ml-8" />
            </span>
          </Button>
        </Row>
      }
    >
      <img src={julesMapBaggage} alt="type" className="aside-slide hidden_mob aside-slide_position_center" />

      <Form
        onBlur={() => {
          const countryResult = gift.isSendByEmail ? undefined : country;

          updateGift({ ...form.getFieldsValue(), country: countryResult });
        }}
      >
        <CardStepByStep label={t('newGiftDiscoveryHeader')} subLabel={t('step4')} className="mb-15" />

        <CardInfo
          headerSize="sm"
          content={
            <Paragraph size="xs" className="mb-20 mt-15">
              {t('newGiftDiscoveryMessage')}
            </Paragraph>
          }
          header={t('newGiftDiscoverySubHeader')}
        />

        <Checkbox
          className="pb-20"
          defaultChecked={gift.isSendByEmail}
          onChange={event => updateGift({ isSendByEmail: event.target.checked })}
        >
          {t('newGiftDiscoveryCheckbox')}
        </Checkbox>

        {!gift.isSendByEmail && (
          <div className="recipient-data-form">
            <Row gutter={30}>
              <Col md={12}>
                <FormItem label={t('form.labels.address1')}>
                  {getFieldDecorator('street', {
                    initialValue: gift.street,
                    rules: [
                      {
                        required: true,
                        message: t('errorRequiredDefault')
                      }
                    ]
                  })(<Input />)}
                </FormItem>
              </Col>

              <Col md={12}>
                <FormItem label={t('form.labels.address2')}>
                  {getFieldDecorator('house', {
                    initialValue: gift.house
                  })(<Input />)}
                </FormItem>
              </Col>
            </Row>
            <Row gutter={30}>
              <Col md={12}>
                <FormItem label={t('form.labels.city')}>
                  {getFieldDecorator('city', {
                    initialValue: gift.city,
                    rules: [
                      {
                        required: true,
                        message: t('errorRequiredDefault')
                      }
                    ]
                  })(<Input />)}
                </FormItem>
              </Col>

              <Col md={12}>
                <FormItem label={t('form.labels.state')}>
                  {getFieldDecorator('state', {
                    initialValue: gift.state,
                    rules: [
                      {
                        required: true,
                        message: t('errorRequiredDefault')
                      }
                    ]
                  })(<Input />)}
                </FormItem>
              </Col>
            </Row>
            <Row gutter={30}>
              <Col md={12}>
                <CountriesSelector fieldName="country" form={form} initialModel={country} updateCountry={setCountry} />
              </Col>

              <Col md={12}>
                <FormItem label={t('form.labels.zipCode')}>
                  {getFieldDecorator('zipCode', {
                    initialValue: gift.zipCode,
                    rules: [
                      {
                        required: true,
                        message: t('errorRequiredDefault')
                      }
                    ]
                  })(<Input />)}
                </FormItem>
              </Col>
            </Row>
          </div>
        )}
      </Form>
    </SeparatedModalContent>
  );
};

export const NewGiftFormDiscovery = Form.create<AllProps>()(NewGiftFormDiscoveryComponent);
