import { AnyAction, Reducer } from 'redux';
import { combineReducers } from 'redux';
import { connectRouter, RouterState } from 'connected-react-router';
import { History } from 'history';
import { communicationCompanies } from 'entities/Companies/Companies.communication';
import { communicationClientNotes } from 'entities/ClientNotes/ClientNotes.communication';
import { communicationMessages } from 'entities/Messages/Messages.communication';
import { communicationAuth } from 'entities/Auth/Auth.communication';
import { communicationUsers } from 'entities/User/Users.communication';
import { communicationSupport } from 'entities/Support/Support.communication';
import { communicationPayment } from 'entities/Payment/Payment.communication';
import { communicationGift } from 'entities/Gift/Gift.communication';
import { communicationInvitation } from 'entities/Invitations/Invitations.communication';
import { communicationContributors } from 'entities/Contributors/Contributors.communication';
import { communicationRequests } from 'entities/Requests/Requests.communication';
import { communicationGiftTransactions } from 'entities/GiftTransactions/GiftTransactions.communication';
import { communicationEmployees } from 'entities/Employees/Employees.communication';
import { communicationGiftRequests } from 'entities/GiftRequests/GiftRequests.communication';
import { communicationTransactions } from 'entities/Transactions/Transactions.communication';
import { communicationRequestExpenses } from 'entities/RequestExpenses/RequestExpenses.communication';
import { communicationCountries } from 'entities/Countries/Countries.communication';

type RoutingReducer = Reducer<RouterState, AnyAction>;

export interface IApplicationState {
  routing?: RoutingReducer | null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

export const reducers = (history: History) =>
  combineReducers<IApplicationState>({
    router: connectRouter(history),
    ...communicationGiftTransactions.reducers,
    ...communicationTransactions.reducers,
    ...communicationAuth.reducers,
    ...communicationSupport.reducers,
    ...communicationContributors.reducers,
    ...communicationInvitation.reducers,
    ...communicationPayment.reducers,
    ...communicationGift.reducers,
    ...communicationGiftRequests.reducers,
    ...communicationRequests.reducers,
    ...communicationRequestExpenses.reducers,
    ...communicationMessages.reducers,
    ...communicationClientNotes.reducers,
    ...communicationCompanies.reducers,
    ...communicationEmployees.reducers,
    ...communicationCountries.reducers,
    ...communicationUsers.reducers
  });
