import React from 'react';
import Spin from 'antd/es/spin';
import { HooksHelper } from '@axmit/clp-library/dist/helpers';
import { communicationPayment, IGiftPaymentCalculatorView, IPaymentConnectedProps } from 'entities/Payment';
import { CheckoutForm, CurrencyForm } from 'entities/Payment/components';

interface IComponentProps {
  minAmount?: number;
  successCallback?: () => void;
  amountInit?: number;
  giftId: string;
  isOwner?: boolean;
  calculateParams?: IGiftPaymentCalculatorView;
  amountWithFee?: number;
  disableAmount?: boolean;
}

type AllProps = IPaymentConnectedProps & IComponentProps;

const GiftCheckoutFormComponent = (props: AllProps) => {
  const {
    paymentModel,
    giftId,
    minAmount,
    successCallback,
    amountInit,
    isOwner,
    addOwnersPaymentModel,
    addPaymentModel,
    amountWithFee = amountInit,
    disableAmount,
    calculateParams
  } = props;

  const amount = HooksHelper.useStateBuilder<number | undefined>(amountInit);
  const currencyIsValid = HooksHelper.useStateBuilder(true);
  const stripeLoading = HooksHelper.useStateBuilder(false);

  const add = isOwner ? addOwnersPaymentModel : addPaymentModel;

  return (
    <Spin spinning={paymentModel.loading || stripeLoading.value}>
      <CurrencyForm
        calculateParams={calculateParams}
        amount={amount.value}
        setAmount={amount.set}
        setValid={currencyIsValid.set}
        minAmount={minAmount}
        disableAmount={disableAmount}
      />

      <CheckoutForm
        add={params => add({ ...params, giftId })}
        amount={amountWithFee}
        currencyIsValid={currencyIsValid.value}
        successCallback={successCallback}
        setStripeLoading={stripeLoading.set}
      />
    </Spin>
  );
};

export const GiftCheckoutForm = communicationPayment.injector(GiftCheckoutFormComponent);
