import React from 'react';
import Typography from 'antd/es/typography';
import Form from 'antd/es/form';
import Spin from 'antd/es/spin';
import message from 'antd/es/message';
import { Button, FormItem, Input } from '@axmit/clp-library';
import { compose } from 'redux';
import { FormComponentProps } from 'antd/es/form';
import { HooksHelper } from '@axmit/clp-library/dist/helpers';
import { useTranslation } from 'react-i18next';
import { RouterHelper } from 'common/helpers';
import { communicationAuth, IAuthConnectedProps } from 'entities/Auth/Auth.communication';
import { PasswordValidationRules } from 'entities/Auth/Auth.consts';

type AllProps = FormComponentProps & IAuthConnectedProps;

const formLayout = {
  labelCol: {
    span: 24
  },
  wrapperCol: {
    md: 12
  }
};

const NewPasswordFormComponent = (props: AllProps) => {
  const { t } = useTranslation();
  const { form, authRestoreModel } = props;
  const { getFieldDecorator } = form;
  const query = RouterHelper.useQuery();

  const confirmDirty = HooksHelper.useStateBuilder(false);

  const submit = () => {
    const code = query.get('code');

    if (!code) {
      message.error(t('validationErrorNewPasswordHaveNoCode'));

      return;
    }

    form.validateFields((err, values) => {
      if (err) {
        return;
      }

      props.updateAuthRestoreModel({ password: values.password, code: code });
    });
  };

  const compareToFirstPassword = (rule: string, value: string, callback: Function) => {
    if (value && value !== form.getFieldValue('password')) {
      return callback(t('validationErrorNewPasswordInconsistentPasswords'));
    }

    return callback();
  };

  const validateToNextPassword = (rule: string, value: string, callback: Function) => {
    if (value && confirmDirty.value) {
      form.validateFields(['confirm'], { force: true });
    }

    return callback();
  };

  const handleConfirmBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const { value } = e.target;

    confirmDirty.set(prevValue => prevValue || !value);
  };

  return (
    <Spin spinning={authRestoreModel.loading}>
      <Typography.Title className="clp-typography-title clp-typography-title_size_md mob-text_center mb-25">
        {t('newPassHeader')}
      </Typography.Title>

      <Form {...formLayout} className="mt-30">
        <FormItem label={t('form.labels.password')} hasFeedback>
          {getFieldDecorator('password', {
            rules: [
              ...PasswordValidationRules,
              {
                validator: validateToNextPassword
              }
            ]
          })(<Input type="password" />)}
        </FormItem>

        <FormItem label={t('form.labels.passwordConfirm')} hasFeedback>
          {getFieldDecorator('confirm', {
            rules: [
              {
                required: true,
                message: t('errorPasswordConfirmation')
              },
              {
                validator: compareToFirstPassword
              }
            ]
          })(<Input type="password" onBlur={handleConfirmBlur} />)}
        </FormItem>

        <FormItem>
          <Button type="primary" block htmlType="submit" onClick={submit}>
            {t('newPassSubmit')}
          </Button>
        </FormItem>
      </Form>
    </Spin>
  );
};

const containers = compose(communicationAuth.injector, Form.create<AllProps>());

export const NewPasswordForm = containers(NewPasswordFormComponent);
