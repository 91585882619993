import { useEffect } from 'react';
import { communicationAuth, IAuthConnectedProps } from 'entities/Auth/Auth.communication';

type AllProps = IAuthConnectedProps;

const LogoutComponent = ({ deleteAuthModel }: AllProps) => {
  useEffect(() => {
    deleteAuthModel();
  }, [deleteAuthModel]);
  return null;
};

export const Logout = communicationAuth.injector(LogoutComponent);
