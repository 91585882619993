import * as React from 'react';
import { Route, Switch } from 'react-router';
import { EBackOfficeRoutes } from 'common/const';
import { Clients } from 'entities/User/components/Clients';
import { ClientsEdit } from 'entities/User/components/ClientsEdit';

export const ClientsPage = () => {
  return (
    <Switch>
      <Route path={`${EBackOfficeRoutes.Clients}/:id`} component={ClientsEdit} exact />
      <Route path="*" component={Clients} />
    </Switch>
  );
};
