import React, { useEffect, useState } from 'react';
import { CardInfo, Paragraph, StepsNumerable } from '@axmit/clp-library';
import { useHistory, useLocation } from 'react-router';
import { getCreds } from '@axmit/axios-patch-jwt';
import { HooksHelper } from '@axmit/clp-library/dist/helpers';
import Typography from 'antd/es/typography';
import { useTranslation } from 'react-i18next';
import { LOCAL_STORAGE_NEW_GIFT, EPrivateRoutes, LOCAL_STORAGE_NEW_GIFT_CLOSED } from 'common/const';
import { SeparatedModal, Spinner } from 'common/components';
import logo from 'app/assets/images/logo.png';
import {
  NewGiftAnauthModal,
  NewGiftFormActivation,
  NewGiftFormDiscovery,
  NewGiftFormExperience,
  NewGiftFormIdea,
  NewGiftFormIdentify,
  NewGiftFormType
} from 'entities/Gift/components';
import { communicationGift, IGiftConnectedProps, IGiftCreateViewModel } from 'entities/Gift';
import { LogInForm, SignUpForm } from 'entities/Auth/components';
import { NewGiftFormSavedOrNew } from 'entities/Gift/components/NewGiftFormSavedOrNew';
import { NewGiftFormCalculator } from 'entities/Gift/components/NewGiftFormCalculator';

enum ELastSubStep {
  Activation,
  Calculator,
  Identify,
  login,
  SignUp
}

type AllProps = IGiftConnectedProps;

const NewGiftComponent = ({
  addGiftCreateModel,
  addGiftViewModel,
  giftCreateModel,
  giftViewModel,
  clearGiftViewModel,
  clearGiftCreateModel
}: AllProps) => {
  const { t } = useTranslation();
  const { state } = useLocation<{ hideNewOrEditChoice?: boolean }>();
  const [haveSavedGift, updateHaveSavedGift] = useState<boolean | undefined>(undefined);
  const currentStep = HooksHelper.useStateBuilder(0);
  const lastSubStep = HooksHelper.useStateBuilder<ELastSubStep>(ELastSubStep.Activation);
  const history = useHistory();

  useEffect(() => {
    const gift = localStorage.getItem(LOCAL_STORAGE_NEW_GIFT);

    updateHaveSavedGift(state?.hideNewOrEditChoice ? false : !!gift);
    gift && addGiftViewModel(JSON.parse(gift));
  }, [addGiftViewModel, updateHaveSavedGift, state]);

  const submit = async () => {
    const creds = await getCreds();

    if (creds.access) {
      giftViewModel.data && addGiftCreateModel(giftViewModel.data);
    } else {
      localStorage.setItem(LOCAL_STORAGE_NEW_GIFT, JSON.stringify(giftViewModel.data));
      localStorage.removeItem(LOCAL_STORAGE_NEW_GIFT_CLOSED);
      lastSubStep.set(ELastSubStep.Identify);
    }
  };

  const redirectToSignUp = () => {
    lastSubStep.set(ELastSubStep.SignUp);
  };

  const redirectToLogIn = () => {
    lastSubStep.set(ELastSubStep.login);
  };

  const handlePreviousSlide = () => {
    currentStep.set(prevState => prevState - 1);
  };

  const handlePreviousSlideForUnAuth = () => {
    switch (lastSubStep.value) {
      case ELastSubStep.Identify: {
        lastSubStep.set(ELastSubStep.Calculator);
        break;
      }
      case ELastSubStep.SignUp:
      case ELastSubStep.login: {
        lastSubStep.set(ELastSubStep.Identify);
        break;
      }
    }
  };

  const handleNextSlide = () => {
    currentStep.set(prevState => prevState + 1);
  };

  const updateGift = (data: Partial<IGiftCreateViewModel>) => {
    addGiftViewModel({ ...giftViewModel.data, ...data });
  };

  const renderLastStep = (): JSX.Element => {
    switch (lastSubStep.value) {
      case ELastSubStep.Activation:
        return (
          <NewGiftFormActivation
            updateGift={updateGift}
            gift={giftViewModel.data || {}}
            handlePreviousSlide={handlePreviousSlide}
            handleNextSlide={() => {
              lastSubStep.set(ELastSubStep.Calculator);
            }}
            loading={giftCreateModel.loading}
          />
        );
      case ELastSubStep.Calculator:
        return (
          <NewGiftFormCalculator
            loading={giftViewModel.loading || giftCreateModel.loading}
            gift={giftViewModel.data || {}}
            handlePreviousSlide={() => {
              lastSubStep.set(ELastSubStep.Activation);
            }}
            handleNextSlide={submit}
          />
        );
      case ELastSubStep.Identify:
        return (
          <NewGiftAnauthModal handlePreviousSlide={handlePreviousSlideForUnAuth} message={giftViewModel.data?.activation}>
            <NewGiftFormIdentify
              redirectToSignUp={redirectToSignUp}
              redirectToLogIn={redirectToLogIn}
              name={giftViewModel.data?.name}
            />
          </NewGiftAnauthModal>
        );
      case ELastSubStep.login:
        return (
          <NewGiftAnauthModal handlePreviousSlide={handlePreviousSlideForUnAuth} message={giftViewModel.data?.activation}>
            <LogInForm redirectToSignUp={redirectToSignUp} wrapperCol={{ xl: 12 }} />
          </NewGiftAnauthModal>
        );
      case ELastSubStep.SignUp:
        return (
          <NewGiftAnauthModal handlePreviousSlide={handlePreviousSlideForUnAuth} message={giftViewModel.data?.activation}>
            <CardInfo
              header={
                <Typography.Title className="clp-typography-title clp-typography-title_size_md mob-text_center" level={2}>
                  {t('newGiftSignUpHeader')}
                </Typography.Title>
              }
              content={
                <Paragraph size="xs" className="mb-20 mt-15">
                  {t('newGiftSignUpMessage')}
                </Paragraph>
              }
            />

            <SignUpForm redirectToLogIn={redirectToLogIn} withSmallBtns />
          </NewGiftAnauthModal>
        );
    }
  };

  const slides = [
    {
      component: (
        <NewGiftFormType updateGift={updateGift} handleNextSlide={handleNextSlide} currency={giftViewModel.data?.currency} />
      )
    },
    {
      component: (
        <NewGiftFormExperience
          updateGift={updateGift}
          gift={giftViewModel.data || {}}
          handlePreviousSlide={handlePreviousSlide}
          handleNextSlide={handleNextSlide}
        />
      )
    },
    {
      component: (
        <NewGiftFormIdea
          updateGift={updateGift}
          gift={giftViewModel.data || {}}
          handlePreviousSlide={handlePreviousSlide}
          handleNextSlide={handleNextSlide}
        />
      )
    },
    {
      component: (
        <NewGiftFormDiscovery
          updateGift={updateGift}
          gift={giftViewModel.data || {}}
          handlePreviousSlide={handlePreviousSlide}
          handleNextSlide={handleNextSlide}
        />
      )
    },
    {
      component: renderLastStep()
    }
  ];

  const onClose = async () => {
    const creds = await getCreds();

    if (creds.access && giftViewModel.data) {
      await localStorage.setItem(LOCAL_STORAGE_NEW_GIFT_CLOSED, 'true');
    }

    clearGiftViewModel();
    clearGiftCreateModel();

    history.push(EPrivateRoutes.Main);
  };

  if (haveSavedGift === undefined) {
    return <SeparatedModal logo={logo} onClose={onClose} rightSlot={<Spinner spinning />} />;
  }

  const handleNew = () => {
    localStorage.removeItem(LOCAL_STORAGE_NEW_GIFT);
    localStorage.removeItem(LOCAL_STORAGE_NEW_GIFT_CLOSED);
    clearGiftViewModel();
    clearGiftCreateModel();
    updateHaveSavedGift(false);
  };

  const handleSaved = () => {
    updateHaveSavedGift(false);
  };

  return (
    <SeparatedModal
      logo={logo}
      onClose={onClose}
      rightSlot={
        haveSavedGift ? (
          <NewGiftFormSavedOrNew handleNew={handleNew} handleSaved={handleSaved} />
        ) : (
          slides[currentStep.value].component
        )
      }
      divider={
        haveSavedGift ? (
          undefined
        ) : (
          <>
            <div className="hidden_mob">
              <StepsNumerable currentStep={currentStep.value} maxSteps={slides.length} />
            </div>

            <div className="hidden_desk mb-30">
              <StepsNumerable currentStep={currentStep.value} maxSteps={slides.length} direction="horizontal" />
            </div>
          </>
        )
      }
    />
  );
};

export const NewGift = communicationGift.injector(NewGiftComponent);
