import { HooksHelper } from '@axmit/clp-library/dist/helpers';
import Modal from 'antd/es/modal';
import * as React from 'react';

interface IComponentProps {
  url: string;
  alt?: string;
  className?: string;
}

export const ImagePreview = ({ className = '', alt = '', url }: IComponentProps) => {
  const showModal = HooksHelper.useStateBuilder<boolean>(false);

  return (
    <>
      <img src={url} alt={alt} className={`${className} image-preview`} onClick={() => showModal.set(true)} />

      <Modal visible={showModal.value} onCancel={() => showModal.set(false)} footer={null} className="img-preview-modal">
        <img src={url} alt={alt} className={className} />
      </Modal>
    </>
  );
};
