import React from 'react';
import { Redirect } from 'react-router';
import { Container } from '@axmit/clp-library';
import { EPrivateRoutes } from 'common/const';
import { Desktop } from 'common/components';
import { BankingInfo } from 'entities/Payment/components/BankingInfo';

export const BankingMobilePage = () => {
  return (
    <Container>
      <BankingInfo />

      <Desktop>
        <Redirect to={EPrivateRoutes.BankingDesktop} />
      </Desktop>
    </Container>
  );
};
