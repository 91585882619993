import React from 'react';
import AntdSelect from 'antd/es/select';
import { compose } from 'redux';
import { FormItem, Select } from '@axmit/clp-library';
import { useTranslation } from 'react-i18next';
import { EmptyData } from 'common/components';
import { communicationPayment, IPaymentConnectedProps } from 'entities/Payment/Payment.communication';

interface IComponentProps {
  onSelect: (id: string) => void;
}

type AllProps = IPaymentConnectedProps & IComponentProps;

const CardsSelectorComponent = (props: AllProps) => {
  const { t } = useTranslation();
  const { paymentMethodCollection, onSelect } = props;
  const { data: methods } = paymentMethodCollection;

  if (!methods?.data.length) {
    return null;
  }

  return (
    <FormItem className="mob-form-wrapper" label={t('cardsSelector.label')}>
      {methods.data.length ? (
        <Select bordered="bottom" onSelect={value => onSelect(value.toString())} width="auto" className="w-100p">
          {methods.data.map(({ id, card }) => (
            <AntdSelect.Option key={id} value={id}>
              {card ? `**** **** **** ${card.last4} ` : '-'}
            </AntdSelect.Option>
          ))}
        </Select>
      ) : (
        <EmptyData />
      )}
    </FormItem>
  );
};

const containers = compose(communicationPayment.injector);

export const CardsSelector = containers(CardsSelectorComponent);
