import React, { FormEvent } from 'react';
import { compose } from 'redux';
import { Row, Col } from 'antd/es/grid';
import Form from 'antd/es/form';
import { Button } from '@axmit/clp-library';
import { useTranslation } from 'react-i18next';
import { FormComponentProps } from 'antd/es/form';
import { TextAreaForm } from 'common/components';
import { RequestDetails } from 'entities/Requests/components/RequestDetails';
import { ERequestStatus } from 'entities/Requests/Requests.models';
import { communicationRequests, IRequestsConnectedProps } from 'entities/Requests/Requests.communication';

type AllProps = FormComponentProps & IRequestsConnectedProps;

const GiftRequestEditPageDetailsComponent = ({ requestsModel, updateRequestsModel, form }: AllProps) => {
  const { t } = useTranslation();
  if (!requestsModel.data?.giftRequestData) {
    return null;
  }

  const { giftRequestData, giftNotes, id, priority, status, requestNumber } = requestsModel.data;
  const isFinished = status === ERequestStatus.Finished;

  const submit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    form.validateFields((err, values) => {
      if (err || giftNotes === values.giftNotes) {
        return;
      }

      updateRequestsModel({ id, data: { ...values, priority } });
    });
  };

  return (
    <Row gutter={30}>
      <Col sm={12}>
        <RequestDetails request={giftRequestData} number={requestNumber} />
      </Col>

      <Col sm={12}>
        <div className="card-details__header">{t('requestNotes')}</div>

        <Form onSubmit={submit}>
          <TextAreaForm
            getFieldDecorator={form.getFieldDecorator}
            textAreaProps={{ disabled: isFinished, autoSize: { minRows: 11, maxRows: 16 } }}
            initialValue={giftNotes}
            fieldName="giftNotes"
          />

          <Button type="primary" htmlType="submit" className="ant-btn_mob-block">
            {t('btnSave')}
          </Button>
        </Form>
      </Col>
    </Row>
  );
};

const containers = compose(communicationRequests.injector);

export const GiftRequestEditPageDetails = containers(Form.create<AllProps>()(GiftRequestEditPageDetailsComponent));
