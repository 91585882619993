import React from 'react';
import { compose } from 'redux';
import { EmptyData } from 'common/components';
import { communicationUsers, IUsersConnectedProps } from 'entities/User/Users.communication';
import { CardCreateForm } from 'entities/Payment/components/CardCreateForm';
import { CardsList } from 'entities/Payment/components/CardsList';

type AllProps = IUsersConnectedProps;

const BankingInfoComponent = ({ usersCurrent }: AllProps) => {
  if (!usersCurrent.data) {
    return <EmptyData />;
  }

  return (
    <>
      <CardCreateForm userId={usersCurrent.data.id} />

      <CardsList userId={usersCurrent.data.id} />
    </>
  );
};

const containers = compose(communicationUsers.injector);

export const BankingInfo = containers(BankingInfoComponent);
