export interface IErrorResponse extends Components.Schemas.ErrorResponseDto {}

export enum EErrorStatus {
  BadRequest = 400,
  Unauthorized = 401,
  Forbidden = 403,
  NotFound = 404,
  Conflict = 409,
  PayloadTooLarge = 413,
  Validation = 422,
  FailedDependency = 424,
  TooManyRequests = 429,
  RetryWith = 449,
  InternalServerError = 500
}

export interface IUpdateParams<T> {
  id: string;
  data: T;
}

export enum EOrderDirection {
  ASC = 'ASC',
  DESC = 'DESC'
}

export interface IBaseFilterModel {
  offset?: number;
  limit?: number;
}
