import { Button, Tabs } from '@axmit/clp-library';
import Alert from 'antd/es/alert';
import Badge from 'antd/es/badge';
import { Row, Col } from 'antd/es/grid';
import AntTabs from 'antd/es/tabs';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { compose } from 'redux';
import { useTranslation } from 'react-i18next';
import Modal from 'antd/es/modal';
import { ButtonProps } from 'antd/es/button/button';
import { BackOfficeBreadcrumb } from 'common/components';
import { EPrivateRoutes, giftRequestEditTabs, requestEditTabs } from 'common/const';
import { RequestEditPageGiftInfo } from 'entities/Requests/components/RequestEditPageGiftInfo';
import { EGiftType, IGiftConnectedProps, communicationGift, EGiftStatus } from 'entities/Gift';
import { RequestEditPageManager } from 'entities/Requests/components/RequestEditPageManager';
import { RequestEditPageRequestInfo } from 'entities/Requests/components/RequestEditPageRequestInfo';
import { RequestEditPageStatusInfo } from 'entities/Requests/components/RequestEditPageStatusInfo';
import { RequestCardHelper } from 'entities/Requests/helpers/RequestCard.helper';
import { communicationRequests, IRequestsConnectedProps } from 'entities/Requests/Requests.communication';
import { ERequestPriority, ERequestStatus } from 'entities/Requests/Requests.models';

type AllProps = IRequestsConnectedProps & IGiftConnectedProps;

const RequestEditPageComponent = ({
  clearRequestsModel,
  getRequestsModel,
  requestsModel,
  updateRequestsModel,
  refundGiftModel,
  finalizeRequestsModel
}: AllProps) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [isVisibleModal, showModal] = useState<boolean>(false);

  useEffect(() => {
    id && getRequestsModel(id);

    return () => {
      clearRequestsModel();
    };
  }, [getRequestsModel, id, clearRequestsModel]);

  const { data: request } = requestsModel;

  if (!request) {
    return null;
  }

  const isActive = request?.status !== ERequestStatus.Finished;
  const isGift = request && RequestCardHelper.isGift(request.requestNumber);

  const giftTransType = request?.giftData?.type === EGiftType.Collective ? 'collective' : 'individual';
  const giftHeader = t(`giftFor.${giftTransType}`, {
    name: request.giftData?.meta.recipientName,
    interpolation: { escapeValue: false }
  });
  const requestHeader = isGift ? giftHeader : request?.giftRequestData?.meta.title;

  const updateRequest = (priority: ERequestPriority) => {
    id && updateRequestsModel({ id, data: { priority } });
  };

  const finalize = () => {
    request && finalizeRequestsModel(request.id);
    showModal(false);
  };

  const updateManager = (value?: string) => {
    // @ts-ignore TODO remove ts-ignore after BE fixes
    id && request && updateRequestsModel({ id, data: { userAssignee: value || null, priority: request.priority } });
  };

  const refund = () => {
    giftData && refundGiftModel({ id: giftData.id, successCallback: () => getRequestsModel(id) });
  };

  const giftData = request?.giftData;
  const giftRequestData = request?.giftRequestData;
  const statusIcon = request && RequestCardHelper.getStatusIcon(request?.status);
  const isNeedRefund = giftData?.status === EGiftStatus.BudgetNotCollected && !!giftData.currentBalance;

  return (
    <>
      <BackOfficeBreadcrumb
        current={requestHeader}
        previous={t('backOfficeDashboard.tab.request')}
        previousHref={EPrivateRoutes.Main}
        currentAffix={
          <Badge status={statusIcon} text={t(`request.statuses.${request.status}`)} className="ml-10 first-letter_capitalize" />
        }
      />

      {isNeedRefund && <Alert message={t('errorRollback')} type="error" />}

      {request && (
        <>
          <Row gutter={30} className="mt-15">
            <Col lg={18}>
              {giftData && (
                <RequestEditPageGiftInfo
                  contactType={giftData.contactType}
                  recipient={giftData.meta.recipientName}
                  contactDate={giftData.contactDate}
                  deliveryDate={giftData.deliveryDate}
                  budget={giftData.currentBalance || undefined}
                  createdDate={request.createdAt}
                  recipientEmail={giftData.meta.recipientEmail}
                  recipientPhone={giftData.meta.recipientPhone}
                  userSenderName={giftData.userSenderName}
                  refund={isNeedRefund ? refund : undefined}
                />
              )}
              {giftRequestData && (
                <RequestEditPageRequestInfo
                  recipient={giftRequestData.userName}
                  recipientPhone={giftRequestData.userPhone || undefined}
                  recipientEmail={giftRequestData.userEmail}
                  contactType={giftRequestData.contactType}
                  contactDate={giftRequestData.contactDate}
                />
              )}
            </Col>

            <Col lg={6}>
              <RequestEditPageStatusInfo
                disabled={!isActive}
                status={request.status}
                priority={request.priority}
                updatePriority={updateRequest}
              />

              <Row gutter={30}>
                <Col sm={isActive ? 12 : 24} lg={24} xl={isActive ? 12 : 24}>
                  <RequestEditPageManager
                    disabled={!isActive}
                    managerId={request.userAssignee || undefined}
                    managerName={request.userAssigneeName || undefined}
                    updateManager={updateManager}
                  />
                </Col>

                {isActive && (
                  <Col sm={12} lg={24} xl={12}>
                    <Button onClick={() => showModal(true)} size="small" className="ant-btn_mob-block mt-15">
                      {t('request.editForm.finalize')}
                    </Button>
                  </Col>
                )}
              </Row>
            </Col>
          </Row>

          <Tabs className="mt-20" type="card" destroyInactiveTabPane>
            {(isGift ? requestEditTabs : giftRequestEditTabs).map(({ key, tab, content, ...rest }) => (
              <AntTabs.TabPane key={key} tab={tab ? t(String(tab)) : ''} {...rest}>
                {content}
              </AntTabs.TabPane>
            ))}
          </Tabs>

          <Modal
            className="alert-modal"
            title={t('request.editForm.finalize')}
            visible={isVisibleModal}
            onOk={finalize}
            onCancel={() => showModal(false)}
            okButtonProps={okButtonProps}
            cancelButtonProps={cancelButtonProps}
            okText={t('request.editForm.modalOk')}
            cancelText={t('request.editForm.modalCancel')}
          >
            {t('request.editForm.modalMessage')}
          </Modal>
        </>
      )}
    </>
  );
};

const containers = compose(communicationRequests.injector, communicationGift.injector);

export const RequestEditPage = containers(RequestEditPageComponent);

const okButtonProps: ButtonProps = { className: 'clp-btn ml-20', size: 'small' };
const cancelButtonProps: ButtonProps = { className: 'clp-btn ml-20', size: 'small', type: 'default' };
