import * as React from 'react';
import { HTMLProps } from 'react';
import Icon from 'antd/es/icon';
import { Row, Col } from 'antd/es/grid';
import { Button, Container } from '@axmit/clp-library';
import { Header, LogoGift } from 'common/components';

interface IComponentProps extends HTMLProps<HTMLDivElement> {
  onLogoClick?: () => void;
  onClose?: () => void;
  logo?: string;
  divider?: JSX.Element | string;
  leftSlot?: JSX.Element | string;
  rightSlot?: JSX.Element | string;
}

export const SeparatedModal = ({
  onLogoClick,
  logo,
  divider,
  leftSlot,
  rightSlot,
  onClose,
  className = '',
  ...allProps
}: IComponentProps) => {
  return (
    <div className={`separated-modal ${className}`} {...allProps}>
      <Header
        leftSlot={<LogoGift logo={logo} onClick={onLogoClick} />}
        rightSlot={
          onClose && (
            <div className="ta-r">
              <Button type="default" size="small" onClick={onClose} className="separated-modal__close-button">
                <Icon type="close" className="separated-modal__close-button-icon" />
              </Button>
            </div>
          )
        }
      />

      <Container>
        <Row>
          <Col md={11} order={2}>
            {leftSlot}
          </Col>

          <Col md={2} order={1}>
            {divider}
          </Col>

          <Col md={11}>
            <div className="ml-8">{rightSlot}</div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
