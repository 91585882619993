import { EActionsTypes, APIProvider, BaseStrategy, Branch, buildCommunication, StoreBranch } from '@axmit/redux-communications';
import message from 'antd/es/message';
import { BaseTransport } from 'common/dto/base.transport';
import i18n from 'app/locales/i18n';
import { IContactUsModel } from 'entities/Support/Support.models';

const namespace = 'support';

export interface ISupportConnectedProps {
  supportMessage: StoreBranch<{}>;
  addSupportMessage(params: IContactUsModel): void;
}

const transport = new BaseTransport('contact-us');

const messageApiProvider = [
  new APIProvider(EActionsTypes.add, transport.add, {
    clearParams: true,
    onSuccess: function() {
      message.success(i18n.t('contactUsSuccessMessage'));
    }
  })
];

const branches = [new Branch('message', messageApiProvider)];

const strategy = new BaseStrategy({
  namespace,
  branches
});

export const communicationSupport = buildCommunication<ISupportConnectedProps>(strategy);
