import { ColumnProps } from 'antd/lib/table';
import i18next from 'i18next';
import { FormatHelper } from 'common/helpers';
import { IGiftContributorModel } from 'entities/Contributors/Contributors.models';

export const giftContributorsColumns: ColumnProps<IGiftContributorModel>[] = [
  {
    dataIndex: 'name',
    title: 'contributors.table.name',
    render: (value, model) => {
      return model.isAnonymous ? i18next.t('contributorsList.anonymous') : value;
    }
  },
  {
    dataIndex: 'receipt',
    title: 'contributors.table.amount',
    render: (amount: number) => {
      return FormatHelper.getCurrency(amount);
    }
  }
];

export const CONTRIBUTORS_PER_PAGE = 5;
