import {
  APIProvider,
  BaseStrategy,
  Branch,
  buildCommunication,
  EActionsTypes,
  StoreBranch,
  getStartType
} from '@axmit/redux-communications';
import { put } from 'redux-saga/effects';
import { RequestLoadingHelper, MessageHelper } from 'common/helpers';
import { BaseTransport } from 'common/dto/base.transport';
import {
  IRequestExpensesCollection,
  IRequestExpensesCollectionParams,
  IRequestExpensesCreateParams
} from 'entities/RequestExpenses/RequestExpenses.models';
import { IGiftTransactionsModel, IGiftTransactionsCollectionParams } from 'entities/GiftTransactions/GiftTransactions.models';

const namespace = 'requestExpenses';

export interface IRequestExpensesConnectedProps {
  requestExpensesModel: StoreBranch<IGiftTransactionsModel, IRequestExpensesCreateParams>;
  addRequestExpensesModel(params: IRequestExpensesCreateParams): IGiftTransactionsModel;
  deleteRequestExpensesModel(id: string): void;

  requestExpensesCollection: StoreBranch<IRequestExpensesCollection, IRequestExpensesCollectionParams>;
  getRequestExpensesCollection(params?: IRequestExpensesCollectionParams): IRequestExpensesCollection;
  clearRequestExpensesCollection(): void;
}

const transport = new BaseTransport('request-expenses');

const modelApiProvider = [
  new APIProvider<IGiftTransactionsModel, IRequestExpensesCreateParams>(EActionsTypes.add, transport.add, {
    clearParams: true,
    onSuccess: function*(response, payload, branchState, fullState) {
      MessageHelper.createSuccess();
      // @ts-ignore reason: bad typing for fullState, can't find collection
      const { params = {} } = fullState?.requestExpenses?.collection;
      yield reloadCollection(params);
    }
  }),
  new APIProvider<IGiftTransactionsModel, string>(EActionsTypes.delete, transport.delete, {
    onSuccess: function*(response, payload, branchState, fullState) {
      MessageHelper.deleteSuccess();
      // @ts-ignore reason: bad typing for fullState, can't find collection
      const { params = {} } = fullState?.requestExpenses?.collection;
      yield reloadCollection(params);
    }
  })
];

function* reloadCollection(payload: IGiftTransactionsCollectionParams) {
  yield put({
    type: getStartType(namespace, 'collection', EActionsTypes.get),
    payload
  });
}

const collectionApiProvider = [
  new APIProvider<IRequestExpensesCollection, IRequestExpensesCollectionParams>(EActionsTypes.get, transport.getCollection, {
    preRequestDataMapper: RequestLoadingHelper.setOldData
  })
];

const branches = [new Branch('model', modelApiProvider), new Branch('collection', collectionApiProvider)];

const strategy = new BaseStrategy({
  namespace,
  branches
});

export const communicationRequestExpenses = buildCommunication<IRequestExpensesConnectedProps>(strategy);
