import moment from 'moment';
import { EGiftType, IGiftCreateMeta, IGiftCreateModel, IGiftCreateViewModel } from 'entities/Gift/Gift.models';
import { GiftCreateHelper } from 'entities/Gift/helpers/GiftCreate.helper';

const mapViewModelToParams = (gift: IGiftCreateViewModel): IGiftCreateModel => {
  const { date = moment(), type = EGiftType.Individual, invites = [] } = gift;

  return {
    deliveryDate: moment(date).toISOString(),
    type,
    meta: mapViewModelMetaToParams(gift),
    invitations: type === EGiftType.Collective ? invites.map(({ id, ...allFields }) => allFields) : undefined
  };
};

const mapViewModelMetaToParams = ({
  name,
  phone,
  city,
  country,
  email,
  idea,
  house = ' ',
  isSendByEmail,
  state,
  street,
  activation,
  zipCode
}: IGiftCreateViewModel): IGiftCreateMeta => {
  return {
    recipientName: name || '',
    recipientPhone: phone || '',
    city,
    country,
    house,
    state,
    street,
    zipCode,
    recipientEmail: email || '',
    isSendByMail: !isSendByEmail,
    description: idea || '',
    emailMessage: GiftCreateHelper.getDiscoveryLetter(name),
    message: activation || ''
  };
};

export const GiftMapper = {
  mapViewModelToParams
};
