import React from 'react';
import Typography from 'antd/es/typography';
import { Row, Col } from 'antd/es/grid';
import Form from 'antd/es/form';
import Spin from 'antd/es/spin';
import { HooksHelper } from '@axmit/clp-library/dist/helpers';
import { FormComponentProps } from 'antd/es/form';
import { Button, CardInfo, Checkbox } from '@axmit/clp-library';
import { useTranslation } from 'react-i18next';
import { CheckboxHooks } from 'common/hooks';
import { InputForm, TextAreaForm } from 'common/components';
import { CurrencyForm } from 'entities/Payment/components';
import { communicationContributors, IContributorsConnectedProps } from 'entities/Contributors/Contributors.communication';
import { AgreePrivacyCheckbox } from 'entities/Contributors/components/ContributorForm/AgreePrivacyCheckbox';

interface IComponentProps {
  giftId: string;
  budget?: number;
  setBudget: (value?: number) => void;
  recipientName: string;
}

type AllProps = FormComponentProps & IComponentProps & IContributorsConnectedProps;

const ContributorFormMainComponent = ({
  recipientName,
  budget,
  setBudget,
  giftId,
  form,
  addContributorsModel,
  contributorsModel
}: AllProps) => {
  const { t } = useTranslation();
  const isAnonymous = HooksHelper.useStateBuilder(false);
  const currencyIsValid = HooksHelper.useStateBuilder(true);
  const agreePrivacy = CheckboxHooks.useCheckbox(false);

  const { getFieldDecorator } = form;

  const submit = () => {
    if (!currencyIsValid.value) {
      return;
    }

    form.validateFields((err, values) => {
      if (err || !agreePrivacy.value) {
        return;
      }

      const name = isAnonymous.value ? undefined : `${values.firstName} ${values.lastName}`;
      const message = isAnonymous.value ? undefined : values.message;
      const suggestion = isAnonymous.value ? undefined : values.suggestion;

      addContributorsModel({ gift: giftId, isAnonymous: isAnonymous.value, name, message, suggestion });
    });
  };

  return (
    <>
      <Typography.Title className="clp-typography-title clp-typography-title_size_md mob-text_center mb-10 hidden_mob">
        {t('contributorForm.header', { name: recipientName })}
      </Typography.Title>

      <Spin spinning={contributorsModel.loading}>
        <CardInfo header={t('newGiftExperienceFormLabelBudget')} headerSize="sm" className="mt-25 mb-10" />

        <CurrencyForm
          calculateParams={{ amount: budget || 0 }}
          amount={budget}
          setAmount={value => {
            setBudget(value);
          }}
          setValid={currencyIsValid.set}
        />

        <Checkbox
          className="mb-30 mob-form-wrapper"
          defaultChecked={isAnonymous.value}
          onChange={event => {
            isAnonymous.set(event.target.checked);
          }}
        >
          {t('contributorForm.items.anon')}
        </Checkbox>

        {!isAnonymous.value && (
          <Form hideRequiredMark>
            <Row gutter={[30, 10]}>
              <Col span={24}>
                <CardInfo header={t('contributorForm.items.name.header')} headerSize="sm" />
              </Col>

              <Col md={12}>
                <InputForm
                  getFieldDecorator={getFieldDecorator}
                  fieldName="firstName"
                  label={t('contributorForm.items.name.labelFirstName')}
                  required
                />
              </Col>

              <Col md={12}>
                <InputForm
                  getFieldDecorator={getFieldDecorator}
                  fieldName="lastName"
                  label={t('contributorForm.items.name.labelLastName')}
                  required
                />
              </Col>

              <Col span={24}>
                <CardInfo
                  header={
                    <>
                      {t('contributorForm.items.idea.header')}{' '}
                      <span className="clp-paragraph optional-message">{t('defaultOptionalFormItem')}</span>
                    </>
                  }
                  headerSize="sm"
                />
              </Col>

              <Col span={24}>
                <TextAreaForm
                  getFieldDecorator={getFieldDecorator}
                  fieldName="suggestion"
                  textAreaProps={{
                    placeholder: `${t('contributorForm.items.idea.placeholder')}, ...`,
                    autoSize: { minRows: 4, maxRows: 8 }
                  }}
                />
              </Col>

              <Col span={24}>
                <CardInfo
                  header={
                    <>
                      {t('contributorForm.items.message.header', { name: recipientName })}{' '}
                      <span className="clp-paragraph optional-message">{t('defaultOptionalFormItem')}</span>
                    </>
                  }
                  headerSize="sm"
                />
              </Col>

              <Col span={24}>
                <TextAreaForm
                  getFieldDecorator={getFieldDecorator}
                  fieldName="message"
                  textAreaProps={{
                    placeholder: `${recipientName}, ...`,
                    autoSize: { minRows: 4, maxRows: 8 }
                  }}
                />
              </Col>
            </Row>
          </Form>
        )}
        <AgreePrivacyCheckbox onChange={agreePrivacy.set} />
        <Row>
          <Col span={24}>
            <Button
              type="primary"
              size="small"
              className="ant-btn_mob-block mb-10"
              onClick={submit}
              disabled={!agreePrivacy.value}
            >
              {t('contributorForm.submit')}
            </Button>
          </Col>
        </Row>
      </Spin>
    </>
  );
};

export const ContributorFormMain = communicationContributors.injector(Form.create<AllProps>()(ContributorFormMainComponent));
