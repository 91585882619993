import React, { useCallback } from 'react';
import Spin from 'antd/es/spin';
import AntSelect from 'antd/es/select';
import debounce from 'lodash.debounce';
import { EmptyData } from 'common/components';
import { SEARCH_WAIT_TIME } from 'common/config';
import { communicationEmployees, IEmployeesConnectedProps } from 'entities/Employees/Employees.communication';

interface IComponentProps {
  setEmployee: (value?: string) => void;
  employee?: string;
  companyId: string;
}

type AllProps = IEmployeesConnectedProps & IComponentProps;

const EmployeeCreditSelectorComponent = (props: AllProps) => {
  const { getEmployeesForTransferCollection, employeesForTransferCollection, companyId, setEmployee, employee } = props;
  const { loading: employeesLoading } = employeesForTransferCollection;

  const debounceSearch = useCallback(
    debounce((value: string) => {
      companyId && getEmployeesForTransferCollection({ company: companyId, isDeleted: false, quickSearch: value });
    }, SEARCH_WAIT_TIME),
    [getEmployeesForTransferCollection]
  );

  return (
    <AntSelect
      dropdownClassName="clp-select__dropdown"
      className="clp-select clp-select_bordered_bottom clp-select_width_sm w-100p"
      notFoundContent={employeesLoading ? <Spin size="small" /> : <EmptyData />}
      showSearch
      value={employee}
      onChange={setEmployee}
      loading={employeesLoading}
      filterOption={false}
      allowClear
      onSearch={debounceSearch}
      onDropdownVisibleChange={open => open && getEmployeesForTransferCollection({ isDeleted: false, company: companyId })}
    >
      {employeesForTransferCollection.data?.data?.map(({ id, firstName, lastName }) => (
        <AntSelect.Option key={id} value={id}>
          {lastName} {firstName}
        </AntSelect.Option>
      ))}
    </AntSelect>
  );
};

export const EmployeeCreditSelector = communicationEmployees.injector(EmployeeCreditSelectorComponent);
