import * as React from 'react';
import { Row, Col } from 'antd/es/grid';
import { RowProps } from 'antd/lib/row';
import { ColProps } from 'antd/lib/col';
import { Paragraph } from '@axmit/clp-library';
import { useTranslation } from 'react-i18next';

interface IItem {
  key: string;
  value: JSX.Element | string | number;
  col?: ColProps;
}

interface IComponentProps extends RowProps {
  data: IItem[];
}

export const ColumnsContent = ({ data, ...rest }: IComponentProps) => {
  const { t } = useTranslation();

  return (
    <Row {...rest}>
      {data.map(({ key, value, col = wrapperCol }) => (
        <Col {...col} key={key}>
          <Paragraph size="sm">
            {t(key)}: <b>{value}</b>
          </Paragraph>
        </Col>
      ))}
    </Row>
  );
};

const wrapperCol = { xs: 24, md: 12, xl: 6 };
