import { ListGridType } from 'antd/es/list';
import { ColProps } from 'antd/es/grid';
import { EUserRoles } from 'entities/User/Users.consts';
import { IUserCollectionParams } from 'entities/User/Users.models';

export const REQUESTS_PER_PAGE = 8;
export const REQUESTS_COL: ColProps = { xl: 6, lg: 8, sm: 12, xs: 24 };
export const REQUESTS_FILTER_COL: ListGridType = { xl: 6, lg: 8, sm: 12 };

export const MANAGERS_FILTERS_ALL: IUserCollectionParams = { roleIn: [EUserRoles.Manager, EUserRoles.Administrator] };
export const MANAGERS_FILTERS_ACTIVE: IUserCollectionParams = {
  isDeleted: false,
  roleIn: [EUserRoles.Manager, EUserRoles.Administrator]
};
