import React from 'react';
import AntTabs from 'antd/es/tabs';
import { Button, CardInfo, Tabs } from '@axmit/clp-library';
import { compose } from 'redux';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { EPrivateRoutes } from 'common/const';
import { TabsHooks } from 'common/hooks';
import { communicationAuth, IAuthConnectedProps } from 'entities/Auth/Auth.communication';
import { communicationUsers, IUsersConnectedProps } from 'entities/User/Users.communication';
import { DashboardGiftsFriendsFor, DashboardGiftsFriendsFrom } from 'entities/Gift/components';
import { BankingInfo } from 'entities/Payment/components/BankingInfo';
import { PersonalInfo } from 'entities/User/components';

type AllProps = IAuthConnectedProps & IUsersConnectedProps;

const DashboardDesktopComponent = (props: AllProps) => {
  const history = useHistory();
  const { t } = useTranslation();

  const { usersCurrent } = props;
  const name = usersCurrent?.data?.firstName || '';

  const activeTab = TabsHooks.useInitFromRoute([
    EPrivateRoutes.Main,
    EPrivateRoutes.PersonalDesktop,
    EPrivateRoutes.BankingDesktop
  ]);

  const logout = () => {
    props.deleteAuthModel();
  };

  const onChangeTab = (key: string) => {
    history.push(key);
    activeTab.set(key);
  };

  return (
    <>
      <div className="mt-50" />

      <CardInfo header={`${t('dashboardHeader')}, ${name}`} content="" />

      <Tabs
        className="mt-15"
        tabBarExtraContent={
          <Button type="link" className="pr-0 " onClick={logout}>
            <span className="t_decoration_none">{t('headerSignOut')}</span>
          </Button>
        }
        activeKey={activeTab.value}
        onChange={onChangeTab}
      >
        <AntTabs.TabPane tab={t('dashboardTabGifts')} key={EPrivateRoutes.Main}>
          <DashboardGiftsFriendsFor />
          <DashboardGiftsFriendsFrom />
        </AntTabs.TabPane>

        <AntTabs.TabPane tab={t('dashboardTabPersonalInfo')} key={EPrivateRoutes.PersonalDesktop}>
          <PersonalInfo />
        </AntTabs.TabPane>

        <AntTabs.TabPane tab={t('dashboardTabBankingInfo')} key={EPrivateRoutes.BankingDesktop}>
          <BankingInfo />
        </AntTabs.TabPane>
      </Tabs>
    </>
  );
};

const containers = compose(communicationAuth.injector, communicationUsers.injector);

export const DashboardDesktop = containers(DashboardDesktopComponent);
