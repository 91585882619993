import React, { useState } from 'react';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';

function useCheckbox(initialState?: boolean) {
  const [value, onChange] = useState(initialState);
  const set = React.useCallback((event: CheckboxChangeEvent) => onChange(event.target.checked), [onChange]);

  return { value, set };
}

export const CheckboxHooks = {
  useCheckbox
};
