import * as React from 'react';
import { FormItem, Input } from '@axmit/clp-library';
import { FormItemProps, ValidationRule } from 'antd/es/form';
import { InputProps } from 'antd/es/input';
import { useTranslation } from 'react-i18next';
import { FieldDecorator } from 'common/models/Form.models';

interface IComponentProps<T> {
  getFieldDecorator: FieldDecorator;
  fieldName: string;
  onBlur?: ({ key: value }: { [key: string]: string }) => void;
  rules?: ValidationRule[];
  initialValue?: T;
  required?: boolean;
  label?: string;
  inputProps?: InputProps;
  formItemProps?: FormItemProps;
}

export function InputForm<T>({
  getFieldDecorator,
  onBlur,
  initialValue,
  required,
  label,
  fieldName,
  inputProps,
  formItemProps,
  rules = []
}: IComponentProps<T>) {
  const { t } = useTranslation();
  const allRules = required
    ? [
        ...rules,
        {
          required: true,
          message: t('errorRequiredDefault')
        }
      ]
    : rules;

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    return onBlur && onBlur({ [fieldName]: event.target.value });
  };

  return (
    <FormItem label={label} {...formItemProps}>
      {getFieldDecorator(fieldName, {
        initialValue,
        rules: allRules
      })(<Input onBlur={handleBlur} {...inputProps} />)}
    </FormItem>
  );
}
