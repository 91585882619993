import i18n from 'i18next';
import moment from 'moment';
import { DATE_FORMAT_DEFAULT } from 'common/config';

const getDateWithYear = (date: Date | string) => {
  return moment(date).format(DATE_FORMAT_DEFAULT);
};

const formatDate = (format: string, date: string = '') => {
  return moment(date).format(format);
};

const getGoogleMapsLink = (lat: string, lng: string): string => {
  return `http://www.google.com/maps/place/${lat},${lng}`;
};

const getCurrency = (valueInit?: number | null): string => {
  const value = valueInit || 0;

  return Intl.NumberFormat([i18n.language], {
    style: 'currency',
    currency: 'eur'
  }).format(value);
};

function sumFieldInArray<T extends Array<Item>, Item extends Object>(array: T, field: keyof Item) {
  return array.reduce((previous, current) => previous + (Number(current[field]) || 0), 0);
}

const formatAmount = (value?: string): number => (value ? +(+value).toFixed(2) : 0);

const normalizeAmount = (value: string, prevValue: string): number | string => {
  if (value === prevValue || !value) {
    return value;
  }

  return FormatHelper.formatAmount(value);
};

export const FormatHelper = {
  getGoogleMapsLink,
  normalizeAmount,
  formatDate,
  getCurrency,
  formatAmount,
  sumFieldInArray,
  getDateWithYear
};
