import React, { useCallback, useEffect } from 'react';
import Spin from 'antd/es/spin';
import { useTranslation } from 'react-i18next';
import { Paragraph } from '@axmit/clp-library';
import { Trans } from 'react-i18next';
import debounce from 'lodash.debounce';
import { CALCULATION_WAIT_TIME } from 'common/config';
import {
  communicationPayment,
  IGiftPaymentCalculatorParams,
  IGiftPaymentCalculatorView,
  IPaymentConnectedProps
} from 'entities/Payment';

interface IComponentProps {
  calculateParams?: IGiftPaymentCalculatorView;
}

type AllProps = IPaymentConnectedProps & IComponentProps;

const GiftCalculatorInfoComponent = (props: AllProps) => {
  const { getPaymentCalculator, paymentCalculator, calculateParams } = props;
  const { isMailRequired, country, amount, viewOnly } = calculateParams || {};
  const { loading, data } = paymentCalculator;
  const { t } = useTranslation();

  const getCalculation = useCallback(
    debounce((params: IGiftPaymentCalculatorParams) => {
      getPaymentCalculator(params);
    }, CALCULATION_WAIT_TIME),
    []
  );

  useEffect(() => {
    if (viewOnly) {
      return;
    }

    getCalculation({ amount: amount || 0, country, isMailRequired });
  }, [getCalculation, amount, country, isMailRequired, viewOnly]);

  const { total, amount: amountCalc, commissionPercentage = '-', mailCharge } = data || {};

  if (amount === 0 || !total) {
    return null;
  }

  return (
    <Spin spinning={loading}>
      <Paragraph className="mb-0" size="xs">
        <Trans
          t={t}
          i18nKey={mailCharge ? 'giftCalculatorInfoWithDelivery' : 'giftCalculatorInfo'}
          values={{
            total,
            amount: amountCalc,
            fee: commissionPercentage,
            delivery: mailCharge
          }}
          components={[<b key="amount" />]}
        />
      </Paragraph>
    </Spin>
  );
};

export const GiftCalculatorInfo = communicationPayment.injector(GiftCalculatorInfoComponent);
