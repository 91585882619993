import { HooksHelper } from '@axmit/clp-library/dist/helpers';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ValidateHelper } from 'common/helpers';

function usePhone(initialState?: string, formErrors?: string[]) {
  const mainError = formErrors ? formErrors[0] : undefined;

  const phone = HooksHelper.useStateBuilder(initialState);
  const [validationMessage, updateValidationMessage] = useState<string | undefined>(mainError);
  const { i18n } = useTranslation();

  useEffect(() => {
    updateValidationMessage(mainError);
  }, [mainError]);

  const validatePhone = () => {
    updateValidationMessage(i18n.t('wrongPhone'));
  };

  const changePhone = (newValue: string) => {
    updateValidationMessage(undefined);
    phone.set(newValue);
  };

  const phoneValidateStatus: 'error' | '' = ValidateHelper.getValidateStatus(validationMessage?.length);

  return {
    phone: phone.value,
    phoneValidateMessage: validationMessage,
    phoneValidateStatus,
    validatePhone,
    changePhone
  };
}

export const PhoneHooks = {
  usePhone
};
