import * as React from 'react';
import * as ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { configureAxiosJWTInterseptors } from '@axmit/axios-patch-jwt';
import { IAsyncStorage, WebStorageDecorator } from '@axmit/universal-storage';
import axios from 'axios';
import { unregister } from 'registerServiceWorker';
import i18next from 'i18next';
import { Integrations } from '@sentry/tracing';
import { ValidateHelper } from 'common/helpers';
import { envConfig } from 'common/config';
import { App } from 'app/pages';
import { createReduxStore } from 'app/store';
import 'app/assets/styles/index.scss';
import { ThirdPartyServicesHelper } from 'common/helpers/ThirdPartyServicesHelper.helper';

export const store = createReduxStore();

// Axios initialization
const storage: IAsyncStorage = new WebStorageDecorator(localStorage);
configureAxiosJWTInterseptors({
  storage,
  axios,
  convertToCamelCase: true,
  refreshTokenEndpoint: '/auth'
});

axios.defaults.baseURL = `/api`;
axios.interceptors.request.use(config => ({
  ...config,
  headers: { 'Source-Service': 'clp', 'Accept-Language': i18next.language, ...config.headers }
}));
axios.interceptors.response.use(
  response => response.data,
  error => {
    const { status, statusText, data } = error.response;

    return Promise.reject(ValidateHelper.mapError(status, statusText, data));
  }
);

// Sentry initialization
Sentry.init({
  dsn: envConfig.SENTRY_DSN || envConfig.REACT_APP_SENTRY_DSN,
  environment: envConfig.NODE_ENV,
  ignoreErrors: ['ResizeObserver loop limit exceeded'],
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0
});

// @ts-ignore
window.checkSentry = (text?: string) => {
  const msg = `Sentry successfully connected. Config: ${JSON.stringify(envConfig)} ${new Date()}. Test string: ${text}`;
  console.log('Go to slack channel and check an error with message: ');
  console.log(msg);
  Sentry.captureException(msg);
};
// //////////////////////////////////////
ThirdPartyServicesHelper.initGoogleAnalytics(
  envConfig.CLP_GOOGLE_ANALYTICS_ID || envConfig.REACT_APP_CLP_GOOGLE_ANALYTICS_ID,
  envConfig.NODE_ENV
);

ReactDOM.render(<App store={store} />, document.getElementById('root'));
unregister();
