import * as React from 'react';
import { Button } from '@axmit/clp-library';
import { useHistory } from 'react-router';
import Typography from 'antd/es/typography';
import { Row, Col } from 'antd/es/grid';
import { useTranslation } from 'react-i18next';
import { FormatHelper } from 'common/helpers';
import { DATE_FORMAT_CONTACT_INFO } from 'common/config';
import { EPrivateRoutes } from 'common/const';
import julesMapVuittonStyle from 'app/assets/images/julesMapVuittonStyle.png';
import { GiftPrice } from 'entities/Gift/components/GiftPrice';

interface IComponentProps {
  showForm: (show: boolean) => void;
  date?: string;
  balance?: number;
  type?: string;
}

export const ContactInfo = ({ date, type, balance = 0, showForm }: IComponentProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const capitalizedType = type ? type[0].toUpperCase() + type.slice(1) : '';

  return (
    <>
      <Row type="flex" justify="space-between" className="mb-20">
        <Col xs={24} md={8} className="d-flex d-flex_justify_between d-flex_direction_column pb-10">
          <Typography.Title
            className="clp-typography-title clp-typography-title_size_md mob-text_center mb-25 t_no-wrap"
            level={3}
          >
            {t('discoveredGift.info.header')}
          </Typography.Title>

          <GiftPrice price={FormatHelper.getCurrency(balance)} className="mb-30" />
        </Col>

        <Col xs={24} md={16} className="ta-r mob-text_center">
          <img src={julesMapVuittonStyle} className="img-activate block_center" alt="gift" />
        </Col>
      </Row>

      <div className="description mob-text_center">
        {t('discoveredGift.info.message', { when: FormatHelper.formatDate(DATE_FORMAT_CONTACT_INFO, date), capitalizedType })}
      </div>

      <Button
        size="small"
        className="ant-btn_mob-block mb-15 mt-15 mr-15"
        onClick={() => {
          showForm(true);
        }}
      >
        {t('discoveredGift.info.anotherTimeBtn')}
      </Button>

      <Button
        size="small"
        className="ant-btn_mob-block mb-30"
        onClick={() => {
          history.push(EPrivateRoutes.Main);
        }}
      >
        {t('discoveredGift.info.myAccountBtn')}
      </Button>
    </>
  );
};
