import React, { useEffect } from 'react';
import { Container, LandingRow } from '@axmit/clp-library';
import { useLocation, Link } from 'react-router-dom';
import Typography from 'antd/es/typography';
import { Col } from 'antd/es/grid';
import { Trans, useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { Spinner } from 'common/components';
import { EPublicRoutes } from 'common/const';
import imageKeyHolder from 'app/assets/images/keyHolder.png';
import { communicationAuth, IAuthConnectedProps } from 'entities/Auth/Auth.communication';

type AllProps = IAuthConnectedProps;

const ConfirmationComponent = (props: AllProps) => {
  const { t } = useTranslation();
  const { addAuthConfirmation } = props;
  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };

  const code = useQuery().get('code');
  const sub = useQuery().get('sub');

  useEffect(() => {
    if (!code || !sub) {
      return;
    }

    addAuthConfirmation({ code, cognitoSub: sub });
  }, [code, sub, addAuthConfirmation]);

  const { authConfirmation } = props;
  const { errors, loading } = authConfirmation;
  const hasErrors = !code || !sub || !!errors;

  return (
    <Container>
      <LandingRow
        leftSlot={
          <Col>
            {/* @ts-ignore */}
            <Spinner spinning={loading}>{hasErrors ? renderError(t, errors?.errorCode) : ''}</Spinner>
          </Col>
        }
        rightSlot={<img src={imageKeyHolder} alt="auth" className="max-w_100 mt-50 mb-100" />}
      />

      <img src={imageKeyHolder} alt="map" className="hidden_desk w-100p mt-25 mb-30" />
    </Container>
  );
};

export const Confirmation = communicationAuth.injector(ConfirmationComponent);

const renderError = (t: TFunction, code?: string): JSX.Element => {
  if (code && code === 'ExpiredCodeException') {
    return (
      <Typography.Text className="clp-typography-text clp-typography-text_size_lg mob-text_center pr-10">
        <Trans i18nKey="error.expiredCode" t={t}>
          Invalid conformation code, or your account has already been activated (Please
          <Link to={EPublicRoutes.LogIn} className="resend-link">
            login to access your account
          </Link>
          )
        </Trans>
      </Typography.Text>
    );
  }

  return (
    <Typography.Title className="clp-typography-title clp-typography-title_size_sm mob-text_center pr-10" level={2}>
      {t('errorDefault')}
    </Typography.Title>
  );
};
