import axios from 'axios';
import { BaseTransport } from 'common/dto/base.transport';
import { IChangePasswordParams, IPasswordRecoveryModel } from 'entities/Auth/Auth.models';

export class RestorePasswordTransport extends BaseTransport<IPasswordRecoveryModel> {
  public sendNewPassword = (params: IPasswordRecoveryModel) => {
    return axios.put(this.path, params);
  };

  public changePassword = ({ id, data }: IChangePasswordParams) => {
    return axios.put(`/users/${id}/change-password`, data);
  };
}
