import * as React from 'react';
import { withRouter } from 'react-router-dom';
import { MainLayout } from '@axmit/clp-library';
import { RouteComponentProps } from 'react-router';
import { ECommonRoutes, EPrivateRoutes } from 'common/const/Routes.const';
import { Router } from 'app/pages/Router';
import { Footer } from 'entities/Layout/Footer';
import { Header } from 'entities/Layout/Header';
import { communicationAuth, IAuthConnectedProps } from 'entities/Auth/Auth.communication';

type AllProps = IAuthConnectedProps & RouteComponentProps;

class LayoutBaseComponent extends React.Component<AllProps> {
  public componentDidMount() {
    const { initAuthModel } = this.props;

    initAuthModel();
  }

  public render() {
    const { history } = this.props;
    const isFullPage: boolean = isFullContentPage(history.location.pathname);

    if (isFullPage) {
      return <Router />;
    }

    return (
      <>
        <Header />

        <MainLayout>
          <Router />
        </MainLayout>

        <Footer />
      </>
    );
  }
}

export const LayoutBase = communicationAuth.injector(withRouter(LayoutBaseComponent));

const fullPageRoutes = [ECommonRoutes.NewGift, ECommonRoutes.Finalize, EPrivateRoutes.GiftPayment];
const isFullContentPage = (route: string): boolean => {
  return fullPageRoutes.some(item => route.startsWith(item));
};
