import React from 'react';
import { CompanyInfoHistory } from 'entities/Companies/components/CompanyInfoHistory';
import { CompanyInfoSummary } from 'entities/Companies/components/CompanyInfoSummary';

interface IComponentProps {
  companyId: string;
}

type AllProps = IComponentProps;

const CompanyInfoComponent = (props: AllProps) => {
  const { companyId } = props;
  return (
    <>
      <CompanyInfoSummary companyId={companyId} />

      <CompanyInfoHistory companyId={companyId} />
    </>
  );
};

export const CompanyInfo = CompanyInfoComponent;
