import React, { useEffect } from 'react';
import { Button, FormItem } from '@axmit/clp-library';
import { FormComponentProps } from 'antd/es/form';
import { Row, Col } from 'antd/es/grid';
import Form from 'antd/es/form';
import { HooksHelper } from '@axmit/clp-library/dist/helpers';
import { AntdFormHelper } from '@axmit/antd-helpers';
import { useTranslation } from 'react-i18next';
import { InputForm } from 'common/components';
import { ERequestStatus } from 'entities/Requests/Requests.models';
import { CurrencyForm } from 'entities/Payment/components/CurrencyForm';
import { ETransactionsType } from 'entities/Payment/Payment.const';
import {
  communicationGiftTransactions,
  IGiftTransactionsConnectedProps
} from 'entities/GiftTransactions/GiftTransactions.communication';
import { communicationRequests, IRequestsConnectedProps } from 'entities/Requests/Requests.communication';
import { TransactionsHeader } from 'entities/Requests/components';

interface IComponentProps {
  isGift?: boolean;
}

type AllProps = FormComponentProps & IGiftTransactionsConnectedProps & IRequestsConnectedProps & IComponentProps;

const ManagersTransactionsMakeComponent = (props: AllProps) => {
  const { t } = useTranslation();
  const { form, isGift, clearGiftTransactionsModel, clearGiftTransactionsRequestModel, requestsModel } = props;
  const { getFieldDecorator } = form;

  const amount = HooksHelper.useStateBuilder<number | undefined>(1);
  const currencyIsValid = HooksHelper.useStateBuilder(true);

  const { loading, data: requestData } = requestsModel;
  const status = requestData?.status;
  const isMakeTransactionBtnEnabled = status && !loading && status !== ERequestStatus.Finished;

  useEffect(() => {
    const clear = isGift ? clearGiftTransactionsModel : clearGiftTransactionsRequestModel;

    clear();
  }, [isGift, clearGiftTransactionsModel, clearGiftTransactionsRequestModel]);

  const submit = () => {
    form.validateFields((err, values) => {
      const { addGiftTransactionsRequestModel, addGiftTransactionsModel, requestsModel } = props;

      if (err || !requestsModel.data?.gift) {
        return;
      }

      const { gift, giftRequestData } = requestsModel.data;

      if (isGift) {
        addGiftTransactionsModel({ ...values, amount: amount.value, gift, type: ETransactionsType.Payment });
      } else {
        const params = { ...values, amount: amount.value, gift, type: ETransactionsType.Payment };

        if (giftRequestData?.employee) {
          params.employee = giftRequestData.employee;
        } else {
          params.user = giftRequestData?.user;
        }
        addGiftTransactionsRequestModel(params);
      }
    });
  };

  return (
    <>
      <TransactionsHeader className="mb-20">{t('transactions.add.header')}</TransactionsHeader>

      <Row gutter={15}>
        <Col xs={24} lg={12} xl={16}>
          <InputForm
            getFieldDecorator={getFieldDecorator}
            fieldName="description"
            label={t('transactions.add.form.description')}
          />
        </Col>

        <Col xs={24} lg={12} xl={8}>
          <FormItem label={t('transactions.add.form.amount')} className="m-0">
            <CurrencyForm amount={amount.value} setAmount={amount.set} setValid={currencyIsValid.set} minAmount={1} />
          </FormItem>
        </Col>
      </Row>

      {isMakeTransactionBtnEnabled && (
        <Button className="ant-btn_mob-block" onClick={submit} type="default">
          {t('transactions.add.submitBtn')}
        </Button>
      )}
    </>
  );
};

export const ManagersTransactionsMake = communicationRequests.injector(
  communicationGiftTransactions.injector(
    Form.create<AllProps>({
      mapPropsToFields({ giftTransactionsModel }) {
        return AntdFormHelper.mapValidationToFields(giftTransactionsModel?.params, giftTransactionsModel?.errors);
      }
    })(ManagersTransactionsMakeComponent)
  )
);
