import '@axmit/clp-api';
import * as moment from 'moment';
import { IInvitationsFormItem } from 'entities/Invitations/Invintations.models';
import { ICountriesCreateModel } from 'entities/Countries/Countries.models';

export interface IGiftMeta extends Components.Schemas.GiftMetaDataDto {}

export interface IGiftModel extends Components.Schemas.GiftDto {
  id: string;
  type: EGiftType;
  meta: IGiftMeta;
  status: EGiftStatus;
  invitations?: [];
}
export interface IGiftCreateModel extends Components.Schemas.GiftDataDto {
  id?: string;
}
export interface IGiftCreateMeta extends IGiftMeta {}
export interface IGiftCollectionParams extends Paths.GiftControllerGetList.QueryParameters {}

export interface IUpdateGiftParams {
  id: string;
  data: IGiftModel;
}

export interface IGiftCollection extends Components.Schemas.GiftCollection {
  meta: Components.Schemas.BaseCollectionMetaResponse;
  data: IGiftModel[];
}

export interface IGiftCreateViewModel {
  type?: EGiftType;

  name?: string;
  email?: string;
  phone?: string;
  currency?: number;
  date?: moment.Moment;
  invites?: IInvitationsFormItem[];

  idea?: string;

  isSendByEmail?: boolean;
  country?: ICountriesCreateModel;
  state?: string;
  city?: string;
  street?: string;
  house?: string;
  zipCode?: string;

  activation?: string;
}

export enum EGiftType {
  Individual = 'individual',
  Request = 'request',
  Collective = 'collective'
}

export enum EGiftStatus {
  Created = 'created',
  Delivered = 'delivered',
  Activated = 'activated',
  InProgress = 'inProgress',
  PaymentAwaiting = 'paymentAwaiting',
  BudgetNotCollected = 'budgetNotCollected',
  Completed = 'completed'
}

export interface IGiftPublicModel extends Components.Schemas.GiftPublicDto {}
export interface IGiftPublicParams extends Paths.GiftControllerGetGiftShort.PathParameters {}

export interface ICheckCodeParams extends Paths.GiftControllerGetGiftByCode.QueryParameters {}

export interface IAcceptGiftParams extends Paths.GiftControllerAcceptGift.PathParameters {}
export interface IRefundGiftParams {
  id: string;
  successCallback?: () => void;
}

export interface IDiscoverGiftParams
  extends Paths.GiftControllerActivateGift.PathParameters,
    Components.Schemas.GiftDiscoverDto {}

export interface ICheckCodeParams extends Paths.GiftControllerGetGiftByCode.QueryParameters {}
