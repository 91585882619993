import axios from 'axios';
import { BaseTransport } from 'common/dto/base.transport';
import {
  ICompanyCollection,
  ICompanyCollectionParams,
  ICompanyTransferHistory,
  ICompanyTransferHistoryParams
} from 'entities/Companies/Companies.models';

export class CompaniesTransport extends BaseTransport<null, ICompanyCollection, null, null, ICompanyCollectionParams> {
  public getTransferHistory = ({ id, ...rest }: ICompanyTransferHistoryParams): Promise<ICompanyTransferHistory> => {
    return axios.get(`${this.path}/${id}/transfer-history`, { params: rest });
  };

  public getSummary = (id: string) => {
    return axios.get(`${this.path}/${id}/summary`);
  };
}
