import { APIProvider, BaseStrategy, Branch, buildCommunication, EActionsTypes, StoreBranch } from '@axmit/redux-communications';
import { BaseTransport } from 'common/dto/base.transport';
import { RequestLoadingHelper } from 'common/helpers';
import { IErrorResponse } from 'common/models/Request.models';
import { ITransactionsCollection, ITransactionsCollectionParams } from 'entities/Transactions/Transactions.models';

const namespace = 'transactions';

export interface ITransactionsConnectedProps {
  transactionsCollection: StoreBranch<ITransactionsCollection>;
  getTransactionsCollection(params: ITransactionsCollectionParams): void;
  clearTransactionsCollection(): void;
}

const transport = new BaseTransport<null, ITransactionsCollection>('transactions');

const collectionApiProvider = [
  new APIProvider<ITransactionsCollection, ITransactionsCollectionParams, IErrorResponse>(
    EActionsTypes.get,
    transport.getCollection,
    {
      preRequestDataMapper: RequestLoadingHelper.setOldData
    }
  )
];

const branches = [new Branch('collection', collectionApiProvider)];

const strategy = new BaseStrategy({
  namespace,
  branches
});

export const communicationTransactions = buildCommunication<ITransactionsConnectedProps>(strategy);
