import { useEffect, useRef } from 'react';
import io from 'socket.io-client';
import { LOCAL_WS_URL } from 'common/config';
import { IChannelsModel, ISocketToggleRoomParams } from 'entities/Messages/Messages.models';

const wsEndpointBase: string = window?.location?.hostname === 'localhost' ? LOCAL_WS_URL : window.location?.origin;
const wsEndpoint: string = `${wsEndpointBase}/chat`;

function useSocket() {
  const { current: socket } = useRef(
    io(wsEndpoint, {
      transports: ['websocket'],
      path: '/api/socket.io'
    })
  );

  useEffect(() => {
    return () => {
      socket?.emit('leaveAll');
      socket?.close();
      socket?.removeAllListeners();
    };
  }, [socket]);

  const toggleRoom = (model: IChannelsModel, isJoin = false) => {
    const userData = model?.userId;
    const clientType = userData ? 'user' : 'client';
    const client = userData || model?.client;

    if (!client) {
      console.error('Bad channel model', model);

      return;
    }

    const params: ISocketToggleRoomParams = { clientType, client };

    socket.emit(isJoin ? 'joinRoom' : 'leaveRoom', params);
  };

  const joinRoom = (model: IChannelsModel) => {
    toggleRoom(model, true);
  };

  const leaveRoom = (model: IChannelsModel) => {
    toggleRoom(model);
  };

  return { socket, joinRoom, leaveRoom };
}

export const SocketHooks = {
  useSocket
};
