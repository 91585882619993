import i18n from 'app/locales/i18n';
import { EMessageType, IChannelsModel } from 'entities/Messages/Messages.models';

const getAvailableChats = (channel: IChannelsModel): EMessageType[] => {
  const result: EMessageType[] = [];
  const unregisteredWithPhone = channel && (channel.type === EMessageType.whatsApp || channel.type === EMessageType.sms);

  if (channel.phoneNumber || unregisteredWithPhone) {
    result.push(EMessageType.whatsApp, EMessageType.sms);
  }

  if (channel.type === EMessageType.messenger) {
    result.push(EMessageType.messenger);
  }

  return result;
};

const getChatHeader = (firstName: string | null, lastName: string | null, clientId?: string | null): string => {
  const hasFullName = firstName && lastName;
  const defaultHeader = `${clientId || ''} (${i18n.t('messages.notRegistered')})`;

  return hasFullName ? `${firstName} ${lastName}` : defaultHeader;
};

export const ChatHelper = { getAvailableChats, getChatHeader };
