import React from 'react';
import { Container } from '@axmit/clp-library/dist/components/Common/Container';
import Col from 'antd/es/grid/col';
import Row from 'antd/es/grid/row';
import { Desktop } from 'common/components';
import { MEDIA_WIDTH_DESKTOP_LG } from 'common/config';

interface IComponentProps {
  contentSlot: JSX.Element | string;
  imageSlot: JSX.Element | string;
}

export const TwoColumnsWithImage: React.FC<IComponentProps> = ({ contentSlot, imageSlot }) => {
  return (
    <Container>
      <Row gutter={40}>
        <Col lg={12}>{contentSlot}</Col>

        <Desktop customWidth={MEDIA_WIDTH_DESKTOP_LG}>
          <Col lg={12}>{imageSlot}</Col>
        </Desktop>
      </Row>
    </Container>
  );
};
